export const CREATE_LEAD = {
  REQUEST: "lead/SET/REQUEST",
  SUCCESS: "lead/SET/SUCCESS",
  FAILURE: "lead/SET/FAILURE"
};

export const GET_LEAD = {
  REQUEST: "lead/GET/REQUEST",
  SUCCESS: "lead/GET/SUCCESS",
  FAILURE: "lead/GET/FAILURE"
};

export const REPRISE_LEAD = {
  REQUEST: "lead/REPRISE/REQUEST",
  SUCCESS: "lead/REPRISE/SUCCESS",
  FAILURE: "lead/REPRISE/FAILURE"
};

export const RESET_REPRISE_LEAD = "lead/REPRISE/RESET";

export const DELETE_LEAD_ID = {
  REQUEST: "lead/DELETE_ID/REQUEST",
  SUCCESS: "lead/DELETE_ID/SUCCESS"
};

export const RESET_LEAD = "lead/RESET";

export const createLead = ({
  assuranceType,
  currentPage,
  creerSimulation,
  clientId,
  emailClient,
  formuleItcRefId,
  id,
  nbAdultes,
  nbJeunes,
  nbSeniors,
  pathologieClientCode,
  telephoneClient,
  typeReseauSoinsRefId
}) => {
  return {
    type: CREATE_LEAD.REQUEST,
    assuranceType,
    currentPage,
    creerSimulation,
    clientId,
    emailClient,
    formuleItcRefId,
    id,
    nbAdultes,
    nbJeunes,
    nbSeniors,
    pathologieClientCode,
    telephoneClient,
    typeReseauSoinsRefId
  };
};

export const repriseLead = withoutApiCall => {
  return {
    type: REPRISE_LEAD.REQUEST,
    withoutApiCall
  };
};

export const getLead = id => {
  return {
    type: GET_LEAD.REQUEST,
    id
  };
};

export const deleteLeadId = () => {
  return {
    type: DELETE_LEAD_ID.REQUEST
  };
};
