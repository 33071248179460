import * as a from "./actions";

const INITIAL_REFERENCE_LIST_STATE = {
  list: [],
  error: false,
  loading: false,
  success: false
};

export function referenceList(state = INITIAL_REFERENCE_LIST_STATE, action) {
  switch (action.type) {
    case a.GET_REFERENCE_LIST.REQUEST:
      return {
        ...state,
        success: false,
        error: false,
        loading: true
      };
    case a.GET_REFERENCE_LIST.SUCCESS:
      return {
        ...state,
        list: action.payload,
        success: true,
        error: false,
        loading: false
      };
    case a.GET_REFERENCE_LIST.FAILURE:
      return {
        ...state,
        success: false,
        error: true, //action.payload,
        loading: false
      };
    case a.RESET_REFERENCE_LIST:
      return INITIAL_REFERENCE_LIST_STATE;
    default:
      return state;
  }
}

export default referenceList;
