import React, { Component } from "react";
import { Trans, withNamespaces } from "react-i18next";
import { compose, bindActionCreators } from "redux";
import { connect } from "react-redux";

// Import media
import theme from "../../theme-style";

// Components
import { StyledNotFoundPage } from "./style";
import LinkButton from "../../components/link-button";
import ScrollToTopOnMount from "../../components/scroll-top-onmount";
import WrapperContent from "../../components/wrapper-content";
import ErrorContainer from "../../components/error-container";
import Title from "../../components/title";
import Paragraph from "../../components/paragraph";

// Store
import {
  resetFullTunnelVariables,
  navigateInto
} from "../../store/tunnel/tunnel/actions";
import Helmet from "react-helmet";

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      resetFullTunnelVariables,
      navigateInto
    },
    dispatch
  )
});

class NotFound extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isErrorPage: props.location.pathname === "/error"
    };
  }

  componentDidCatch(e) {
    this.props.actions.postLog({
      message: "ERREUR PAGE NOT FOUND ::\n",
      level: "warn",
      error: e,
      info: "On est dans la page 404 not found avec une erreur catch : " + e
    });
  }

  componentDidMount() {
    this.props.actions.resetFullTunnelVariables();

    this.props.actions.navigateInto();
  }

  renderNotFound() {
    const { t } = this.props;
    const { isErrorPage } = this.state;
    const { Media404 } = theme.icons.theme;
    if (isErrorPage) return <ErrorContainer />;
    return (
      <WrapperContent>
        <Title noMargin textAlign="center" titleType={1}>
          {t("notFound.title")}
        </Title>
        <Paragraph textAlign="center" className="notFound-intro">
          <Trans i18nKey="notFound.text">
            not found text
            <LinkButton
              config={{
                type: "link",
                style: "noStyle"
              }}
              to={"/"}
            >
              label lien accueil
            </LinkButton>
          </Trans>
        </Paragraph>
        <div className="svg-container">
          <img src={Media404} alt="404" />
        </div>
        <Paragraph textAlign="center" className="notFound-link">
          <LinkButton
            to="/"
            config={{
              type: "link",
              style: "plain"
            }}
          >
            {t("shared.retourAccueil")}
          </LinkButton>
        </Paragraph>
      </WrapperContent>
    );
  }

  render() {
    const { isErrorPage } = this.state;
    return (
      <StyledNotFoundPage id="not-found" className={isErrorPage ? "error" : ""}>
        <Helmet>
          <meta name="prerender-status-code" content="404" />
        </Helmet>
        <ScrollToTopOnMount />
        {this.renderNotFound()}
      </StyledNotFoundPage>
    );
  }
}

export default compose(
  withNamespaces("translations"),
  connect(
    null,
    mapDispatchToProps
  )
)(NotFound);
