import React, { Component } from "react";
import { Spring, animated } from "react-spring";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { compose } from "redux";

// Selectors
import { getMainMenuState } from "../../store/main-menu/selectors";

// Actions
import { openCloseMainMenu } from "../../store/main-menu/actions";

// Import style
import { StyledBurgerMenuIcon } from "./style";

const mapStateToProps = state => ({
  mainMenuState: getMainMenuState(state)
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      openCloseMainMenu
    },
    dispatch
  )
});

class BurgerMenuIcon extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(e) {
    e.preventDefault();
    this.props.actions.openCloseMainMenu(
      !this.props.mainMenuState.isOpened,
      -1
    );
  }

  render() {
    const { mainMenuState } = this.props;
    const { isOpened } = mainMenuState;
    return (
      <StyledBurgerMenuIcon className="burger-menu">
        <Spring
          key={"burger-animation"}
          native
          from={{
            x: 0,
            y: 1
          }}
          to={{
            x: isOpened ? 1 : 0,
            y: isOpened ? 0 : 1
          }}
          config={{ tension: 2000, friction: 100, precision: 1, duration: 275 }}
        >
          {({ x, y }) => {
            return (
              <svg viewBox="0 0 96 96" onClick={this.handleClick}>
                <g
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="6"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <animated.line
                    transform={x.interpolate(
                      value =>
                        `translate(${value * 12}, ${value *
                          -7}) rotate(${value * 45}, 7, 26)`
                    )}
                    x1="7"
                    y1="26"
                    x2="89"
                    y2="26"
                  />
                  <animated.line
                    transform={x.interpolate(
                      value =>
                        `translate(${value * 12}, ${value * 7}) rotate(${value *
                          -45}, 7, 70)`
                    )}
                    x1="7"
                    y1="70"
                    x2="89"
                    y2="70"
                  />
                  <animated.line
                    transform={x.interpolate(
                      value => `translate(${value * -96})`
                    )}
                    style={{ opacity: y }}
                    x1="7"
                    y1="48"
                    x2="89"
                    y2="48"
                  />
                </g>
              </svg>
            );
          }}
        </Spring>
      </StyledBurgerMenuIcon>
    );
  }
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(BurgerMenuIcon);
