import React from "react";
import SvgIcon from "../svg-icons";

const IconBaloonO = () => {
  return (
    <SvgIcon width="17px" height="18px" viewBox="0 0 17 18">
      <g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.97119 5.76897C0.540247 6.76284 0.324219 7.83556 0.324219 8.9877C0.324219 10.1155 0.540247 11.1824 0.97119 12.1876C1.40269 13.1931 1.99378 14.0755 2.74501 14.8355C3.4968 15.5956 4.37175 16.1902 5.36876 16.6191C6.36688 17.0482 7.44535 17.2626 8.60306 17.2626C9.73602 17.2626 10.8081 17.0482 11.8182 16.6191C12.8288 16.1902 13.7154 15.5956 14.4789 14.8355C15.2424 14.0755 15.8404 13.1931 16.2714 12.1876C16.7023 11.1824 16.918 10.1155 16.918 8.9877V0.749268H8.60306C7.44535 0.749268 6.36688 0.964255 5.36876 1.39312C4.37175 1.82199 3.4968 2.41023 2.74501 3.15813C1.99378 3.90602 1.40269 4.77676 0.97119 5.76897ZM8.60306 4.02223H13.6287V8.9877C13.6287 9.67416 13.4994 10.3238 13.2409 10.937C12.982 11.5496 12.625 12.0833 12.1688 12.5368C11.7136 12.9906 11.1776 13.3456 10.5612 13.6037C9.94622 13.8607 9.29257 13.9891 8.60306 13.9891C7.91328 13.9891 7.26658 13.8607 6.66243 13.6037C6.05938 13.3456 5.52946 12.9906 5.07322 12.5368C4.61753 12.0833 4.26054 11.5496 4.0017 10.937C3.74313 10.3238 3.61357 9.67416 3.61357 8.9877C3.61357 8.30123 3.74313 7.65765 4.0017 7.05668C4.26054 6.45627 4.61753 5.9289 5.07322 5.47513C5.52946 5.02191 6.05938 4.66609 6.66243 4.40877C7.26658 4.15117 7.91328 4.02223 8.60306 4.02223Z"
          fill="currentColor"
        />
      </g>
    </SvgIcon>
  );
};

export default IconBaloonO;
