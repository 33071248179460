import React from "react";

// Style
import { StyledHasNoRequiredInfos } from "./style";

// Components
import ErrorContainer from "../../components/error-container";

const HasNoRequiredInfos = () => {
  return (
    <StyledHasNoRequiredInfos>
      <ErrorContainer souscriptionEnCours />
    </StyledHasNoRequiredInfos>
  );
};

export default HasNoRequiredInfos;
