import axios from "axios";
import API from "../../../api";

export async function get({
  mainToken,
  id,
  clientId,
  telephoneClient,
  emailClient,
  nbJeunes,
  nbAdultes,
  nbSeniors,
  typeReseauSoinsRefId,
  formuleItcRefId
}) {
  return new Promise(resolve => {
    const url = API.tunnel.sante.tauxCouverture.main;
    const params = {
      id: id,
      clientId: clientId,
      telephoneClient: telephoneClient,
      emailClient: emailClient,
      nbJeunes: nbJeunes,
      nbAdultes: nbAdultes,
      nbSeniors: nbSeniors,
      typeReseauSoinsRefId: typeReseauSoinsRefId,
      formuleItcRefId: formuleItcRefId
    };
    const request = axios.get(url, {
      params,
      headers: {
        Authorization: `bearer ${mainToken}`
      }
    });
    return request
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        resolve({ error: true, res: err });
      });
  });
}
