export const GET_DATE_EFFET = {
  REQUEST: "dateEffet/GET/REQUEST",
  SUCCESS: "dateEffet/GET/SUCCESS",
  FAILURE: "dateEffet/GET/FAILURE"
};

export const POST_DATE_EFFET = {
  REQUEST: "dateEffet/POST/REQUEST",
  SUCCESS: "dateEffet/POST/SUCCESS",
  FAILURE: "dateEffet/POST/FAILURE"
};

export const RESET_DATE_EFFET_STATUS = "dateEffet/RESET_STATUS";

export const getDateEffet = assuranceType => {
  return {
    type: GET_DATE_EFFET.REQUEST,
    assuranceType
  };
};

export const postDateEffet = ({ dateEffetContrat, assuranceType }) => {
  return {
    type: POST_DATE_EFFET.REQUEST,
    dateEffetContrat,
    assuranceType
  };
};

export const resetDateEffetStatus = () => {
  return {
    type: RESET_DATE_EFFET_STATUS
  };
};
