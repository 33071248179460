import styled from "styled-components";
import { CommonStyledMenuWrapper } from "../../style";

export const StyledMenuCorporate = styled(CommonStyledMenuWrapper)`
  > div {
    .title {
      transition: background-color 200ms linear, border-color 200ms linear;
      + div[role="region"] {
        ul {
          margin-bottom: 25px;
          padding-left: 20px;
          .link {
            color: ${props => props.theme.colors.themeColors.thirdLight};
            height: auto;
            padding-bottom: 10px;
            padding-top: 10px;
            > span {
              align-items: flex-start;
              svg {
                transform: translateY(1px);
              }
              > span {
                align-items: flex-start;
                box-sizing: border-box;
                display: flex;
                flex-flow: column wrap;
                justify-content: flex-start;
                line-height: 1.25;
                width: calc(100% - 16px);
              }
            }
          }
        }
      }
      &[aria-expanded="true"] {
        border-bottom-color: ${props =>
          props.theme.colors.themeColors.white} !important;
      }
      &:first-child {
        border-color: ${props => props.theme.colors.bgColors.altLight};
        border-top-style: solid;
        border-top-width: 15px;
        &[aria-expanded="true"] {
          border-color: ${props => props.theme.colors.themeColors.white};
        }
      }
      &[aria-expanded="true"] {
        background-color: ${props => props.theme.colors.themeColors.white};
        + div[role="region"] {
          background-color: ${props => props.theme.colors.themeColors.white};
        }
      }
      &.no-child {
        background-color: ${props => props.theme.colors.bgColors.altLight};
        &[aria-expanded="true"] {
          background-color: inherit;
        }
      }
    }
  }
  .tous-aide {
    box-sizing: border-box;
    display: flex;
    padding: 0 20px 0 40px;
    .linkButton {
      margin: 10px 0 20px 0;
      padding: 0;
      width: auto;
    }
  }
  .join-us-by-phone {
    align-items: flex-start;
    justify-items: flex-start;
    display: flex;
    flex-flow: row wrap;
    .linkButton {
      color: ${props => props.theme.colors.themeColors.thirdLight};
      font-size: 2.4rem;
      font-weight: ${props => props.theme.fonts.main.variants.bold};
      padding: 0;
      margin: 0;
      span {
        border-bottom: none;
        padding-bottom: 0;
      }
    }
  }
`;
