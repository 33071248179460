// Is user on tunnel
export const isUserOnTunnel = state => state.tunnel.tunnel.isTunnel.status;

// Get step of tunnel
export const getCurrentStep = state => state.tunnel.tunnel.currentStep.id;

// Envoi de la requête sauvegarder tunnel automobile
export const isSaveTunnelAutoLoading = state =>
  state.tunnel.tunnel.saveTunnelAuto.loading;

// Envoi succes sauvegarder tunnel automobile
export const isSaveTunnelAutoSuccessfull = state =>
  state.tunnel.tunnel.saveTunnelAuto.success;

// Erreur de l'envoi de la sauvegarde du tunnel automobile
export const isSaveTunnelAutoOnError = state =>
  state.tunnel.tunnel.saveTunnelAuto.error;

// Get currentSimulationIds
export const getCurrentSimulationIds = state =>
  state.tunnel.tunnel.currentSouscription.ids;

// Get currentSimulation radlad
export const getCurrentSimulationRadlad = state =>
  state.tunnel.tunnel.currentSouscription.radlad;

// Get currentSimulation checking radlad
export const getCurrentSimulationCheckRadlad = state =>
  state.tunnel.tunnel.currentSouscription.needCheckRadlad;

// Show go back
export const isGoBackVisible = state => {
  return state.tunnel.tunnel.showGoBack;
};

// Get all parcours
export const getParcours = state => {
  return state.tunnel.tunnel.parcoursUtilisateur;
};

// Get last item of parcours
export const getLastParcours = state => {
  const parcoursLength = state.tunnel.tunnel.parcoursUtilisateur.length;
  return parcoursLength > 0
    ? state.tunnel.tunnel.parcoursUtilisateur[parcoursLength - 1]
    : null;
};

// Get before last item of parcours
export const getBeforeLastParcours = state => {
  const parcoursLength = state.tunnel.tunnel.parcoursUtilisateur.length;
  return parcoursLength > 1
    ? state.tunnel.tunnel.parcoursUtilisateur[parcoursLength - 2]
    : null;
};

// Get go back in component booleans
export const getBackBoolInComponent = state => {
  return state.tunnel.tunnel.goBack;
};

export const getAssuranceType = state => state.tunnel.tunnel.assuranceType;

export const getCurrentSimulationInputProcess = state =>
  state.tunnel.tunnel.currentSouscription.inputProcess;

export const isIP2Marque = state => {
  const currentRoute = state.router.location.pathname.split("/");
  return (
    currentRoute[currentRoute.length - 1] === "PageMarque" &&
    currentRoute[currentRoute.length - 2] === "InfoVehicule2"
  );
};
