import React, { Component } from "react";
import { withRouter } from "react-router";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { compose } from "redux";
import { Spring, animated } from "react-spring";
// import {
//   disableBodyScroll,
//   enableBodyScroll,
//   clearAllBodyScrollLocks
// } from "body-scroll-lock";

// Selectors
import { getMainMenuState } from "../../../store/main-menu/selectors";
import { isUserOnTunnel } from "../../../store/tunnel/tunnel/selectors";
import { getUrlEspaceClient } from "../../../store/information/selectors";
// Actions
import { openCloseMainMenu } from "../../../store/main-menu/actions";

// Import style
import { StyledMainMenu } from "./style";

// Import components
import MenuCorporate from "./components/corporate";
import MenuTunnel from "./components/tunnel";

const mapStateToProps = state => ({
  mainMenuState: getMainMenuState(state),
  isUserOnTunnel: isUserOnTunnel(state),
  urlEspaceClient: getUrlEspaceClient(state)
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      openCloseMainMenu
    },
    dispatch
  )
});

class MainMenu extends Component {
  constructor(props) {
    super(props);
    this.targetRef = React.createRef();
    this.targetElement = null;
  }

  componentDidMount() {
    this.targetElement = this.targetRef.current;
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.props.actions.openCloseMainMenu(false, -1);
    }
  }

  menuRender() {
    if (this.props.isUserOnTunnel) return <MenuTunnel />;
    return (
      <MenuCorporate
        urlEspaceClient={this.props.urlEspaceClient}
        helpArticles={this.props.helpArticles}
      />
    );
  }

  render() {
    const { mainMenuState } = this.props;
    const { isOpened } = mainMenuState;
    return (
      <Spring
        native
        key={"main-menu"}
        config={{ tension: 120, friction: 14, precision: 1 }}
        from={{ h: 0 }}
        to={{ h: isOpened ? "auto" : 0 }}
      >
        {({ h }) => {
          return (
            <StyledMainMenu ref={this.targetElement} heightValue={h.getValue()}>
              <animated.div
                style={{
                  height: h,
                  overflow: "hidden"
                }}
                className="menu-container"
              >
                {this.menuRender()}
              </animated.div>
            </StyledMainMenu>
          );
        }}
      </Spring>
    );
  }
}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(MainMenu);
