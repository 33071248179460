import React, { Component } from "react";
import { withNamespaces } from "react-i18next";
import slug from "limax";
import { compose, bindActionCreators } from "redux";
import { connect } from "react-redux";

// Import layout
import { StyledSimpleContentPage } from "./style";

// Import components
import Title from "../../components/title";
import ScrollToTopOnMount from "../../components/scroll-top-onmount";
import WrapperContent from "../../components/wrapper-content";
import PageHeader from "../../components/page-header";

// Store
import { navigateInto } from "../../store/tunnel/tunnel/actions";

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      navigateInto
    },
    dispatch
  )
});

class SimpleContent extends Component {
  componentDidMount() {
    this.props.actions.navigateInto();
  }

  render() {
    const { t, siteURL, siteLangue, children, ...rest } = this.props;

    return (
      <StyledSimpleContentPage
        siteURL={siteURL}
        siteLangue={siteLangue}
        {...rest}
        id={slug(rest.titlePage)}
      >
        <ScrollToTopOnMount />
        <section>
          <PageHeader page="category">
            <Title noMargin textAlign="center" titleType={1}>
              {rest.titlePage}
            </Title>
          </PageHeader>
          <WrapperContent>
            <article className="bodyContent">{children}</article>
          </WrapperContent>
        </section>
      </StyledSimpleContentPage>
    );
  }
}

export default compose(
  withNamespaces("translations"),
  connect(
    null,
    mapDispatchToProps
  )
)(SimpleContent);
