import queryString from "query-string";
import appConstants from "../../configuration/constants";

function findIn(params, urlId) {
  return Object.keys(params).find(x => {
    if (x === urlId) return 1;
    return 0;
  });
}

export const getRouterSearch = state => {
  const params = queryString.parse(state.router.location.search);
  let count = 0;
  for (let urlId of appConstants.searchURLIds) {
    count += findIn(params, urlId);
  }
  if (!params || Object.keys(params).length === 0 || count === 0) {
    return null;
  } else {
    return params;
  }
};
