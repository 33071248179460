import axios from "axios";
import API from "../api";

export async function get(mainToken, codeReferentiel, refParentId) {
  const params = {
    code: codeReferentiel,
    parentId: refParentId
  };
  return new Promise(resolve => {
    const url = API.referenceList.main;
    const request = axios.get(url, {
      params,
      headers: {
        Authorization: `bearer ${mainToken}`
      }
    });

    return request
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        resolve({ error: true, res: err });
      });
  });
}
