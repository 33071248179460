import React from "react";
import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
//importer selector charteGraphique
import { getTheme } from "../../store/information/selectors";

// Import brand logos
import BaloonAssurance from "../../components/icons/baloon-assurance";

import logoAxaAssurance from "../../assets/logo_axa.png";
import logoOlea from "../../assets/logo_olea.png";

// Import style
import { StyledLogo } from "./style";

const mapStateToProps = state => ({
  charteGraphique: getTheme(state)
});

const BrandLogo = ({ charte }) => {
  if (charte === "axa") {
    return <img src={logoAxaAssurance} alt="logo axa assurance" />;
  } else if (charte === "olea") {
    return <img src={logoOlea} alt="logo Olea Insurance solutions" />;
  } else {
    return <BaloonAssurance />;
  }
};

class Logo extends React.Component {
  render() {
    const { onlyLogo, url, charteGraphique } = this.props;
    if (onlyLogo)
      return (
        <div className="only-logo">
          <BrandLogo charte={charteGraphique} />
        </div>
      );
    return (
      <StyledLogo
        config={{
          type: "link",
          style: "noStyle"
        }}
        to={url ? url : "/"}
      >
        <BrandLogo charte={charteGraphique} />
      </StyledLogo>
    );
  }
}

export default compose(
  withNamespaces("translations"),
  connect(mapStateToProps)
)(Logo);
