export const OPENCLOSE_MAINMENU = {
  REQUEST: "mainMenu/SET/REQUEST",
  SUCCESS: "mainMenu/SET/SUCCESS",
  FAILURE: "mainMenu/SET/FAILURE"
};

export const openCloseMainMenu = (isOpened, openedName) => {
  return {
    type: OPENCLOSE_MAINMENU.REQUEST,
    isOpened,
    openedName
  };
};
