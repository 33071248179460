import styled from "styled-components";

export const StyledPhoneInput = styled.div`
  .input-wrapper {
    align-items: center;
    display: flex;
    .indicator {
      align-items: center;
      background-color: ${props => props.theme.colors.themeColors.white};
      border-bottom: 1px solid
        ${props => props.theme.colors.themeColors.lightgrey};
      border-left: 1px solid
        ${props => props.theme.colors.themeColors.lightgrey};
      border-top: 1px solid ${props => props.theme.colors.themeColors.lightgrey};
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      box-sizing: border-box;
      display: flex;
      height: 50px;
      padding-left: 15px;
      padding-right: 5px;
    }
    input {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-left: none;
      flex: 1 1 auto;
      padding-left: 0;
      padding-right: 0;
    }
  }
`;
