import { createStore, applyMiddleware, compose } from "redux";
import { connectRouter, routerMiddleware } from "connected-react-router";
import { END } from "redux-saga";
import { createBrowserHistory } from "history";
import { sagaMiddleware } from "./sagas";
import rootReducer from "./rootReducer";

export default () => {
  // Create a history
  const history = createBrowserHistory();
  const enhancers = [];

  // Dev tools are helpful
  if (process.env.NODE_ENV === "development") {
    // const devToolsExtension = window.devToolsExtension;
    const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;

    if (typeof devToolsExtension === "function") {
      enhancers.push(devToolsExtension());
    }
  }

  const middleware = [sagaMiddleware, routerMiddleware(history)];
  const composedEnhancers = compose(
    applyMiddleware(...middleware),
    ...enhancers
  );

  // Do we have preloaded state available? Great, save it.
  const initialState = window.__PRELOADED_STATE__;

  // Delete it once we have it stored in a variable
  delete window.__PRELOADED_STATE__;

  // Create the store
  const store = createStore(
    connectRouter(history)(rootReducer),
    initialState,
    composedEnhancers
  );

  store.runSaga = sagaMiddleware.run;
  store.close = () => store.dispatch(END);

  return {
    store,
    history
  };
};
