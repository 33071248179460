import styled, { css } from "styled-components";

export const StyledRadioCardList = styled.div`
  .radio-choices {
    display: flex;
    flex-direction: ${props =>
      typeof props.oneLine !== "undefined" && props.oneLine
        ? "row"
        : props.listDirection};
    margin-bottom: 25px;
    margin-left: auto;
    margin-right: auto;
    max-width: 345px;
  }
  ${props => {
    if (typeof props.oneLine !== "undefined" && props.oneLine) {
      return css`
        .choice {
          &:first-of-type {
            margin-right: 35px;
          }
        }
      `;
    }
  }};
  --mdc-theme-primary: ${props => props.theme.colors.themeColors.thirdLight};
  --mdc-theme-secondary: ${props => props.theme.colors.themeColors.thirdLight};
  --mdc-theme-on-primary: ${props => props.theme.colors.themeColors.thirdLight};
  --mdc-theme-on-secondary: ${props =>
    props.theme.colors.themeColors.thirdLight};
`;
