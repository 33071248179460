export const SIGNATURE = {
  REQUEST: "signature/post/REQUEST",
  SUCCESS: "signature/post/SUCCESS",
  FAILURE: "signature/post/FAILURE"
};

export const GET_REPRISE_SIGNATURE_INFORMATION = {
  REQUEST: "reprise_signature/REPRISE_SIGNATURE_INFORMATION/GET/REQUEST",
  SUCCESS: "reprise_signature/REPRISE_SIGNATURE_INFORMATION/GET/SUCCESS",
  FAILURE: "reprise_signature/REPRISE_SIGNATURE_INFORMATION/GET/FAILURE"
};

export const IS_SIGNATURE = {
  REQUEST: "isSignature/GET/REQUEST",
  SUCCESS: "isSignature/GET/SUCCESS",
  FAILURE: "isSignature/GET/FAILURE"
};

export const ATTESTATION = {
  REQUEST: "attestation/GET/REQUEST",
  SUCCESS: "attestation/GET/SUCCESS",
  FAILURE: "attestation/GET/FAILURE"
};

export const postSignature = assuranceType => {
  return {
    type: SIGNATURE.REQUEST,
    assuranceType
  };
};

export const isSignature = () => {
  return {
    type: IS_SIGNATURE.REQUEST
  };
};

export const getAttestation = () => {
  return {
    type: ATTESTATION.REQUEST
  };
};
