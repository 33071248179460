import React, { Component } from "react";
import { withRouter } from "react-router";
import { withNamespaces } from "react-i18next";
import { compose } from "redux";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import disableScroll from "disable-scroll";

// Store
import {
  setIsTunnel,
  setAssuranceType
} from "../../store/tunnel/tunnel/actions";
import { openCloseModalSaveTunnelAuto } from "../../store/modal/actions";
import { getBranches } from "../../store/information/selectors";
import {
  getPaiementId,
  getInfosPaiement
} from "../../store/tunnel/common/paiement/selectors";

// Import layout
import { StyledLayoutTunnel } from "./style";

const mapStateToProps = state => ({
  branches: getBranches(state),
  paiementId: getPaiementId(state),
  infosPaiement: getInfosPaiement(state)
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      setIsTunnel,
      openCloseModalSaveTunnelAuto,
      setAssuranceType
    },
    dispatch
  )
});

class LayoutTunnel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resetContentPopin: false,
      popinFromEvent: false,
      assuranceType: props.match.params.assuranceType
    };
    this.onClosePopin = this.onClosePopin.bind(this);
    this.onBlockScroll = this.onBlockScroll.bind(this);
    this.onLeaveTunnel = this.onLeaveTunnel.bind(this);
  }

  onBlockScroll(isLoading) {
    if (isLoading) {
      disableScroll.on();
    } else {
      disableScroll.off();
    }
  }

  onClosePopin(e) {
    e.preventDefault();
    this.setState({
      resetContentPopin: !this.state.resetContentPopin,
      popinFromEvent: false
    });
    this.props.actions.openCloseModalSaveTunnelAuto(!this.props.modalOpened);
  }

  onLeaveTunnel(e) {
    this.setState(
      {
        popinFromEvent: true
      },
      () => {
        this.props.actions.openCloseModalSaveTunnelAuto(true);
      }
    );
    const text = "";
    e.returnValue = text;
    return text;
  }

  componentDidMount() {
    const { actions } = this.props;
    actions.setAssuranceType(this.state.assuranceType);
    actions.setIsTunnel(true);
    const { REACT_APP_LOCALE_AS_PROD } = process.env;
    if (REACT_APP_LOCALE_AS_PROD) {
    }
  }

  componentDidUpdate(prevProps) {
    const { paiementId, infosPaiement } = this.props;
    if (paiementId !== prevProps.paiementId && paiementId !== null) {
      //addGoogleEvent
    }
    if (infosPaiement !== prevProps.infosPaiement) {
      if (infosPaiement.formule !== null) {
      }
    }
  }
  componentWillUnmount() {
    //TODO
  }

  render() {
    const { t } = this.props;
    const { assuranceType } = this.state;
    return (
      <StyledLayoutTunnel
        titlePage={`${t("shared.souscription")} ${t(
          `produit.${assuranceType}`
        )}`}
        id="main-tunnel"
        {...this.props}
      >
        {this.props.children}
      </StyledLayoutTunnel>
    );
  }
}

export default compose(
  withRouter,
  withNamespaces("translations"),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(LayoutTunnel);
