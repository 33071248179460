// isMainMenuVisible
export const isMainMenuVisible = state => state.layoutsShowHide.showMainMenu;

// Get url header only logo
export const getHeaderOnlyLogoURL = state =>
  state.layoutsShowHide.showHeaderOnlyLogoURL;

// isHeaderOnyLogo
export const isHeaderOnyLogo = state =>
  state.layoutsShowHide.showHeaderOnlyLogo;
