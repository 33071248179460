import styled from "styled-components";

export const StyledErrorContainer = styled.div`
  .error {
    margin-bottom: 30px;
  }
  .wrapper-content {
    align-items: center;
    display: flex;
    flex-direction: column;
  }
  img {
    margin-bottom: 30px;
    margin-top: 30px;
  }
  > div {
    .wrapper-content {
      padding-top: 30px;
    }
  }
`;
