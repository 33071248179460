import React, { Component } from "react";
import { withNamespaces } from "react-i18next";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { compose } from "redux";
import Paragraph from "../paragraph";
import Title from "../title";

// Store
import { openCloseModalEtreRappelle } from "../../store/modal/actions";
import { isMarqueBlanche } from "../../store/information/selectors";
import { openCloseMainMenu } from "../../store/main-menu/actions";
import {
  getContactInfos,
  getUrlEspaceClient
} from "../../store/information/selectors";

import { getCountry } from "../../store/information/selectors";

// Import components
import WrapperContent from "../../components/wrapper-content";
import LinkButton from "../../components/link-button";

// Import icons
import BaloonAssurance from "../../components/icons/baloon-assurance";
import { Car, MapMarker, Medkit, Phone, Plane } from "styled-icons/fa-solid";
import { Envelope } from "styled-icons/fa-regular/Envelope";
import {
  Facebook,
  Twitter,
  Linkedin,
  Instagram,
  Youtube
} from "styled-icons/fa-brands";

// Import style
import { StyledFooter } from "./style";

const copyrightYear = startYear => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  if (startYear !== currentYear && typeof startYear !== "undefined")
    return `${startYear} - ${currentYear}`;
  return currentYear;
};

const mapStateToProps = state => ({
  contactInfos: getContactInfos(state),
  urlEspaceClient: getUrlEspaceClient(state),
  isMarqueBlanche: isMarqueBlanche(state),
  pays: getCountry(state)
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      openCloseModalEtreRappelle,
      openCloseMainMenu
    },
    dispatch
  )
});

class Footer extends Component {
  constructor(props) {
    super(props);
    this.openModalEtreRappelle = this.openModalEtreRappelle.bind(this);
    this.openMenuToHelp = this.openMenuToHelp.bind(this);
    this.onClickPhoneNumber = this.onClickPhoneNumber.bind(this);
  }

  openModalEtreRappelle(e) {
    e.preventDefault();
    this.props.actions.openCloseModalEtreRappelle(true);
  }

  openMenuToHelp(e) {
    e.preventDefault();
    this.props.actions.openCloseMainMenu(true, 1);
  }

  onClickPhoneNumber = (e, phone) => {
    e.preventDefault();

    window.open(phone);
  };

  render() {
    const { t, contactInfos, urlEspaceClient, isMarqueBlanche } = this.props;
    const { adresse, telephoneFixe, email, socialNetworks } = contactInfos;
    const {
      facebook: facebookUrl,
      twitter: twitterUrl,
      linkedin: linkedinUrl,
      instagram: instagramUrl,
      youtube: youtubeUrl
    } = socialNetworks;

    const { pays } = this.props;

    if (isMarqueBlanche) return null;

    const voyageUrl = window.location.host.includes("ci")
      ? `${process.env.REACT_APP_VOYAGE_URL}`
      : "/assurance/voyage";

    return (
      <StyledFooter>
        <WrapperContent>
          <div className="navigationFooter">
            <div>
              <div>
                <Title titleType={4}>{t("shared.contact")}</Title>
                <ul>
                  {telephoneFixe !== null && (
                    <li>
                      <Paragraph noMargin size="small" className="telephone">
                        <LinkButton
                          onClick={e =>
                            this.onClickPhoneNumber(
                              e,
                              `tel:${telephoneFixe.value || ""}`
                            )
                          }
                          config={{ type: "button", style: "noStyle" }}
                          className="icon-container"
                        >
                          <Phone size={16} />
                          {telephoneFixe.label || ""}
                        </LinkButton>
                      </Paragraph>
                    </li>
                  )}
                  <li>
                    <Paragraph noMargin size="small">
                      <LinkButton
                        to={`mailto:${email || ""}`}
                        config={{ type: "link", style: "noStyle" }}
                        className="icon-container"
                      >
                        <Envelope size={16} />
                        {email || ""}
                      </LinkButton>
                    </Paragraph>
                  </li>
                  <li className="icon-container">
                    <MapMarker size={16} />
                    <Paragraph size="small" noMargin>
                      {adresse || ""}
                    </Paragraph>
                  </li>
                  <li>
                    <Paragraph>
                      <LinkButton
                        to={contactInfos.localisation}
                        target="_blank"
                        config={{ type: "link", style: "noStyle" }}
                        className="icon-container"
                      >
                        cliquer ici pour nous géolocaliser
                      </LinkButton>
                    </Paragraph>
                  </li>
                </ul>
              </div>
              <div className="social-networks">
                <Title titleType={4}>{t("shared.suivezNous")}</Title>
                <ul>
                  <li>
                    <Paragraph noMargin size="small">
                      <LinkButton
                        to={facebookUrl || ""}
                        config={{ type: "link", style: "noStyle" }}
                      >
                        <Facebook size={20} />
                      </LinkButton>
                    </Paragraph>
                  </li>
                  <li>
                    <Paragraph noMargin size="small">
                      <LinkButton
                        to={twitterUrl || ""}
                        config={{ type: "link", style: "noStyle" }}
                      >
                        <Twitter size={20} />
                      </LinkButton>
                    </Paragraph>
                  </li>
                  {pays != "ne" && (
                    <li>
                      <Paragraph noMargin size="small">
                        <LinkButton
                          to={linkedinUrl || ""}
                          config={{ type: "link", style: "noStyle" }}
                        >
                          <Linkedin size={20} />
                        </LinkButton>
                      </Paragraph>
                    </li>
                  )}
                  <li>
                    <Paragraph noMargin size="small">
                      <LinkButton
                        to={instagramUrl || ""}
                        config={{ type: "link", style: "noStyle" }}
                      >
                        <Instagram size={20} />
                      </LinkButton>
                    </Paragraph>
                  </li>
                  <li>
                    <Paragraph noMargin size="small">
                      <LinkButton
                        to={youtubeUrl || ""}
                        config={{ type: "link", style: "noStyle" }}
                      >
                        <Youtube size={20} />
                      </LinkButton>
                    </Paragraph>
                  </li>
                </ul>
              </div>
            </div>
            <div>
              <div>
                <BaloonAssurance isWhite />
              </div>
              <div>
                <Title titleType={4}>{t("produit.assurances")}</Title>
                <ul>
                  <li>
                    <Paragraph noMargin size="small">
                      <LinkButton
                        to="/assurance/automobile"
                        config={{ type: "link", style: "noStyle" }}
                        className="icon-container"
                      >
                        <Car size={16} />
                        {` `}
                        {t("produit.automobile")}
                      </LinkButton>
                    </Paragraph>
                  </li>
                  <li>
                    <Paragraph noMargin size="small">
                      <LinkButton
                        to="/assurance/sante"
                        config={{ type: "link", style: "noStyle" }}
                        className="icon-container"
                      >
                        <Medkit size={16} />
                        {` `}
                        {t("produit.sante")}
                      </LinkButton>
                    </Paragraph>
                  </li>
                  <li>
                    <Paragraph noMargin size="small">
                      <LinkButton
                        to={voyageUrl}
                        config={{ type: "link", style: "noStyle" }}
                        className="icon-container"
                      >
                        <Plane size={16} />
                        {` `}
                        {t("produit.voyage")}
                      </LinkButton>
                    </Paragraph>
                  </li>
                </ul>
              </div>
              <div>
                <Title titleType={4}>{t("shared.services")}</Title>
                <ul>
                  <li>
                    <Paragraph noMargin size="small">
                      <span
                        className="linkButton"
                        onClick={e => this.openModalEtreRappelle(e)}
                      >
                        {t("contact.etreAppele")}
                      </span>
                    </Paragraph>
                  </li>
                  <li>
                    <Paragraph noMargin size="small">
                      <LinkButton
                        to={urlEspaceClient}
                        config={{ type: "link", style: "noStyle" }}
                      >
                        {t("shared.espacePersonnel")}
                      </LinkButton>
                    </Paragraph>
                  </li>
                </ul>
              </div>
              <div className="corporate">
                <Title titleType={4}>{t("global.baloon")}</Title>
                <ul>
                  {pays != "ne" && (
                    <li>
                      <Paragraph noMargin size="small">
                        <LinkButton
                          to="https://www.baloon.africa"
                          config={{ type: "link", style: "noStyle" }}
                        >
                          {t("global.baloonAfrica")}
                        </LinkButton>
                      </Paragraph>
                    </li>
                  )}

                  <li>
                    <Paragraph noMargin size="small">
                      <LinkButton
                        to="https://www.assurancepourtous.africa"
                        config={{ type: "link", style: "noStyle" }}
                      >
                        {t("global.assurancePourTous")}
                      </LinkButton>
                    </Paragraph>
                  </li>
                  <li>
                    <Paragraph noMargin size="small">
                      <LinkButton
                        to="/actualite"
                        config={{ type: "link", style: "noStyle" }}
                      >
                        {t("shared.actualite.long")}
                      </LinkButton>
                    </Paragraph>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="institutionnel">
            <BaloonAssurance isWhite />
            <nav>
              <ul>
                <li>
                  <Paragraph noMargin size="small">
                    <LinkButton
                      config={{
                        type: "link",
                        style: "noStyle"
                      }}
                      to="/politique-confidentialite"
                    >
                      {t("legal.link.label")}
                    </LinkButton>
                  </Paragraph>
                </li>
                <li>
                  <Paragraph noMargin size="small">
                    <LinkButton
                      config={{
                        type: "link",
                        style: "noStyle"
                      }}
                      to="/conditions-generales"
                    >
                      {t("conditions.link.label")}
                    </LinkButton>
                  </Paragraph>
                </li>
              </ul>
              <Paragraph className="right" noMargin size="small">
                &copy; {copyrightYear()} - {t("shared.tousDroitsReserves")}
              </Paragraph>
            </nav>
          </div>
        </WrapperContent>
      </StyledFooter>
    );
  }
}

export default compose(
  withNamespaces("translations"),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(Footer);
