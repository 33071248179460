import { call, all, put, takeLatest, select, delay } from "redux-saga/effects";
import appConstants from "../../../../configuration/constants";
import * as a from "./actions";

// Import selects
import * as informationSelectors from "../../../information/selectors";
import * as tunnelSelectors from "../../tunnel/selectors";
import * as loggerSagas from "../../../logger/saga";

import * as paiementService from "./services";
import { addEventGoogle } from "../../../../helpers/tags/tagsHelper";

function* getPaiementIntra({
  paiementId,
  nbTry,
  delayTry,
  tryCount,
  fromEchecPage
}) {
  try {
    const mainToken = yield select(informationSelectors.getMainToken);
    const verifierOperateur = false;
    const paiementStatus = yield call(
      paiementService.getIntra,
      mainToken,
      verifierOperateur,
      paiementId
    );
    const timer = delayTry * 1000;
    if (tryCount >= 0) {
      yield delay(timer);
    }
    if (!paiementStatus.error) {
      if (tryCount !== null || tryCount < nbTry) {
        if (
          paiementStatus.statut === "AttenteConfirmationClient" &&
          !fromEchecPage
        ) {
          yield put({
            type: a.PAIEMENT_STATUS_INTRA.REQUEST,
            paiementId,
            nbTry,
            delayTry,
            tryCount: tryCount
          });
        } else {
          yield put({
            type: a.PAIEMENT_STATUS_INTRA.SUCCESS,
            payload: paiementStatus
          });
        }
      } else if (paiementStatus.status === 400) {
        yield put({
          type: a.PAIEMENT_STATUS_INTRA.FAILURE,
          payload: false
        });
      } else {
        const errorRes = paiementStatus.res.response;
        if (errorRes.request.status !== 400) {
          yield call(loggerSagas.postLogErrorApi, {
            sagaInit: "getPaiementIntra",
            apiResponseUrl: errorRes.request.responseURL,
            statusResponse: errorRes.request.status,
            dataResponse: errorRes.data,
            token: mainToken,
            params: {
              verifierOperateur,
              paiementId
            }
          });
        }
        yield put({
          type: a.PAIEMENT_STATUS_INTRA.FAILURE,
          payload: true
        });
      }
    } else {
      const errorRes = paiementStatus.res.response;
      if (errorRes.request.status !== 400) {
        yield call(loggerSagas.postLogErrorApi, {
          sagaInit: "getPaiementIntra",
          apiResponseUrl: errorRes.request.responseURL,
          statusResponse: errorRes.request.status,
          dataResponse: errorRes.data,
          token: mainToken,
          params: {
            verifierOperateur,
            paiementId
          }
        });
      }
      yield put({
        type: a.PAIEMENT_STATUS_INTRA.FAILURE,
        payload: true
      });
    }
  } catch (e) {}
}

function* postPaiement({ assuranceType, operateurPaiement, telephone }) {
  try {
    const mainToken = yield select(informationSelectors.getMainToken);
    const ids = yield select(tunnelSelectors.getCurrentSimulationIds);
    const quittanceId = ids.quittanceId;
    const urlSucces = `${
      appConstants.websiteUrl
    }/assurance-${assuranceType}/paiement/succes`;
    const urlEchec = `${
      appConstants.websiteUrl
    }/assurance-${assuranceType}/paiement/echec`;
    const paiement = yield call(
      paiementService.post,
      mainToken,
      quittanceId,
      operateurPaiement,
      telephone,
      urlSucces,
      urlEchec
    );
    if (!paiement.error) {
      yield put({
        type: a.POST_PAIEMENT.SUCCESS,
        payload: paiement
      });
      const paiementId = paiement.id;
      sessionStorage.setItem("paiementId", JSON.stringify(paiementId));
      if (paiement.urlRedirection === null) {
        const operateurConfig = yield select(
          informationSelectors.getOperateurConfig,
          operateurPaiement
        );
        const tryCount = 0;
        const nbTry = operateurConfig.nbTry;
        const delayTry = operateurConfig.delayTry;
        const data = {
          paiementId,
          nbTry,
          delayTry,
          tryCount
        };
        yield call(getPaiementIntra, data);
      }
    } else {
      const errorRes = paiement.res.response;
      if (errorRes.request.status !== 400) {
        yield call(loggerSagas.postLogErrorApi, {
          sagaInit: "postPaiement",
          apiResponseUrl: errorRes.request.responseURL,
          statusResponse: errorRes.request.status,
          dataResponse: errorRes.data,
          token: mainToken,
          params: {
            quittanceId,
            operateurPaiement,
            telephone,
            urlSucces,
            urlEchec
          }
        });
      }
      yield put({
        type: a.POST_PAIEMENT.FAILURE,
        payload: errorRes.data
      });
    }
  } catch (e) {}
}

function* rappelPaiement() {
  try {
    const mainToken = yield select(informationSelectors.getMainToken);
    const ids = yield select(tunnelSelectors.getCurrentSimulationIds);
    const quittanceId = ids.quittanceId;
    let rappelPaiementRes;
    if (quittanceId !== null) {
      rappelPaiementRes = yield call(
        paiementService.rappel,
        mainToken,
        quittanceId
      );
    } else {
      yield put({
        type: a.RAPPEL_PAIEMENT.FAILURE,
        payload: true
      });
    }

    if (!rappelPaiementRes.error) {
      yield put({
        type: a.RAPPEL_PAIEMENT.SUCCESS,
        payload: rappelPaiementRes
      });
    } else {
      const errorRes = rappelPaiementRes.res.response;
      if (errorRes.request.status !== 400) {
        yield call(loggerSagas.postLogErrorApi, {
          sagaInit: "rappelPaiement",
          apiResponseUrl: errorRes.request.responseURL,
          statusResponse: errorRes.request.status,
          dataResponse: errorRes.data,
          token: mainToken,
          params: {
            quittanceId
          }
        });
      }
      yield put({
        type: a.RAPPEL_PAIEMENT.FAILURE,
        payload: true
      });
    }
  } catch (e) {}
}

function* getInfosPaiement(paiementId) {
  try {
    const mainToken = yield select(informationSelectors.getMainToken);

    //yield select paiement Id
    const result = yield call(
      paiementService.getInfosPaiement,
      mainToken,
      paiementId.paiementId
    );
    if (result !== null && typeof result !== "undefined") {
      yield put({
        type: a.GET_INFOS_PAIEMENT.SUCCESS,
        payload: result
      });
      if (process.env.REACT_APP_LOCALE_AS_PROD) {
        addEventGoogle(
          result.montant,
          result.codeFormule,
          result.acteurCommercialCode
        );
      }
      //addEventFacebook(result.montant, result.deviseCode)
    } else {
      yield put({
        type: a.GET_INFOS_PAIEMENT.FAILURE
      });
    }
  } catch (e) {}
}

export default all([
  takeLatest(a.PAIEMENT_STATUS_INTRA.REQUEST, getPaiementIntra),
  takeLatest(a.POST_PAIEMENT.REQUEST, postPaiement),
  takeLatest(a.RAPPEL_PAIEMENT.REQUEST, rappelPaiement),
  takeLatest(a.GET_INFOS_PAIEMENT.REQUEST, getInfosPaiement)
]);
