import styled, { css } from "styled-components";
import { paragraphDefaultStyle } from "../../../../../paragraph";

export const StyledRadioCard = styled.div`
  *:not(label) {
    ${props =>
      props.checked
        ? `color: ${props.theme.colors.themeColors.thirdLight} !important`
        : ""};
  }
  &.is-paiement {
    box-sizing: border-box;
    padding-right: 15px;
    .mdc-form-field {
      width: calc(100% - 42px);
      box-sizing: border-box;
    }
    img {
      border-radius: 50%;
      display: block;
      height: 42px;
      width: 42px;
    }
  }
  &.isOnWarning .mdc-radio__outer-circle {
    border-color: ${props =>
      props.theme.colors.themeColors.altLight} !important;
  }
  label {
    box-sizing: border-box;
    ${props => {
      return paragraphDefaultStyle("radioList", props.checked);
    }};
    margin-bottom: ${props => (props.listDirection === "row" ? "7px" : 0)};
    padding: ${props =>
      props.listDirection === "row" ? "0 10px" : 0} !important;
    text-align: ${props => (props.listDirection === "row" ? "center" : "left")};
    width: 100%;
  }
  .mdc-form-field {
    width: 100%;
  }
  input {
    width: 50vh;
  }
  ${props => {
    // if not one line
    if (typeof props.oneLine === "undefined" || !props.oneLine) {
      return css`
        align-items: center;
        border-radius: 5px;
        box-shadow: 0 0 12px ${props => props.theme.colors.bgColors.regular};
        box-sizing: border-box;
        border: 1px solid
          ${props =>
            props.checked
              ? props.theme.colors.themeColors.thirdLight
              : props.theme.colors.themeColors.white};
        background-color: ${props =>
          props.checked
            ? props.theme.colors.themeColors.thirdUltraLight
            : props.theme.colors.themeColors.white};
        display: flex;
        flex: 1;
        flex-flow: ${props =>
            props.listDirection === "row" ? "column" : "row"}
          nowrap;
        ${props =>
          props.listDirection === "row"
            ? "margin-right: 10px;"
            : "margin-bottom: 10px;"};
        padding: ${props => (props.listDirection === "row" ? "0 0 10px 0" : 0)};
        &:not(.img-content) {
          width: 100%;
        }
        &.img-content {
          &:not(.is-paiement) {
            width: calc((100% - 10px) / 2);
          }
        }
        .icon {
          align-items: center;
          box-sizing: border-box;
          display: flex;
          flex: 1 1 auto;
          padding-left: 20px;
          padding-right: 20px;
          max-width: 100%;
          > img,
          > svg {
            max-width: 85px;
          }
        }
        &:last-of-type {
          ${props =>
            props.listDirection === "row"
              ? "margin-right: 0;"
              : "margin-bottom: 0;"};
        }
        .mdc-form-field {
          align-items: center;
          box-sizing: border-box;
          display: flex;
          flex-direction: ${props =>
            props.listDirection === "row" ? "column-reverse" : "row"};
          margin-top: ${props => (props.listDirection === "row" ? "auto" : 0)};
          padding: ${props =>
            props.listDirection === "row" ? "0 10px" : "14px 20px 14px 10px"};
          text-align: center;
        }
        .mobile-paiement {
          margin-left: auto;
        }
      `;
    }
  }};
`;
