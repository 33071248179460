export const GET_USER_BALOONPRO_INFO = {
  REQUEST: "userBaloonpro/GET/REQUEST",
  SUCCESS: "userBaloonpro/GET/SUCCESS",
  FAILURE: "userBaloonpro/GET/FAILURE"
};

export const getUserBaloonProInformations = (assuranceType, guid) => {
  return {
    type: GET_USER_BALOONPRO_INFO.REQUEST,
    assuranceType,
    guid
  };
};
