import * as a from "./actions";

import appConstants from "../../../configuration/constants";

const radladDocuments = () => {
  const radlad = {};
  const documents = [];
  appConstants.allowedTunnels.map(tunnel => {
    appConstants.uploadableDocuments[tunnel].map(document => {
      if (documents.indexOf(document) < 0) {
        documents.push(document);
      }
      return false;
    });
    return false;
  });
  documents.map(document => {
    radlad[document] = false;
    return false;
  });
  return radlad;
};

const INITIAL_STATE = {
  isTunnel: {
    loading: false,
    status: false
  },
  currentStep: {
    loading: false,
    id: 0
  },
  currentSouscription: {
    ids: {
      simulationId: null,
      risqueId: null,
      leadId: null,
      versionContratId: null,
      contratId: null,
      quittanceId: null
    },
    radlad: {
      ...radladDocuments()
    },
    needCheckRadlad: {
      ...radladDocuments()
    },
    inputProcess: {
      name: null,
      id: null
    }
  },
  showGoBack: true,
  parcoursUtilisateur: [],
  assuranceType: null
};

export function tunnel(state = INITIAL_STATE, action) {
  switch (action.type) {
    /* IS TUNNEL */
    case a.SET_IS_TUNNEL.REQUEST:
      return {
        ...state,
        isTunnel: {
          ...state.isTunnel,
          loading: true
        }
      };
    case a.SET_IS_TUNNEL.SUCCESS:
      return {
        ...state,
        isTunnel: {
          ...state.isTunnel,
          loading: false,
          status: action.payload.is
        }
      };
    case a.SET_IS_TUNNEL.FAILURE:
      return {
        ...state,
        isTunnel: INITIAL_STATE.isTunnel
      };
    /* SET CURRENT STEP */
    case a.SET_TUNNEL_CURRENT_STEP.REQUEST:
      return {
        ...state,
        currentStep: {
          ...state.currentStep,
          loading: true
        }
      };
    case a.SET_TUNNEL_CURRENT_STEP.SUCCESS:
      return {
        ...state,
        currentStep: {
          ...state.currentStep,
          loading: false,
          id: action.payload.id
        }
      };
    case a.SET_TUNNEL_CURRENT_STEP.FAILURE:
      return {
        currentStep: INITIAL_STATE.currentStep
      };
    /* SET CURRENT SOUSCRIPTION IDS */
    case a.SET_CURRENT_SOUSCRIPTION_IDS:
      return {
        ...state,
        currentSouscription: {
          ...state.currentSouscription,
          ids: {
            ...state.currentSouscription.ids,
            ...action.payload
          }
        }
      };
    case a.RESET_CURRENT_SOUSCRIPTION_IDS:
      return {
        ...state,
        currentSouscription: {
          ...state.currentSouscription,
          ids: INITIAL_STATE.currentSouscription.ids
        }
      };
    /* SET CURRENT SOUSCRIPTION RADLAD */
    case a.SET_CURRENT_SOUSCRIPTION_RADLAD:
      return {
        ...state,
        currentSouscription: {
          ...state.currentSouscription,
          radlad: {
            ...state.currentSouscription.radlad,
            ...action.payload
          }
        }
      };
    case a.RESET_CURRENT_SOUSCRIPTION_RADLAD:
      return {
        ...state,
        currentSouscription: {
          ...state.currentSouscription,
          ids: INITIAL_STATE.currentSouscription.radlad
        }
      };
    /* SET CURRENT SOUSCRIPTION CHECKRADLAD */
    case a.SET_CURRENT_SOUSCRIPTION_CHECKRADLAD:
      return {
        ...state,
        currentSouscription: {
          ...state.currentSouscription,
          needCheckRadlad: {
            ...state.currentSouscription.needCheckRadlad,
            ...action.payload
          }
        }
      };
    case a.RESET_CURRENT_SOUSCRIPTION_CHECKRADLAD:
      return {
        ...state,
        currentSouscription: {
          ...state.currentSouscription,
          needCheckRadlad: INITIAL_STATE.currentSouscription.needCheckRadlad
        }
      };
    /* SET CURRENT SOUSCRIPTION RISQUE ID */
    case a.SET_CURRENT_SOUSCRIPTION_SIMULATION_ID:
      return {
        ...state,
        currentSouscription: {
          ...state.currentSouscription,
          ids: {
            ...state.currentSouscription.ids,
            simulationId: action.payload
          }
        }
      };
    /* SET CURRENT SOUSCRIPTION RISQUE ID */
    case a.SET_CURRENT_SOUSCRIPTION_RISQUE_ID:
      return {
        ...state,
        currentSouscription: {
          ...state.currentSouscription,
          ids: {
            ...state.currentSouscription.ids,
            risqueId: action.payload
          }
        }
      };
    /* SET CURRENT SOUSCRIPTION LEAD ID */
    case a.SET_CURRENT_SOUSCRIPTION_LEAD_ID:
      return {
        ...state,
        currentSouscription: {
          ...state.currentSouscription,
          ids: {
            ...state.currentSouscription.ids,
            leadId: action.payload
          }
        }
      };
    case a.SET_ASSURANCE_TYPE:
      return {
        ...state,
        assuranceType: action.assuranceType
      };
    /* SHOW GO BACK */
    case a.SHOW_GO_BACK:
      return {
        ...state,
        showGoBack: action.visible
      };
    /* NAVIGATE INTO TUNNEL */
    case a.NAVIGATE_INTO.REQUEST:
      return {
        ...state
      };
    case a.NAVIGATE_INTO.SUCCESS:
      return {
        ...state,
        parcoursUtilisateur: action.payload
      };
    /* RESET GO BACK BOOL */
    case a.RESET_GO_BACK_BOOL:
      return {
        ...state,
        goBack: INITIAL_STATE.goBack
      };
    /* RESET */
    case a.RESET_TUNNEL:
      return {
        ...state,
        currentStep: INITIAL_STATE.currentStep,
        currentSouscription: INITIAL_STATE.currentSouscription
        // parcoursUtilisateur: INITIAL_STATE.parcoursUtilisateur
      };
    /* INPUT PROCESS */
    case a.SET_CURRENT_INPUT_PROCESS:
      return {
        ...state,
        currentSouscription: {
          ...state.currentSouscription,
          inputProcess: {
            ...state.currentSouscription.inputProcess,
            name: action.payload.name,
            id: action.payload.id
          }
        }
      };
    case a.RESET_CURRENT_INPUT_PROCESS:
      return {
        ...state,
        currentSouscription: {
          ...state.currentSouscription,
          inputProcess: INITIAL_STATE.currentSouscription.inputProcess
        }
      };
    default:
      return state;
  }
}

export default tunnel;
