export const SHOW_MAIN_MENU = "SHOW_MAIN_MENU/SET";
export const SHOW_HEADER_ONLY_LOGO = "SHOW_HEADER_ONLY_LOGO/SET";
export const SET_HEADER_ONLY_LOGO_URL = "SET_HEADER_ONLY_LOGO_URL/SET";

export const showMainMenu = isVisible => {
  return {
    type: SHOW_MAIN_MENU,
    isVisible
  };
};

export const showHeaderOnlyLogo = isVisible => {
  return {
    type: SHOW_HEADER_ONLY_LOGO,
    isVisible
  };
};

export const setHeaderOnlyLogoURL = url => {
  return {
    type: SET_HEADER_ONLY_LOGO_URL,
    url
  };
};
