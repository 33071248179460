import { withNamespaces } from "react-i18next";
// The basics
import React, { Component } from "react";
import { withRouter } from "react-router";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import OutsideClickHandler from "react-outside-click-handler";
import {
  addFacebookTag,
  addBingTag,
  addGoogle,
  tagGoogleUniversalAnalytic,
  tagGoogleUniversalAnalyticMois
} from "./helpers/tags/tagsHelper";
// Import i18n config
import I18nextProvider from "./i18n-provider";
import theme from "./theme-style";
import { ThemeProvider } from "styled-components";
import AxaIcon from "./assets/logo_axa.png";
import OleaIcon from "./assets/logo_olea.png";
import BaloonIcon from "./assets/favicon.ico";

// Import components
import BarEnv from "./components/barre-environnement";
import Header from "./components/header";
import CookieBar from "./components/cookie-bar";
import MainMenu from "./components/main-menu";
import Loader from "./components/loader";

// Import main style
import MainDefaultStyle from "./../../src/app/theme-style/main-default-style";
import { StyledWrapperLoader } from "./style";

// Helpers
import { isOnTunnel } from "./helpers/routing/is-on-tunnel";

// Import routing
import Routes from "./routes/index";

/* Store */
import {
  getInformation,
  isInformationsLoading,
  isInformationsError,
  isInformationsSuccess,
  getLocales,
  getBranches,
  getCurrentVisitorOrigin,
  isUserBaloonPro as isUserBaloonProSelector,
  isUserAnonyme as isUserAnonymeSelector,
  isUserClient as isUserClientSelector,
  getTracking,
  getTheme,
  isGtagLog
} from "./store/information/selectors";
import {
  setInformation,
  setTrackingBing,
  setTrackingFB,
  setGtagLog
} from "./store/information/actions";
import { openCloseMainMenu } from "./store/main-menu/actions";
import { bindSessionStorageToStore } from "./store/tunnel/tunnel/actions";
import { getMainMenuState } from "./store/main-menu/selectors";
import {
  isGetHelpArticlesLoading,
  isGetHelpArticlesSuccessfull,
  isGetHelpArticlesError,
  getHelpArticles
} from "./store/article/selectors";
import { getHelpArticles as getHelpArticlesAction } from "./store/article/actions";
import {
  showMainMenu,
  showHeaderOnlyLogo,
  setHeaderOnlyLogoURL
} from "./store/layouts-show-hide/actions";
import { isMainMenuVisible as isMainMenuVisibleSelector } from "./store/layouts-show-hide/selectors";
import {
  getChoixQuestionsMedicalesCookie,
  isGetChoixQuestionsMedicalesError,
  isGetChoixQuestionsMedicalesLoading,
  isGetChoixQuestionsMedicalesSuccess
} from "./store/tunnel/sante/questions-medicales/selectors";
import { postLog } from "./store/logger/actions";

const mapStateToProps = state => ({
  informations: getInformation(state),
  isInformationsLoading: isInformationsLoading(state),
  isInformationsError: isInformationsError(state),
  isInformationsSuccess: isInformationsSuccess(state),
  mainMenuState: getMainMenuState(state),
  languages: getLocales(state),
  branches: getBranches(state),
  isMainMenuVisible: isMainMenuVisibleSelector(state),
  currentVisitorOrigin: getCurrentVisitorOrigin(state),
  choixQuestionsMedicalesCookie: getChoixQuestionsMedicalesCookie(state),
  isUserBaloonPro: isUserBaloonProSelector(state),
  isUserAnonyme: isUserAnonymeSelector(state),
  isUserClient: isUserClientSelector(state),
  isGetChoixQuestionsMedicalesOnError: isGetChoixQuestionsMedicalesError(state),
  isGetChoixQuestionsMedicalesOnLoad: isGetChoixQuestionsMedicalesLoading(
    state
  ),
  isGetChoixQuestionsMedicalesOnSuccess: isGetChoixQuestionsMedicalesSuccess(
    state
  ),
  isGetHelpArticlesOnLoad: isGetHelpArticlesLoading(state),
  isGetHelpArticlesOnSuccess: isGetHelpArticlesSuccessfull(state),
  isGetHelpArticlesOnError: isGetHelpArticlesError(state),
  helpArticles: getHelpArticles(state),
  tracking: getTracking(state),
  charteGraphique: getTheme(state),
  isGtagLog: isGtagLog(state)
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      bindSessionStorageToStore,
      setInformation,
      openCloseMainMenu,
      showMainMenu,
      showHeaderOnlyLogo,
      setHeaderOnlyLogoURL,
      getHelpArticlesAction,
      postLog,
      setTrackingBing,
      setTrackingFB,
      setGtagLog
    },
    dispatch
  )
});

function setThemeColors(charteGraphique) {
  try {
    const newTheme = theme;
    newTheme.colors.themeColors = theme.colors.brandColors[charteGraphique];
    return newTheme;
  } catch (e) {
    this.props.actions.postLog({
      error: e,
      info: "setColorsTheme catch",
      level: "error",
      message:
        "On est dans le catch de setThemeColors a l'url :" +
        window.location.href
    });
  }
}

function setIconsTheme(iconsTheme) {
  try {
    const newTheme = theme;
    const charteGraphique = iconsTheme === "baloon" ? iconsTheme : "default";
    newTheme.icons.theme = theme.icons.brandsIcons[charteGraphique];
    return newTheme.icons.theme;
  } catch (e) {
    this.props.actions.postLog({
      error: e,
      info: "setIconsTheme catch",
      level: "error",
      message:
        "On est dans le catch de setIconsTheme a l'url :" + window.location.href
    });
  }
}

class App extends Component {
  constructor(props) {
    super(props);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  routerExceptionsRedirection() {
    const {
      location,
      branches,
      choixQuestionsMedicalesCookie,
      history,
      isUserAnonyme,
      isUserClient
    } = this.props;
    if (
      isOnTunnel(location.pathname, branches) &&
      JSON.parse(sessionStorage.getItem("currentTunnel")) === "sante"
    ) {
      if (isUserAnonyme || isUserClient) {
        // if tunnel santé
        if (choixQuestionsMedicalesCookie !== null) {
          if (
            choixQuestionsMedicalesCookie === "NeSaisPas" ||
            choixQuestionsMedicalesCookie === "true"
          ) {
            history.push("/assurance-sante/contact-medical");
          }
        }
      }
    }
  }

  componentDidMount() {
    const { actions, currentVisitorOrigin, tracking, isGtagLog } = this.props;
    if (window.location.href.includes("gtagLog=1") && !isGtagLog) {
      actions.setGtagLog();
    }
    if (currentVisitorOrigin !== null) {
      sessionStorage.setItem(
        "currentVisitorOrigin",
        JSON.stringify(currentVisitorOrigin)
      );
    }
    const {
      REACT_APP_LOCAL_GOOGLE_TAG_ID,
      REACT_APP_LOCAL_FACEBOOK_TAG_ID,
      REACT_APP_LOCAL_BING_TAG_ID,
      REACT_APP_LOCALE_AS_PROD
    } = process.env;
    if (REACT_APP_LOCALE_AS_PROD) {
      if (isGtagLog || window.location.href.includes("gtagLog=1")) {
        console.log(
          "Gtag Log : on ajoute les id de tracking a google (google ads ):: ",
          REACT_APP_LOCAL_GOOGLE_TAG_ID
        );
      }
      addGoogle(REACT_APP_LOCAL_GOOGLE_TAG_ID);
      tagGoogleUniversalAnalytic();
      tagGoogleUniversalAnalyticMois();
      if (!tracking.fbTracking) {
        actions.setTrackingFB();
        addFacebookTag(REACT_APP_LOCAL_FACEBOOK_TAG_ID);
      }
      if (!tracking.bingTracking) {
        actions.setTrackingBing();
        addBingTag(REACT_APP_LOCAL_BING_TAG_ID);
      }
    }
    actions.setInformation(window.location.href);
    // actions.getHelpArticlesAction();
  }

  /*  componentWillUnmount() {
    this.unlisten();
  }*/

  setTplExceptions() {
    const { actions } = this.props;
    if (this.props.location.pathname === "/coinafrique") {
      actions.showMainMenu(false);
      actions.showHeaderOnlyLogo(true);
      actions.setHeaderOnlyLogoURL("/coinafrique");
    } else {
      actions.showMainMenu(true);
      actions.showHeaderOnlyLogo(false);
      actions.setHeaderOnlyLogoURL("/");
    }
  }

  componentDidCatch(error, errorInfo) {
    const { actions } = this.props;

    actions.postLog({
      message: "ERREUR GENERIQUE ::\n",
      level: "error",
      error: error,
      info: errorInfo
    });
  }

  componentDidUpdate(prevProps) {
    const { actions } = this.props;
    if (
      prevProps.isInformationsSuccess !== this.props.isInformationsSuccess &&
      this.props.isInformationsSuccess
    ) {
      actions.getHelpArticlesAction();
      if (this.props.languages.length > 0) {
        // Get first element of array
        I18nextProvider.changeLanguage(this.props.languages[0]);
      }
      this.props.actions.bindSessionStorageToStore(
        isOnTunnel(this.props.location.pathname, this.props.branches)
      );
      this.setTplExceptions();
      this.routerExceptionsRedirection();
    }

    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.props.actions.bindSessionStorageToStore(
        isOnTunnel(this.props.location.pathname, this.props.branches)
      );
      this.routerExceptionsRedirection();
      this.setTplExceptions();
    }
  }

  handleClickOutside() {
    if (this.props.mainMenuState.isOpened) {
      // this.props.actions.openCloseMainMenu(false, -1);
    }
  }

  renderApp() {
    const {
      isInformationsSuccess,
      isMainMenuVisible,
      isGetHelpArticlesOnSuccess,
      helpArticles,
      charteGraphique,
      t,
      isInformationsLoading,
      actions
    } = this.props;
    try {
      const favicon = document.getElementById("favicon");
      if (
        !isInformationsLoading &&
        charteGraphique === "axa" &&
        isInformationsSuccess
      ) {
        favicon.href = AxaIcon;
        const currentUrl = window.location.hostname;
        const isMarqueBlanche = !currentUrl.includes("baloon");
        //Changer le nom de l'onglet
        document.title = isMarqueBlanche
          ? t("global.siteNameDefaultTab")
          : t("global.siteNameTab");
      } else if (
        !isInformationsLoading &&
        charteGraphique === "olea" &&
        isInformationsSuccess
      ) {
        favicon.href = OleaIcon;
        const currentUrl = window.location.hostname;
        const isMarqueBlanche = !currentUrl.includes("baloon");
        //Changer le nom de l'onglet
        document.title = isMarqueBlanche
          ? t("global.siteNameDefaultTab")
          : t("global.siteNameTab");
      } else if (
        !isInformationsLoading &&
        charteGraphique === "baloon" &&
        isInformationsSuccess
      ) {
        favicon.href = BaloonIcon;
      } else {
        favicon.href = null;
      }
      let iconsTheme = "";
      if (isInformationsSuccess) {
        iconsTheme = charteGraphique;
      } else if (isInformationsLoading) {
        const currentUrl = window.location.hostname;
        iconsTheme = currentUrl.includes("baloon") ? "baloon" : "default";
      }
      if (isInformationsSuccess && isGetHelpArticlesOnSuccess) {
        return (
          <ThemeProvider
            theme={setThemeColors(charteGraphique)}
            icons={setIconsTheme(iconsTheme)}
          >
            <React.Fragment>
              <OutsideClickHandler onOutsideClick={this.handleClickOutside}>
                <div className="top-container">
                  <Header helpArticlesLength={helpArticles.length} />
                  {isMainMenuVisible && (
                    <MainMenu helpArticles={helpArticles} />
                  )}
                </div>
              </OutsideClickHandler>
              <Routes />
              <CookieBar />
            </React.Fragment>
          </ThemeProvider>
        );
      }
      return (
        <ThemeProvider
          theme={setThemeColors(charteGraphique)}
          icons={setIconsTheme(iconsTheme)}
        >
          <StyledWrapperLoader>
            <Loader />
          </StyledWrapperLoader>
        </ThemeProvider>
      );
    } catch (e) {
      console.log("On a une erreur dans le render app de l'app : ", e);
      actions.postLog({
        error: e,
        info:
          "catch dans le render app de app.js\nUrl courante :" +
          window.location.href,
        level: "error",
        message:
          "on a catch une erreur dans le renderApp de app.js, une des actions de cette fonction a declenché un bug"
      });
    }
  }

  render() {
    return (
      <div id="app">
        <MainDefaultStyle />
        <main id="content">
          <BarEnv />
          {this.renderApp()}
        </main>
      </div>
    );
  }
}

export default compose(
  withNamespaces("translations"),
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(App);
