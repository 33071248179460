// Envoi de la requête GET
export const isGetRecapitulatifLoading = state =>
  state.tunnel.common.recapitulatif.get.loading;

// Envoi succes GET
export const isGetRecapitulatifSuccessfull = state =>
  state.tunnel.common.recapitulatif.get.success;

// Erreur de l'envoi GET
export const isGetRecapitulatifError = state =>
  state.tunnel.common.recapitulatif.get.error;

// Get Recapitulatif
export const getRecapitulatif = state => {
  return state.tunnel.common.recapitulatif.get.data;
};

// Get modification go Recapitulatif
export const getModifyGo = state => state.tunnel.common.recapitulatif.modify.go;
