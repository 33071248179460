import React, { Component } from "react";
import { Trans, withNamespaces } from "react-i18next";

// Import model page
import { StyledPrivacyPolicy } from "./style";

// Import components
import ScrollToTopOnMount from "../../components/scroll-top-onmount";
import ParagraphBreaks from "../../components/paragraph-breaks";
import LinkButton from "../../components/link-button";
import List from "../../components/list";
import Paragraph from "../../components/paragraph";
import Title from "../../components/title";

// Store
import { getCountry } from "../../store/information/selectors";
import { compose } from "redux";
import { withRouter } from "react-router";
import { connect } from "react-redux";

const mapStateToProps = state => ({
  country: getCountry(state)
});

class LegalMentions extends Component {
  render() {
    const { t } = this.props;
    return (
      <StyledPrivacyPolicy id="article" titlePage={t("privacyPolicy.title")}>
        <ScrollToTopOnMount />
        <Paragraph>
          <Trans i18nKey="privacyPolicy.contents.block1.paragraph1">
            Paragraph 1
            <LinkButton
              to={"/"}
              config={{
                type: "link",
                style: "noStyle"
              }}
            >
              URL_PAYS
            </LinkButton>
          </Trans>
        </Paragraph>
        <Paragraph>{t("privacyPolicy.contents.block1.paragraph2")}</Paragraph>
        <Title titleType={2}>{t("privacyPolicy.contents.block2.title")}</Title>
        <Title titleType={3}>
          1. {t("privacyPolicy.contents.block2.subTitle1")}
        </Title>
        <Paragraph>
          <Trans i18nKey="privacyPolicy.contents.block2.paragraph1">
            privacyPolicy.contents.block2.paragraph1
            <LinkButton
              config={{
                type: "link",
                style: "noStyle"
              }}
              to={"/"}
            >
              url site pays
            </LinkButton>
          </Trans>
        </Paragraph>
        <Paragraph noMargin>
          {t("privacyPolicy.contents.block2.paragraph2")}
        </Paragraph>
        <List
          content={[
            [
              <Trans i18nKey="privacyPolicy.contents.block2.paragraph3">
                privacyPolicy.contents.block2.paragraph1
                <LinkButton
                  config={{
                    type: "link",
                    style: "noStyle"
                  }}
                  to={"/"}
                >
                  url site pays
                </LinkButton>
              </Trans>
            ],
            [
              <Trans i18nKey="privacyPolicy.contents.block2.paragraph4">
                privacyPolicy.contents.block2.paragraph1
                <LinkButton
                  config={{
                    type: "link",
                    style: "noStyle"
                  }}
                  to={"/"}
                >
                  url site pays
                </LinkButton>
              </Trans>
            ],
            [
              <Trans i18nKey="privacyPolicy.contents.block2.paragraph5">
                privacyPolicy.contents.block2.paragraph1
                <LinkButton
                  config={{
                    type: "link",
                    style: "noStyle"
                  }}
                  to={"/"}
                >
                  url site pays
                </LinkButton>
              </Trans>
            ],
            [t("privacyPolicy.contents.block2.paragraph6")]
          ]}
        />
        <Paragraph noMargin>
          {t("privacyPolicy.contents.block2.paragraph7")}
        </Paragraph>
        <Paragraph noMargin>
          {t("privacyPolicy.contents.block2.paragraph8")}
        </Paragraph>
        <Paragraph noMargin>
          {t("privacyPolicy.contents.block2.paragraph9")}
        </Paragraph>
        <List
          content={[
            [t("privacyPolicy.contents.block2.paragraph10")],
            [t("privacyPolicy.contents.block2.paragraph11")],
            [t("privacyPolicy.contents.block2.paragraph12")],
            [t("privacyPolicy.contents.block2.paragraph13")],
            [t("privacyPolicy.contents.block2.paragraph14")],
            [t("privacyPolicy.contents.block2.paragraph15")]
          ]}
        />
        <Title titleType={3}>
          2. {t("privacyPolicy.contents.block2.subTitle2")}
        </Title>
        <Paragraph>
          <Trans i18nKey="privacyPolicy.contents.block2.paragraph16">
            privacyPolicy.contents.block2.paragraph16
            <LinkButton
              config={{
                type: "link",
                style: "noStyle"
              }}
              to={"/"}
            >
              url site pays
            </LinkButton>
          </Trans>
        </Paragraph>
        <Title titleType={3}>
          3. {t("privacyPolicy.contents.block2.subTitle3")}
        </Title>
        <Paragraph noMargin>
          {t("privacyPolicy.contents.block2.paragraph17")}
        </Paragraph>
        <List
          content={[
            [
              <Trans i18nKey="privacyPolicy.contents.block2.paragraph18">
                privacyPolicy.contents.block2.paragraph18
                <LinkButton
                  config={{
                    type: "link",
                    style: "noStyle"
                  }}
                  to={"/"}
                >
                  url site pays
                </LinkButton>
              </Trans>
            ]
          ]}
        />
        <Paragraph noMargin>
          {t("privacyPolicy.contents.block2.paragraph19")}
        </Paragraph>
        <List
          content={[
            [t("privacyPolicy.contents.block2.paragraph20")],
            [t("privacyPolicy.contents.block2.paragraph21")],
            [t("privacyPolicy.contents.block2.paragraph22")],
            [t("privacyPolicy.contents.block2.paragraph23")],
            [t("privacyPolicy.contents.block2.paragraph24")],
            [t("privacyPolicy.contents.block2.paragraph25")],
            [t("privacyPolicy.contents.block2.paragraph26")],
            [t("privacyPolicy.contents.block2.paragraph27")]
          ]}
        />
        <Paragraph noMargin>
          {t("privacyPolicy.contents.block2.paragraph28")}
        </Paragraph>
        {this.props.country === "ci" && (
          <Paragraph>
            {t("privacyPolicy.contents.block2.paragraph29")}
          </Paragraph>
        )}
        <Title titleType={3}>
          4. {t("privacyPolicy.contents.block2.subTitle4")}
        </Title>
        <Paragraph noMargin>
          {t("privacyPolicy.contents.block2.paragraph30")}
        </Paragraph>
        <List
          content={[
            [t("privacyPolicy.contents.block2.paragraph31")],
            [t("privacyPolicy.contents.block2.paragraph32")],
            [t("privacyPolicy.contents.block2.paragraph33")],
            [t("privacyPolicy.contents.block2.paragraph34")],
            [t("privacyPolicy.contents.block2.paragraph35")],
            [t("privacyPolicy.contents.block2.paragraph36")]
          ]}
        />
        <Paragraph noMargin>
          {t("privacyPolicy.contents.block2.paragraph37")}
        </Paragraph>
        <Paragraph noMargin>
          {t("privacyPolicy.contents.block2.paragraph38")}
        </Paragraph>
        <Paragraph>
          <Trans i18nKey="privacyPolicy.contents.block2.paragraph39">
            privacyPolicy.contents.block2.paragraph39
            <LinkButton
              config={{
                type: "link",
                style: "noStyle"
              }}
              to={"/"}
            >
              url site pays
            </LinkButton>
          </Trans>
        </Paragraph>
        <Title titleType={4}>
          5. {t("privacyPolicy.contents.block2.subTitle5")}
        </Title>
        <Paragraph noMargin>
          {t("privacyPolicy.contents.block2.paragraph40")}
        </Paragraph>
        <Paragraph noMargin>
          {t("privacyPolicy.contents.block2.paragraph41")}
        </Paragraph>
        <Paragraph>{t("privacyPolicy.contents.block2.paragraph42")}</Paragraph>
        <Title titleType={3}>
          6. {t("privacyPolicy.contents.block2.subTitle6")}
        </Title>
        <Paragraph noMargin>
          {t("privacyPolicy.contents.block2.paragraph43")}
        </Paragraph>
        <Paragraph>{t("privacyPolicy.contents.block2.paragraph44")}</Paragraph>
        <Title titleType={3}>
          7. {t("privacyPolicy.contents.block2.subTitle7")}
        </Title>
        <Paragraph noMargin>
          {t("privacyPolicy.contents.block2.paragraph45")}
        </Paragraph>
        <ParagraphBreaks
          text={t("privacyPolicy.contents.block2.paragraph46")}
        />
        <Title titleType={3}>
          8. {t("privacyPolicy.contents.block2.subTitle8")}
        </Title>
        <Paragraph>{t("privacyPolicy.contents.block2.paragraph47")}</Paragraph>
        <Title titleType={3}>
          9. {t("privacyPolicy.contents.block2.subTitle9")}
        </Title>
        <Paragraph noMargin>
          {t("privacyPolicy.contents.block2.paragraph48")}
        </Paragraph>
        <Paragraph>{t("privacyPolicy.contents.block2.paragraph49")}</Paragraph>
        <Title titleType={2}>
          II. {t("privacyPolicy.contents.block3.title")}
        </Title>
        <Paragraph>{t("privacyPolicy.contents.block3.paragraph1")}</Paragraph>
        <Title titleType={3}>
          1. {t("privacyPolicy.contents.block3.subTitle1")}
        </Title>
        <Paragraph>{t("privacyPolicy.contents.block3.paragraph2")}</Paragraph>
        <Paragraph>{t("privacyPolicy.contents.block3.paragraph3")}</Paragraph>
        <Paragraph>{t("privacyPolicy.contents.block3.paragraph4")}</Paragraph>
        <Title titleType={3}>
          2. {t("privacyPolicy.contents.block3.subTitle2")}
        </Title>
        <Paragraph>{t("privacyPolicy.contents.block3.paragraph5")}</Paragraph>
        <Paragraph>{t("privacyPolicy.contents.block3.paragraph6")}</Paragraph>
        <Paragraph>{t("privacyPolicy.contents.block3.paragraph7")}</Paragraph>
        <Title titleType={4}>
          a. {t("privacyPolicy.contents.block3.subTitle2a")}
        </Title>
        <Paragraph>{t("privacyPolicy.contents.block3.paragraph8")}</Paragraph>
        <Title titleType={4}>
          b. {t("privacyPolicy.contents.block3.subTitle2b")}
        </Title>
        <Paragraph>{t("privacyPolicy.contents.block3.paragraph9")}</Paragraph>
        <Paragraph>{t("privacyPolicy.contents.block3.paragraph10")}</Paragraph>
        <Paragraph>{t("privacyPolicy.contents.block3.paragraph11")}</Paragraph>
        <Paragraph>
          <Trans i18nKey="privacyPolicy.contents.block3.paragraph12">
            privacyPolicy.contents.block3.paragraph12
            <LinkButton
              config={{
                type: "link",
                style: "noStyle"
              }}
              to={"https://www.hotjar.com/legal/compliance/gdpr-commitment"}
            >
              hotjar url
            </LinkButton>
          </Trans>
        </Paragraph>
        <Paragraph>{t("privacyPolicy.contents.block3.paragraph13")}</Paragraph>
        <List
          content={[
            [
              <Trans i18nKey="privacyPolicy.contents.block3.paragraph14">
                privacyPolicy.contents.block3.paragraph14
                <LinkButton
                  config={{
                    type: "link",
                    style: "noStyle"
                  }}
                  to={"https://fr-fr.facebook.com/policies/cookies/"}
                >
                  facebook url privacy
                </LinkButton>
              </Trans>
            ],
            [
              <Trans i18nKey="privacyPolicy.contents.block3.paragraph15">
                privacyPolicy.contents.block3.paragraph15
                <LinkButton
                  config={{
                    type: "link",
                    style: "noStyle"
                  }}
                  to={"https://www.linkedin.com/legal/cookie-policy?_l=fr_FR"}
                >
                  linkedin privacy url
                </LinkButton>
              </Trans>
            ],
            [
              <Trans i18nKey="privacyPolicy.contents.block3.paragraph16">
                privacyPolicy.contents.block3.paragraph16
                <LinkButton
                  config={{
                    type: "link",
                    style: "noStyle"
                  }}
                  to={"https://support.twitter.com/articles/20170518#"}
                >
                  twitter url privacy
                </LinkButton>
              </Trans>
            ],
            [
              <Trans i18nKey="privacyPolicy.contents.block3.paragraph17">
                privacyPolicy.contents.block3.paragraph17
                <LinkButton
                  config={{
                    type: "link",
                    style: "noStyle"
                  }}
                  to={"https://support.google.com/youtube/answer/32050?hl=fr"}
                >
                  url site pays
                </LinkButton>
                <LinkButton
                  config={{
                    type: "link",
                    style: "noStyle"
                  }}
                  to={
                    "https://www.google.fr/intl/fr/policies/technologies/cookies/"
                  }
                >
                  url site pays
                </LinkButton>
              </Trans>
            ]
          ]}
        />
        <Title titleType={4}>
          c. {t("privacyPolicy.contents.block3.subTitle2c")}
        </Title>
        <Paragraph noMargin>
          {t("privacyPolicy.contents.block3.paragraph18")}
        </Paragraph>
        <Paragraph noMargin>
          {t("privacyPolicy.contents.block3.paragraph19")}
        </Paragraph>
        <Paragraph noMargin>
          {t("privacyPolicy.contents.block3.paragraph20")}
        </Paragraph>
        <Paragraph noMargin>
          {t("privacyPolicy.contents.block3.paragraph21")}
        </Paragraph>
        <Paragraph noMargin>
          {t("privacyPolicy.contents.block3.paragraph22")}
        </Paragraph>
        <List
          content={[
            [
              <Trans i18nKey="privacyPolicy.contents.block3.paragraph23">
                privacyPolicy.contents.block3.paragraph23
                <LinkButton
                  config={{
                    type: "link",
                    style: "noStyle"
                  }}
                  to={"https://support.google.com/chrome/answer/95647?hl=fr"}
                >
                  facebook url privacy
                </LinkButton>
              </Trans>
            ],
            [
              <Trans i18nKey="privacyPolicy.contents.block3.paragraph24">
                privacyPolicy.contents.block3.paragraph24
                <LinkButton
                  config={{
                    type: "link",
                    style: "noStyle"
                  }}
                  to={
                    "https://support.mozilla.org/fr/kb/activer-desactiver-cookies"
                  }
                >
                  facebook url privacy
                </LinkButton>
              </Trans>
            ],
            [
              <Trans i18nKey="privacyPolicy.contents.block3.paragraph25">
                privacyPolicy.contents.block3.paragraph25
                <LinkButton
                  config={{
                    type: "link",
                    style: "noStyle"
                  }}
                  to={
                    "https://support.microsoft.com/fr-fr/help/17442/windows-internet-explorer-delete-manage-cookies#ie=ie-11"
                  }
                >
                  facebook url privacy
                </LinkButton>
              </Trans>
            ],
            [
              <Trans i18nKey="privacyPolicy.contents.block3.paragraph26">
                privacyPolicy.contents.block3.paragraph26
                <LinkButton
                  config={{
                    type: "link",
                    style: "noStyle"
                  }}
                  to={
                    "https://help.opera.com/en/latest/web-preferences/#cookies"
                  }
                >
                  facebook url privacy
                </LinkButton>
              </Trans>
            ],
            [
              <Trans i18nKey="privacyPolicy.contents.block3.paragraph27">
                privacyPolicy.contents.block3.paragraph27
                <LinkButton
                  config={{
                    type: "link",
                    style: "noStyle"
                  }}
                  to={
                    "https://support.apple.com/fr-fr/guide/safari/sfri11471/mac"
                  }
                >
                  facebook url privacy
                </LinkButton>
              </Trans>
            ]
          ]}
        />
      </StyledPrivacyPolicy>
    );
  }
}

export default compose(
  withRouter,
  withNamespaces("translations"),
  connect(
    mapStateToProps,
    null
  )
)(LegalMentions);
