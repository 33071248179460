import React from "react";
import ScrollUpButton from "react-scroll-up-button";
import { ArrowUpward } from "styled-icons/material/ArrowUpward";

// Import style layout
import { StyledLayoutScrollTop } from "./style";

const LayoutScrollTop = props => {
  return (
    <StyledLayoutScrollTop {...props}>
      {props.children}
      <ScrollUpButton
        StopPosition={0}
        ShowAtPosition={250}
        ContainerClassName="scrollUpButton"
        TransitionClassName="scrollUpButton-toggled"
      >
        <ArrowUpward size={32} />
      </ScrollUpButton>
    </StyledLayoutScrollTop>
  );
};

export default LayoutScrollTop;
