export const SET_INFORMATION = {
  REQUEST: "information/SET/REQUEST",
  SUCCESS: "information/SET/SUCCESS",
  FAILURE: "information/SET/FAILURE"
};
export const SET_GTAG_LOG = {
  REQUEST: "information/SET/GTAG/LOG/REQUEST"
};

export const SET_TRACKING_FB = {
  REQUEST: "information/SET/TRACKING/FB/REQUEST"
};
export const SET_TRACKING_BING = {
  REQUEST: "information/SET/TRACKING/BING/REQUEST"
};
export const SET_TRACKING_DEMANDE_DE_CONTACT = {
  REQUEST: "information/SET/TRACKING/DEMANDEDECONTACT/REQUEST"
};
export const SET_TRACKING_TARIF = {
  REQUEST: "information/SET/TRACKING/TARIF/REQUEST"
};
export const SET_TRACKING_PAIEMENT = {
  REQUEST: "information/SET/TRACKING/PAIEMENT/REQUEST"
};

export const setInformation = envURL => {
  return {
    type: SET_INFORMATION.REQUEST,
    envURL
  };
};

export const setTrackingFB = () => {
  return {
    type: SET_TRACKING_FB.REQUEST
  };
};
export const setTrackingBing = () => {
  return {
    type: SET_TRACKING_BING.REQUEST
  };
};
export const setTrackingDemandeDeContact = () => {
  return {
    type: SET_TRACKING_DEMANDE_DE_CONTACT.REQUEST
  };
};
export const setTrackingTarif = () => {
  return {
    type: SET_TRACKING_TARIF.REQUEST
  };
};
export const setTrackingPaiement = () => {
  return {
    type: SET_TRACKING_PAIEMENT.REQUEST
  };
};

export const setGtagLog = () => {
  return {
    type: SET_GTAG_LOG.REQUEST
  };
};
