export const GET_TARIF = {
  REQUEST: "tarif/GET/REQUEST",
  SUCCESS: "tarif/GET/SUCCESS",
  FAILURE: "tarif/GET/FAILURE"
};

export const CHOOSE_TARIF = {
  REQUEST: "tarif/CHOOSE/REQUEST",
  SUCCESS: "tarif/CHOOSE/SUCCESS",
  FAILURE: "tarif/CHOOSE/FAILURE"
};

export const RESET_TARIF = "tarif/RESET";

export const SET_COTATION_DISPONNIBLE = {
  REQUEST: "tarif/SET/COTATION/DISPONNIBLE/REQUEST",
  SUCCESS: "tarif/SET/COTATION/DISPONNIBLE/SUCCESS"
};

export const getTarif = assuranceType => {
  return {
    type: GET_TARIF.REQUEST,
    assuranceType
  };
};

export const chooseTarif = (assuranceType, cotationId) => {
  return {
    type: CHOOSE_TARIF.REQUEST,
    assuranceType,
    cotationId
  };
};

export const resetTarif = () => {
  return {
    type: RESET_TARIF
  };
};

export const setCotationDisponnible = (disponnible, simuId) => {
  return {
    type: SET_COTATION_DISPONNIBLE.REQUEST,
    disponnible,
    simuId
  };
};
