import React from "react";
import { render, hydrate } from "react-dom";
import { Provider } from "react-redux";
import Loadable from "react-loadable";
import { Frontload } from "react-frontload";
import { ConnectedRouter } from "connected-react-router";
import { I18nextProvider } from "react-i18next";
import { ThemeProvider } from "styled-components";

/* Store and sagas */
import createStore from "./app/store/configureStore";
import mainSaga from "./app/store/sagas";

import App from "./app/app";
import ErrorBoundary from "./app/components/error-boundary";
import i18nProvider from "./app/i18n-provider"; // initialized i18next instance

// Import style
import theme from "./app/theme-style";

require("dotenv").config();

// Create a store and get back itself and its history object
const { store, history } = createStore();
store.runSaga(mainSaga);

// Running locally, we should run on a <ConnectedRouter /> rather than on a <StaticRouter /> like on the server
// Let's also let React Frontload explicitly know we're not rendering on the server here
const Application = (
  <Provider store={store}>
    <I18nextProvider i18n={i18nProvider}>
      <ThemeProvider theme={theme}>
        <ConnectedRouter history={history}>
          <Frontload noServerRender={true}>
            <ErrorBoundary>
              <App />
            </ErrorBoundary>
          </Frontload>
        </ConnectedRouter>
      </ThemeProvider>
    </I18nextProvider>
  </Provider>
);

const root = document.querySelector("#root");

if (root.hasChildNodes() === true) {
  // If it's an SSR, we use hydrate to get fast page loads by just
  // attaching event listeners after the initial render
  Loadable.preloadReady().then(() => {
    hydrate(Application, root);
  });
} else {
  // If we're not running on the server, just render like normal
  render(Application, root);
}
