import * as a from "./actions";

const INITIAL_DATE_EFFET_CONTRAT_STATE = {
  get: {
    error: null,
    loading: false,
    success: false,
    chosenDate: null,
    dateMin: null,
    dateMax: null,
    heureMin: null,
    cleHelper: null
  },
  post: {
    error: null,
    loading: false,
    success: false
  }
};

export function dateEffetContrat(
  state = INITIAL_DATE_EFFET_CONTRAT_STATE,
  action
) {
  let error;
  switch (action.type) {
    case a.GET_DATE_EFFET.REQUEST:
      return {
        ...state,
        get: {
          success: false,
          error: null,
          loading: true
        }
      };
    case a.GET_DATE_EFFET.SUCCESS:
      return {
        ...state,
        get: {
          success: true,
          error: null,
          loading: false,
          chosenDate: action.payload.value,
          dateMin: action.payload.dateMin,
          dateMax: action.payload.dateMax,
          heureMin: action.payload.heureMin,
          cleHelper: action.payload.cleHelper
        }
      };
    case a.GET_DATE_EFFET.FAILURE:
      error = action.payload || { message: action.payload.message };
      return {
        ...state,
        get: {
          success: false,
          error: error,
          loading: false
        }
      };
    case a.POST_DATE_EFFET.REQUEST:
      return {
        ...state,
        post: {
          success: false,
          error: null,
          loading: true
        }
      };
    case a.POST_DATE_EFFET.SUCCESS:
      return {
        ...state,
        post: {
          success: true,
          error: null,
          loading: false
        }
      };
    case a.POST_DATE_EFFET.FAILURE:
      error = action.payload || { message: action.payload.message };
      return {
        ...state,
        post: {
          success: false,
          error: error,
          loading: false
        }
      };
    case a.RESET_DATE_EFFET_STATUS:
      return {
        ...state,
        post: INITIAL_DATE_EFFET_CONTRAT_STATE.post
      };
    default:
      return state;
  }
}

export default dateEffetContrat;
