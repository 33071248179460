import { isAllowedBranche } from "../data/is-allowed";

export const isOnTunnel = (url, branches) => {
  const regex = new RegExp("^/assurance-");
  const isOn = regex.test(url);
  const data = {
    status: false,
    name: null
  };
  if (isOn) {
    const extract = url.split("/assurance-")[1].split("/")[0];
    data.status = isAllowedBranche(branches, extract);
    data.name = extract;
  }
  return data;
};
