import { call, all, put, takeLatest, select, take } from "redux-saga/effects";
import * as a from "./actions";

// Selectors
import * as tunnelSelectors from "../../tunnel/selectors";
import * as recapitulatifSelectors from "../recapitulatif/selectors";
import * as informationSelectors from "../../../information/selectors";
// Sagas
import * as tunnelSagas from "../../tunnel/saga";
import * as loggerSagas from "../../../logger/saga";
// Actions
import * as uploadDocumentActions from "../upload-document/actions";
import * as inputProcessActions from "../input-process/actions";
// Services
import * as dateEffetContratService from "./services";

function* getDateEffetContrat({ assuranceType }) {
  try {
    const mainToken = yield select(informationSelectors.getMainToken);
    yield call(tunnelSagas.rebindIdsFromSessionStorage);
    const simulationIds = yield select(tunnelSelectors.getCurrentSimulationIds);
    if (simulationIds.simulationId === null) {
      yield call(
        tunnelSagas.redirectToUrl,
        `/assurance-${assuranceType || "automobile"}/informations-manquantes`
      );
    } else {
      const dateEffetInterval = yield call(
        dateEffetContratService.get,
        assuranceType,
        mainToken,
        simulationIds.simulationId
      );
      if (!dateEffetInterval.error) {
        yield put({
          type: a.GET_DATE_EFFET.SUCCESS,
          payload: dateEffetInterval
        });
      } else {
        const errorRes = dateEffetInterval.res.response;
        if (errorRes.request.status !== 400) {
          yield call(loggerSagas.postLogErrorApi, {
            sagaInit: "getDateEffetContrat",
            apiResponseUrl: errorRes.request.responseURL,
            statusResponse: errorRes.request.status,
            dataResponse: errorRes.data,
            token: mainToken,
            params: {
              simulationId: simulationIds.simulationId
            }
          });
        }
        yield put({
          type: a.GET_DATE_EFFET.FAILURE,
          payload: true
        });
      }
    }
  } catch (e) {
    yield put({
      type: a.GET_DATE_EFFET.FAILURE,
      payload: true
    });
  }
}

function* postDateEffetContrat({ dateEffetContrat, assuranceType }) {
  try {
    const mainToken = yield select(informationSelectors.getMainToken);
    const simulationIds = yield select(tunnelSelectors.getCurrentSimulationIds);
    const dateEffet = yield call(
      dateEffetContratService.post,
      assuranceType,
      mainToken,
      simulationIds.simulationId,
      dateEffetContrat
    );
    if (!dateEffet.error) {
      const needToGoBackToRecapitulatif = yield select(
        recapitulatifSelectors.getModifyGo
      );
      if (needToGoBackToRecapitulatif === "dateDebut") {
        yield call(tunnelSagas.setModificationRecap);
        yield call(
          tunnelSagas.redirectToUrl,
          `/assurance-${assuranceType}/recapitulatif`
        );
      } else {
        yield put({
          type: a.POST_DATE_EFFET.SUCCESS,
          payload: dateEffet
        });
        if (assuranceType === "automobile") {
          yield put({
            type: uploadDocumentActions.NEED_UPLOAD_DOCUMENT.REQUEST,
            assuranceType: "automobile",
            documentType: "carteGrise"
          });
          const waitSuccess = yield take(
            uploadDocumentActions.NEED_UPLOAD_DOCUMENT.SUCCESS
          );
          const need = waitSuccess.payload.carteGrise;
          if (need) {
            yield call(
              tunnelSagas.redirectToUrl,
              "/assurance-automobile/envoi-document/carte-grise"
            );
          } else {
            yield put({
              type: inputProcessActions.GET_INPUT_PROCESS.REQUEST,
              inputProcessType: "InfoVehicule4",
              assuranceType: "automobile",
              startInputProcess: true
            });
          }
        } else if (assuranceType === "sante") {
          yield call(
            tunnelSagas.redirectToUrl,
            "/assurance-sante/recapitulatif"
          );
        }
      }
    } else {
      const errorRes = dateEffet.res.response;
      if (errorRes.request.status !== 400) {
        yield call(loggerSagas.postLogErrorApi, {
          sagaInit: "postDateEffetContrat",
          apiResponseUrl: errorRes.request.responseURL,
          statusResponse: errorRes.request.status,
          dataResponse: errorRes.data,
          token: mainToken,
          params: {
            simulationId: simulationIds.simulationId,
            dateEffetContrat
          }
        });
      }
      yield put({
        type: a.POST_DATE_EFFET.FAILURE,
        payload: true
      });
    }
  } catch (e) {}
}

export default all([
  takeLatest(a.GET_DATE_EFFET.REQUEST, getDateEffetContrat),
  takeLatest(a.POST_DATE_EFFET.REQUEST, postDateEffetContrat)
]);
