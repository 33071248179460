import React from "react";
import PropTypes from "prop-types";

const ListObjects = props => {
  return props.content.map((text, index) => {
    if (!Array.isArray(text))
      return (
        <li key={index} data-index={index}>
          <span>{text}</span>
        </li>
      );
    else
      return (
        <li key={index} data-index={index}>
          {text.map((subtext, subIndex) => {
            return <span key={subIndex}>{subtext}</span>;
          })}
        </li>
      );
  });
};

const List = props => {
  const { numbered, ...rest } = props;
  return (
    <div className={`list${numbered ? " numbered" : ""}`}>
      {numbered && (
        <ol>
          <ListObjects {...rest} />
        </ol>
      )}
      {!numbered && (
        <ul>
          <ListObjects {...rest} />
        </ul>
      )}
    </div>
  );
};

List.propTypes = {
  numbered: PropTypes.bool,
  content: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)])
  ).isRequired
};

export default List;
