import styled from "styled-components";

export const StyledPopinForm = styled.div`
  form {
    padding-top: 10px;
  }
  input {
    &.nom {
      text-transform: capitalize;
    }
  }
`;
