import React, { Component } from "react";
import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";

// Import components
import SouscriptionSteps from "./souscription-steps";

// Store & Actions
import { getMainMenuState } from "../../../../../store/main-menu/selectors";

// Import style
import { StyledMenuTunnel } from "./style";

const mapStateToProps = state => ({
  mainMenuState: getMainMenuState(state)
});

class MenuTunnel extends Component {
  render() {
    return (
      <StyledMenuTunnel>
        <SouscriptionSteps desktop />
      </StyledMenuTunnel>
    );
  }
}

export default compose(
  withNamespaces("translations"),
  connect(
    mapStateToProps,
    null
  )
)(MenuTunnel);
