import * as a from "./actions";

const INITIAL_STATE = {
  loading: false,
  isOpened: false,
  openedName: null
};

export function mainMenu(state = INITIAL_STATE, action) {
  switch (action.type) {
    case a.OPENCLOSE_MAINMENU.REQUEST:
      return {
        ...state,
        loading: true,
        isOpened: action.isOpened,
        openedName: action.openedName
      };
    case a.OPENCLOSE_MAINMENU.SUCCESS:
      return {
        ...state,
        loading: false,
        isOpened: action.payload.isOpened,
        openedName: action.payload.openedName
      };
    case a.OPENCLOSE_MAINMENU.FAILURE:
      return INITIAL_STATE;
    default:
      return state;
  }
}

export default mainMenu;
