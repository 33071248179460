import styled from "styled-components";

export const ItemNavStyle = styled.span`
  .whatsAppContact {
  }
  .whatsAppContact > img {
    width: 32px;
    margin-left: -7px;
  }
  .whatsAppTextMobile {
    margin: auto;
    margin-left: -9px;
  }
`;
