import React from "react";
import ItemNavText from "../../../item-nav-text";

const ChildList = props => {
  const { child, openModalFunc, splitDesktop, isAideBlock } = props;
  if (splitDesktop === 1) {
    return (
      <ul className="unique-line">
        {child.map((childItem, index) => {
          const { icon, label, url, openModal } = childItem;
          const isModalItem = openModal ? openModalFunc : () => {};
          return (
            <li key={index}>
              <ItemNavText
                label={label}
                icon={icon}
                url={typeof url !== "undefined" ? url : null}
                onClick={isModalItem}
              />
            </li>
          );
        })}
      </ul>
    );
  } else {
    const segments = [];
    let childItems = child;
    if (
      typeof splitDesktop === "object" &&
      Object.values(splitDesktop).length > 1
    ) {
      for (let i = 0; i < splitDesktop.length; i++) {
        const startSliceIndex = i === 0 ? 0 : splitDesktop[i - 1];
        const endSliceIndex =
          i === 0 ? splitDesktop[i] : splitDesktop[i - 1] + splitDesktop[i];
        segments.push(childItems.slice(startSliceIndex, endSliceIndex));
      }
    } else {
      if (isAideBlock) {
        childItems = childItems.filter((item, index) => index < 6);
      }
      const itemsSegmentor = Math.round(childItems.length / splitDesktop);
      for (let i = 0; i < splitDesktop; i++) {
        const startSliceIndex = i === 0 ? i : i + itemsSegmentor - 1;
        segments.push(
          childItems.slice(startSliceIndex, startSliceIndex + itemsSegmentor)
        );
      }
    }
    return (
      <React.Fragment>
        {segments.map((segment, index) => {
          return (
            <ul
              key={index}
              style={{
                maxWidth: `calc((100% - ${index === 0 ? "30px" : 0}) / ${
                  segments.length
                })`,
                width: "100%"
              }}
            >
              {segment.map((childItem, childIndex) => {
                const { icon, label, url, openModal } = childItem;
                const isModalItem = openModal ? openModalFunc : () => {};
                return (
                  <li key={childIndex}>
                    <ItemNavText
                      label={label}
                      icon={icon}
                      url={typeof url !== "undefined" ? url : null}
                      onClick={isModalItem}
                      isDesktopButtonModal={openModal}
                    />
                  </li>
                );
              })}
            </ul>
          );
        })}
      </React.Fragment>
    );
  }
};

export default ChildList;
