import { combineReducers } from "redux";

// Import required reducers.js
// COMMON
import information from "./information/reducers";
import layoutsShowHide from "./layouts-show-hide/reducers";
import mainMenu from "./main-menu/reducers";
import modal from "./modal/reducers";
import tunnel from "./tunnel/tunnel/reducers";
import opportuniteProspection from "./opportunite-prospection/reducers";
import uploadDocument from "./tunnel/common/upload-document/reducers";
import inputProcess from "./tunnel/common/input-process/reducers";
import referenceList from "./reference-list/reducers";
import tarif from "./tunnel/common/tarif/reducers";
import client from "./client/reducers";
import dateEffetContrat from "./tunnel/common/date-effet-contrat/reducers";
import recapitulatif from "./tunnel/common/recapitulatif/reducers";
import creationContrat from "./tunnel/common/creation-contrat/reducers";
import paiement from "./tunnel/common/paiement/reducers";
import signature from "./tunnel/common/signature/reducers";
import logger from "./logger/reducers";
import userBaloonPro from "./user-baloonpro/reducers";
import verifierTelephone from "./verifier-telephone/reducers";
import article from "./article/reducers";
import parrainage from "./parrainage/reducers";
import reprisePaiement from "./reprise-paiement/reducers";
// TUNNEL SANTE
import tranchesAges from "./tunnel/sante/tranches-ages/reducers";
import lead from "./tunnel/sante/lead/reducers";
import reseauxSoins from "./tunnel/sante/reseaux-soins/reducers";
import tauxCouverture from "./tunnel/sante/taux-couverture/reducers";
import assures from "./tunnel/sante/assures/reducers";
import questionsMedicales from "./tunnel/sante/questions-medicales/reducers";

const tunnelSanteReducers = combineReducers({
  tranchesAges,
  lead,
  reseauxSoins,
  tauxCouverture,
  assures,
  questionsMedicales
});

const commonTunnelReducers = combineReducers({
  uploadDocument,
  inputProcess,
  dateEffetContrat,
  tarif,
  recapitulatif,
  creationContrat,
  paiement,
  signature
});

const tunnelReducers = combineReducers({
  tunnel,
  common: commonTunnelReducers,
  sante: tunnelSanteReducers
});

const rootReducer = combineReducers({
  information,
  mainMenu,
  modal,
  opportuniteProspection,
  referenceList,
  client,
  logger,
  userBaloonPro,
  verifierTelephone,
  article,
  parrainage,
  reprisePaiement,
  layoutsShowHide,
  tunnel: tunnelReducers
});

export default rootReducer;
