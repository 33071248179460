import { getRouterSearch } from "../../../router/selectors";

export const needRadladInRouter = state => {
  const routerSearch = getRouterSearch(state);
  if (
    routerSearch !== null &&
    typeof routerSearch.need_radlad !== "undefined" &&
    routerSearch.need_radlad !== ""
  ) {
    return JSON.parse(routerSearch.need_radlad);
  }
  return null;
};

// Envoi de la requête upload document
export const isCreateUploadDocumentLoading = state =>
  state.tunnel.common.uploadDocument.create.loading;

// Envoi succes upload document
export const isCreateUploadDocumentSuccessfull = state => {
  return {
    success: state.tunnel.common.uploadDocument.create.success,
    radLad: state.tunnel.common.uploadDocument.create.radLad,
    risqueId: state.tunnel.common.uploadDocument.create.risqueId,
    simulationId: state.tunnel.common.uploadDocument.create.simulationId
  };
};

export const getRadladDocuments = () => {
  const radladFromSessionStorage = JSON.parse(
    sessionStorage.getItem("radladDocuments")
  );
  return radladFromSessionStorage;
};

// Erreur de l'envoi du document
export const isCreateUploadDocumentOnError = state =>
  state.tunnel.common.uploadDocument.create.error;

// Envoi de la requête upload document
export const isNeededUploadDocumentLoading = state =>
  state.tunnel.common.uploadDocument.need.loading;

// Envoi succes upload document
export const isNeededUploadDocumentSuccessfull = state =>
  state.tunnel.common.uploadDocument.need.success;

// Erreur de l'envoi du document
export const isNeededUploadDocumentError = state =>
  state.tunnel.common.uploadDocument.need.error;

// Donne l'autorisation du besoin d'upload d'un document
export const isDocumentNeeded = state =>
  state.tunnel.common.uploadDocument.need.isNeeded;

/**
 * Get attendu
 */
// Envoi de la requête
export const isDocumentAttenduLoading = state =>
  state.tunnel.common.uploadDocument.documentAttendu.loading;

export const isDocumentAttenduSuccessfull = state => {
  return {
    success: state.tunnel.common.uploadDocument.documentAttendu.success
  };
};

export const isDocumentAttenduError = state =>
  state.tunnel.common.uploadDocument.documentAttendu.error;

export const getDocumentAttenduCode = state => {
  return state.tunnel.common.uploadDocument.documentAttendu.code;
};
export const getDocumentAttenduRefId = state =>
  state.tunnel.common.uploadDocument.documentAttendu.natureDocumentClientRefId;

// Get current upload step
export const getCurrentUploadStep = state =>
  state.tunnel.common.uploadDocument.currentUploadStep;

/**
 * Documents risque attendu
 */
export const getDocumentsRisqueAttendu = state =>
  state.tunnel.common.uploadDocument.documentsRisqueAttendu.list.data;

export const isDocumentsRisqueAttenduLoading = state =>
  state.tunnel.common.uploadDocument.documentsRisqueAttendu.list.loading;

export const isDocumentsRisqueAttenduSuccess = state =>
  state.tunnel.common.uploadDocument.documentsRisqueAttendu.list.success;

export const isDocumentsRisqueAttenduError = state =>
  state.tunnel.common.uploadDocument.documentsRisqueAttendu.list.error;

/**
 * upload documents risque attendu
 */

export const isCreateUploadDocumentRisqueAttenduLoading = state =>
  state.tunnel.common.uploadDocument.documentsRisqueAttendu.create.loading;

export const isCreateUploadDocumentRisqueAttenduSuccess = state =>
  state.tunnel.common.uploadDocument.documentsRisqueAttendu.create.success;

export const isCreateUploadDocumentRisqueAttenduError = state =>
  state.tunnel.common.uploadDocument.documentsRisqueAttendu.create.error;

/**
 * End of upload
 */
export const isEndOfUploadLoading = state => {
  return null;
};
