import React from "react";
import { withNamespaces } from "react-i18next";

// Import components
import LinkButton from "../../../link-button";
import Title from "../../../title";

// Import media
import theme from "../../../../theme-style";

//helpers
import { gtagEvent } from "../../../../helpers/tags/tagsHelper";

const sas_tmstp = Math.round(Math.random() * 10000000000);

const SuccessForm = props => {
  const { t, closePopin } = props;
  const { Thumbup } = theme.icons.theme;

  if (window.location.hostname.includes("baloon.sn")) {
    gtagEvent(745039823, "LKZUCKbs1PUBEM_PoeMC");
  }

  return (
    <div className="success">
      {window.location.hostname.includes(".sn") && (
        <>
          <img
            src={
              "https://www3.smartadserver.com/h/tp?advid=447692&trcid=31612&val=&transid=&ref=&uid=&tmstp='" +
              sas_tmstp +
              "'"
            }
            width={1}
            height={1}
            border={0}
          />
          <img
            src={"https://secure.adnxs.com/px?id=1443270&t=2"}
            width={1}
            height={1}
          />
        </>
      )}
      <Title titleType={2} textAlign="center">
        {t("shared.merciExclam")}
      </Title>
      <Title titleType={2} noMargin textAlign="center">
        {t("contact.bienRecuVotreDemandeRappel")}
      </Title>
      <Title titleType={2} noMargin textAlign="center">
        {t("contact.unConseillerVousContacte")}
      </Title>
      <img src={Thumbup} alt="thumbUp" />
      <LinkButton
        config={{ type: "button", style: "plain" }}
        onClick={e => closePopin(e)}
      >
        {t("shared.continuer")}
      </LinkButton>
    </div>
  );
};

export default withNamespaces("translations")(SuccessForm);
