import React from "react";
// Import custom proptypes
import { titleProptypes } from "./proptypes";

// Import styles
import { TitleLv02, TitleLv1, TitleLv2, TitleLv3, TitleLv4 } from "./style";

const Title = props => {
  const { titleType, children, ...rest } = props;
  const titleProps = () => {
    return {
      children: children,
      titleType: titleType ? titleType : 1,
      ...rest
    };
  };
  if (titleType === 2) return <TitleLv2 {...titleProps()} />;
  if (titleType === 3) return <TitleLv3 {...titleProps()} />;
  if (titleType === 4) return <TitleLv4 {...titleProps()} />;
  if (titleType === 0) return <TitleLv02 {...titleProps()} />;
  return <TitleLv1 {...titleProps()} />;
};

Title.propTypes = titleProptypes;

export default Title;
