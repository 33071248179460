import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { position } from "caret-pos";

// Store
import { getPhoneConfig } from "../../../../store/information/selectors";

// Import components
import Input from "../input";

// Helper
import formatPhoneNumber from "../../../../helpers/data/format-phone-number";

// Import style
import { StyledPhoneInput } from "./style";

const mapStateToProps = state => ({
  config: getPhoneConfig(state)
});

class PhoneInput extends Component {
  constructor(props) {
    super(props);
    const modele = this.props.config.modulo.split(",");
    const length = modele.reduce((acc, m) => {
      return acc + parseInt(m, 10) + 1;
    }, -1);
    this.state = {
      triggerFocusInput: false,
      phoneNumber: "",
      isPhoneFilled: false,
      shift: 0,
      reqLength: length,
      modele,
      cursorPos: null
    };
    this.onChangeInputPhone = this.onChangeInputPhone.bind(this);
    this.cursorShift = this.cursorShift.bind(this);
    this.onTriggerFocus = this.onTriggerFocus.bind(this);
  }

  onTriggerFocus(e) {
    e.preventDefault();
    this.setState({
      triggerFocusInput: !this.state.triggerFocusInput
    });
  }

  onChangeInputPhone(e) {
    e.persist();
    const { modele } = this.state;
    const modeleLength = modele.length;

    let tmpPhoneNumber = e.target.value.replace(/\D/g, ""); //Filtre qui retire tout ce qui n'est pas un chiffre

    const phoneNumber = formatPhoneNumber(
      tmpPhoneNumber,
      modele,
      this.props.config.separator
    );
    let reqLength = 0;
    for (let i = 0; i < modeleLength; i++) {
      reqLength += parseInt(modele[i], 10) + 1; //On calcule la longueur requise du numéro de téléphone selon le modèle, en pensant aux espaces (+1)
    }
    const isPhoneFilled = phoneNumber.length === reqLength - 1;
    //Boolean de la vérification de la longueur requise (en pensant que le dernier chiffre ne comporte pas d'espace (-1))

    this.setState(
      //Mise dans le state des valeurs calculées
      {
        phoneNumber,
        isPhoneFilled,
        reqLength: reqLength - 1
      },
      () => {
        e.target.value = phoneNumber;
        e.isValidPhone = this.state.isPhoneFilled;
        this.props.onChangeCallbackPhone(e);

        let pos;
        if (this.state.cursorPos !== null) {
          //Si un des trois cas du shift a été detecté
          pos = this.state.cursorPos; //on utilise l'ancienne position
        } else {
          pos = position(e.target).pos; //sinon, on prends la position actuelle
        }
        position(e.target, pos + this.state.shift); //et on y applique le shift calculé
        if (e.target.value.length === 0) {
          this.setState({ shift: 0 }, () => {
            position(e.target, -1);
          });
        }
      }
    );
  }

  cursorShift = e => {
    //SetUp des informations nécessaires au calcul du shift d'ajustement
    const cursorPos = position(e.target).pos;
    this.setState({ shift: 0 });
    const charAfter = e.target.value.slice(cursorPos, cursorPos + 1);
    const charBefore = e.target.value.slice(cursorPos - 1, cursorPos);
    const targetKey = e.key.toLowerCase();

    //SetUp des calculs conditionnels
    const isSpaceCharBefore = charBefore === " ";
    const isSpaceCharAfter = charAfter === " ";
    const targetKeyEquals = key => {
      return targetKey === key;
    };

    if (targetKeyEquals("backspace")) {
      //Si on appuie sur "Retour"
      this.setState({ shift: -1 }); //le curseur se décale de -1
      if (isSpaceCharBefore) {
        position(e.target, cursorPos - 1);
        this.setState({ shift: -2 }); //mais si on se trouve après un espace, le curseur se décale de -2
      }
    } else if (targetKeyEquals("delete")) {
      //Si on appuie sur "delete"
      this.setState({ shift: 0 }); //le curseur ne se décale pas
      if (isSpaceCharAfter) {
        position(e.target, cursorPos + 1);
        this.setState({ shift: 1 }); //mais si on se trouve avant un espace, le curseur se décale de 1
      }
    } else if (/\d/g.test(targetKey) && charBefore !== "") {
      //Si on insère un chiffre qui n'est pas le premier chiffre du numéro
      this.setState({ shift: 2 }); //on décale de curseur de 2
    } else {
      this.setState({ shift: 1 }); //sinon, on décale de 1
    }

    if (
      targetKeyEquals("backspace") ||
      targetKeyEquals("delete") ||
      (/\d/g.test(targetKey) && charBefore !== "")
    ) {
      this.setState({
        cursorPos //Si l'on rentre dans un des trois cas précédents, on envoie la position pré-modification dans le state
      });
    }
  };

  render() {
    const { onChangeCallback, config, className, ...rest } = this.props;
    const { triggerFocusInput, reqLength } = this.state;
    const { placeholder, indicator } = config;
    const InputTelIndicator = () => (
      <span onClick={e => this.onTriggerFocus(e)} className="indicator">
        <span>{indicator == "241" ? "241(0)" : indicator}</span>
      </span>
    );
    return (
      <StyledPhoneInput
        className={`input-phone ${
          typeof className !== "undefined" ? className : ""
        }`}
      >
        <Input
          triggerFocus={triggerFocusInput}
          inputType="tel"
          inputPrefix={<InputTelIndicator />}
          onChangeCallback={e => this.onChangeInputPhone(e)}
          onInputKeyDown={this.cursorShift}
          placeholder={placeholder}
          maxLength={reqLength}
          {...rest}
        />
      </StyledPhoneInput>
    );
  }
}

export default compose(
  connect(
    mapStateToProps,
    null
  )
)(PhoneInput);
