import styled from "styled-components";

export const StyledSvgIcon = styled.svg`
  .hover {
    circle {
      stroke: ${props =>
        props.isActive
          ? props.theme.colors.themeColors.altRegular
          : "transparent"};
    }
  }
`;
