import * as a from "./actions";

const INITIAL_STATE = {
  showMainMenu: true,
  showHeaderOnlyLogo: false,
  showHeaderOnlyLogoURL: null
};

export function layoutsShowHide(state = INITIAL_STATE, action) {
  switch (action.type) {
    case a.SHOW_MAIN_MENU:
      return {
        ...state,
        showMainMenu: action.isVisible
      };
    case a.SHOW_HEADER_ONLY_LOGO:
      return {
        ...state,
        showHeaderOnlyLogo: action.isVisible
      };
    case a.SET_HEADER_ONLY_LOGO_URL:
      return {
        ...state,
        showHeaderOnlyLogoURL: action.url
      };
    default:
      return state;
  }
}

export default layoutsShowHide;
