import * as a from "./actions";

const INITIAL_STATE = {
  success: false,
  loading: false,
  error: false,
  token: null,
  clientId: null
};

export function userBaloonPro(state = INITIAL_STATE, action) {
  switch (action.type) {
    case a.GET_USER_BALOONPRO_INFO.REQUEST:
      return {
        ...state,
        success: false,
        loading: true,
        error: false
      };
    case a.GET_USER_BALOONPRO_INFO.SUCCESS:
      return {
        ...state,
        success: true,
        loading: false,
        error: false,
        token: action.payload.token,
        clientId: action.payload.clientId
      };
    case a.GET_USER_BALOONPRO_INFO.FAILURE:
      return {
        ...state,
        success: false,
        error: true,
        loading: false
      };
    default:
      return state;
  }
}

export default userBaloonPro;
