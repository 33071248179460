import styled from "styled-components";

export const StyledProgressBar = styled.div`
  background-color: ${props => props.theme.colors.themeColors.ultralightgrey};
  height: 4px;
  width: 100%;
  > span {
    background-color: ${props => props.theme.colors.themeColors.regular};
    display: block;
    height: 100%;
    width: calc(((100% / 4) * ${props => props.currentStep + 1}) - 2%);
  }
`;
