import axios from "axios";
import API from "../api";

export async function ajout(mainToken, telephones) {
  return new Promise(resolve => {
    const url = API.parrainage.main;
    const dataPost = telephones;
    const request = axios.post(url, dataPost, {
      headers: {
        Authorization: `bearer ${mainToken}`
        // "Content-Type": "multipart/form-data"
      }
    });
    return request
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        resolve({ error: true, res: err });
      });
  });
}

export async function connexion(mainToken, guid) {
  const params = {
    guid: guid
  };
  return new Promise(resolve => {
    const url = API.parrainage.connexion;
    const request = axios.get(url, {
      params,
      headers: {
        Authorization: `bearer ${mainToken}`
      }
    });
    return request
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        resolve({ error: true, res: err });
      });
  });
}
