import React, { Component } from "react";
import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import { compose, bindActionCreators } from "redux";
import Title from "../../../title";
import Paragraph from "../../../paragraph";

// Store
import {
  createOpportuniteProspection,
  resetOpportuniteProspection,
  createOpportuniteProspectionWithComment
} from "../../../../store/opportunite-prospection/actions";
import {
  isDemandeContactLoading,
  isDemandeContactOnError,
  isDemandeSuccessfull
} from "../../../../store/opportunite-prospection/selectors";
import { isModalEtreRappelleOpened } from "../../../../store/modal/selectors";
import {
  getContactInfos,
  getPhoneConfig
} from "../../../../store/information/selectors";
import { getExtractedUserInfosFrom } from "../../../../store/client/selectors";

// Import components
import Loader from "../../../loader";
import PopinFormMarqueBlanche from "../popin-form-marque-blanche";
import LinkButton from "../../../link-button";
import SuccessForm from "../success-form";

// Import media
import { MapMarker } from "styled-icons/fa-solid";
import { Envelope } from "styled-icons/fa-regular/Envelope";

// Import style
import { StyledContactServiceClientMarqueBlanche } from "./style";

const mapStateToProps = state => ({
  modalOpened: isModalEtreRappelleOpened(state),
  isDemandeLoading: isDemandeContactLoading(state),
  isDemandeOnError: isDemandeContactOnError(state),
  isDemandeSuccess: isDemandeSuccessfull(state),
  contactInfos: getContactInfos(state),
  clientIds: getExtractedUserInfosFrom(state),
  phoneConfig: getPhoneConfig(state)
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      createOpportuniteProspection,
      resetOpportuniteProspection,
      createOpportuniteProspectionWithComment
    },
    dispatch
  )
});

class ContactServiceClientMarqueBlanche extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSubmittable: props.clientIds !== null,
      resetForm: false,
      phoneOnError: false
    };
    this.onClosePopin = this.onClosePopin.bind(this);
    this.getFormSubmittedData = this.getFormSubmittedData.bind(this);
    this.isSubmittableForm = this.isSubmittableForm.bind(this);
    this.onClickPhoneNumber = this.onClickPhoneNumber.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.resetContent !== this.props.resetContent) {
      this.resetForm();
    }
    if (prevProps.isDemandeLoading !== this.props.isDemandeLoading) {
      if (this.props.isDemandeLoading) this.props.callbackBlockScroll(true);
    }

    if (!this.props.isDemandeLoading) {
      this.props.callbackBlockScroll(false);
    }

    if (
      prevProps.isDemandeOnError !== this.props.isDemandeOnError &&
      typeof this.props.isDemandeOnError !== "undefined" &&
      this.props.isDemandeOnError !== null
    ) {
      this.setState({
        phoneOnError: typeof this.props.isDemandeOnError === "object"
      });
    }
  }

  isSubmittableForm(isSubmittable) {
    this.setState({
      isSubmittable
    });
  }

  onClickPhoneNumber = (e, phone) => {
    e.preventDefault();
    window.open(phone);
  };

  resetForm() {
    this.props.callbackBlockScroll(false);
    this.props.actions.resetOpportuniteProspection();
    this.setState({
      resetForm: !this.state.resetForm,
      isSubmittable: false
    });
  }

  getFormSubmittedData(data) {
    const { clientIds } = this.props;
    if (clientIds !== null) {
      if (data.telephone === clientIds.telephone) {
        this.props.actions.createOpportuniteProspection(data);
      } else {
        this.props.actions.createOpportuniteProspectionWithComment({
          civilite: data.civilite,
          nom: data.nom,
          telephone: data.telephone,
          sujetDemande:
            "Le client a pour numeros : " +
            clientIds.telephone +
            " mais demande a �tre rappel� sur le :" +
            data.telephone,
          branche: data.branche,
          origineWebCode: data.origineWebCode
        });
      }
    } else {
      this.props.actions.createOpportuniteProspection(data);
    }
  }

  onClosePopin(e) {
    e.preventDefault();
    this.resetForm();
    this.props.onClose(e);
    // ALLLO
  }

  renderForSubmitLoader() {
    if (this.props.isDemandeLoading)
      return {
        component: <Loader />,
        className: "is-loading"
      };
    return {
      component: null,
      className: ""
    };
  }

  renderDemand() {
    const {
      t,
      isDemandeSuccess,
      contactInfos,
      clientIds,
      phoneConfig
    } = this.props;
    const { telephoneFixe, horairesOuverture, adresse, email } = contactInfos;
    const { phoneOnError, isSubmittable, resetForm } = this.state;
    if (isDemandeSuccess) return <SuccessForm closePopin={this.onClosePopin} />;
    let nousContacter = null;
    if (telephoneFixe) {
      nousContacter = (
        <LinkButton
          config={{ type: "button", style: "nostyle" }}
          // to={`tel:${telephoneFixe.value}`}
          onClick={e =>
            this.onClickPhoneNumber(e, `tel:${telephoneFixe.value}`)
          }
        >
          {telephoneFixe.label}
        </LinkButton>
      );
    } else {
      nousContacter = (
        <LinkButton
          config={{ type: "button", style: "nostyle" }}
          // to={`tel:${telephoneFixe.value}`}
        />
      );
    }
    return (
      <React.Fragment>
        <div>
          <Title titleType={2} textAlign="center">
            {t("contact.pourNousJoindreContactez")} {nousContacter}
          </Title>
          <div className="presence">
            <div>
              <Paragraph noMargin>
                {`${t("shared.nousSommesLa")} ${horairesOuverture}`}
              </Paragraph>
            </div>
          </div>
          <div className="contact-infos">
            <Paragraph>
              <Envelope size={16} /> <a href={`mailto:${email}`}>{email}</a>
            </Paragraph>
            <Paragraph>
              <MapMarker size={16} /> {adresse}
            </Paragraph>
          </div>
        </div>
        <div>
          <Title titleType={3}>{t("shared.etreRappelleCoordonnees")}</Title>
          <PopinFormMarqueBlanche
            callback={this.getFormSubmittedData}
            callbackSubmittable={this.isSubmittableForm}
            isOnError={phoneOnError ? true : null}
            resetForm={resetForm}
            clientIds={clientIds}
            phoneConfig={phoneConfig}
            fromContactServiceClient={true}
          >
            <LinkButton
              config={{ type: "button", style: "plain", isSubmit: true }}
              style={{ visibility: isSubmittable ? "visible" : "hidden" }}
            >
              {t("shared.envoyerDemandeRappel")}
            </LinkButton>
          </PopinFormMarqueBlanche>
        </div>
      </React.Fragment>
    );
  }

  renderContactServiceClient() {
    const isSuccess =
      !this.props.isDemandeLoading &&
      !this.props.isDemandeOnError &&
      this.props.isDemandeSuccess;
    return (
      <React.Fragment>
        {this.renderForSubmitLoader().component}
        <div className={isSuccess ? "" : "initial"}>{this.renderDemand()}</div>
      </React.Fragment>
    );
  }

  render() {
    return (
      <StyledContactServiceClientMarqueBlanche
        className={this.renderForSubmitLoader().className}
      >
        {this.renderContactServiceClient()}
      </StyledContactServiceClientMarqueBlanche>
    );
  }
}

export default compose(
  withNamespaces("translations"),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ContactServiceClientMarqueBlanche);
