import * as a from "./actions";

const INITIAL_STATE = {
  etreRappelle: {
    loading: false,
    isOpened: false
  },
  campaign: {
    loading: false,
    isOpened: false
  },
  saveTunnelAuto: {
    loading: false,
    isOpened: false
  }
};

export function modal(state = INITIAL_STATE, action) {
  switch (action.type) {
    /* Etre rappellé */
    case a.OPENCLOSE_MODAL_ETRE_RAPPELLE.REQUEST:
      return {
        ...state,
        etreRappelle: {
          loading: true,
          isOpened: action.isOpened
        }
      };
    case a.OPENCLOSE_MODAL_ETRE_RAPPELLE.SUCCESS:
      return {
        ...state,
        etreRappelle: {
          loading: false,
          isOpened: action.payload.isOpened
        }
      };
    case a.OPENCLOSE_MODAL_ETRE_RAPPELLE.FAILURE:
      return {
        etreRappelle: INITIAL_STATE.etreRappelle
      };
    /* Campaign modal */
    case a.OPENCLOSE_MODAL_CAMPAIGN.REQUEST:
      return {
        ...state,
        campaign: {
          loading: true,
          isOpened: action.isOpened
        }
      };
    case a.OPENCLOSE_MODAL_CAMPAIGN.SUCCESS:
      return {
        ...state,
        campaign: {
          loading: false,
          isOpened: action.payload.isOpened
        }
      };
    case a.OPENCLOSE_MODAL_CAMPAIGN.FAILURE:
      return {
        campaign: INITIAL_STATE.etreRappelle
      };
    /* Save tunnel Auto */
    case a.OPENCLOSE_MODAL_SAVE_TUNNEL_AUTO.REQUEST:
      return {
        ...state,
        saveTunnelAuto: {
          loading: true,
          isOpened: action.isOpened
        }
      };
    case a.OPENCLOSE_MODAL_SAVE_TUNNEL_AUTO.SUCCESS:
      return {
        ...state,
        saveTunnelAuto: {
          loading: false,
          isOpened: action.payload.isOpened
        }
      };
    case a.OPENCLOSE_MODAL_SAVE_TUNNEL_AUTO.FAILURE:
      return {
        saveTunnelAuto: INITIAL_STATE.saveTunnelAuto
      };
    /* Close all modals */
    case a.CLOSE_MODALS.REQUEST:
      return {
        ...state,
        INITIAL_STATE
      };
    case a.CLOSE_MODALS.SUCCESS:
      return {
        ...state,
        INITIAL_STATE
      };
    case a.CLOSE_MODALS.FAILURE:
      return { state };
    default:
      return state;
  }
}

export default modal;
