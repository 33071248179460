import { call, all, put, takeLatest, select, take } from "redux-saga/effects";
import * as a from "./actions";

// Import selects
import * as informationSelectors from "../information/selectors";
// Services
import * as parrainageServices from "./services";
// Actions
import * as verifierTelephoneActions from "../verifier-telephone/actions";
// Sagas
import * as loggerSagas from "../logger/saga";
import { setToken } from "../../helpers/data/cookie-user";

function* verifierTelephoneFilleul({ telephone, index }) {
  try {
    yield put({
      type: verifierTelephoneActions.VERIFIER_TELEPHONE.REQUEST,
      telephone
    });
    const result = yield take([
      verifierTelephoneActions.VERIFIER_TELEPHONE.FAILURE,
      verifierTelephoneActions.VERIFIER_TELEPHONE.SUCCESS
    ]);
    if (typeof result.payload.error !== "undefined") {
      yield put({
        type: a.VERIFIER_TELEPHONE_FILLEUL.FAILURE,
        payload: {
          index,
          error: result.payload.error
        }
      });
    } else {
      yield put({
        type: a.VERIFIER_TELEPHONE_FILLEUL.SUCCESS,
        payload: {
          index,
          valide: result.payload.valide
        }
      });
    }
  } catch (e) {}
}

function* ajoutParrainages({ telephones }) {
  try {
    const mainToken = yield select(informationSelectors.getMainToken);
    const ajoutParrainage = yield call(
      parrainageServices.ajout,
      mainToken,
      telephones
    );
    if (!ajoutParrainage.error) {
      yield put({
        type: a.AJOUTER_PARRAINAGES.SUCCESS,
        payload: ajoutParrainage
      });
    } else {
      const errorRes = ajoutParrainage.res.response;
      if (errorRes.request.status !== 400) {
        yield call(loggerSagas.postLogErrorApi, {
          sagaInit: "ajoutParrainages",
          apiResponseUrl: errorRes.request.responseURL,
          statusResponse: errorRes.request.status,
          dataResponse: errorRes.data,
          token: mainToken,
          params: {
            telephones
          }
        });
      }
      yield put({
        type: a.AJOUTER_PARRAINAGES.FAILURE,
        payload: true
      });
    }
  } catch (e) {}
}

function* connexionClient({ guid }) {
  try {
    const mainToken = yield select(informationSelectors.getMainToken);
    const connexionClient = yield call(
      parrainageServices.connexion,
      mainToken,
      guid
    );
    if (!connexionClient.error) {
      const tokenClient = connexionClient.token;
      setToken(tokenClient);
      yield put({
        type: a.CONNEXION_CLIENT.SUCCESS,
        payload: connexionClient
      });
    } else {
      const errorRes = connexionClient.res.response;
      if (errorRes.request.status !== 400) {
        yield call(loggerSagas.postLogErrorApi, {
          sagaInit: "connexionClient",
          apiResponseUrl: errorRes.request.responseURL,
          statusResponse: errorRes.request.status,
          dataResponse: errorRes.data,
          token: mainToken,
          params: {
            guid
          }
        });
      }
      yield put({
        type: a.CONNEXION_CLIENT.FAILURE,
        payload: { error: true }
      });
    }
  } catch (e) {}
}

export default all([
  takeLatest(a.VERIFIER_TELEPHONE_FILLEUL.REQUEST, verifierTelephoneFilleul),
  takeLatest(a.AJOUTER_PARRAINAGES.REQUEST, ajoutParrainages),
  takeLatest(a.CONNEXION_CLIENT.REQUEST, connexionClient)
]);
