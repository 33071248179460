import * as a from "./actions";

const INITIAL_OPPORTUNITE_PROSPECTION_STATE = {
  error: null,
  loading: false,
  success: false
};

export function opportuniteProspection(
  state = INITIAL_OPPORTUNITE_PROSPECTION_STATE,
  action
) {
  let error;
  switch (action.type) {
    case a.CREATE_OPPORTUNITE_PROSPECTION.REQUEST:
      return {
        ...state,
        success: state.success,
        error: null,
        loading: true
      };
    case a.CREATE_OPPORTUNITE_PROSPECTION.SUCCESS:
      return {
        ...state,
        success: action.payload,
        error: null,
        loading: false
      };
    case a.CREATE_OPPORTUNITE_PROSPECTION.FAILURE:
      error = action.payload;
      return {
        ...state,
        success: false,
        error: error,
        loading: false
      };
    case a.CREATE_OPPORTUNITE_PROSPECTION_WITH_COMMENT.REQUEST:
      return {
        ...state,
        success: state.success,
        error: null,
        loading: true
      };
    case a.CREATE_OPPORTUNITE_PROSPECTION_WITH_COMMENT.SUCCESS:
      return {
        ...state,
        success: action.payload,
        error: null,
        loading: false
      };
    case a.CREATE_OPPORTUNITE_PROSPECTION_WITH_COMMENT.FAILURE:
      error = action.payload;
      return {
        ...state,
        success: false,
        error: error,
        loading: false
      };
    case a.RESET_NEW_OPPORTUNITE_PROSPECTION:
      return INITIAL_OPPORTUNITE_PROSPECTION_STATE;
    default:
      return state;
  }
}

export default opportuniteProspection;
