import styled from "styled-components";
import Page from "../page";

export const StyledLayoutTunnel = styled(Page)`
  .wrapper-content {
    &.retour {
      align-items: flex-start;
      color: ${props => props.theme.colors.themeColors.regular};
      svg {
        cursor: pointer;
      }
      + .wrapper-content {
        padding-top: 0;
      }
    }
  }
`;
