import { getRouterSearch } from "../router/selectors";

export const getReprisePaiementGuid = state => {
  const pattern = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
  let guid = null;
  if (getRouterSearch(state) !== null) {
    const routerSearch = getRouterSearch(state);
    if (
      typeof routerSearch.paiement_guid !== "undefined" &&
      routerSearch.paiement_guid !== "" &&
      pattern.test(routerSearch.paiement_guid)
    ) {
      guid = routerSearch.paiement_guid;
    }
  }
  return guid;
};

// Is loading post ReprisePaiement
export const isReprisePaiementLoading = state => state.reprisePaiement.loading;

// Is loading post ReprisePaiement
export const isReprisePaiementSuccess = state => state.reprisePaiement.success;

// Is loading post ReprisePaiement
export const isReprisePaiementError = state => state.reprisePaiement.error;

export const getBranche = state => state.reprisePaiement.branche;
