import styled from "styled-components";
import breakpoint from "styled-components-breakpoint";
import { commonPopinFormStyle } from "../../style";

export const StyledContactServiceClient = styled.div`
  .error {
    margin-bottom: 15px;
  }
  .contactWhatsApp {
    color: #25d366;
    height: 75px;
    width: 80%;
    margin-top: 20px;
    margin: 20px auto;
    display: flex;
    flex-direction: column;
    text-align: left;
    border: solid grey 2px;
    border-radius: 15px;
    padding: 8px;
  }
  .contactWhatsApp .iconWhatsApp {
    position: absolute;
    margin: 0;
  }
  .contactWhatsApp .titre {
    flex: 3;
    margin-top: 12px;
    font-size: 20px;
    color: #25d366;
  }
  .contactWhatsApp > div {
    flex: 2;
    margin-left: 70px;
    font-size: 15px;
    color: grey;
  }

  .contactInstagram {
    color: #25d366;
    height: 45px;
    width: 80%;
    display: flex;
    flex-direction: column;
    text-align: left;
    padding: 8px;
  }
  .contactInstagram .iconInstagram {
    position: absolute;
    margin: 0;
  }
  .iconInstagram > img {
    width: 32px;
  }
  .contactInstagram .titre {
    flex: 3;
    font-size: 20px;
  }
  .contactInstagram > div {
    margin-top: 6px;
    flex: 2;
    margin-left: 35px;
    font-size: 15px;
    color: grey;
  }

  .InstaParag {
    padding-left: 0px !important;
  }
  .InstaLogo {
    width: 16px;
    margin-right: 13px;
  }
  a {
    color: inherit;
    text-decoration: none;
  }
  .title {
    display: flex;
    flex-direction: column;
    text-align: center;
    .linkButton {
      color: inherit;
      display: inline;
      text-decoration: none;
    }
    > span {
      font-size: 2.6rem;
    }
  }
  .baloon-shape {
    background-color: ${props => props.theme.colors.themeColors.altLight};
    height: 110px;
    margin-right: 20px;
    width: 110px;
    img {
      border-radius: 50% 0 50% 50%;
    }
  }
  .presence {
    align-items: flex-start;
    > div {
      display: flex;
      margin: 10px auto 0;
      max-width: 90%;
    }
  }
  .contact-infos {
    display: none;
  }
  .success {
    .title {
      max-width: 100% !important;
    }
  }
  .radios-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    > * {
      max-width: 345px;
      width: 100%;
    }
  }
  .radio-choices {
    flex-flow: row wrap;
    .choice {
      box-sizing: border-box;
      margin-right: 0;
      padding-right: 20px;
      width: 50%;
    }
  }
  ${commonPopinFormStyle};
  ${breakpoint("mobile", "tablet")`
    .presence {
      border-bottom: 1px solid lightgray;
      margin-bottom: 20px;
      padding-bottom: 20px;
    }
  `};
  ${breakpoint("desktop")`
    .initial {
      display: flex;
      padding: 10px;
      > div {
        &:first-of-type {
          margin-right: 28px;
        }
        &:last-of-type {
          border-left: 1px solid lightgray;
          padding-left: 28px;
        }
      }
    }
    .presence > div {
      margin-top: 20px;
    }
    .contact-infos {
      display: block;
      margin-top: 55px;
      .paragraph {
        box-sizing: border-box;
        color: ${props => props.theme.colors.themeColors.thirdLight};
        font-size: 1.6rem;
        font-weight: ${props => props.theme.fonts.main.variants.bold};
        margin-bottom: 10px;
        padding-left: 30px;
        position: relative;
        svg {
          left: 0;
          margin-right: 15px;
          position: absolute;
          transform: translateY(1px);
        }
      }
    }
  `};
`;
