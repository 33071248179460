import { call, all, put, takeLatest, select, take } from "redux-saga/effects";
import * as a from "./actions";

// Cookie
import { setToken } from "../../../../helpers/data/cookie-user";

// Selectors
import * as tunnelSelectors from "../../tunnel/selectors";
import * as informationSelectors from "../../../information/selectors";
import * as contratSelector from "./selectors";
// Services
import * as creationContratService from "./services";
// Sagas
import * as tunnelSagas from "../../tunnel/saga";
import * as loggerSagas from "../../../logger/saga";
// Actions
import * as paiementActions from "../paiement/actions";

function* creationContrat({ assuranceType, redirectionUrl }) {
  try {
    const mainToken = yield select(informationSelectors.getMainToken);
    const simulationIds = yield select(tunnelSelectors.getCurrentSimulationIds);
    const hasToRedirect =
      typeof redirectionUrl !== "undefined" || redirectionUrl !== null;
    if (simulationIds.simulationId === null) {
      yield call(
        tunnelSagas.redirectToUrl,
        `/assurance-${assuranceType || "automobile"}/informations-manquantes`
      );
    } else {
      const contrat = yield call(
        creationContratService.create,
        assuranceType,
        mainToken,
        simulationIds.simulationId
      );
      if (!contrat.error) {
        if (assuranceType === "sante") {
          if (contrat.token !== null) setToken(contrat.token);
        }
        yield call(tunnelSagas.setIds, {
          contratId: contrat.contratId,
          quittanceId: contrat.quittanceId,
          versionContratId: contrat.versionContratId
        });
        yield put({
          type: a.SET_CREATION_CONTRAT.SUCCESS,
          payload: contrat
        });
        if (hasToRedirect) {
          let redir;
          const hasNoPaiementOperators = yield select(
            informationSelectors.getOperateursPaiement
          );
          if (assuranceType === "automobile") {
            redir = "/assurance-automobile/paiement";
            if (hasNoPaiementOperators.length <= 0) {
              redir = "/assurance-automobile/rappel-paiement";
              yield put({
                type: paiementActions.RAPPEL_PAIEMENT.REQUEST
              });
              //const waitSuccess =
              yield take(paiementActions.RAPPEL_PAIEMENT.SUCCESS);
              // const need = waitSuccess.payload;
            }
          } else if (assuranceType === "sante") {
            redir =
              redirectionUrl === true
                ? "/assurance-sante/connexion"
                : "/assurance-sante/paiement";
            if (hasNoPaiementOperators && !redirectionUrl) {
              redir = "/assurance-sante/rappel-paiement";
              yield put({
                type: paiementActions.RAPPEL_PAIEMENT.REQUEST
              });
              // const waitSuccess =
              yield take(paiementActions.RAPPEL_PAIEMENT.SUCCESS);
              // const need = waitSuccess.payload;
            }
          }
          yield call(tunnelSagas.redirectToUrl, redir);
        }
      } else {
        const errorRes = contrat.res.response;
        if (errorRes.request.status !== 400) {
          yield call(loggerSagas.postLogErrorApi, {
            sagaInit: "creationContrat",
            apiResponseUrl: errorRes.request.responseURL,
            statusResponse: errorRes.request.status,
            dataResponse: errorRes.data,
            token: mainToken,
            params: {
              simulationId: simulationIds.simulationId
            }
          });
        }
        yield put({
          type: a.SET_CREATION_CONTRAT.FAILURE,
          payload: true
        });
      }
    }
  } catch (e) {
    yield put({
      type: a.SET_CREATION_CONTRAT.FAILURE,
      payload: true
    });
  }
}

function* downloadConditions(conditionType) {
  try {
    const sessionIds = JSON.parse(sessionStorage.getItem("ids"));
    let contratId = yield select(contratSelector.getContratId);
    let versionId = yield select(contratSelector.getVersionContratId);
    if (contratId == null || typeof contratId === "undefined") {
      contratId = sessionIds.contratId;
      versionId = sessionIds.versionContratId;
    }
    const mainToken = yield select(informationSelectors.getMainToken);
    const conditions = yield call(
      creationContratService.downloadConditions,
      contratId,
      versionId,
      conditionType,
      mainToken
    );

    let url = window.URL.createObjectURL(conditions);
    let fakeClick = document.createElement("a");
    fakeClick.href = url;
    fakeClick.download =
      conditionType.conditionType === "conditionsParticulieres"
        ? "Contrat.pdf"
        : "ConditionsGenerales.pdf";
    fakeClick.click();
    if (conditions !== null) {
      yield put({
        type: a.DOWNLOAD_CONDITIONS.SUCCESS,
        payload: conditions,
        conditionType
      });
    } else {
      yield put({
        type: a.DOWNLOAD_CONDITIONS.FAILURE,
        payload: conditions
      });
    }
  } catch (e) {}
}

export default all([
  takeLatest(a.SET_CREATION_CONTRAT.REQUEST, creationContrat),
  takeLatest(a.DOWNLOAD_CONDITIONS.REQUEST, downloadConditions)
]);
