// Import helpers
import {
  getToken,
  isAnonyme,
  isClient,
  isUserBaloonPro as isUserBaloonProHelper
} from "../../helpers/data/cookie-user";
import { getRouterSearch } from "../router/selectors";

// Import appConstants
import appConstants from "../../configuration/constants";

// Get current information
export function getInformation(state) {
  return state.information;
}

// is loading main informations
export const isInformationsSuccess = state => state.information.success;

// is loading main informations
export const isInformationsLoading = state => state.information.loading;

// is error main informations
export const isInformationsError = state => state.information.error;

// Get current token
export const getMainToken = state => {
  if (getToken() !== null) return getToken();
  return state.information.token;
};

// Get current Popin
export const getCurrentCampaignPopin = state => {
  const campaigns = state.information.campaign.popin;
  if (campaigns.length > 0) {
    const routerSearch = getRouterSearch(state);
    if (
      routerSearch !== null &&
      typeof routerSearch.popinCampaignName !== "undefined" &&
      routerSearch.popinCampaignName !== ""
    ) {
      const validCampaign = campaigns.find(
        campaign => campaign.name === routerSearch.popinCampaignName
      );
      if (validCampaign) {
        return routerSearch.popinCampaignName;
      }
      return null;
    }
    return null;
  }
  return null;
};

// Get current country
export const getCountry = state => state.information.pays.toLowerCase();

// Get locales
export const getLocales = state => state.information.locales;

// Get current locales
export const getCurrentLocale = state => state.information.locales[0];

// Get current locales
export const getHorairesOuverture = state => {
  const horairesArray = state.information.horairesOuverture;
  const extract = horairesArray.find(
    horaire => horaire.locale === getCurrentLocale(state)
  );
  return typeof extract !== "undefined" ? extract.text : null;
};

// Extract coordonnées infos
export const extractCoordonnes = (state, type) => {
  const coordonneesArray = state.information.coordonneesContact;
  const extract = coordonneesArray.find(coord => coord.type === type);
  let callback;
  let valeur = typeof extract !== "undefined" ? extract.valeur : null;
  let callbackValues = null;
  if (valeur !== null) {
    switch (type) {
      case "TelFixe":
        callbackValues = {};
        callbackValues.label = valeur;
        callbackValues.value = `+${valeur.replace(/\D/g, "")}`;
        callback = callbackValues;
        break;
      case "Whatsapp":
        callbackValues = {};
        callbackValues.label = valeur;
        callbackValues.value = `+${valeur.replace(/\D/g, "")}`;
        callback = callbackValues;
        break;
      default:
        callback = valeur;
        break;
    }
  } else {
    callback = null;
  }
  return callback;
};

// Get current contact infos
export const getContactInfos = state => {
  return {
    nomActeurCommercial: state.information.nomActeurCommercial,
    adresse: state.information.adresse,
    telephoneFixe: extractCoordonnes(state, "TelFixe"),
    email: extractCoordonnes(state, "Email"),
    horairesOuverture: getHorairesOuverture(state),
    socialNetworks: {
      facebook: extractCoordonnes(state, "Facebook"),
      twitter: extractCoordonnes(state, "Twitter"),
      linkedin: extractCoordonnes(state, "Linkedin"),
      instagram: extractCoordonnes(state, "Instagram"),
      youtube: extractCoordonnes(state, "Youtube")
    },
    whatsapp: extractCoordonnes(state, "Whatsapp"),
    localisation: extractCoordonnes(state, "Localisation")
  };
};

// Get partenaires
const partnersMediaPath = appConstants.mediasPaths.partenaires;
export const getPartenaires = state => {
  return {
    mediaPath: partnerCode => partnersMediaPath(partnerCode),
    partenaires: state.information.partenairesAssureurs
  };
};

// Get phone configuration
const phoneConstants = appConstants.phone;
export const getPhoneConfig = state => {
  return {
    separator: phoneConstants.sep,
    ...phoneConstants.masks[getCountry(state)]
  };
};

// Get operateurs de paiement
export const getOperateursPaiement = state =>
  state.information.operateursPaiementActifs;

// Get Operateurs paiement config
export const getOperateurConfig = (state, operateurCode) => {
  const config = state.information.operateursPaiementActifs.find(
    operateur => operateur.code === operateurCode
  );
  const configCb = {
    nbTry: config.nombreEssais,
    delayTry: config.delaiEntreChaqueEssai
  };
  return configCb;
};

// Get url espace client
export const getUrlEspaceClient = state =>
  state.information.espaceClientUrl.replace(
    "api/EspaceClient/v1/connexion",
    "EspaceClient/connexion"
  );

// Get branches assurances
export const getBranches = state => state.information.branches;

// Get current origin visitor origin
export const getCurrentVisitorOrigin = state => {
  let visitorOrigin = null;
  const routerSearch = getRouterSearch(state);
  if (routerSearch) {
    visitorOrigin =
      routerSearch.pk_campaign ||
      routerSearch.lr ||
      routerSearch.utm_campaign ||
      null;
  }
  if (visitorOrigin === null) {
    visitorOrigin =
      JSON.parse(sessionStorage.getItem("currentVisitorOrigin")) || null;
  }
  return visitorOrigin;
};

// Is user a client
export const isUserClient = () => isClient();

// Is user an anonyme
export const isUserAnonyme = () => isAnonyme();

// Is user Baloon Pro
export const isUserBaloonPro = () => isUserBaloonProHelper();

export const isBandeauCoronavirus = state => state.information.isBandeauCovid19;

export const isTarifBeforeInfoClient = state => state.information.isTarifFirst;

export const isGabon = state => state.information.pays === "GA";

export const getTheme = state =>
  state.information.charteGraphique !== null
    ? state.information.charteGraphique.toLowerCase()
    : null;
//state.information.charteGraphique.toLowerCase();

export const isMarqueBlanche = state => state.information.isMarqueBlanche;

export const getHomepageUrl = state => state.information.homepage;
// state.information.charteGraphique !== null
//   ? state.information.charteGraphique.toLowerCase()
//   : null;

export const getTimeOutLoaderDocument = state =>
  state.information.timeoutLoaderDocument;

export const getIsTimeoutonLoaderDocument = state =>
  state.information.isTimeoutonLoaderDocument;

export const getTracking = state => state.information.tracking;

export const isGtagLog = state => state.information.isGtagLog;
