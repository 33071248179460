import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

const commonAllowedStyle = {
  main: {
    normal: [
      "color",
      "backgroundColor",
      "fontFamily",
      "fontVariant",
      "fontSize",
      "lineHeight",
      "marginBottom"
    ]
  }
};

const allowedStylePropsButton = commonAllowedStyle;
allowedStylePropsButton.main.normal.push("backgroundColor");

const defaultStyleFromTheme = (itemName, isLink) => {
  return css`
    ${props => {
      return `
          color: ${
            isLink
              ? props.theme.colors.themeColors.regular
              : props.theme.colors.themeColors.white
          };
          font-family: ${props.theme.fonts.main.name};
          font-weight: ${props.theme.fonts.main.variants.medium};
          line-height: "20px";
        `;
    }};
    // mappingThemeStyle(
    //   itemName,
    //   props.theme,
    //   isLink ? allowedStylePropsLink : allowedStylePropsButton
    // )
    ${mainLinkButtonStyle(isLink)};
  `;
};

//flex-flow: row wrap;

const commonButtonStyle = css`
  align-items: center;
  border-radius: 20px;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  font-size: 1.5rem;
  font-weight: 700;
  justify-content: center;
  margin: 0 auto 15px;
  max-width: 345px;
  padding: 10px 15px;
  text-decoration: none;
  width: 100%;
  svg {
    height: 16px;
    margin-right: 10px;
    width: 16px;
  }
  &:disabled {
    background-color: ${props => props.theme.colors.bgColors.altRegular};
  }
  &:hover {
    cursor: pointer;
  }
`;

const plainStyle = css`
  ${commonButtonStyle};
  background-color: ${props => props.theme.colors.themeColors.thirdLight};
  color: ${props => props.theme.colors.themeColors.white};
  &:hover {
    background-color: ${props => props.theme.colors.themeColors.thirdDark};
  }
  &.alt-style {
    background-color: ${props => props.theme.colors.themeColors.regularFonce};
    &:hover {
      background-color: ${props => props.theme.colors.themeColors.dark};
    }
  }
  &.third-style {
    background-color: ${props => props.theme.colors.themeColors.altLight};
    color: ${props => props.theme.colors.themeColors.regular};
  }
`;

const borderStyle = css`
  ${commonButtonStyle};
  border: 1px solid ${props => props.theme.colors.themeColors.thirdLight};
  color: ${props => props.theme.colors.themeColors.thirdLight};
  padding-bottom: 9px;
  padding-top: 9px;
  &:hover {
    color: ${props => props.theme.colors.themeColors.white};
    background-color: ${props => props.theme.colors.themeColors.thirdLight};
  }
`;

const mainLinkButtonStyle = isLink => {
  return css`
    display: ${isLink ? "inline-" : ""}flex;
    align-items: center;
  `;
};

const styleLink = css`
  text-decoration: underline;
  transition: 250ms;
  &:hover,
  &:focus,
  &:active,
  &:visited {
    text-decoration: inherit;
  }
  &.no-style {
    &:not(button) {
      text-decoration: none;
      &:hover {
        color: inherit;       
      }
      &:focus,
      &:active {
        color: inherit;
      }
    }
    &[type="button"] {
      ${commonButtonStyle};
      color: ${props => props.theme.colors.themeColors.thirdLight};
      &:hover{
        cursor:default !important;
      }
      > *:hover{
        cursor:pointer;
      }
      span {
        border-bottom: 1px solid
          ${props => props.theme.colors.themeColors.thirdLight};
        padding-bottom: 1px;
      }
    }    
  }
  &:not(.no-style) {
    /*&:hover {
      background-color: ${props => props.theme.colors.themeColors.thirdRegular};
    }
    &:focus,
    &:active {
      background-color: ${props => props.theme.colors.themeColors.thirdDark};
    }*/
  }
  &.plain {
    ${plainStyle};
  }
  &.border {
    ${borderStyle};
  }
`;

const linkButtonClassName = "linkButton";
export const StyledOutsideLink = styled.a.attrs({
  className: linkButtonClassName
})`
  ${defaultStyleFromTheme("linkButton.link", true)};
  ${styleLink};
`;

export const StyledInternalLink = styled(Link).attrs({
  className: linkButtonClassName
})`
  ${defaultStyleFromTheme("linkButton.link", true)};
  ${styleLink};
`;

export const StyledRedirectButton = styled.button.attrs({
  className: linkButtonClassName
})`
  ${defaultStyleFromTheme("linkButton.button")};
  ${plainStyle};
`;

export const StyledButton = styled.button.attrs(props => ({
  type: props.isSubmit ? "submit" : "button",
  className: linkButtonClassName
}))`
  ${defaultStyleFromTheme("linkButton.button")};
  ${styleLink};
`;
