import axios from "axios";
// import qs from "qs";
import API from "../../../api";

export async function post(
  mainToken,
  contratId,
  versionContratId,
  urlSucces,
  urlCancel
) {
  const bodyFormData = new FormData();
  bodyFormData.set("contratId", contratId);
  bodyFormData.set("versionContratId", versionContratId);
  bodyFormData.set("urlSucces", urlSucces);
  bodyFormData.set("urlCancel", urlCancel);
  return new Promise(resolve => {
    const url = API.tunnel.common.signature.main;
    const request = axios.post(url, bodyFormData, {
      headers: {
        Authorization: `bearer ${mainToken}`,
        "Content-Type": "multipart/form-data"
      }
    });
    return request
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        resolve({ error: true, res: err });
      });
  });
}

export async function getSignatureRepriseInformations(guid, mainToken) {
  const params = {
    guid: guid
  };
  return new Promise(resolve => {
    const url = API.tunnel.common.signature.reprise;
    const request = axios.get(url, {
      params,
      headers: {
        Authorization: `bearer ${mainToken}`
      }
    });
    return request
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        resolve({ error: true, res: err });
      });
  });
}

export async function isSignature(mainToken) {
  const params = {};
  return new Promise(resolve => {
    const url = API.tunnel.common.signature.isSignature;
    const request = axios.get(url, {
      params,
      headers: {
        Authorization: `bearer ${mainToken}`
      }
    });
    return request
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        resolve({ error: true, res: err });
      });
  });
}

// Attestattion dématérialisée

export async function downloadAttestation(contratId, versionId, mainToken) {
  const params = {
    contratId,
    versionId
  };
  return new Promise(resolve => {
    const url = API.tunnel.common.attestation.main;
    const request = axios.get(url, {
      params,
      headers: {
        Authorization: `bearer ${mainToken}`
      }
    });
    return request
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        resolve({ error: true, res: err });
      });
  });
}
