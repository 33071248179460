import axios from "axios";
// import qs from "qs";
import API from "../../../api";

export async function create(assuranceType, mainToken, simulationId) {
  const params = {
    simulationId
  };
  return new Promise(resolve => {
    const url = API.tunnel.common.creationContrat[assuranceType];
    const request = axios.post(url, null, {
      params,
      headers: {
        Authorization: `bearer ${mainToken}`
      }
    });
    return request
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        resolve({ error: true, res: err });
      });
  });
}
export async function downloadConditions(
  contratId,
  versionId,
  conditionType,
  mainToken
) {
  const params = {
    contratId,
    versionId
  };
  return new Promise(resolve => {
    const url = API.tunnel.common.creationContrat[conditionType.conditionType];
    const request = axios.get(url, {
      params,
      headers: {
        Authorization: `bearer ${mainToken}`
      },
      responseType: "blob"
    });
    return request
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        resolve({ error: true, res: err });
      });
  });
}
