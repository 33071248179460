import styled from "styled-components";
import breakpoint from "styled-components-breakpoint";
import footerBG from "../../assets/footer-bg.svg";

export const StyledFooter = styled.footer`
  background-color: ${props => props.theme.colors.themeColors.regular};
  background-image: local(none) url(${footerBG});
  background-repeat: no-repeat;
  background-position: -248px -111px;
  background-size: 370px auto;
  // font-family: ${props => props.theme.fonts.main.name};
  position: relative;
  z-index: 50;
  .paragraph,
  .paragraph span,
  .paragraph .linkButton {
    color: ${props => props.theme.colors.themeColors.white};
  }
  .paragraph.telephone .linkButton {
    text-decoration: none;
  }
  .paragraph * {
    color: inherit;
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
  }
  .navigationFooter {
    width: 100%;
    display: flex;
    flex-direction: column;
    ${breakpoint("desktop")`
      flex-direction: row-reverse;
    `};
    > div {
      border-bottom: 1px solid ${props => props.theme.colors.themeColors.light};
      padding-bottom: 25px;
      margin-bottom: 25px;
      .title {
        text-transform: uppercase;
        font-size: 1rem !important;
      }
      &:first-child {
        padding-top: 0;
        > div {
          &:first-child ul {
            li {
              &:last-child {
                align-items: flex-start;
                svg {
                  min-width: 20px;
                }
              }
            }
          }
          &:last-child ul {
            display: flex;
          }
        }
      }
      &:last-child {
        margin: 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        div {
          width: 50%;
          max-width: 157.5px;
          &:first-child {
            display: none;
          }
        }
      }
      > div {
        .title {
          font-size: 1.5rem;
          color: #84a4b2;
        }
        ul {
          margin-bottom: 20px;
          li {
            align-items: center;
            display: flex;
            margin-bottom: 15px;
            &:last-of-type {
              margin-bottom: 0;
            }
            .linkButton {
              cursor: pointer;
              position: relative;
            }
            svg {
              color: ${props => props.theme.colors.themeColors.white};
              width: 20px;
            }
          }
        }
        .icon-container {
          padding-left: 30px;
          position: relative;
          svg {
            left: 0;
            position: absolute;
            transform: translateY(-1px);
          }
        }
      }
    }
  }
  .social-networks {
    ul {
      margin-bottom: 0 !important;
      li {
        margin-bottom: 0 !important;
        margin-right: 40px !important;
        svg {
          margin: 0 !important;
        }
      }
    }
  }
  .corporate {
    ul {
      margin-bottom: 0 !important;
      li {
      }
    }
  }
  .institutionnel {
    align-items: center;
    display: flex;
    justify-content: end;
    margin-bottom: 10px;
    > svg {
      height: auto;
      max-width: 130px;
      margin: 25px 0 15px;
    }
    * {
      transition: 250ms;
    }
    a span {
      &:hover {
        color: ${props => props.theme.colors.themeColors.white};
      }
    }
    nav {
      margin-left: auto;
      ul {
        align-items: center;
        display: flex;
        flex-direction: column;
        li {
          width: 100%;
          text-align: right;
        }
      }
      > p {
        text-align: right;
      }
    }
  }
  ${breakpoint("mobile", "tablet")`
    .institutionnel {
      align-items: flex-start;
      flex-direction: column;
      nav {
        margin-left: 0;
        > p,
        ul li {
          text-align: left;
        }
      }
    }
  `};
  ${breakpoint("tablet")`
    .navigationFooter > div:first-child{
      display:flex;
      flex-direction: column;
      > div:first-child{
        margin-right:30px;
      }
    }
  `};
  ${breakpoint("desktop")`
    > div{
      max-width:900px;
      margin:0 auto;
      .navigationFooter{
         > div{         
          margin-bottom:0;
          &:last-child{
            align-items: flex-start;
            flex:1;
            > div{
              ul{
                margin-bottom:0;
              }
              &:first-child{
                margin-bottom:30px;
                max-width:initial;
                flex-basis:100%
                width:100%;
                display:block;
              }
            }
          }
          &:first-child{
            flex:0.5;
            flex-direction:column;
            padding-left:20px;
          }
         }
      }
      .institutionnel{
        svg{
          display:none;
        }
        nav{
          padding-top: 25px;
          li, p{
            text-align:center;
          }
          margin: 0 auto;
          ul{
            margin-bottom:10px;
            flex-direction: row;
            li{
              width:auto
              &:first-child{
                margin-right:16px;
                p {
                  position:relative;
                  &:after{
                    content: "|";
                    font-size: inherit;
                    position:absolute;
                    right: -10px;
                    top:0;
                  }
                }
              }
            }
          }
        }
      }
    }
    
  `};
`;
