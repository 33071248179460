const common = {
  main: {
    name: '"Raleway", sans-serif',
    variants: {
      light: "200",
      default: "400",
      medium: "500",
      bold: "700"
    }
  },
  forms: {
    name: '"Nunito Sans", sans-serif',
    variants: {
      default: "400",
      bold: "700"
    }
  }
};

const fonts = {
  ...common
};

export default fonts;
