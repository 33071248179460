import styled from "styled-components";
import { CommonStyledMenuWrapper } from "../../style";

export const StyledMenuTunnel = styled(CommonStyledMenuWrapper)`
  .ma-souscription {
    background-color: ${props => props.theme.colors.themeColors.white};
    padding: 15px 20px 30px;
    .title {
      box-shadow: none;
      font-size: 1.6rem;
      font-weight: 700;
    }
    .baloon-shape {
      background-color: ${props => props.theme.colors.themeColors.regular50};
      height: 25px;
      padding: 0;
      width: 25px;
      > span {
        color: ${props => props.theme.colors.themeColors.white};
        font-family: ${props => props.theme.fonts.main.name};
        font-size: 1.6rem;
        transform: translateY(-2px);
      }
    }
    > div {
      width: 650px;
      margin: 20px auto;
      display: flex;
      > img {
        margin: 0 10px;
        max-width: 85px;
      }
      > div {
        .paragraph {
          font-size: 1.4rem;
        }
      }
    }
    > ul {
      width: 650px;
      margin: 0 auto;
      display: flex;
      .paragraph {
        font-weight: 700;
        opacity: 0.5;
      }
      .separator {
        width: calc((100% - 25px) / 2);
        height: 1px;
        stroke: ${props => props.theme.colors.themeColors.regular50};
        stroke-dasharray: 2;
        stroke-width: 1;
      }
      li {
        width: 25%;
        > div:first-child {
          align-items: center;
          display: flex;
          flex-flow: row wrap;
          margin-bottom: 15px;
          > div {
            display: flex;
            flex-flow: column wrap;
            justify-content: start;
          }
        }
        > div:last-child {
          display: flex;
          justify-content: center;
          align-items: center;
          .icon-status {
            margin-left: 10px;
          }
        }
        &:first-child {
          > div:first-child div {
            margin-left: auto;
          }
        }
        &:last-of-type {
          > div {
            height: auto;
            max-height: initial;
            min-height: initial;
          }
        }
        &.passed {
          .baloon-shape {
            background-color: darkcyan;
          }
          .icon-status {
            color: darkcyan;
          }
          .separator {
            stroke: darkcyan;
          }
        }
        &.current {
          .baloon-shape {
            background-color: ${props =>
              props.theme.colors.themeColors.regular};
          }
          .icon-status {
            color: darkred;
          }
          .separator {
            stroke: ${props => props.theme.colors.themeColors.regular};
          }
        }
        &.passed,
        &.current {
          .paragraph {
            opacity: 1;
          }
        }
      }
    }
  }
`;
