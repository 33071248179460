import appConstants from "../../configuration/constants";

export const isAllowedBranche = (branches, assuranceType) => {
  const assurance = assuranceType === "automobile" ? "Auto" : assuranceType;
  let currentAssuranceType =
    assurance.charAt(0).toUpperCase() + assurance.slice(1);
  return branches.indexOf(currentAssuranceType) > -1;
};

export const isAllowedProduct = product => {
  return appConstants.allowedProducts.indexOf(product) > -1;
};
