import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { compose } from "redux";
import { ItemNavStyle } from "./style";
// Import components
// import LinkButton from "../../../link-button";

// Import actions
import { openCloseMainMenu } from "../../../../../store/main-menu/actions";

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      openCloseMainMenu
    },
    dispatch
  )
});

class ItemNavText extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(e, url, isWhatsApp) {
    e.preventDefault();
    if (typeof url === "string") {
      if (isWhatsApp) {
        window.open(url, "_blank");
      } else {
        this.props.history.push(url);
      }
    } else {
      window.location.href = url.spec;
    }
    this.props.actions.openCloseMainMenu(false, -1);
  }

  render() {
    const { icon, label, url, onClick, name } = this.props;
    if (url) {
      if (name === "whatsApp") {
        return (
          <ItemNavStyle
            className="link"
            onClick={e => this.handleClick(e, url, true)}
          >
            <span className="whatsAppContact">
              {icon}
              <span className={"whatsAppTextMobile"}>{label}</span>
            </span>
          </ItemNavStyle>
        );
      } else {
        return (
          <span className="link" onClick={e => this.handleClick(e, url, false)}>
            <span>
              {icon}
              <span>{label}</span>
            </span>
          </span>
        );
      }
    }
    return (
      <span className="link" onClick={onClick}>
        <span>
          {icon}
          <span>{label}</span>
        </span>
      </span>
    );
  }
}

export default compose(
  withRouter,
  connect(
    null,
    mapDispatchToProps
  )
)(ItemNavText);
