const genericColors = {
  black: "rgb(0,0,0)",
  black20: "rgba(0,0,0, 0.2)",
  black50: "rgba(0,0,0, 0.5)",
  black80: "rgba(0,0,0, 0.8)",
  white: "rgb(255,255,255)",
  white50: "rgba(255,255,255, 0.5)",
  white80: "rgba(255,255,255, 0.8)",
  ultralightgrey: "rgba(216,216,216, 1)",
  lightgrey: "rgba(131,131,131, 1)",
  darkgrey: "rgba(61,61,58, 1)",
  darkgrey50: "rgba(61,61,58, 0.5)"
};

const bgColors = {
  light: "#f5f5f5",
  altLight: "rgba(242,242,242)",
  regular: "lightgrey",
  altRegular: "#D8D8D8",
  dark: "#92a7af",
  error: "rgba(238,58,0, 0.1)"
};

const statusColors = {
  valid: "rgb(49,83,13)",
  error: "rgb(238,58,0)",
  warning: "rgba(224, 188, 0, 0.2)"
};

const baloonColors = {
  ultralight: "rgb(0,168,158)", //BLEU CYAN
  ultralight50: "rgba(0,168,158, 0.5)", //BLEU CYAN Attenue
  light: "rgb(51,104,127)",
  light0: "rgba(51,104,127, 0)",
  light20: "rgba(51,104,127, 0.2)",
  light50: "rgba(51,104,127, 0.5)",
  light80: "rgba(51,104,127, 0.8)",
  regular: "rgb(38, 79, 96)", //BLEU ECRITURE
  regularFonce: "rgb(38, 79, 96)", //BLEU ECRITURE
  regular50: "rgba(38, 79, 96, 0.5)",
  dark: "rgb(12,40,53)",
  altLight: "rgb(224,188,0)", //JAUNE BALOON
  altRegular: "rgb(186,147,28)",
  altDark: "rgb(124,102,15)",
  thirdUltraLight: "rgb(254, 241, 243)",
  thirdLight: "rgb(200, 25, 75)", //BORDEAU BOUTTONS
  thirdRegular: "rgb(158, 15, 56)",
  thirdDark: "rgb(158,15,56)",
  ...genericColors
};
//color: #00008f!important; // BLEU ECRITURE ET LOGO
//BLANC POUR lE RESTE
const axaColors = {
  ultralight: "rgb(0,168,158)",
  ultralight50: "rgba(0,168,158, 0.5)",
  light: "rgb(51,104,127)",
  light0: "rgba(51,104,127, 0)",
  light20: "rgba(51,104,127, 0.2)",
  light50: "rgba(51,104,127, 0.5)",
  light80: "rgba(51,104,127, 0.8)",
  regular: "rgb(0,0,143)",
  regularFonce: "rgb(0,0,143)",
  regular50: "rgba(0,0,143, 0.5)",
  dark: "rgb(30,30,143)",
  altLight: "rgb(255,255,255)",
  altRegular: "rgb(186,147,28)",
  altDark: "rgb(124,102,15)",
  thirdUltraLight: "rgb(254, 241, 243)",
  thirdLight: "rgb(0,0,143)",
  thirdRegular: "rgb(15,56,150))",
  thirdDark: "rgb(15,56,158)",
  ...genericColors
};

const oleaColors = {
  ultralight: "rgb(168, 62, 0)",
  ultralight50: "rgba(168,62,0, 0.5)",
  light: "rgb(66, 17, 4)",
  light0: "rgba(66, 17, 4, 0)",
  light20: "rgba(127,104,51, 0.2)",
  light50: "rgba(66, 17, 4, 0.5)",
  light80: "rgba(51,104,127, 0.8)",

  regular: "rgb(102, 92, 85)", //"rgb(152, 137, 127)",
  regularFonce: "rgb(187, 64, 37)",

  regular50: "rgba(213,72,41, 0.5)",

  dark: "rgb(253,191,45)",

  altLight: "rgb(245, 205, 111)",
  altRegular: "rgb(186,147,28)",
  altDark: "rgb(232, 186, 5)",

  thirdUltraLight: "rgb(254, 241, 243)",
  thirdLight: "rgb(187,64,37)",
  thirdRegular: "rgb(145, 43, 20)",
  thirdDark: "rgb(228, 82, 50)",
  ...genericColors
};

const colors = {
  brandColors: {
    baloon: baloonColors,
    axa: axaColors,
    olea: oleaColors
  },
  themeColors: baloonColors,
  bgColors: bgColors,
  statusColors: statusColors
};

export default colors;
