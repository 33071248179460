import { all, select, put, takeLatest } from "redux-saga/effects";
import * as a from "./actions";
// Selectors
import * as mainMenuSelectors from "../main-menu/selectors";
// Actions
import * as mainMenuActions from "../main-menu/actions";
import * as opportuniteProspectionActions from "../opportunite-prospection/actions";

function* closeAllModals() {
  try {
    yield put({
      type: a.CLOSE_MODALS.SUCCESS
    });
  } catch (e) {
    yield put({
      type: a.CLOSE_MODALS.FAILURE,
      error: e.message
    });
  }
}

function* setModalEtreRappelleState({ isOpened }) {
  try {
    yield put({
      type: opportuniteProspectionActions.RESET_NEW_OPPORTUNITE_PROSPECTION
    });
    yield put({
      type: a.OPENCLOSE_MODAL_ETRE_RAPPELLE.SUCCESS,
      payload: {
        isOpened: isOpened
      }
    });
    if (isOpened) {
      const isMainMenuOpened = yield select(mainMenuSelectors.getMainMenuState);
      if (isMainMenuOpened.isOpened) {
        yield put({
          type: mainMenuActions.OPENCLOSE_MAINMENU.REQUEST,
          isOpened: false,
          openedName: null
        });
      }
    }
  } catch (e) {
    yield put({
      type: a.OPENCLOSE_MODAL_ETRE_RAPPELLE.FAILURE,
      error: e.message
    });
  }
}

function* setModalCampaignState({ isOpened }) {
  try {
    yield put({
      type: a.OPENCLOSE_MODAL_CAMPAIGN.SUCCESS,
      payload: {
        isOpened: isOpened
      }
    });
  } catch (e) {
    yield put({
      type: a.OPENCLOSE_MODAL_CAMPAIGN.FAILURE,
      error: e.message
    });
  }
}

function* setModalSaveTunnelAutoState({ isOpened }) {
  try {
    yield put({
      type: a.OPENCLOSE_MODAL_SAVE_TUNNEL_AUTO.SUCCESS,
      payload: {
        isOpened: isOpened
      }
    });
  } catch (e) {
    yield put({
      type: a.OPENCLOSE_MODAL_SAVE_TUNNEL_AUTO.FAILURE,
      error: e.message
    });
  }
}

export default all([
  takeLatest(a.CLOSE_MODALS.REQUEST, closeAllModals),
  takeLatest(
    a.OPENCLOSE_MODAL_ETRE_RAPPELLE.REQUEST,
    setModalEtreRappelleState
  ),
  takeLatest(a.OPENCLOSE_MODAL_CAMPAIGN.REQUEST, setModalCampaignState),
  takeLatest(
    a.OPENCLOSE_MODAL_SAVE_TUNNEL_AUTO.REQUEST,
    setModalSaveTunnelAutoState
  )
]);
