import { call, all, put, takeLatest, select } from "redux-saga/effects";
// Import helpers
import { isAnonyme } from "../../../../helpers/data/cookie-user";
import * as a from "./actions";
// Import selectors from INFORMATION
import * as informationSelectors from "../../../information/selectors";
import * as tunnelSelectors from "../../tunnel/selectors";
import * as tarifService from "./services";
import * as recapitulatifSelectors from "../recapitulatif/selectors";
import * as clientSelectors from "../../../client/selectors";
import * as tunnelSagas from "../../tunnel/saga";
import * as loggerSagas from "../../../logger/saga";

import formatPhoneNumber from "../../../../helpers/data/format-phone-number";

function* getTarif({ assuranceType }) {
  try {
    const mainToken = yield select(informationSelectors.getMainToken);
    yield call(tunnelSagas.rebindIdsFromSessionStorage);
    const currentSimIds = yield select(tunnelSelectors.getCurrentSimulationIds);

    let hasNotRequiredIds = false;
    if (
      typeof currentSimIds.simulationId === "undefined" ||
      currentSimIds.simulationId === null
    ) {
      hasNotRequiredIds = true;
    }
    if (
      assuranceType === "automobile" &&
      (typeof currentSimIds.risqueId === "undefined" ||
        currentSimIds.risqueId === null)
    ) {
      currentSimIds.risqueId = 1;
    }
    if (hasNotRequiredIds) {
      yield call(
        tunnelSagas.redirectToUrl,
        `/assurance-${assuranceType || "automobile"}/informations-manquantes`
      );
    } else {
      const tarif = yield call(
        tarifService.get,
        assuranceType,
        mainToken,
        currentSimIds.simulationId
      );
      if (tarif.status === 200) {
        yield put({
          type: a.GET_TARIF.SUCCESS,
          payload: tarif.data
        });
        yield call(tunnelSagas.setIds, {
          simulationId: tarif.data.simulationId,
          risqueId: tarif.data.risqueId
        });
      } else if (tarif.res.response.status === 400) {
        yield put({
          type: a.GET_TARIF.FAILURE,
          payload: {
            error: false
          }
        });
      } else {
        const errorRes = tarif.res.response;
        if (errorRes.request.status !== 400) {
          yield call(loggerSagas.postLogErrorApi, {
            sagaInit: "getTarif",
            apiResponseUrl: errorRes.request.responseURL,
            statusResponse: errorRes.request.status,
            dataResponse: errorRes.data,
            token: mainToken,
            params: {
              simulationId: currentSimIds.simulationId
            }
          });
        }
        yield put({
          type: a.GET_TARIF.FAILURE,
          payload: {
            error: true
          }
        });
      }
    }
  } catch (e) {}
}

function* chooseTarif({ assuranceType, cotationId }) {
  try {
    const mainToken = yield select(informationSelectors.getMainToken);
    const isTarifFirst = yield select(
      informationSelectors.isTarifBeforeInfoClient
    );
    const currentSimIds = yield select(tunnelSelectors.getCurrentSimulationIds);
    const tarif = yield call(
      tarifService.post,
      assuranceType,
      mainToken,
      currentSimIds.simulationId,
      currentSimIds.risqueId,
      cotationId
    );
    if (tarif.status === 200) {
      yield put({
        type: a.CHOOSE_TARIF.SUCCESS,
        payload: tarif.data
      });
      const needToGoBackToRecapitulatif = yield select(
        recapitulatifSelectors.getModifyGo
      );
      if (needToGoBackToRecapitulatif === "formule") {
        yield call(tunnelSagas.setModificationRecap);
        yield call(
          tunnelSagas.redirectToUrl,
          `/assurance-${assuranceType}/recapitulatif`
        );
      } else if (assuranceType === "sante") {
        yield call(
          tunnelSagas.redirectToUrl,
          "/assurance-sante/information-preparation-contrat"
        );
      } else if (assuranceType === "automobile") {
        if (isTarifFirst) {
          if (isAnonyme()) {
            yield call(
              tunnelSagas.redirectToUrl,
              "/assurance-automobile/preparation-contrat"
            );
          } else {
            const clientInfos = yield select(
              clientSelectors.getExtractedUserInfosFrom
            );
            const phoneConfig = yield select(
              informationSelectors.getPhoneConfig
            );
            const formatedPhone = formatPhoneNumber(
              clientInfos.telephone.replace(`+${phoneConfig.indicator}`, ""),
              phoneConfig.modulo.split(","),
              phoneConfig.separator
            );
            yield call(
              tunnelSagas.redirectToUrl,
              "/assurance-automobile/debut-assurance",
              {
                telephone: formatedPhone
              }
            );
          }
        } else {
          yield call(
            tunnelSagas.redirectToUrl,
            "/assurance-automobile/debut-assurance"
          );
        }
      }
    } else {
      const errorRes = tarif.res.response;
      if (errorRes.request.status !== 400) {
        yield call(loggerSagas.postLogErrorApi, {
          sagaInit: "chooseTarif",
          apiResponseUrl: errorRes.request.responseURL,
          statusResponse: errorRes.request.status,
          dataResponse: errorRes.data,
          token: mainToken,
          params: {
            simulationId: currentSimIds.simulationId,
            risqueId: currentSimIds.risqueId,
            cotationId
          }
        });
      }
      yield put({
        type: a.GET_TARIF.FAILURE,
        payload: {
          error: true
        }
      });
    }
  } catch (e) {}
}

function* setCotationDisponnible(disponnible, simuId) {
  yield put({
    type: a.SET_COTATION_DISPONNIBLE.SUCCESS,
    payload: {
      disponnible,
      simuId
    }
  });
}

export default all([
  takeLatest(a.GET_TARIF.REQUEST, getTarif),
  takeLatest(a.CHOOSE_TARIF.REQUEST, chooseTarif),
  takeLatest(a.SET_COTATION_DISPONNIBLE.REQUEST, setCotationDisponnible)
]);
