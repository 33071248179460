import * as a from "./actions";

const INITIAL_STATE = {
  success: false,
  loading: false,
  error: false,
  data: null
};

export function tranchesAges(state = INITIAL_STATE, action) {
  switch (action.type) {
    case a.GET_TRANCHES_AGES.REQUEST:
      return {
        ...state,
        success: false,
        loading: true,
        error: false
      };
    case a.GET_TRANCHES_AGES.SUCCESS:
      return {
        ...state,
        success: true,
        loading: false,
        error: null,
        data: action.payload
      };
    case a.GET_TRANCHES_AGES.FAILURE:
      return {
        ...state,
        success: false,
        error: true,
        loading: false
      };
    default:
      return state;
  }
}

export default tranchesAges;
