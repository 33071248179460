import { call, all, put, takeLatest, select } from "redux-saga/effects";
import * as a from "./actions";

// Import selects from INFORMATION
import * as informationSelectors from "../../../information/selectors";
import * as tunnelSagas from "../../tunnel/saga";
import * as loggerSagas from "../../../logger/saga";
import * as tunnelSelectors from "../../tunnel/selectors";
import * as leadSelectors from "./selectors";
import * as leadService from "./services";

export function* removeIdsBackFromTarif() {
  const hasShowTarif = JSON.parse(sessionStorage.getItem("tarifAffiche"));
  if (hasShowTarif) {
    yield call(deleteLeadId);
    yield call(tunnelSagas.setIds, {
      simulationId: null,
      risqueId: null,
      leadId: null
    });
    sessionStorage.removeItem("tarifAffiche");
  }
}

export function* deleteLeadId() {
  yield put({
    type: a.DELETE_LEAD_ID.SUCCESS
  });
  yield call(tunnelSagas.setIds, { leadId: null });
}

export function* createLead({
  assuranceType,
  currentPage,
  clientId = null,
  emailClient = null,
  id = null,
  formuleItcRefId = null,
  nbAdultes = null,
  nbJeunes = null,
  nbSeniors = null,
  pathologieClientCode = null,
  telephoneClient = null,
  typeReseauSoinsRefId = null,
  creerSimulation = false
}) {
  if (currentPage === "assurerFamille") {
    yield call(deleteLeadId);
  }
  const origineWebCode = yield select(
    informationSelectors.getCurrentVisitorOrigin
  );
  yield call(removeIdsBackFromTarif);
  const mainToken = yield select(informationSelectors.getMainToken);
  const currentLead = yield select(leadSelectors.getLead);
  const data = {
    clientId,
    emailClient,
    formuleItcRefId,
    id,
    nbAdultes,
    nbJeunes,
    nbSeniors,
    pathologieClientCode,
    telephoneClient,
    typeReseauSoinsRefId
  };
  const mergeData = {};
  const currentLeadValues = Object.values(currentLead);
  const dataValues = Object.values(data);
  const keys = Object.keys(currentLead);
  currentLeadValues.map((item, index) => {
    if (dataValues[index] !== null) {
      mergeData[keys[index]] = dataValues[index];
    } else {
      mergeData[keys[index]] = currentLeadValues[index];
    }
    return false;
  });
  mergeData.mainToken = mainToken;
  mergeData.origineWebCode = origineWebCode;
  if (creerSimulation) mergeData.creerSimulation = creerSimulation;
  const lead = yield call(leadService.post, { ...mergeData });
  if (!lead.error) {
    if (lead.telephoneClient !== null)
      sessionStorage.setItem(
        "telephoneClient",
        JSON.stringify(lead.telephoneClient)
      );
    yield call(tunnelSagas.setIds, {
      leadId: lead.id,
      simulationId: lead.simulationId
    });
    yield put({
      type: a.CREATE_LEAD.SUCCESS,
      payload: lead
    });
    let redirectionUrl;
    switch (currentPage) {
      case "assurerFamille":
        redirectionUrl = "/assurance-sante/questions-medicales";
        break;
      case "questionsMedicales":
        const reponsePathologies = lead.pathologieClientCode;
        redirectionUrl = "/assurance-sante/informations-de-contact";
        if (
          reponsePathologies === "Oui" ||
          reponsePathologies === "NeSaisPas"
        ) {
          redirectionUrl = "/assurance-sante/contact-medical";
        }
        break;
      case "informationsContact":
        redirectionUrl = "/assurance-sante/tarif";
        //redirectionUrl = "/assurance-sante/lieu-couverture-soins";
        break;
      case "lieuCouvertureSoins":
        redirectionUrl = "/assurance-sante/taux-couverture";
        break;
      case "tauxCouverture":
        redirectionUrl = "/assurance-sante/tarif";
        break;
      default:
    }
    yield call(tunnelSagas.redirectToUrl, redirectionUrl);
  } else {
    const errorRes = lead.res.response;
    if (errorRes.status !== 400) {
      yield call(loggerSagas.postLogErrorApi, {
        sagaInit: "createLead",
        apiResponseUrl: errorRes.request.responseURL,
        statusResponse: errorRes.request.status,
        dataResponse: errorRes.data,
        token: mainToken,
        params: {
          ...mergeData
        }
      });
    }
    yield put({
      type: a.CREATE_LEAD.FAILURE,
      payload: errorRes.data
    });
  }
}

export function* repriseLead(setSuccess) {
  const goSetSuccess =
    typeof setSuccess !== "undefined" ? setSuccess.withoutApiCall : false;
  const storeLeadId = yield select(leadSelectors.getLeadId);
  let leadId = null;
  const origineWebCode = yield select(
    informationSelectors.getCurrentVisitorOrigin
  );
  if (storeLeadId === null) {
    yield call(tunnelSagas.rebindIdsFromSessionStorage);
    const currentSimuIds = yield select(
      tunnelSelectors.getCurrentSimulationIds
    );
    leadId = currentSimuIds.leadId;
    if (leadId !== null) {
      const isReprise = true;
      const lead = yield call(getLead, leadId, isReprise, origineWebCode);
      if (lead) {
        if (goSetSuccess) {
          yield put({
            type: a.REPRISE_LEAD.SUCCESS
          });
        }
        yield call(tunnelSagas.setIds, { leadId });
        return lead;
      }
    }
  } else {
    if (goSetSuccess) {
      yield put({
        type: a.REPRISE_LEAD.SUCCESS
      });
    }
    return true;
  }
}

export function* getLead(id, isReprise) {
  const origineWebCode = yield select(
    informationSelectors.getCurrentVisitorOrigin
  );
  const mainToken = yield select(informationSelectors.getMainToken);
  const lead = yield call(leadService.get, mainToken, id, origineWebCode);
  if (!lead.error) {
    yield put({
      type: a.GET_LEAD.SUCCESS,
      payload: lead
    });
    if (isReprise) {
      return true;
    }
  } else {
    const errorRes = lead.res.response;
    yield call(loggerSagas.postLogErrorApi, {
      sagaInit: "getLead",
      apiResponseUrl: errorRes.request.responseURL,
      statusResponse: errorRes.request.status,
      dataResponse: errorRes.data,
      token: mainToken,
      params: {
        id
      }
    });
    yield put({
      type: a.GET_LEAD.FAILURE,
      payload: true
    });
  }
}

export default all([
  takeLatest(a.CREATE_LEAD.REQUEST, createLead),
  takeLatest(a.REPRISE_LEAD.REQUEST, repriseLead),
  takeLatest(a.GET_LEAD.REQUEST, getLead),
  takeLatest(a.DELETE_LEAD_ID.REQUEST, deleteLeadId)
]);
