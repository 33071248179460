import axios from "axios";
import API from "../api";

export async function get(envURL, urlReferrer) {
  const encoded = encodeURI(envURL);
  const params = {
    params: {
      url: encoded,
      urlReferrer: urlReferrer
    }
  };
  return new Promise(resolve => {
    const request = axios.get(API.information.main, params);
    return request
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        resolve({ error: true, res: err });
      });
  });
}
