import styled from "styled-components";
import SimpleContent from "../../containers/simple-content";

export const StyledPrivacyPolicy = styled(SimpleContent)`
  h3 {
    font-size: 1.8rem !important;
  }
  h4 {
    font-size: 1.6rem !important;
  }
`;
