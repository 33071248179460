import styled, { css } from "styled-components";

const borderRadiusShape = angle => {
  let angleCss;
  switch (angle) {
    case "bottom-right":
      angleCss = css`
        border-radius: 50% 50% 0 50%;
      `;
      break;
    case "bottom-left":
      angleCss = css`
        border-radius: 50% 50% 50% 0;
      `;
      break;
    case "top-left":
      angleCss = css`
        border-radius: 0 50% 50% 50%;
      `;
      break;
    default:
      angleCss = css`
        border-radius: 50% 0 50% 50%;
      `;
      break;
  }
  return angleCss;
};

const StyledBaloonShape = styled.div`
  align-items: center;
  background-color: ${props =>
    props.alt
      ? props.theme.colors.themeColors.altLight
      : props.theme.colors.themeColors.regular};
  ${props => borderRadiusShape(props.angle)};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: auto;
  justify-content: center;
  padding: 8px;
  width: auto;
`;

export default StyledBaloonShape;
