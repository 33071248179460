import { call, all, put, takeLatest, select } from "redux-saga/effects";
import * as a from "./actions";

// Selectors
import * as informationSelectors from "../../../information/selectors";
import * as tunnelSelectors from "../../tunnel/selectors";
// Services
import * as assuresService from "./services";
// Sagas
import * as tunnelSagas from "../../tunnel/saga";
import * as loggerSagas from "../../../logger/saga";

export function* getAssures() {
  const mainToken = yield select(informationSelectors.getMainToken);
  yield call(tunnelSagas.rebindIdsFromSessionStorage);
  const currentSimIds = yield select(tunnelSelectors.getCurrentSimulationIds);
  if (currentSimIds.simulationId === null) {
    const { assuranceType } = this.state;
    yield call(
      tunnelSagas.redirectToUrl,
      `/assurance-${assuranceType || "automobile"}/informations-manquantes`
    );
  } else {
    const assures = yield call(
      assuresService.get,
      mainToken,
      currentSimIds.simulationId
    );
    if (!assures.error) {
      yield put({
        type: a.GET_ASSURES.SUCCESS,
        payload: assures
      });
    } else {
      const errorRes = assures.res.response;
      yield call(loggerSagas.postLogErrorApi, {
        sagaInit: "getAssures",
        apiResponseUrl: errorRes.request.responseURL,
        statusResponse: errorRes.request.status,
        dataResponse: errorRes.data,
        token: mainToken,
        params: {
          simulationId: currentSimIds.simulationId
        }
      });
      yield put({
        type: a.GET_ASSURES.FAILURE,
        payload: true
      });
    }
  }
}

export function* postAssures({ data }) {
  const mainToken = yield select(informationSelectors.getMainToken);
  const assures = yield call(assuresService.post, mainToken, data);
  if (!assures.error) {
    yield put({
      type: a.POST_ASSURES.SUCCESS,
      payload: assures
    });
    yield call(tunnelSagas.setIds, {
      simulationId: assures[0].simulationId
    });
    yield call(tunnelSagas.redirectToUrl, "/assurance-sante/debut-assurance");
  } else {
    const errorRes = assures.res.response;
    yield call(loggerSagas.postLogErrorApi, {
      sagaInit: "postAssures",
      apiResponseUrl: errorRes.request.responseURL,
      statusResponse: errorRes.request.status,
      dataResponse: errorRes.data,
      token: mainToken,
      params: {
        data
      }
    });
    yield put({
      type: a.POST_ASSURES.FAILURE,
      payload: true
    });
  }
}

export default all([
  takeLatest(a.GET_ASSURES.REQUEST, getAssures),
  takeLatest(a.POST_ASSURES.REQUEST, postAssures)
]);
