import axios from "axios";
import API from "../api";

export async function getOne(mainToken, slug) {
  const params = {
    article: slug
  };
  return new Promise(resolve => {
    const url = API.article.get;
    const request = axios.get(url, {
      params,
      headers: {
        Authorization: `bearer ${mainToken}`
      }
    });
    return request
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        resolve({ error: true, res: err });
      });
  });
}

export async function get(mainToken, typeArticles, value) {
  const params = {};
  switch (typeArticles) {
    case "mea":
      params.misenavant = true;
      break;
    case "categorie":
      params.categorie = value;
      break;
    case "aide":
      params.misenavant = true;
      params.categorie = "aide";
      break;
    default:
  }
  return new Promise(resolve => {
    const url = API.article.get;
    const request = axios.get(url, {
      params,
      headers: {
        Authorization: `bearer ${mainToken}`
      }
    });
    return request
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        resolve({ error: true, res: err });
      });
  });
}
