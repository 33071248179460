export const VERIFIER_TELEPHONE = {
  REQUEST: "verifierTelephone/GET/REQUEST",
  SUCCESS: "verifierTelephone/GET/SUCCESS",
  FAILURE: "verifierTelephone/GET/FAILURE"
};

export const verifierTelephone = telephone => {
  return {
    type: VERIFIER_TELEPHONE.REQUEST,
    telephone
  };
};
