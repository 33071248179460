import { call, all, put, takeLatest, select } from "redux-saga/effects";
import appConstants from "../../../../configuration/constants";
import * as a from "./actions";

// Selectors
import * as informationSelectors from "../../../information/selectors";
import * as tunnelSelectors from "../../tunnel/selectors";
// Services
import * as signatureService from "./services";
// Saga
import * as loggerSagas from "../../../logger/saga";
import * as tunnelSagas from "../../tunnel/saga";

import { setToken } from "../../../../helpers/data/cookie-user";

function* postSignature({ assuranceType }) {
  try {
    const mainToken = yield select(informationSelectors.getMainToken);

    const ids = yield select(tunnelSelectors.getCurrentSimulationIds);
    const contratId = ids.contratId;
    const versionContratId = ids.versionContratId;
    const urlSucces = `${
      appConstants.websiteUrl
    }/assurance-${assuranceType}/signature-electronique-validee`;
    const urlCancel = `${
      appConstants.websiteUrl
    }/assurance-${assuranceType}/signature-electronique/echec`;
    const signature = yield call(
      signatureService.post,
      mainToken,
      contratId,
      versionContratId,
      urlSucces,
      urlCancel
    );
    if (!signature.error) {
      yield put({
        type: a.SIGNATURE.SUCCESS,
        payload: signature
      });
    } else {
      const errorRes = signature.res.response;
      if (errorRes.request.status !== 400) {
        yield call(loggerSagas.postLogErrorApi, {
          sagaInit: "postSignature",
          apiResponseUrl: errorRes.request.responseURL,
          statusResponse: errorRes.request.status,
          dataResponse: errorRes.data,
          token: mainToken,
          params: {
            contratId,
            urlSucces,
            urlCancel
          }
        });
      }
      yield put({
        type: a.SIGNATURE.FAILURE,
        payload: true
      });
    }
  } catch (e) {
    yield put({
      type: a.SIGNATURE.FAILURE,
      payload: true
    });
  }
}

function* getRepriseSignatureInfos({ guid }) {
  try {
    const mainToken = yield select(informationSelectors.getMainToken);
    const signatureInformations = yield call(
      signatureService.getSignatureRepriseInformations,
      guid,
      mainToken
    );
    if (!signatureInformations.error) {
      const tokenClient = signatureInformations.token;
      setToken(tokenClient);
      sessionStorage.setItem("isRepriseSignature", JSON.stringify(true));
      yield call(tunnelSagas.setIds, {
        contratId: signatureInformations.contratId,
        versionContratId: signatureInformations.versionContratId
      });
      yield put({
        type: a.GET_REPRISE_SIGNATURE_INFORMATION.SUCCESS,
        payload: signatureInformations
      });
    } else {
      const errorRes = signatureInformations.res.response;
      if (errorRes.request.status !== 400) {
        yield call(loggerSagas.postLogErrorApi, {
          sagaInit: "signatureInformations",
          apiResponseUrl: errorRes.request.responseURL,
          statusResponse: errorRes.request.status,
          dataResponse: errorRes.data,
          token: mainToken,
          params: {
            guid
          }
        });
      }
      yield put({
        type: a.GET_REPRISE_SIGNATURE_INFORMATION.FAILURE,
        payload: { error: true }
      });
    }
  } catch (e) {}
}

function* isSignature() {
  try {
    const mainToken = yield select(informationSelectors.getMainToken);
    const result = yield call(signatureService.isSignature, mainToken);
    if (result !== null && typeof result !== "undefined") {
      yield put({
        type: a.IS_SIGNATURE.SUCCESS,
        payload: result
      });
    }
  } catch (e) {
    yield put({
      type: a.IS_SIGNATURE.FAILURE,
      payload: e
    });
  }
}

function* getAttestation() {
  try {
    const mainToken = yield select(informationSelectors.getMainToken);

    const ids = yield select(tunnelSelectors.getCurrentSimulationIds);
    const contratId = ids.contratId;
    const versionContratId = ids.versionContratId;

    const attestation = yield call(
      signatureService.downloadAttestation,
      contratId,
      versionContratId,
      mainToken
    );

    if (!attestation.error) {
      yield put({
        type: a.ATTESTATION.SUCCESS,
        payload: attestation
      });
    } else {
      const errorRes = attestation.res.response;
      if (errorRes.request.status !== 400) {
        yield call(loggerSagas.postLogErrorApi, {
          sagaInit: "GetAttestation",
          apiResponseUrl: errorRes.request.responseURL,
          statusResponse: errorRes.request.status,
          dataResponse: errorRes.data,
          token: mainToken,
          params: {
            contratId,
            versionContratId
          }
        });
      }
      yield put({
        type: a.SIGNATURE.FAILURE,
        payload: true
      });
    }
  } catch (e) {
    yield put({
      type: a.SIGNATURE.FAILURE,
      payload: true
    });
  }
}

export default all([
  takeLatest(a.SIGNATURE.REQUEST, postSignature),
  takeLatest(
    a.GET_REPRISE_SIGNATURE_INFORMATION.REQUEST,
    getRepriseSignatureInfos
  ),
  takeLatest(a.IS_SIGNATURE.REQUEST, isSignature),
  takeLatest(a.ATTESTATION.REQUEST, getAttestation)
]);
