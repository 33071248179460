import * as a from "./actions";

const INITIAL_CREATE_CLIENT_STATE = {
  token: null,
  create: {
    error: null,
    loading: false,
    success: false
  },
  connect: {
    error: null,
    loading: false,
    success: false
  },
  forgot: {
    error: null,
    loading: false,
    success: false
  },
  authentificationNecessaire: {
    error: null,
    loading: false,
    success: false,
    status: null
  }
};

export function client(state = INITIAL_CREATE_CLIENT_STATE, action) {
  let error;
  switch (action.type) {
    case a.CREATE_CLIENT.REQUEST:
      return {
        ...state,
        create: {
          success: false,
          error: null,
          loading: true
        }
      };
    case a.CREATE_CLIENT.SUCCESS:
      return {
        ...state,
        token: action.payload,
        create: {
          success: true,
          error: null,
          loading: false
        }
      };
    case a.CREATE_CLIENT.FAILURE:
      return {
        ...state,
        create: {
          success: false,
          error: action.payload,
          loading: false
        }
      };
    case a.CONNECT_CLIENT.REQUEST:
      return {
        ...state,
        connect: {
          success: false,
          error: null,
          loading: true
        }
      };
    case a.CONNECT_CLIENT.SUCCESS:
      return {
        ...state,
        token: action.payload,
        connect: {
          success: true,
          error: null,
          loading: false
        }
      };
    case a.CONNECT_CLIENT.FAILURE:
      error = action.payload;
      return {
        ...state,
        connect: {
          success: false,
          error: error,
          loading: false
        }
      };
    case a.FORGOT_CLIENT.REQUEST:
      return {
        ...state,
        forgot: {
          success: false,
          error: null,
          loading: true
        }
      };
    case a.FORGOT_CLIENT.SUCCESS:
      return {
        ...state,
        token: action.payload,
        forgot: {
          success: true,
          error: null,
          loading: false
        }
      };
    case a.FORGOT_CLIENT.FAILURE:
      error = action.payload;
      return {
        ...state,
        forgot: {
          success: false,
          error: error,
          loading: false
        }
      };
    case a.AUTHENTIFICATION_CLIENT_NECESSAIRE.REQUEST:
      return {
        ...state,
        authentificationNecessaire: {
          success: false,
          error: false,
          loading: true
        }
      };
    case a.AUTHENTIFICATION_CLIENT_NECESSAIRE.SUCCESS:
      return {
        ...state,
        authentificationNecessaire: {
          success: true,
          error: false,
          loading: false,
          status: action.payload
        }
      };
    case a.AUTHENTIFICATION_CLIENT_NECESSAIRE.FAILURE:
      return {
        ...state,
        authentificationNecessaire: {
          success: false,
          error: true,
          loading: false
        }
      };
    case a.RESET_LOADING_CREATION_CLIENT:
      return {
        ...state,
        create: INITIAL_CREATE_CLIENT_STATE.create
      };
    case a.RESET_CLIENT:
      return INITIAL_CREATE_CLIENT_STATE;
    default:
      return state;
  }
}

export default client;
