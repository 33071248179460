// Envoi de la requête input process
export const isInputProcessLoading = state =>
  state.tunnel.common.inputProcess.loading;

// Envoi succes
export const isInputProcessSuccessfull = state =>
  state.tunnel.common.inputProcess.success;

// Erreur de l'envoi de l'input
export const isInputProcessOnError = state =>
  state.tunnel.common.inputProcess.error;

// Fournis le process en cours
export const getCurrentProcess = state => {
  return state.tunnel.common.inputProcess.process;
};

// Get id page précédente
export const getPagePrecedenteId = state => {
  if (
    typeof state.tunnel.common.inputProcess.process.pageDefinition.id !==
    "undefined"
  )
    return state.tunnel.common.inputProcess.process.pageDefinition.id;
  return null;
};
export const getCurrentPageCode = state =>
  state.tunnel.common.inputProcess.process.pageDefinition.code;

// Get current Input Pocess Name
export const getCurrentInputProcessName = state => {
  return state.tunnel.common.inputProcess.process.inputProcessCode;
};

export const isEditingReadWrite = state =>
  state.tunnel.common.inputProcess.insideReadWriteComponent;

export const isOnErrorMetier = state =>
  state.tunnel.common.inputProcess.onError;
export const getErrorsMetier = state => state.tunnel.common.inputProcess.errors;
export const getModelInput = state => state.tunnel.common.inputProcess.input;
export const getAttributOnError = state =>
  state.tunnel.common.inputProcess.attributOnError;
