import i18n from "i18next";
import BackendXHR from "i18next-xhr-backend";
import BackendLocize from "i18next-locize-backend";
import { reactI18nextModule } from "react-i18next";
// import LanguageDetector from "i18next-browser-languagedetector";

// Import languages files
import langfrFR from "./locales/translations.fr-FR";
import langfrCI from "./locales/translations.fr-CI";
import langfrCM from "./locales/translations.fr-CM";
import langfrGA from "./locales/translations.fr-GA";
import langfrNE from "./locales/translations.fr-NE";
import langfrSN from "./locales/translations.fr-SN";

const commonConfig = {
  fallbackLng: "fr-FR",
  lng: "fr-FR",
  defaultNS: "translations",
  keySeparator: ".",
  interpolation: {
    escapeValue: false
  },
  react: {
    wait: true
  }
};

const i18nDev = () => {
  return i18n
    .use(BackendLocize)
    .use(reactI18nextModule)
    .init({
      backend: {
        projectId: process.env.REACT_APP_LOCIZE_PROJECT_ID,
        apiKey: process.env.REACT_APP_LOCIZE_API_KEY,
        private: true,
        version: process.env.REACT_APP_LOCIZE_BRANCH
      },
      preload: ["fr-CI", "fr-CM", "fr-GA", "fr-NE", "fr-SN", "fr-FR"],
      saveMissing: true,
      nsSeparator: false,
      debug: true,
      ...commonConfig
    });
};

const i18nProduction = () => {
  return i18n
    .use(BackendXHR)
    .use(reactI18nextModule)
    .init({
      resources: {
        "fr-FR": {
          translations: langfrFR
        },
        "fr-CI": {
          translations: langfrCI
        },
        "fr-CM": {
          translations: langfrCM
        },
        "fr-GA": {
          translations: langfrGA
        },
        "fr-NE": {
          translations: langfrNE
        },
        "fr-SN": {
          translations: langfrSN
        }
      },
      ns: ["translations"],
      debug: false,
      ...commonConfig
    });
};

export default (parseInt(process.env.REACT_APP_LOCALE_AS_PROD, 10) === 1
  ? i18nProduction()
  : i18nDev());
