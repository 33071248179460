export const PAIEMENT_STATUS_INTRA = {
  REQUEST: "paiement/GET_INTRA/REQUEST",
  SUCCESS: "paiement/GET_INTRA/SUCCESS",
  FAILURE: "paiement/GET_INTRA/FAILURE"
};

export const PAIEMENT_STATUS_EXTERNAL = {
  REQUEST: "paiement/GET_EXTERNAL/REQUEST",
  SUCCESS: "paiement/GET_EXTERNAL/SUCCESS",
  FAILURE: "paiement/GET_EXTERNAL/FAILURE"
};

export const POST_PAIEMENT = {
  REQUEST: "paiement/POST/REQUEST",
  SUCCESS: "paiement/POST/SUCCESS",
  FAILURE: "paiement/POST/FAILURE"
};

export const RAPPEL_PAIEMENT = {
  REQUEST: "paiementRappel/POST/REQUEST",
  SUCCESS: "paiementRappel/POST/SUCCESS",
  FAILURE: "paiementRappel/POST/FAILURE"
};

export const CHOSEN_OPERATOR = {
  SET: "paiement/CHOIX_OPERATEUR/SET",
  RESET: "paiement/CHOIX_OPERATEUR/RESET"
};

export const PHONE_NUMBER = {
  SET: "paiement/PHONE_NUMBER/SET",
  RESET: "paiement/PHONE_NUMBER/RESET"
};

export const RESET_CLIENT_SETTINGS = "paiement/CLIENT_SETTINGS/RESET";

export const GET_INFOS_PAIEMENT = {
  REQUEST: "paiement/GET/INFO/REQUEST",
  SUCCESS: "paiement/GET/INFO/SUCCESS",
  FAILURE: "paiement/GET/INFO/FAILURE"
};

export const paiementStatusIntra = ({
  nbTry,
  delay,
  paiementId,
  tryCount,
  fromEchecPage
}) => {
  return {
    type: PAIEMENT_STATUS_INTRA.REQUEST,
    paiementId,
    nbTry,
    delay,
    tryCount,
    fromEchecPage
  };
};

export const redirectPaiementExternal = () => {
  return {
    type: PAIEMENT_STATUS_EXTERNAL.REQUEST
  };
};

export const rappelPaiement = () => {
  return {
    type: RAPPEL_PAIEMENT.REQUEST
  };
};

export const postPaiement = ({
  assuranceType,
  operateurPaiement,
  telephone
}) => {
  return {
    type: POST_PAIEMENT.REQUEST,
    assuranceType,
    operateurPaiement,
    telephone
  };
};

export const setChosenOperator = code => {
  const codeToString = JSON.stringify(code);
  sessionStorage.setItem("paiementOperatorCode", codeToString);
  return {
    type: CHOSEN_OPERATOR.SET,
    code
  };
};

export const resetChosenOperator = () => {
  sessionStorage.removeItem("paiementOperatorCode");
  return {
    type: CHOSEN_OPERATOR.RESET
  };
};

export const setPhoneNumber = phoneNumber => {
  const phoneNumberToString = JSON.stringify(phoneNumber);
  sessionStorage.setItem("paiementPhoneNumber", phoneNumberToString);
  return {
    type: PHONE_NUMBER.SET,
    phoneNumber
  };
};

export const resetPhoneNumber = () => {
  sessionStorage.removeItem("paiementPhoneNumber");
  return {
    type: PHONE_NUMBER.RESET
  };
};

export const resetClientSettings = () => {
  sessionStorage.removeItem("paiementOperatorCode");
  sessionStorage.removeItem("paiementPhoneNumber");
  return {
    type: RESET_CLIENT_SETTINGS
  };
};

export const getInfosPaiement = paiementId => {
  return {
    type: GET_INFOS_PAIEMENT.REQUEST,
    paiementId
  };
};
