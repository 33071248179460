import { withNamespaces } from "react-i18next";
import React from "react";
import Paragraph from "../paragraph";

// Import style
import { StyledErrorGlobal } from "./style";

const ErrorGlobal = props => {
  const { errors, className, warning, t } = props;
  if (errors.error) {
    return (
      <StyledErrorGlobal
        className={`${typeof className !== "undefined" ? className : ""} error${
          errors.error.length <= 1 ? " one" : ""
        } ${warning && "warning"}
        `}
      >
        {errors.error.map((error, index) => (
          <Paragraph noMargin={index <= 0} key={index}>
            {t("shared." + error)}
          </Paragraph>
        ))}
      </StyledErrorGlobal>
    );
  } else {
    return (
      <StyledErrorGlobal
        className={`${typeof className !== "undefined" ? className : ""} error${
          errors.length <= 1 ? " one" : ""
        } ${warning && "warning"}
        `}
      >
        {errors.map((error, index) => (
          <Paragraph noMargin={index <= 0} key={index}>
            {t(error)}
          </Paragraph>
        ))}
      </StyledErrorGlobal>
    );
  }
};

export default withNamespaces("translations")(ErrorGlobal);
