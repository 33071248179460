import styled, { css } from "styled-components";
import breakpoint from "styled-components-breakpoint";

const position = position => {
  let positionBorder = "";
  switch (position) {
    case "right":
      positionBorder = "flex-end";
      break;
    case "center":
      positionBorder = "center";
      break;
    default:
      positionBorder = "flex-start";
  }
  return positionBorder;
};

const defaultStyleFromTheme = css`
  font-family: ${props => props.theme.fonts.main.name};
  font-weight: ${props => props.theme.fonts.main.variants.bold};
  margin-bottom: 15px;
  width: 100%;
  ${props => (props.noMargin ? "margin-bottom: 0 !important;" : "")};
  color: ${props =>
    typeof props.altColor !== "undefined"
      ? props.theme.colors.themeColors.white
      : props.theme.colors.themeColors.regular};
  text-align: ${props =>
    props.textAlign === "left" ||
    props.textAlign === "center" ||
    props.textAlign === "right"
      ? props.textAlign
      : "left"};
  ${props => {
    if (
      typeof props.underline !== "undefined" ||
      typeof props.altUnderline !== "undefined"
    ) {
      const underlineStyle =
        typeof props.underline !== "undefined"
          ? css`
              height: 3px;
              background-color: ${props =>
                props.theme.colors.themeColors.thirdLight};
            `
          : css`
              height: 5px;
              background-color: ${props =>
                props.theme.colors.themeColors.regular};
            `;
      return css`
        display: flex;
        flex-flow: column wrap;
        &:after {
          content: "";
          display: block;
          margin-top: 20px;
          width: 100px;
          align-self: ${props => position(props.textAlign || "left")};
          ${underlineStyle};
        }
      `;
    }
  }};
`;

const titleMainClassName = "title";

export const TitleLv1 = styled.h1.attrs({
  className: titleMainClassName
})`
  ${defaultStyleFromTheme};
  font-size: 3.6rem;
  line-height: 1.14;
  ${breakpoint("mobile", "tablet")`
    font-size: 2.6rem;
    line-height: 1.15;
  `};
  > a {
    line-height: initial !important;
  }
`;

export const TitleLv2 = styled.h2.attrs({
  className: titleMainClassName
})`
  ${defaultStyleFromTheme};
  font-size: 2.6rem;
  line-height: 1.15;
  ${breakpoint("desktop")`
    font-size: 3.2rem;
    line-height: 1.16;
  `};
`;

export const TitleLv02 = styled.h3.attrs({
  className: titleMainClassName
})`
  ${defaultStyleFromTheme};
  font-size: 2.6rem;
  line-height: 1.15;
  ${breakpoint("desktop")`
    font-size: 3.2rem;
    line-height: 1.16;
  `};
`;

export const TitleLv3 = styled.h3.attrs({
  className: titleMainClassName
})`
  ${defaultStyleFromTheme};
  font-size: 2rem;
  line-height: 1.2;
`;

export const TitleLv4 = styled.h4.attrs({
  className: titleMainClassName
})`
  ${defaultStyleFromTheme};
  font-size: 2rem;
  line-height: 1.2;
  ${breakpoint("mobile", "tablet")`
    font-size: 1.4rem;
    line-height: 1.14;
  `};
`;
