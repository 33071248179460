import axios from "axios";
import API from "../api";
import qs from "qs";
export async function post(mainToken, message, level) {
  const bodyFormData = new FormData();
  bodyFormData.set("level", level);
  bodyFormData.set("message", message);
  const data = qs.stringify({
    level: level,
    message: message
  });
  return new Promise(resolve => {
    const url = API.logger.main;
    const request = axios.post(url, data, {
      headers: {
        Authorization: `bearer ${mainToken}`,
        "Content-Type": "application/x-www-form-urlencoded"
      }
    });
    return request
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        resolve({ error: true, res: err });
      });
  });
}
