import moment from "moment";

export const isFormValid = formValidation => {
  const formItems = Object.values(formValidation);
  const errors = formItems.reduce(function(acc, formItem) {
    return !formItem ? acc + 1 : acc;
  }, 0);
  return errors === 0;
};

// FormValidation
const FormValidation = element => {
  const node = element.target;
  const type = node.nodeName.toLowerCase();
  const isElementRequired = node => {
    return typeof node.attributes["required"] !== "undefined"
      ? node.attributes["required"]
      : null;
  };

  /* Valid input[type=text] */
  const validInputText = () => {
    return node.value.length > 1;
  };

  /* Valid input[type=number] */
  const validInputNumber = () => {
    const intValue = node.value.trim();
    const numberValue = intValue.replace(/\s/g, "");
    return (
      intValue !== "" &&
      typeof parseInt(numberValue, 10) === "number" &&
      parseInt(numberValue, 10) > 0
    );
  };

  /* Valid input[type=tel] */
  const validInputTel = () => {
    return element.isValidPhone;
  };

  /* Valid input[type=password] */
  const validInputPassword = () => {
    return node.value.length > 1;
  };

  /* Valid input[type=date] */
  const validInputDate = () => {
    return moment(node.value).isValid();
  };

  const validInputByType = () => {
    const type = node.type.toLowerCase();
    let isValid = false;
    switch (type) {
      case "text":
        isValid = node.isAmount ? validInputNumber() : validInputText();
        break;
      case "number":
        isValid = validInputNumber();
        break;
      case "tel":
        isValid = validInputTel();
        break;
      case "password":
        isValid = validInputPassword();
        break;
      case "radio":
        isValid = validSelectRadio();
        break;
      case "date":
        isValid = validInputDate();
        break;
      default:
        break;
    }
    return isValid;
  };

  const validSelectRadio = () => {
    return typeof node.value !== "undefined";
  };

  if (isElementRequired(node)) {
    let isValid = false;
    switch (type) {
      case "input":
        isValid = validInputByType();
        break;
      case "select":
        isValid = validSelectRadio();
        break;
      default:
        break;
    }
    return isValid;
  } else {
    return true; // default return not required
  }
};

export default FormValidation;
