import * as a from "./actions";

const INITIAL_STATE = {
  assures: [],
  get: {
    success: false,
    loading: false,
    error: false
  },
  post: {
    success: false,
    loading: false,
    error: false
  }
};

export function assures(state = INITIAL_STATE, action) {
  switch (action.type) {
    case a.GET_ASSURES.REQUEST:
      return {
        ...state,
        get: {
          success: false,
          loading: true,
          error: false
        }
      };
    case a.GET_ASSURES.SUCCESS:
      return {
        ...state,
        get: {
          success: true,
          loading: false,
          error: false
        },
        assures: action.payload
      };
    case a.GET_ASSURES.FAILURE:
      return {
        ...state,
        get: {
          success: false,
          error: true,
          loading: false
        }
      };
    case a.POST_ASSURES.REQUEST:
      return {
        ...state,
        post: {
          success: false,
          loading: true,
          error: false
        }
      };
    case a.POST_ASSURES.SUCCESS:
      return {
        ...state,
        post: {
          success: true,
          loading: false,
          error: false
        },
        assures: action.payload
      };
    case a.POST_ASSURES.FAILURE:
      return {
        ...state,
        post: {
          success: false,
          error: true,
          loading: false
        }
      };
    default:
      return state;
  }
}

export default assures;
