export const GET_RESEAUX_SOINS = {
  REQUEST: "reseauxSoins/GET/REQUEST",
  SUCCESS: "reseauxSoins/GET/SUCCESS",
  FAILURE: "reseauxSoins/GET/FAILURE"
};

export const RESET_RESEAUX_SOINS = "reseauxSoins/RESET";

export const getReseauxSoins = () => {
  return {
    type: GET_RESEAUX_SOINS.REQUEST
  };
};
