export const CREATE_UPLOAD_DOCUMENT = {
  REQUEST: "uploadDocument/CREATE/REQUEST",
  SUCCESS: "uploadDocument/CREATE/SUCCESS",
  FAILURE: "uploadDocument/CREATE/FAILURE"
};

export const GET_NEXT_DOCUMENT_ATTENDU = {
  REQUEST: "uploadDocument/GET_ATTENDU/REQUEST",
  SUCCESS: "uploadDocument/GET_ATTENDU/SUCCESS",
  FAILURE: "uploadDocument/GET_ATTENDU/FAILURE"
};

export const CREATE_UPLOAD_DOCUMENT_ATTENDU = {
  REQUEST: "uploadDocument/CREATE_ATTENDU/REQUEST",
  SUCCESS: "uploadDocument/CREATE_ATTENDU/SUCCESS",
  FAILURE: "uploadDocument/CREATE_ATTENDU/FAILURE"
};

export const GET_DOCUMENTS_RISQUE_ATTENDU = {
  REQUEST: "uploadDocument/GET_DOCUMENTS_RISQUE_ATTENDU/REQUEST",
  SUCCESS: "uploadDocument/GET_DOCUMENTS_RISQUE_ATTENDU/SUCCESS",
  FAILURE: "uploadDocument/GET_DOCUMENTS_RISQUE_ATTENDU/FAILURE"
};

export const CREATE_UPLOAD_DOCUMENT_RISQUE_ATTENDU = {
  REQUEST: "uploadDocument/CREATE_DOCUMENT_RISQUE_ATTENDU/REQUEST",
  SUCCESS: "uploadDocument/CREATE_DOCUMENT_RISQUE_ATTENDU/SUCCESS",
  FAILURE: "uploadDocument/CREATE_DOCUMENT_RISQUE_ATTENDU/FAILURE"
};

export const NEED_UPLOAD_DOCUMENT = {
  REQUEST: "uploadDocument/NEED/REQUEST",
  SUCCESS: "uploadDocument/NEED/SUCCESS",
  FAILURE: "uploadDocument/NEED/FAILURE"
};

export const GO_BACK = "uploadDocument/GO_BACK";

export const GET_END_OF_UPLOAD_AUTO = "uploadDocument/AUTOMOBILE/GET_END";
export const GET_END_OF_UPLOAD_SANTE = "uploadDocument/SANTE/GET_END";

export const RESET_NEED_UPLOAD_STATUS = "uploadDocument/NEED/RESET_STATUS";

export const SET_CURRENT_UPLOAD_STEP = {
  REQUEST: "uploadDocument/SET_STEP/REQUEST",
  SUCCESS: "uploadDocument/SET_STEP/SUCCESS"
};

export const RESET_UPLOAD_DOCUMENT = "uploadDocument/RESET";

export const createUploadDocument = (
  assuranceType,
  documentType,
  file,
  needToComplete
) => {
  return {
    type: CREATE_UPLOAD_DOCUMENT.REQUEST,
    assuranceType,
    documentType,
    file,
    needToComplete
  };
};

export const getNextDocumentAttendu = assuranceType => {
  return {
    type: GET_NEXT_DOCUMENT_ATTENDU.REQUEST,
    assuranceType
  };
};

export const createUploadDocumentAttendu = (
  assuranceType,
  documentRefId,
  file
) => {
  return {
    type: CREATE_UPLOAD_DOCUMENT_ATTENDU.REQUEST,
    assuranceType,
    documentRefId,
    file
  };
};

export const needUploadDocument = (assuranceType, documentType) => {
  return {
    type: NEED_UPLOAD_DOCUMENT.REQUEST,
    assuranceType,
    documentType
  };
};

export const resetUploadDocument = () => {
  return {
    type: RESET_UPLOAD_DOCUMENT
  };
};

export const setCurrentUploadStep = step => {
  return {
    type: SET_CURRENT_UPLOAD_STEP.REQUEST,
    step
  };
};

export const getEndOfUploadAutomobile = documentType => {
  return {
    type: GET_END_OF_UPLOAD_AUTO,
    documentType
  };
};

export const getEndOfUploadSante = documentType => {
  return {
    type: GET_END_OF_UPLOAD_SANTE,
    documentType
  };
};

export const goBack = (assuranceType, documentType) => {
  return {
    type: GO_BACK,
    assuranceType,
    documentType
  };
};

export const getDocumentsRisqueAttendu = assuranceType => {
  return {
    type: GET_DOCUMENTS_RISQUE_ATTENDU.REQUEST,
    assuranceType
  };
};

export const createDocumentRisqueAttendu = (
  assuranceType,
  natureDocumentAttenduRefId,
  file,
  risqueId
) => {
  return {
    type: CREATE_UPLOAD_DOCUMENT_RISQUE_ATTENDU.REQUEST,
    assuranceType,
    natureDocumentAttenduRefId,
    file,
    risqueId
  };
};
