import React from "react";

// Import components
import WrapperViewResponsive from "../wrapper-view-responsive";
import MainMenuMobile from "./mobile";
import MainMenuDesktop from "./desktop";

const MainMenuResp = props => {
  const { helpArticles } = props;
  const MobileMenu = () => <MainMenuMobile helpArticles={helpArticles} />;
  const DesktopMenu = () => <MainMenuDesktop helpArticles={helpArticles} />;

  return (
    <WrapperViewResponsive
      componentRender={{
        mobile: <MobileMenu />,
        desktop: <DesktopMenu />
      }}
    />
  );
};

export default MainMenuResp;
