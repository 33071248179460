export const CREATE_CLIENT = {
  REQUEST: "client/CREATE/REQUEST",
  SUCCESS: "client/CREATE/SUCCESS",
  FAILURE: "client/CREATE/FAILURE"
};

export const RESET_LOADING_CREATION_CLIENT = "client/CREATE/RESET";

export const CONNECT_CLIENT = {
  REQUEST: "client/CONNECT/REQUEST",
  SUCCESS: "client/CONNECT/SUCCESS",
  FAILURE: "client/CONNECT/FAILURE"
};

export const FORGOT_CLIENT = {
  REQUEST: "client/FORGOT_CLIENT/REQUEST",
  SUCCESS: "client/FORGOT_CLIENT/SUCCESS",
  FAILURE: "client/FORGOT_CLIENT/FAILURE"
};

export const AUTHENTIFICATION_CLIENT_NECESSAIRE = {
  REQUEST: "client/AUTHENTIFICATION_NECESSAIRE/REQUEST",
  SUCCESS: "client/AUTHENTIFICATION_NECESSAIRE/SUCCESS",
  FAILURE: "client/AUTHENTIFICATION_NECESSAIRE/FAILURE"
};

export const RESET_CLIENT = "CLIENT/RESET";

export const createClient = ({
  civilite,
  prenom,
  nom,
  telephone,
  assuranceType
}) => {
  return {
    type: CREATE_CLIENT.REQUEST,
    civilite,
    prenom,
    nom,
    telephone,
    assuranceType
  };
};

export const resetLoadingCreationClient = () => {
  return {
    type: RESET_LOADING_CREATION_CLIENT
  };
};

export const connectClient = ({ assuranceType, telephone, password }) => {
  return {
    type: CONNECT_CLIENT.REQUEST,
    assuranceType,
    telephone,
    password
  };
};

export const forgotClient = ({ telephone }) => {
  return {
    type: FORGOT_CLIENT.REQUEST,
    telephone
  };
};

export const authentificationClientNecessaire = assuranceType => {
  const telephone = JSON.parse(sessionStorage.getItem("telephoneClient"));
  return {
    type: AUTHENTIFICATION_CLIENT_NECESSAIRE.REQUEST,
    assuranceType,
    telephone
  };
};

export const resetClient = () => {
  return {
    type: RESET_CLIENT
  };
};
