import * as a from "./actions";

const INITIAL_PAIEMENT_STATE = {
  chosenOperator: null,
  phoneNumber: null,
  getIntra: {
    error: false,
    loading: false,
    success: false,
    httpMethod: null,
    id: null,
    parametres: null,
    statut: null,
    urlRedirection: null,
    motifRefusPaiement: null
  },
  post: {
    error: null,
    loading: false,
    success: false,
    httpMethod: null,
    id: null,
    parametres: null,
    statut: null,
    urlRedirection: null
  },
  rappel: {
    error: null,
    loading: false,
    success: false
  },
  infos: {
    error: null,
    loading: false,
    success: false,
    paiementId: null,
    formule: null,
    acteurCommercial: null,
    devise: null,
    montantPaye: null
  }
};

export function paiement(state = INITIAL_PAIEMENT_STATE, action) {
  switch (action.type) {
    case a.CHOSEN_OPERATOR.SET:
      return {
        ...state,
        chosenOperator: action.code
      };
    case a.CHOSEN_OPERATOR.RESET:
      return {
        ...state,
        chosenOperator: INITIAL_PAIEMENT_STATE.chosenOperator
      };
    case a.PHONE_NUMBER.SET:
      return {
        ...state,
        phoneNumber: action.phoneNumber
      };
    case a.PHONE_NUMBER.RESET:
      return {
        ...state,
        phoneNumber: null
      };
    case a.RESET_CLIENT_SETTINGS:
      return {
        ...state,
        chosenOperator: INITIAL_PAIEMENT_STATE.chosenOperator,
        phoneNumber: INITIAL_PAIEMENT_STATE.phoneNumber
      };
    case a.PAIEMENT_STATUS_INTRA.REQUEST:
      return {
        ...state,
        getIntra: {
          success: false,
          error: false,
          loading: true
        }
      };
    case a.PAIEMENT_STATUS_INTRA.SUCCESS:
      return {
        ...state,
        getIntra: {
          success: true,
          error: false,
          loading: false,
          httpMethod: action.payload.httpMethod,
          id: action.payload.id,
          parametres: action.payload.parametres,
          statut: action.payload.statut,
          urlRedirection: action.payload.urlRedirection,
          motifRefusPaiement: action.payload.motifRefusPaiement
        }
      };
    case a.PAIEMENT_STATUS_INTRA.FAILURE:
      return {
        ...state,
        getIntra: {
          success: false,
          error: true,
          loading: false
        }
      };
    case a.POST_PAIEMENT.REQUEST:
      return {
        ...state,
        post: {
          success: false,
          error: null,
          loading: true
        }
      };
    case a.POST_PAIEMENT.SUCCESS:
      return {
        ...state,
        post: {
          success: true,
          error: null,
          loading: false,
          httpMethod: action.payload.httpMethod,
          id: action.payload.id,
          parametres: action.payload.parametres,
          statut: action.payload.statut,
          urlRedirection: action.payload.urlRedirection
        }
      };
    case a.POST_PAIEMENT.FAILURE:
      return {
        ...state,
        post: {
          success: false,
          error: action.payload,
          loading: false
        }
      };
    // RAPPEL
    case a.RAPPEL_PAIEMENT.REQUEST:
      return {
        ...state,
        rappel: {
          error: null,
          success: false,
          loading: true
        }
      };
    case a.RAPPEL_PAIEMENT.SUCCESS:
      return {
        ...state,
        rappel: {
          error: null,
          success: true,
          loading: false
        }
      };
    case a.RAPPEL_PAIEMENT.FAILURE:
      return {
        ...state,
        rappel: {
          error: action.payload,
          success: false,
          loading: false
        }
      };
    case a.GET_INFOS_PAIEMENT.REQUEST:
      return {
        ...state,
        infos: {
          error: null,
          loading: true,
          success: false,
          paiementId: null,
          formule: null,
          acteurCommercial: null,
          devise: null,
          montantPaye: null
        }
      };
    case a.GET_INFOS_PAIEMENT.SUCCESS:
      return {
        ...state,
        infos: {
          error: null,
          loading: false,
          success: true,
          paiementId: action.payload.id,
          formule: action.payload.codeFormule,
          acteurCommercial: action.payload.acteurCommercialCode,
          devise: action.payload.deviseCode,
          montantPaye: action.payload.montant
        }
      };
    case a.GET_INFOS_PAIEMENT.FAILURE:
      return {
        ...state,
        infos: {
          error: action.payload,
          loading: false,
          success: false
        }
      };
    default:
      return state;
  }
}

export default paiement;
