import * as a from "./actions";

const INITIAL_ARTICLE_STATE = {
  article: {
    error: null,
    loading: false,
    success: false,
    data: null
  },
  articles: {
    error: null,
    loading: false,
    success: false,
    data: null
  },
  aide: {
    error: null,
    loading: false,
    success: false,
    data: null
  }
};

export function article(state = INITIAL_ARTICLE_STATE, action) {
  switch (action.type) {
    // GET ONE ARTICLE
    case a.GET_ARTICLE.REQUEST:
      return {
        ...state,
        article: {
          ...state.article,
          success: false,
          error: null,
          loading: true
        }
      };
    case a.GET_ARTICLE.SUCCESS:
      return {
        ...state,
        article: {
          ...state.article,
          success: true,
          error: null,
          loading: false,
          data: action.payload
        }
      };
    case a.GET_ARTICLE.FAILURE:
      return {
        ...state,
        article: {
          ...state.article,
          success: false,
          error: true,
          loading: false
        }
      };
    case a.RESET_ARTICLE:
      return INITIAL_ARTICLE_STATE.article;
    // GET ARTICLES
    case a.GET_ARTICLES.REQUEST:
      return {
        ...state,
        articles: {
          ...state.articles,
          success: false,
          error: null,
          loading: true
        }
      };
    case a.GET_ARTICLES.SUCCESS:
      return {
        ...state,
        articles: {
          ...state.articles,
          success: true,
          error: null,
          loading: false,
          data: action.payload
        }
      };
    case a.GET_ARTICLES.FAILURE:
      return {
        ...state,
        articles: {
          ...state.articles,
          success: false,
          error: true,
          loading: false
        }
      };
    // GET HELP ARTICLES
    case a.GET_HELP_ARTICLES.REQUEST:
      return {
        ...state,
        aide: {
          ...state.aide,
          success: false,
          error: null,
          loading: true
        }
      };
    case a.GET_HELP_ARTICLES.SUCCESS:
      return {
        ...state,
        aide: {
          ...state.aide,
          success: true,
          error: null,
          loading: false,
          data: action.payload
        }
      };
    case a.GET_HELP_ARTICLES.FAILURE:
      return {
        ...state,
        aide: {
          ...state.aide,
          success: false,
          error: true,
          loading: false
        }
      };
    case a.RESET_ARTICLES:
      return INITIAL_ARTICLE_STATE.articles;

    default:
      return state;
  }
}

export default article;
