import { getOperateursPaiement } from "../../../information/selectors";

// Envoi de la requête GET
export const isGetPaiementIntraLoading = state =>
  state.tunnel.common.paiement.getIntra.loading;

// Envoi succes GET
export const isGetPaiementIntraSuccessfull = state =>
  state.tunnel.common.paiement.getIntra.success;

// Erreur de l'envoi GET
export const isGetPaiementIntraError = state =>
  state.tunnel.common.paiement.getIntra.error;

// Envoi de la requête POST
export const isPostPaiementLoading = state =>
  state.tunnel.common.paiement.post.loading;

// Envoi succes POST
export const isPostPaiementSuccessfull = state =>
  state.tunnel.common.paiement.post.success;

// Erreur de l'envoi POST
export const isPostPaiementError = state =>
  state.tunnel.common.paiement.post.error;

// Envoi de la requête RAPPEL
export const isRappelPaiementLoading = state =>
  state.tunnel.common.paiement.rappel.loading;

// Envoi succes RAPPEL
export const isRappelPaiementSuccessfull = state =>
  state.tunnel.common.paiement.rappel.success;

// Erreur de l'envoi RAPPEL
export const isRappelPaiementError = state =>
  state.tunnel.common.paiement.rappel.error;

// Get informations for redirection
export const getInfosPaiementRedirect = state => {
  return {
    urlRedirection: state.tunnel.common.paiement.post.urlRedirection,
    httpMethod: state.tunnel.common.paiement.post.httpMethod,
    parametres: state.tunnel.common.paiement.post.parametres
  };
};

// Get paiement erreur
export const isGetPaiementIntraStatut = state =>
  state.tunnel.common.paiement.getIntra.statut;

// Get paiement motif refus
export const getPaiementModifRefus = state =>
  state.tunnel.common.paiement.getIntra.motifRefusPaiement;

// Get chosen operator code
export const getChosenOperatorCode = state =>
  state.tunnel.common.paiement.chosenOperator;

// Get chosen operator name
export const getChosenOperatorInfo = state => {
  let chosenOperator = getChosenOperatorCode(state);
  if (chosenOperator === null) {
    chosenOperator = JSON.parse(sessionStorage.getItem("paiementOperatorCode"));
  }
  if (typeof chosenOperator !== "undefined" && chosenOperator !== null) {
    const operators = getOperateursPaiement(state);
    return operators.find(element => element.code === chosenOperator);
  } else {
    return null;
  }
};

// Get phone number for paiement
export const getPhoneNumber = state => {
  let phoneNumber = state.tunnel.common.paiement.phoneNumber;
  if (phoneNumber === null) {
    phoneNumber = JSON.parse(sessionStorage.getItem("paiementPhoneNumber"));
  }
  return phoneNumber;
};

export const getInfosPaiement = state => state.tunnel.common.paiement.infos;

export const getPaiementId = state => state.tunnel.common.paiement.paiementId;
