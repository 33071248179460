import { call, all, put, takeLatest, select } from "redux-saga/effects";
import { push } from "connected-react-router";

// Constants
import appConstants from "../../../configuration/constants";

// Actions
import * as a from "./actions";
import * as inputProcessActions from "../common/input-process/actions";
import * as mainMenuActions from "../../main-menu/actions";
import * as referenceListActions from "../../reference-list/actions";
import * as uploadDocumentActions from "../common/upload-document/actions";
import * as tarifActions from "../common/tarif/actions";
import * as paiementActions from "../common/paiement/actions";
import * as recapitulatifActions from "../common/recapitulatif/actions";
import * as leadActions from "../sante/lead/actions";
import * as reseauxSoinsActions from "../sante/reseaux-soins/actions";
import * as tauxCouvertureActions from "../sante/taux-couverture/actions";
// Selectors
import * as tunnelSelectors from "./selectors";
import * as recapitulatifSelectors from "../common/recapitulatif/selectors";

// Sagas

function* initCheckRadlad({ checkRadlad }) {
  yield call(setCheckRadladDocuments, checkRadlad);
}

export function* setIds(ids) {
  if (typeof ids === "undefined" || ids === null) {
    sessionStorage.removeItem("ids");
    yield put({
      type: a.RESET_CURRENT_SOUSCRIPTION_IDS
    });
  } else {
    yield put({
      type: a.SET_CURRENT_SOUSCRIPTION_IDS,
      payload: ids
    });
    const currentIds = yield select(tunnelSelectors.getCurrentSimulationIds);
    const idsToSessionStorage = JSON.stringify(currentIds);
    sessionStorage.setItem("ids", idsToSessionStorage);
  }
}

export function* goBackFromStartTunnel({ assuranceType }) {
  const lastBeforeParcours = yield select(
    tunnelSelectors.getBeforeLastParcours
  );
  let redirectionURL = `/assurance/${assuranceType}`;
  if (lastBeforeParcours === "homePage") {
    redirectionURL = "/";
  }
  yield call(redirectToUrl, redirectionURL);
}

export function* setInputProcess(ip) {
  if (typeof ip === "undefined") {
    yield put({
      type: a.RESET_CURRENT_INPUT_PROCESS
    });
    sessionStorage.removeItem("currentInputProcess");
  } else {
    yield put({
      type: a.SET_CURRENT_INPUT_PROCESS,
      payload: ip
    });
    const currentInputProcess = yield select(
      tunnelSelectors.getCurrentSimulationInputProcess
    );
    const inputProcessToSessionStorage = JSON.stringify(currentInputProcess);
    sessionStorage.setItem("currentInputProcess", inputProcessToSessionStorage);
  }
}

export function* setRadladDocuments(radlad) {
  if (typeof radlad === "undefined" || radlad === null) {
    sessionStorage.removeItem("radlad");
    yield put({
      type: a.RESET_CURRENT_SOUSCRIPTION_RADLAD
    });
  } else {
    yield put({
      type: a.SET_CURRENT_SOUSCRIPTION_RADLAD,
      payload: radlad
    });
    const currentRadlad = yield select(
      tunnelSelectors.getCurrentSimulationRadlad
    );
    const radladToSessionStorage = JSON.stringify(currentRadlad);
    sessionStorage.setItem("radlad", radladToSessionStorage);
  }
}

export function* setCheckRadladDocuments(checkRadlad) {
  if (typeof checkRadlad === "undefined" || checkRadlad === null) {
    sessionStorage.removeItem("needCheckRadlad");
    yield put({
      type: a.RESET_CURRENT_SOUSCRIPTION_CHECKRADLAD
    });
  } else {
    yield put({
      type: a.SET_CURRENT_SOUSCRIPTION_CHECKRADLAD,
      payload: checkRadlad
    });
    const currentCheckRadlad = yield select(
      tunnelSelectors.getCurrentSimulationCheckRadlad
    );
    const radladToSessionStorage = JSON.stringify(currentCheckRadlad);
    sessionStorage.setItem("needCheckRadlad", radladToSessionStorage);
  }
}

export function resetPaiementDatas() {
  ["paiementId", "paiementOperatorCode"].forEach(item => {
    sessionStorage.removeItem(item);
  });
}

export function* setModificationRecap(go) {
  if (typeof go === "undefined" || go === null) {
    sessionStorage.removeItem("modificationContratGo");
    yield put({
      type: recapitulatifActions.RESET_RECAPITULATIF_MODIFICATION
    });
  } else {
    yield put({
      type: recapitulatifActions.SET_RECAPITULATIF_MODIFICATION,
      payload: go
    });
    const currentModifRecap = yield select(recapitulatifSelectors.getModifyGo);
    const recapModifToSessionStorage = JSON.stringify(currentModifRecap);
    sessionStorage.setItem("modificationContratGo", recapModifToSessionStorage);
  }
}

export function* rebindModifRecapFromSessionStorage() {
  const modifRecapFromSessionStorage = sessionStorage.getItem(
    "modificationContratGo"
  );
  if (
    typeof modifRecapFromSessionStorage !== "undefined" &&
    modifRecapFromSessionStorage !== null
  ) {
    yield call(setModificationRecap, JSON.parse(modifRecapFromSessionStorage));
  }
}

export function* rebindIdsFromSessionStorage() {
  const idsFromSessionStorage = sessionStorage.getItem("ids");
  if (
    typeof idsFromSessionStorage !== "undefined" &&
    idsFromSessionStorage !== null
  ) {
    yield call(setIds, JSON.parse(idsFromSessionStorage));
  }
}

export function* rebindInputProcessFromSessionStorage() {
  const inputProcessFromSessionStorage = sessionStorage.getItem(
    "currentInputProcess"
  );
  if (
    typeof inputProcessFromSessionStorage !== "undefined" &&
    inputProcessFromSessionStorage !== null
  ) {
    yield call(setInputProcess, JSON.parse(inputProcessFromSessionStorage));
  }
}

export function* rebindRadladFromSessionStorage() {
  const radladFromSessionStorage = sessionStorage.getItem("radlad");
  if (
    typeof radladFromSessionStorage !== "undefined" &&
    radladFromSessionStorage !== null
  ) {
    yield call(setRadladDocuments, JSON.parse(radladFromSessionStorage));
  }
}

export function* rebindCheckRadladFromSessionStorage() {
  const checkRadladFromSessionStorage = sessionStorage.getItem(
    "needCheckRadlad"
  );
  if (
    typeof checkRadladFromSessionStorage !== "undefined" &&
    checkRadladFromSessionStorage !== null
  ) {
    yield call(
      setCheckRadladDocuments,
      JSON.parse(checkRadladFromSessionStorage)
    );
  }
}

export function* rebindParcoursFromSessionStorage() {
  let userParcours = sessionStorage.getItem("parcoursUtilisateur");
  if (typeof userParcours !== "undefined" && userParcours !== null) {
    userParcours = JSON.parse(userParcours);
    yield put({
      type: a.NAVIGATE_INTO.SUCCESS,
      payload: userParcours
    });
  }
}

export function* redirectToUrl(url, state) {
  yield put(push(url, state));
}

function* isTunnel({ isTunnel }) {
  try {
    yield put({
      type: a.SET_IS_TUNNEL.SUCCESS,
      payload: {
        is: isTunnel
      }
    });
  } catch (e) {
    yield put({
      type: a.SET_IS_TUNNEL.FAILURE,
      error: e.message
    });
  }
}

function* removeLastParcours() {
  const parcours = yield select(tunnelSelectors.getParcours);
  parcours.pop();
  yield put({
    type: a.NAVIGATE_INTO.REQUEST,
    sectionName: parcours
  });
}

function* setTunnelCurrentStep({ currentStepId }) {
  try {
    yield put({
      type: a.SET_TUNNEL_CURRENT_STEP.SUCCESS,
      payload: {
        id: currentStepId
      }
    });
  } catch (e) {
    yield put({
      type: a.SET_TUNNEL_CURRENT_STEP.FAILURE,
      error: e.message
    });
  }
}

function* navigateInto({ sectionName, assuranceType }) {
  const isStartTunnel =
    sectionName === appConstants.tunnel.start[assuranceType];
  let userParcours = yield select(tunnelSelectors.getParcours);
  const hasToGetSessionStorageParcours = userParcours.length <= 0;

  if (sectionName !== "paiement") {
    yield call(resetPaiementDatas);
    yield put({
      type: paiementActions.RESET_CLIENT_SETTINGS
    });
  }

  if (isStartTunnel) {
    yield call(setCheckRadladDocuments);
    yield call(setRadladDocuments);
    yield call(setIds);
    yield call(setInputProcess);
    yield call(setModificationRecap);
    if (assuranceType === "sante") {
      yield call(resetLeadSante);
    }
  }

  if (hasToGetSessionStorageParcours) {
    const parcoursFromSessionStorage = JSON.parse(
      sessionStorage.getItem("parcoursUtilisateur")
    );
    userParcours =
      parcoursFromSessionStorage !== null
        ? parcoursFromSessionStorage
        : [sectionName];
  } else {
    if (Array.isArray(sectionName)) {
      userParcours = sectionName;
    } else {
      const duplication = userParcours.findIndex(
        parcours => parcours === sectionName
      );
      const yetExists =
        duplication > -1 && duplication === userParcours.length - 1;
      if (!yetExists) {
        userParcours = [...userParcours, sectionName];
      }
    }
  }

  const serializedParcours = JSON.stringify(userParcours);
  sessionStorage.setItem("parcoursUtilisateur", serializedParcours);
  yield put({
    type: a.NAVIGATE_INTO.SUCCESS,
    payload: userParcours
  });
}

function* resetLeadSante() {
  yield put({
    type: leadActions.RESET_LEAD
  });
  yield put({
    type: reseauxSoinsActions.RESET_RESEAUX_SOINS
  });
  yield put({
    type: tauxCouvertureActions.RESET_TAUX_COUVERTURE
  });
}

function* resetFullTunnelVariables() {
  const sessionStorageKeys = Object.keys(sessionStorage);
  sessionStorageKeys.map(item => {
    if (
      item !== "parcoursUtilisateur" &&
      item !== "currentVisitorOrigin" &&
      item !== "tunnelSante_choixQuestionsMedicales"
    )
      sessionStorage.removeItem(item);
    return false;
  });

  yield put({
    type: inputProcessActions.RESET_INPUT_PROCESS
  });
  yield put({
    type: a.RESET_TUNNEL
  });
  yield put({
    type: referenceListActions.RESET_REFERENCE_LIST
  });
  yield put({
    type: uploadDocumentActions.RESET_UPLOAD_DOCUMENT
  });
  yield put({
    type: tarifActions.RESET_TARIF
  });
  yield put({
    type: mainMenuActions.OPENCLOSE_MAINMENU.SUCCESS,
    payload: {
      isOpened: false,
      openedName: null
    }
  });
}

function* bindSessionStorageToStore({ isOnTunnel }) {
  const lastTunnel = JSON.parse(sessionStorage.getItem("currentTunnel"));
  if (
    ((isOnTunnel.name !== lastTunnel && lastTunnel !== null) ||
      !isOnTunnel.status) &&
    (!JSON.parse(sessionStorage.getItem("isReprisePaiement")) &&
      !JSON.parse(sessionStorage.getItem("isRepriseSignature")))
  ) {
    yield call(resetFullTunnelVariables);
    if (isOnTunnel.status)
      sessionStorage.setItem("currentTunnel", JSON.stringify(isOnTunnel.name));
  } else {
    sessionStorage.setItem("currentTunnel", JSON.stringify(isOnTunnel.name));
    yield call(rebindIdsFromSessionStorage);
    yield call(rebindRadladFromSessionStorage);
    yield call(rebindParcoursFromSessionStorage);
    yield call(rebindInputProcessFromSessionStorage);
    yield call(rebindCheckRadladFromSessionStorage);
    yield call(rebindModifRecapFromSessionStorage);
  }
}

export default all([
  takeLatest(a.SET_IS_TUNNEL.REQUEST, isTunnel),
  takeLatest(a.SET_TUNNEL_CURRENT_STEP.REQUEST, setTunnelCurrentStep),
  takeLatest(a.REMOVE_LAST_PARCOURS, removeLastParcours),
  takeLatest(a.RESET_FULL_TUNNEL_VARIABLES, resetFullTunnelVariables),
  takeLatest(a.NAVIGATE_INTO.REQUEST, navigateInto),
  takeLatest(a.BIND_SESSION_STORAGE_TO_STORE, bindSessionStorageToStore),
  takeLatest(a.INIT_CHECKRADLAD, initCheckRadlad),
  takeLatest(a.GO_BACK_FROM_START_TUNNEL, goBackFromStartTunnel)
]);
