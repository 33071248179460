import React from "react";
import Radio, { NativeRadioControl } from "@material/react-radio";
import cuid from "cuid";

// Import style
import { StyledRadioCard } from "./style";

const RadioCardItem = props => {
  const {
    name,
    data,
    radioChecked,
    radioRequired,
    onClick,
    listDirection,
    isPaiement,
    className,
    noLabel,
    oneLine,
    isOnWarning
  } = props;
  const { text, img, value, title } = data;
  const labelId = cuid();
  return (
    <StyledRadioCard
      checked={radioChecked === value}
      className={`choice ${radioChecked === value ? "active" : ""} ${
        isPaiement ? "is-paiement" : ""
      } ${listDirection === "row" || isPaiement ? "img-content" : ""} ${
        typeof className !== "undefined" ? className : ""
      } ${isOnWarning && "isOnWarning"}`}
      onClick={onClick}
      listDirection={listDirection}
      oneLine={oneLine}
    >
      {listDirection === "row" && <div className="icon">{img}</div>}
      {title && title}
      <Radio label={noLabel ? "" : text} key={labelId}>
        <NativeRadioControl
          name={name}
          value={value}
          id={labelId}
          checked={radioChecked === value}
          onChange={() => {}}
          required={radioRequired}
        />
      </Radio>
      {isPaiement && <img src={img} alt={text} />}
    </StyledRadioCard>
  );
};

export default RadioCardItem;
