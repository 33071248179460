import * as a from "./actions";

const INITIAL_STATE = {
  success: false,
  loading: false,
  error: false,
  valide: false
};

export function verifierTelephone(state = INITIAL_STATE, action) {
  switch (action.type) {
    case a.VERIFIER_TELEPHONE.REQUEST:
      return {
        ...state,
        success: false,
        loading: true,
        error: false,
        valide: false
      };
    case a.VERIFIER_TELEPHONE.SUCCESS:
      return {
        ...state,
        success: true,
        loading: false,
        error: false,
        valide: action.payload.valide
      };
    case a.VERIFIER_TELEPHONE.FAILURE:
      return {
        ...state,
        success: false,
        error: action.payload,
        loading: false,
        valide: false
      };
    default:
      return state;
  }
}

export default verifierTelephone;
