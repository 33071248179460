import * as a from "./actions";

const INITIAL_LOGGER_STATE = {};

export function logger(state = INITIAL_LOGGER_STATE, action) {
  switch (action.type) {
    case a.POST_LOG:
      return {
        ...state
      };
    default:
      return state;
  }
}

export default logger;
