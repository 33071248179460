export const GET_REFERENCE_LIST = {
  REQUEST: "referenceList/GET/REQUEST",
  SUCCESS: "referenceList/GET/SUCCESS",
  FAILURE: "referenceList/GET/FAILURE"
};

export const RESET_REFERENCE_LIST = "referenceList/RESET";

export const getReferenceList = (codeReferentiel, refParentId) => {
  return {
    type: GET_REFERENCE_LIST.REQUEST,
    codeReferentiel,
    refParentId
  };
};

export const resetReferenceList = () => {
  return {
    type: RESET_REFERENCE_LIST
  };
};
