import { call, all, put, takeLatest, select } from "redux-saga/effects";
import * as a from "./actions";

// Selectors
import * as informationSelectors from "../../../information/selectors";
// import * as userBaloonProSelectors from "../../user-baloonpro/selectors";
// Services
import * as questionsMedicalesService from "./services";
// Sagas
import * as loggerSagas from "../../../logger/saga";

function* getChoixQuestionsMedicales({ clientId }) {
  try {
    const mainToken = yield select(informationSelectors.getMainToken);
    const choixQuestionsMedicales = yield call(
      questionsMedicalesService.get,
      mainToken,
      clientId
    );
    if (!choixQuestionsMedicales.error) {
      yield put({
        type: a.GET_CHOIX_QUESTIONS_MEDICALES.SUCCESS,
        payload: choixQuestionsMedicales
      });
    } else {
      const errorRes = choixQuestionsMedicales.res.response;
      yield call(loggerSagas.postLogErrorApi, {
        sagaInit: "postChoixQuestionsMedicales",
        apiResponseUrl: errorRes.request.responseURL,
        statusResponse: errorRes.request.status,
        dataResponse: errorRes.data,
        token: mainToken,
        params: {
          clientId
        }
      });
      yield put({
        type: a.GET_CHOIX_QUESTIONS_MEDICALES.FAILURE,
        payload: true
      });
    }
  } catch (e) {}
}

function* postChoixQuestionsMedicales({ choix, clientId = null }) {
  try {
    const mainToken = yield select(informationSelectors.getMainToken);
    // const isUserBaloonPro = yield select(userBaloonProSelectors.isUserBaloonPro);
    const choixQuestionsMedicales = yield call(
      questionsMedicalesService.post,
      mainToken,
      choix,
      clientId
    );
    if (!choixQuestionsMedicales.error) {
      yield put({
        type: a.POST_CHOIX_QUESTIONS_MEDICALES.SUCCESS,
        payload: choixQuestionsMedicales
      });
    } else {
      const errorRes = choixQuestionsMedicales.res.response;
      yield call(loggerSagas.postLogErrorApi, {
        sagaInit: "postChoixQuestionsMedicales",
        apiResponseUrl: errorRes.request.responseURL,
        statusResponse: errorRes.request.status,
        dataResponse: errorRes.data,
        token: mainToken,
        params: {
          choix
        }
      });
      yield put({
        type: a.POST_CHOIX_QUESTIONS_MEDICALES.FAILURE,
        payload: true
      });
    }
  } catch (e) {}
}

export default all([
  takeLatest(
    a.POST_CHOIX_QUESTIONS_MEDICALES.REQUEST,
    postChoixQuestionsMedicales
  ),
  takeLatest(
    a.GET_CHOIX_QUESTIONS_MEDICALES.REQUEST,
    getChoixQuestionsMedicales
  )
]);
