import React, { Component } from "react";
import { Trans, withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";

// Import model page
import SimpleContent from "../simple-content";

// Import components
import ScrollToTopOnMount from "../../components/scroll-top-onmount";
import ParagraphBreaks from "../../components/paragraph-breaks";
import LinkButton from "../../components/link-button";
import Paragraph from "../../components/paragraph";
import Title from "../../components/title";

// Import store
import { getContactInfos } from "../../store/information/selectors";

const mapStateToProps = state => ({
  contactInfos: getContactInfos(state)
});

class Cgu extends Component {
  render() {
    const { t, contactInfos } = this.props;
    return (
      <SimpleContent id="article" titlePage={t("cgu.title")}>
        <ScrollToTopOnMount />
        <Paragraph>
          <Trans i18nKey="cgu.contents.intro">
            cgu.contents.intro
            <LinkButton
              config={{
                type: "link",
                style: "noStyle"
              }}
              to="/"
            >
              url site pays
            </LinkButton>
          </Trans>
        </Paragraph>
        <Title titleType={2}>1. {t("cgu.contents.block1.title")}</Title>
        <Paragraph noMargin>
          <Trans i18nKey="cgu.contents.block1.paragraph1">
            cgu.contents.block1.paragraph1
            <LinkButton
              config={{
                type: "link",
                style: "noStyle"
              }}
              to={"/"}
            >
              url site pays
            </LinkButton>
          </Trans>
        </Paragraph>
        <ParagraphBreaks text={t("cgu.contents.block1.paragraphs")} />
        <Title titleType={2}>2. {t("cgu.contents.block2.title")}</Title>
        <ParagraphBreaks noMargin text={t("cgu.contents.block2.paragraphs1")} />
        <Paragraph noMargin>
          <Trans i18nKey="cgu.contents.block2.paragraph3">
            cgu.contents.block2.paragraph3
            <LinkButton
              config={{
                type: "link",
                style: "noStyle"
              }}
              to={`mailto:${contactInfos.email}`}
            >
              email contact site pays
            </LinkButton>
          </Trans>
        </Paragraph>
        <ParagraphBreaks text={t("cgu.contents.block2.paragraphs2")} />
        <Title titleType={2}>3. {t("cgu.contents.block3.title")}</Title>
        <ParagraphBreaks noMargin text={t("cgu.contents.block3.paragraphs")} />
        <Paragraph>
          <Trans i18nKey="cgu.contents.block3.paragraph4">
            cgu.contents.block3.paragraph4
            <LinkButton
              config={{
                type: "link",
                style: "noStyle"
              }}
              to={"/"}
            >
              url site pays
            </LinkButton>
          </Trans>
        </Paragraph>
        <Title titleType={2}>4. {t("cgu.contents.block4.title")}</Title>
        <Paragraph>
          <Trans i18nKey="cgu.contents.block4.paragraph1">
            cgu.contents.block4.paragraph1
            <LinkButton
              config={{
                type: "link",
                style: "noStyle"
              }}
              to={`mailto:${contactInfos.email}`}
            >
              email contact site pays
            </LinkButton>
          </Trans>
        </Paragraph>
        <Paragraph noMargin>
          <strong>{t("cgu.baloonEntite.name")}</strong>
        </Paragraph>
        <Paragraph noMargin>{t("cgu.baloonEntite.status")}</Paragraph>
        <Paragraph>{contactInfos.adresse}</Paragraph>
      </SimpleContent>
    );
  }
}

export default compose(
  withNamespaces("translations"),
  connect(mapStateToProps)
)(Cgu);
