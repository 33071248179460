import * as a from "./actions";

const INITIAL_RECAPITULATIF_STATE = {
  get: {
    error: null,
    loading: false,
    success: false,
    data: {
      montant: null,
      formule: null,
      nomCommercialCompagnie: null,
      codeFrequenceFractionnement: null,
      debutContrat: null,
      attributs: [],
      typeReseauSoins: null,
      nbAssures: null,
      nomSouscripteur: null,
      prenomSouscripteur: null,
      nomConducteur: null,
      prenomConducteur: null,
      devise: null,
      montantPromotion: null,
      libellePromotion: null
    }
  },
  modify: {
    error: null,
    loading: false,
    success: false,
    go: null
  }
};

export function recapitulatif(state = INITIAL_RECAPITULATIF_STATE, action) {
  switch (action.type) {
    case a.GET_RECAPITULATIF.REQUEST:
      return {
        ...state,
        get: {
          success: false,
          error: null,
          loading: true
        }
      };
    case a.GET_RECAPITULATIF.SUCCESS:
      return {
        ...state,
        get: {
          success: true,
          error: null,
          loading: false,
          data: {
            montant: action.payload.montant,
            formule: action.payload.formule,
            nomCommercialCompagnie: action.payload.nomCommercialCompagnie,
            codeFrequenceFractionnement:
              action.payload.codeFrequenceFractionnement,
            debutContrat: action.payload.debutContrat,
            attributs: [...action.payload.attributs],
            nomSouscripteur: action.payload.nomSouscripteur,
            prenomSouscripteur: action.payload.prenomSouscripteur,
            nomConducteur: action.payload.nomConducteur,
            prenomConducteur: action.payload.prenomConducteur,
            devise: action.payload.devise,
            typeReseauSoins: action.payload.typeReseauSoins,
            nbAssures: action.payload.nbAssures,
            montantPromotion: action.payload.montantPromotion,
            libellePromotion: action.payload.libellePromotion
          }
        }
      };
    case a.GET_RECAPITULATIF.FAILURE:
      return {
        ...state,
        get: {
          success: false,
          error: true,
          loading: false
        }
      };
    case a.SET_RECAPITULATIF_MODIFICATION_GO.REQUEST:
      return {
        ...state,
        modify: {
          success: false,
          error: null,
          loading: true
        }
      };
    case a.SET_RECAPITULATIF_MODIFICATION_GO.SUCCESS:
    case a.SET_RECAPITULATIF_MODIFICATION:
      return {
        ...state,
        modify: {
          success: true,
          error: null,
          loading: false,
          go: action.payload
        }
      };
    case a.SET_RECAPITULATIF_MODIFICATION_GO.FAILURE:
      return {
        ...state,
        modify: {
          success: false,
          error: true,
          loading: false
        }
      };
    case a.RESET_RECAPITULATIF_MODIFICATION:
      return {
        ...state,
        modify: {
          ...INITIAL_RECAPITULATIF_STATE.modify
        }
      };
    default:
      return state;
  }
}

export default recapitulatif;
