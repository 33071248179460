import styled from "styled-components";

export const StyledErrorGlobal = styled.div`
  background-color: ${props => props.theme.colors.bgColors.error};
  border-radius: 4px;
  box-sizing: border-box;
  margin: 0 auto;
  max-width: 345px;
  padding: 10px 15px;
  width: 100%;
  .paragraph {
    color: ${props => props.theme.colors.statusColors.error};
    margin-bottom: 10px;
  }
  &.one {
    .paragraph {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  &.warning {
    background-color: ${props => props.theme.colors.statusColors.warning};
    margin: -15px 0 20px;
    .paragraph {
      color: ${props => props.theme.colors.themeColors.altRegular};
    }
  }
`;
