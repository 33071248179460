import { setTunnelSante_choixQuestionsMedicales } from "../../../../helpers/data/cookie-user";

export const GET_CHOIX_QUESTIONS_MEDICALES = {
  REQUEST: "questionsMedicales/GET/REQUEST",
  SUCCESS: "questionsMedicales/GET/SUCCESS",
  FAILURE: "questionsMedicales/GET/FAILURE"
};

export const POST_CHOIX_QUESTIONS_MEDICALES = {
  REQUEST: "questionsMedicales/POST/REQUEST",
  SUCCESS: "questionsMedicales/POST/SUCCESS",
  FAILURE: "questionsMedicales/POST/FAILURE"
};

export const SET_COOKIE_QUESTIONS_MEDICALES = "questionsMedicales/COOKIE/SET";

export const setCookieChoixQuestionsMedicales = reponse => {
  setTunnelSante_choixQuestionsMedicales(reponse);
  return {
    type: SET_COOKIE_QUESTIONS_MEDICALES,
    reponse
  };
};

export const getChoixQuestionsMedicales = clientId => {
  return {
    type: GET_CHOIX_QUESTIONS_MEDICALES.REQUEST,
    clientId
  };
};

export const postChoixQuestionsMedicales = (choix, clientId) => {
  return {
    type: POST_CHOIX_QUESTIONS_MEDICALES.REQUEST,
    choix,
    clientId
  };
};
