import styled from "styled-components";
import breakpoint from "styled-components-breakpoint";

export const StyledCookieBar = styled.div.attrs({
  className: "cookie-bar"
})`
  background-color: ${props => props.theme.colors.themeColors.black80};
  color: ${props => props.theme.colors.themeColors.white};
  position: fixed;
  bottom: ${props => (props.visible ? "calc((100% + 30px) * -1)" : "0")};
  height: auto;
  left: 0;
  transition: bottom 250ms ease;
  width: 100%;
  z-index: 80000;
  .wrapper-content {
    padding: 15px 35px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .paragraph {
      color: ${props => props.theme.colors.themeColors.white};
      padding-right: 20px;
      .linkButton {
        color: ${props => props.theme.colors.themeColors.white};
      }
    }
    > div .linkButton {
      background-color: ${props => props.theme.colors.themeColors.regular};
      display: block;
      height: 30px;
      margin-right: 30px;
      padding: 0 15px;
      width: auto;
      transition: background-color 200ms, box-shadow 50ms;
      &:hover {
        background-color: ${props => props.theme.colors.themeColors.light};
      }
      &:active {
        box-shadow: inset 1px 1px 3px 1px
          ${props => props.theme.colors.themeColors.regular};
      }
    }
    > button {
      position: absolute;
      top: 5px;
      right: 20px;
      cursor: pointer;
    }
  }
  ${breakpoint("mobile", "tablet")`
  	.wrapper-content {
  		flex-flow: column wrap;
  		padding-left:20px;
  		padding-right:20px;
  		.paragraph {
  			padding-right: 0;
  			width: 100%;
  		}
  		> div {
  			align-self: flex-start;
  			.linkButton {
					margin-right: 0;
					margin-top: 15px;
				}
  		}
  		> button {
				right: 5px;
			}
  	}
  `};
`;
