import * as a from "./actions";

const INITIAL_STATE = {
  success: false,
  loading: false,
  error: false,
  token: null,
  quittanceId: null,
  branche: null
};

export function reprisePaiement(state = INITIAL_STATE, action) {
  switch (action.type) {
    case a.GET_REPRISE_PAIEMENT_INFORMATION.REQUEST:
      return {
        ...state,
        success: false,
        loading: true,
        error: false
      };
    case a.GET_REPRISE_PAIEMENT_INFORMATION.SUCCESS:
      return {
        ...state,
        success: true,
        loading: false,
        error: false,
        token: action.payload.token,
        quittanceId: action.payload.quittanceId,
        branche:
          action.payload.branche.toLowerCase() === "auto"
            ? "automobile"
            : action.payload.branche,
        contratId: action.payload.contratId,
        versionContratId: action.payload.versionContratId
      };
    case a.GET_REPRISE_PAIEMENT_INFORMATION.FAILURE:
      return {
        ...state,
        success: false,
        loading: false,
        error: action.payload
      };
    default:
      return state;
  }
}

export default reprisePaiement;
