import styled from "styled-components";
import { CommonStyledMenuWrapper } from "../../style";

export const StyledMenuTunnel = styled(CommonStyledMenuWrapper)`
  .ma-souscription {
    background-color: ${props => props.theme.colors.themeColors.white};
    padding: 15px 20px 30px;
    .title {
      box-shadow: none;
      font-size: 1.6rem;
      font-weight: 700;
    }
    .baloon-shape {
      background-color: ${props => props.theme.colors.themeColors.regular50};
      height: 25px;
      margin-right: 15px;
      padding: 0;
      width: 25px;
      > span {
        color: ${props => props.theme.colors.themeColors.white};
        font-family: ${props => props.theme.fonts.main.name};
        font-size: 1.6rem;
        transform: translateY(-2px);
      }
    }
    > div {
      align-items: center;
      display: flex;
      flex-direction: row;
      line-height: 1;
      margin-bottom: 35px;
      margin-top: 10px;
      > img {
        margin-right: 10px;
        max-width: 85px;
      }
      > div {
        flex: 1 1 auto;
        width: auto;
        .paragraph {
          font-size: 1.4rem;
        }
      }
    }
    > ul {
      padding-left: 20px;
      .paragraph {
        font-weight: 700;
        opacity: 0.5;
      }
      .separator {
        margin-left: 12px;
        stroke: ${props => props.theme.colors.themeColors.regular50};
        stroke-dasharray: 2;
        stroke-width: 1;
      }
      li {
        > div {
          align-items: flex-start;
          display: flex;
          flex-flow: row wrap;
          justify-items: flex-start;
          > div {
            align-items: center;
            display: flex;
            flex-flow: row wrap;
            justify-items: flex-start;
            width: 100%;
            .icon-status {
              margin-left: auto;
            }
          }
        }
        &:last-of-type {
          > div {
            height: auto;
            max-height: initial;
            min-height: initial;
          }
        }
        &.passed {
          .baloon-shape {
            background-color: darkcyan;
          }
          .icon-status {
            color: darkcyan;
          }
          .separator {
            stroke: darkcyan;
          }
        }
        &.current {
          .baloon-shape {
            background-color: ${props =>
              props.theme.colors.themeColors.regular};
          }
          .icon-status {
            color: darkred;
          }
          .separator {
            stroke: ${props => props.theme.colors.themeColors.regular};
          }
        }
        &.passed,
        &.current {
          .paragraph {
            opacity: 1;
          }
        }
      }
    }
  }
`;
