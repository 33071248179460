import React, { Component } from "react";
import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import { compose, bindActionCreators } from "redux";
import Title from "../../../title";
import Paragraph from "../../../paragraph";

// Store
import {
  createOpportuniteProspection,
  resetOpportuniteProspection,
  createOpportuniteProspectionWithComment
} from "../../../../store/opportunite-prospection/actions";
import {
  isDemandeContactLoading,
  isDemandeContactOnError,
  isDemandeSuccessfull
} from "../../../../store/opportunite-prospection/selectors";
import { isModalEtreRappelleOpened } from "../../../../store/modal/selectors";
import {
  getContactInfos,
  getPhoneConfig
} from "../../../../store/information/selectors";
import { getExtractedUserInfosFrom } from "../../../../store/client/selectors";

// Import components
import BaloonShape from "../../../baloon-shape";
import Loader from "../../../loader";
import PopinForm from "../popin-form";
import LinkButton from "../../../link-button";
import SuccessForm from "../success-form";

// Import media
import ConseillereCommerciale from "../../../../assets/conseillere_commerciale.jpg";
import WhatsAppIcon from "../../../../assets/whatsapp.svg";
import InstaLogo from "../../../../assets/instagram.png";
import { MapMarker } from "styled-icons/fa-solid";
import { Envelope } from "styled-icons/fa-regular/Envelope";

// Import style
import { StyledContactServiceClient } from "./style";

const mapStateToProps = state => ({
  modalOpened: isModalEtreRappelleOpened(state),
  isDemandeLoading: isDemandeContactLoading(state),
  isDemandeOnError: isDemandeContactOnError(state),
  isDemandeSuccess: isDemandeSuccessfull(state),
  contactInfos: getContactInfos(state),
  clientIds: getExtractedUserInfosFrom(state),
  phoneConfig: getPhoneConfig(state)
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      createOpportuniteProspection,
      resetOpportuniteProspection,
      createOpportuniteProspectionWithComment
    },
    dispatch
  )
});

class ContactServiceClient extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSubmittable: props.clientIds !== null,
      resetForm: false,
      phoneOnError: false
    };
    this.onClosePopin = this.onClosePopin.bind(this);
    this.getFormSubmittedData = this.getFormSubmittedData.bind(this);
    this.isSubmittableForm = this.isSubmittableForm.bind(this);
    this.onClickPhoneNumber = this.onClickPhoneNumber.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.resetContent !== this.props.resetContent) {
      this.resetForm();
    }
    if (prevProps.isDemandeLoading !== this.props.isDemandeLoading) {
      if (this.props.isDemandeLoading) this.props.callbackBlockScroll(true);
    }

    if (!this.props.isDemandeLoading) {
      this.props.callbackBlockScroll(false);
    }

    if (
      prevProps.isDemandeOnError !== this.props.isDemandeOnError &&
      typeof this.props.isDemandeOnError !== "undefined" &&
      this.props.isDemandeOnError !== null
    ) {
      this.setState({
        phoneOnError: typeof this.props.isDemandeOnError === "object"
      });
    }
  }

  isSubmittableForm(isSubmittable) {
    this.setState({
      isSubmittable
    });
  }

  onClickPhoneNumber = (e, phone) => {
    e.preventDefault();
    window.open(phone);
  };

  resetForm() {
    this.props.callbackBlockScroll(false);
    this.props.actions.resetOpportuniteProspection();
    this.setState({
      resetForm: !this.state.resetForm,
      isSubmittable: false
    });
  }

  getFormSubmittedData(data) {
    const { clientIds } = this.props;
    if (clientIds !== null) {
      if (data.telephone === clientIds.telephone) {
        //this.props.actions.createOpportuniteProspection(data);
        this.props.actions.createOpportuniteProspectionWithComment({
          civilite: data.civilite,
          nom: data.nom,
          telephone: data.telephone,
          sujetDemande: data.possessionVoiture,
          branche: data.branche,
          origineWebCode: data.origineWebCode
        });
      } else {
        this.props.actions.createOpportuniteProspectionWithComment({
          civilite: data.civilite,
          nom: data.nom,
          telephone: clientIds.telephone,
          sujetDemande:
            "Le client a pour numeros : " +
            clientIds.telephone +
            " mais demande a �tre rappel� sur le :" +
            data.telephone,
          branche: data.branche,
          origineWebCode: data.origineWebCode
        });
      }
    } else {
      //this.props.actions.createOpportuniteProspection(data);
      this.props.actions.createOpportuniteProspectionWithComment({
        civilite: data.civilite,
        nom: data.nom,
        telephone: data.telephone,
        sujetDemande: "Possession voiture : " + data.possessionVoiture,
        branche: data.branche,
        origineWebCode: data.origineWebCode
      });
    }
  }

  onClosePopin(e) {
    e.preventDefault();
    this.resetForm();
    this.props.onClose(e);
    // ALLLO
  }

  renderForSubmitLoader() {
    if (this.props.isDemandeLoading)
      return {
        component: <Loader />,
        className: "is-loading"
      };
    return {
      component: null,
      className: ""
    };
  }

  renderDemand() {
    const {
      t,
      isDemandeSuccess,
      contactInfos,
      clientIds,
      phoneConfig
    } = this.props;
    const { telephoneFixe, horairesOuverture, adresse, email } = contactInfos;
    const { phoneOnError, isSubmittable, resetForm } = this.state;
    const textWhatsApp = "Bonjour Baloon";
    const urlInstagram = "https://www.instagram.com/baloonassurance/";

    const urlWhatsapp =
      "https://wa.me/" + contactInfos.whatsapp.label + "?text=" + textWhatsApp;
    if (isDemandeSuccess) return <SuccessForm closePopin={this.onClosePopin} />;
    return (
      <React.Fragment>
        <div>
          <Title titleType={2} textAlign="center">
            {t("contact.pourNousJoindreContactez")}{" "}
            <LinkButton
              config={{ type: "button", style: "nostyle" }}
              // to={`tel:${telephoneFixe.value}`}
              onClick={e =>
                this.onClickPhoneNumber(e, `tel:${telephoneFixe.value}`)
              }
            >
              {telephoneFixe.label}
            </LinkButton>
          </Title>
          <div className="presence">
            <div>
              <BaloonShape>
                <img
                  src={ConseillereCommerciale}
                  alt="conseillereCommerciale"
                />
              </BaloonShape>
              <Paragraph noMargin>
                {`${t("shared.nousSommesLa")} ${horairesOuverture}`}
              </Paragraph>
            </div>
          </div>

          <a
            style={{ marginLeft: "4px" }}
            href={urlWhatsapp}
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="contactWhatsApp" href={urlWhatsapp} target="_blank">
              <div className="iconWhatsApp">
                <img alt="" src={WhatsAppIcon} />
              </div>
              <div className="titre">
                <h2> WhatsApp</h2>
              </div>
              <div className="contactWAText">
                Contactez nous sur
                <a
                  style={{ marginLeft: "4px" }}
                  href={urlWhatsapp}
                  target="_blank"
                >
                  <strong style={{ color: "black" }}>WhatsApp</strong>
                </a>
              </div>
            </div>
          </a>
          <div className="contact-infos">
            <Paragraph className="InstaParag">
              <a href={urlInstagram} target="_blank" rel="noopener noreferrer">
                <img alt="" className="InstaLogo" size={16} src={InstaLogo} />
                {t("shared.suivezNousInstagram")}
              </a>
            </Paragraph>
            <Paragraph>
              <Envelope size={16} /> <a href={`mailto:${email}`}>{email}</a>
            </Paragraph>
            <Paragraph>
              <MapMarker size={16} /> {adresse}
            </Paragraph>
          </div>
        </div>
        <div>
          <Title titleType={3}>{t("shared.etreRappelleCoordonnees")}</Title>
          <PopinForm
            callback={this.getFormSubmittedData}
            callbackSubmittable={this.isSubmittableForm}
            isOnError={phoneOnError ? true : null}
            resetForm={resetForm}
            clientIds={clientIds}
            phoneConfig={phoneConfig}
            fromContactServiceClient={true}
          >
            <LinkButton
              config={{ type: "button", style: "plain", isSubmit: true }}
              style={{ visibility: isSubmittable ? "visible" : "hidden" }}
            >
              {t("shared.envoyerDemandeRappel")}
            </LinkButton>
          </PopinForm>
        </div>
      </React.Fragment>
    );
  }

  renderContactServiceClient() {
    const isSuccess =
      !this.props.isDemandeLoading &&
      !this.props.isDemandeOnError &&
      this.props.isDemandeSuccess;
    return (
      <React.Fragment>
        {this.renderForSubmitLoader().component}
        <div className={isSuccess ? "" : "initial"}>{this.renderDemand()}</div>
      </React.Fragment>
    );
  }

  render() {
    return (
      <StyledContactServiceClient
        className={this.renderForSubmitLoader().className}
      >
        {this.renderContactServiceClient()}
      </StyledContactServiceClient>
    );
  }
}

export default compose(
  withNamespaces("translations"),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(ContactServiceClient);
