import React from "react";
import StyledBaloonShape from "./style";

const BaloonShape = props => {
  const { children, className, ...rest } = props;
  return (
    <StyledBaloonShape
      className={`baloon-shape ${
        typeof className !== "undefined" ? className : ""
      }`}
      {...rest}
    >
      {children}
    </StyledBaloonShape>
  );
};

export default BaloonShape;
