import axios from "axios";
import API from "../../../api";

export async function getIntra(mainToken, verifierOperateur, paiementId) {
  const params = {
    verifierOperateur,
    paiementId
  };
  return new Promise(resolve => {
    const url = API.tunnel.common.paiement.main;
    const request = axios.get(url, {
      params,
      headers: {
        Authorization: `bearer ${mainToken}`
      }
    });
    return request
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        resolve({ error: true, res: err });
      });
  });
}

export async function post(
  mainToken,
  quittanceId,
  operateurPaiement,
  telephone,
  urlSucces,
  urlEchec
) {
  const bodyFormData = new FormData();
  bodyFormData.set("quittanceId", quittanceId);
  bodyFormData.set("operateurPaiement", operateurPaiement);
  bodyFormData.set("telephone", encodeURI(telephone));
  bodyFormData.set("urlSucces", urlSucces);
  bodyFormData.set("urlEchec", urlEchec);
  return new Promise(resolve => {
    const url = API.tunnel.common.paiement.main;
    const request = axios.post(url, bodyFormData, {
      headers: {
        Authorization: `bearer ${mainToken}`,
        "Content-Type": "multipart/form-data"
      }
    });
    return request
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        resolve({ error: true, res: err });
      });
  });
}

export async function getInfosPaiement(mainToken, paiementId) {
  const params = {
    paiementId
  };
  const bodyFormData = new FormData();
  bodyFormData.set("paiementId", paiementId);
  return new Promise(resolve => {
    const url = API.tunnel.common.paiement.infosPaiement;
    const request = axios.get(url, {
      params,
      headers: {
        Authorization: `bearer ${mainToken}`
      }
    });
    return request
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        resolve({ error: true, res: err });
      });
  });
}

export async function rappel(mainToken, quittanceId) {
  const bodyFormData = new FormData();
  bodyFormData.set("quittanceId", quittanceId);
  return new Promise(resolve => {
    const url = API.tunnel.common.paiement.rappel;
    const request = axios.post(url, bodyFormData, {
      headers: {
        Authorization: `bearer ${mainToken}`,
        "Content-Type": "multipart/form-data"
      }
    });
    return request
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        resolve({ error: true, res: err });
      });
  });
}
