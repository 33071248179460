import { getRouterSearch } from "../../../router/selectors";

// Envoi de la requête POST
export const isSignatureLoading = state =>
  state.tunnel.common.signature.main.loading;

// Envoi succes POST
export const isSignatureSuccessfull = state =>
  state.tunnel.common.signature.main.success;

// Erreur de l'envoi POST
export const isSignatureError = state =>
  state.tunnel.common.signature.main.error;

export const isRepriseSignatureLoading = state =>
  state.tunnel.common.signature.reprise.loading;

export const isRepriseSignatureSuccess = state =>
  state.tunnel.common.signature.reprise.success;

export const isRepriseSignatureError = state =>
  state.tunnel.common.signature.reprise.error;

// Get url redirection
export const getSignatureUrlRedirection = state =>
  state.tunnel.common.signature.main.urlRedirection;

export const getRepriseSignatureGuid = state => {
  const pattern = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
  let guid = null;
  if (getRouterSearch(state) !== null) {
    const routerSearch = getRouterSearch(state);
    if (
      typeof routerSearch.signature_guid !== "undefined" &&
      routerSearch.signature_guid !== "" &&
      pattern.test(routerSearch.signature_guid)
    ) {
      guid = routerSearch.signature_guid;
    }
  }
  return guid;
};

export const getTokenReprise = state =>
  state.tunnel.common.signature.reprise.token;
export const getBrancheRepriseSignature = state =>
  state.tunnel.common.signature.reprise.branche;

export const isSignatureCallLoading = state =>
  state.tunnel.common.signature.isSignature.loading;

export const isSignatureSuccess = state =>
  state.tunnel.common.signature.isSignature.success;

export const isSignature = state =>
  state.tunnel.common.signature.isSignature.state;

export const getAttestation = state =>
  state.tunnel.common.signature.attestation;
