import * as a from "./actions";

const INITIAL_TARIF_STATE = {
  designationRisque: null,
  devise: null,
  formules: [],
  comparaisonFormules: [],
  cotationDisponnible: {
    disponnible: true,
    simuId: null
  },
  getTarifStatus: {
    error: false,
    loading: false,
    success: false
  },
  chooseTarifStatus: {
    error: false,
    loading: false,
    success: false
  }
};

export function tarif(state = INITIAL_TARIF_STATE, action) {
  switch (action.type) {
    case a.GET_TARIF.REQUEST:
      return {
        ...state,
        getTarifStatus: {
          success: false,
          error: false,
          loading: true
        }
      };
    case a.GET_TARIF.SUCCESS:
      return {
        ...state,
        designationRisque: action.payload.designationRisque,
        devise: action.payload.devise,
        formules: action.payload.formules,
        comparaisonFormules: action.payload.comparaisonFormules,
        getTarifStatus: {
          success: true,
          error: false,
          loading: false
        }
      };
    case a.GET_TARIF.FAILURE:
      return {
        ...state,
        getTarifStatus: {
          success: false,
          error: true,
          loading: false
        }
      };
    case a.CHOOSE_TARIF.REQUEST:
      return {
        ...state,
        chooseTarifStatus: {
          success: false,
          error: false,
          loading: true
        }
      };
    case a.CHOOSE_TARIF.SUCCESS:
      return {
        ...state,
        chooseTarifStatus: {
          success: true,
          error: false,
          loading: false
        }
      };
    case a.CHOOSE_TARIF.FAILURE:
      return {
        ...state,
        chooseTarifStatus: {
          success: false,
          error: true,
          loading: false
        }
      };
    case a.SET_COTATION_DISPONNIBLE.REQUEST:
      return {
        ...state,
        cotationDisponnible: {
          ...state
        }
      };
    case a.SET_COTATION_DISPONNIBLE.SUCCESS:
      return {
        ...state,
        cotationDisponnible: {
          disponnible: action.payload.disponnible,
          simuId: action.payload.simuId
        }
      };
    case a.RESET_TARIF:
      return {
        designationRisque: null,
        devise: null,
        formules: [],
        comparaisonFormules: [],
        getTarifStatus: {
          error: false,
          loading: false,
          success: false
        },
        chooseTarifStatus: {
          error: false,
          loading: false,
          success: false
        },
        cotationDisponnible: {
          ...state.cotationDisponnible
        }
      };
    default:
      return state;
  }
}

export default tarif;
