// Constants
import appConstants from "../configuration/constants";

const tunnelEndpoints = apiURL => {
  return {
    automobile: {
      main: `${apiURL}sitecommercial/sauvegardertunnel`
    }
  };
};

const uploadDocumentEndpoints = apiURL => {
  const defaultApiTunnelAuto = `${apiURL}TunnelAuto/`;
  const defaultApiTunnelSante = `${apiURL}TunnelSante/`;
  return {
    automobile: {
      create: {
        carteGrise: `${defaultApiTunnelAuto}documentclient/CG`,
        permisConduire: `${defaultApiTunnelAuto}documentclient/Permis`,
        carteIdentite: `${defaultApiTunnelAuto}documentclient/Identite`
      },
      need: {
        carteGrise: `${defaultApiTunnelAuto}needUploadDocumentclient/CG`,
        permisConduire: `${defaultApiTunnelAuto}needUploadDocumentclient/Permis`,
        carteIdentite: `${defaultApiTunnelAuto}needUploadDocumentclient/Identite`
      },
      documentAttendu: `${defaultApiTunnelAuto}documentattendu`
    },
    sante: {
      create: {
        carteIdentite: `${defaultApiTunnelSante}documentclient/Identite`
      },
      documentsRisqueAttendu: `${defaultApiTunnelSante}documentsRisqueAttendus`
    }
  };
};

const inputProcessEndpoints = apiURL => {
  return {
    automobile: {
      InfoVehicule1: `${apiURL}InputProcess/InfoVehicule1`,
      InfoVehicule2: `${apiURL}InputProcess/InfoVehicule2`,
      InfoVehicule3: `${apiURL}InputProcess/InfoVehicule3`,
      InfoVehicule4: `${apiURL}InputProcess/InfoVehicule4`,
      InfoPermis1: `${apiURL}InputProcess/InfoPermis1`
    },
    sante: {
      InfoSanteIdentite1: `${apiURL}InputProcess/InfoSanteIdentite1`,
      InfoSanteIdentite2: `${apiURL}InputProcess/InfoSanteIdentite2`
    }
  };
};

const dateEffetContratEndpoints = apiURL => {
  return {
    automobile: `${apiURL}TunnelAuto/dateEffetContrat`,
    sante: `${apiURL}TunnelSante/dateEffetContrat`
  };
};

const tarifEndpoints = apiURL => {
  return {
    automobile: `${apiURL}TunnelAuto/simulation/tarif`,
    sante: `${apiURL}TunnelSante/tarif`
  };
};

const recapitulatifEndpoints = apiURL => {
  return {
    automobile: `${apiURL}TunnelAuto/recapitulatif`,
    sante: `${apiURL}TunnelSante/recapitulatif`
  };
};

const creationContratEndpoints = apiURL => {
  return {
    automobile: `${apiURL}TunnelAuto/creationContrat`,
    sante: `${apiURL}TunnelSante/creationContrat`,
    conditionsParticulieres: `${apiURL}TunnelAuto/conditionsparticulieres`,
    conditionsGenerales: `${apiURL}TunnelAuto/conditionsgenerales`
  };
};

const paiementEndpoints = apiURL => {
  return {
    main: `${apiURL}paiements`,
    rappel: `${apiURL}paiements/rappelPaiement`,
    infosPaiement: `${apiURL}paiements/infos`
  };
};

const signatureEndpoints = apiURL => {
  return {
    main: `${apiURL}signature`,
    reprise: `${apiURL}TunnelAuto/reprise-signature-info`,
    isSignature: `${apiURL}signature/disponible`
  };
};

const tranchesAgesEndpoints = apiURL => {
  return {
    main: `${apiURL}TunnelSante/tranchesages`
  };
};

const leadEndpoints = apiURL => {
  return {
    main: `${apiURL}TunnelSante/lead`
  };
};

const reseauxSoinsEndpoints = apiURL => {
  return {
    main: `${apiURL}TunnelSante/typereseauxsoins`
  };
};

const tauxCouvertureEndpoints = apiURL => {
  return {
    main: `${apiURL}TunnelSante/tauxcouverture`
  };
};

const assuresEndpoints = apiURL => {
  return {
    main: `${apiURL}TunnelSante/assures`
  };
};

const questionsMedicalesEndpoints = apiURL => {
  return {
    main: `${apiURL}client/questionnaireMedicalObligatoire`
  };
};

const informationEndpoints = apiURL => {
  return {
    main: `${apiURL}sitecommercial/info`
  };
};

const userBaloonProEndpoints = apiURL => {
  return {
    sante: `${apiURL}TunnelSante/nouvellesimulationbaloonpro`
  };
};

const opportuniteProspectionEndpoints = apiURL => {
  return {
    main: `${apiURL}sitecommercial/opportuniteprospection`,
    withComment: `${apiURL}sitecommercial/opportuniteprospection`
  };
};

const referenceListEndpoints = apiURL => {
  return {
    main: `${apiURL}referentiel`
  };
};

const clientEndpoints = apiURL => {
  const commonClient = `${apiURL}client/`;
  return {
    create: `${commonClient}`,
    connect: `${commonClient}connexion`,
    forgot: `${commonClient}motdepasseoublie`,
    authentificationNecessaire: `${commonClient}authentificationClientNecessaire`
  };
};

const loggerEndpoints = apiURL => {
  return {
    main: `${apiURL}logger`
  };
};

const verifierTelephoneEndpoints = apiURL => {
  return {
    main: `${apiURL}telephone/verifier`
  };
};

const articleEndpoints = apiURL => {
  return {
    get: `${apiURL}articles/siteCommercial/`
  };
};

const parrainageEndpoints = apiURL => {
  const mainParrainageUrl = `${apiURL}parrainage/v1/`;
  return {
    main: `${mainParrainageUrl}ajout`,
    connexion: `${mainParrainageUrl}acces`
  };
};

const reprisePaiementEndpoints = apiURL => {
  return {
    main: `${apiURL}paiements/reprise-paiement-info`
  };
};

const attestationEndpoint = apiURL => {
  return {
    main: `${apiURL}TunnelAuto/lienAttestation`
  };
};

const tunnelApis = {
  tunnel: tunnelEndpoints(appConstants.mainAPIEndpoint),
  common: {
    uploadDocument: uploadDocumentEndpoints(appConstants.mainAPIEndpoint),
    inputProcess: inputProcessEndpoints(appConstants.mainAPIEndpoint),
    dateEffetContrat: dateEffetContratEndpoints(appConstants.mainAPIEndpoint),
    tarif: tarifEndpoints(appConstants.mainAPIEndpoint),
    recapitulatif: recapitulatifEndpoints(appConstants.mainAPIEndpoint),
    creationContrat: creationContratEndpoints(appConstants.mainAPIEndpoint),
    paiement: paiementEndpoints(appConstants.mainAPIEndpoint),
    signature: signatureEndpoints(appConstants.mainAPIEndpoint),
    attestation: attestationEndpoint(appConstants.mainAPIEndpoint)
  },
  sante: {
    tranchesAges: tranchesAgesEndpoints(appConstants.mainAPIEndpoint),
    lead: leadEndpoints(appConstants.mainAPIEndpoint),
    reseauxSoins: reseauxSoinsEndpoints(appConstants.mainAPIEndpoint),
    tauxCouverture: tauxCouvertureEndpoints(appConstants.mainAPIEndpoint),
    assures: assuresEndpoints(appConstants.mainAPIEndpoint),
    questionsMedicales: questionsMedicalesEndpoints(
      appConstants.mainAPIEndpoint
    )
  }
};

const api = {
  information: informationEndpoints(appConstants.mainAPIEndpoint),
  userBaloonPro: userBaloonProEndpoints(appConstants.mainAPIEndpoint),
  opportuniteProspection: opportuniteProspectionEndpoints(
    appConstants.mainAPIEndpoint
  ),
  referenceList: referenceListEndpoints(appConstants.mainAPIEndpoint),
  client: clientEndpoints(appConstants.mainAPIEndpoint),
  logger: loggerEndpoints(appConstants.mainAPIEndpoint),
  verifierTelephone: verifierTelephoneEndpoints(appConstants.mainAPIEndpoint),
  article: articleEndpoints(appConstants.mainAPIEndpoint),
  parrainage: parrainageEndpoints(appConstants.mainAPIEndpoint),
  reprisePaiement: reprisePaiementEndpoints(appConstants.mainAPIEndpoint),
  tunnel: tunnelApis
};

export default api;
