export const POST_LOG = "logger/POST";

export const postLog = ({ message, level, error, info }) => {
  return {
    type: POST_LOG,
    message,
    level,
    error,
    info
  };
};
