import axios from "axios";
import API from "../../../api";

export async function post({
  mainToken,
  creerSimulation,
  id,
  clientId,
  telephoneClient,
  emailClient,
  nbJeunes,
  nbAdultes,
  nbSeniors,
  pathologieClientCode,
  typeReseauSoinsRefId,
  formuleItcRefId,
  origineWebCode
}) {
  const data = JSON.stringify({
    id: id,
    clientId: clientId,
    telephoneClient: telephoneClient,
    emailClient: emailClient,
    nbJeunes: nbJeunes,
    nbAdultes: nbAdultes,
    nbSeniors: nbSeniors,
    pathologieClientCode: pathologieClientCode,
    typeReseauSoinsRefId: typeReseauSoinsRefId,
    formuleItcRefId: formuleItcRefId
  });
  const params = {
    creerSimulation,
    origineWebCode
  };
  return new Promise(resolve => {
    const url = API.tunnel.sante.lead.main;
    const request = axios.post(url, data, {
      params,
      headers: {
        Authorization: `bearer ${mainToken}`,
        "Content-Type": "application/json"
      }
    });
    return request
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        resolve({ error: true, res: err });
      });
  });
}

export async function get(mainToken, id, origineWebCode) {
  const params = {
    id,
    origineWebCode
  };
  return new Promise(resolve => {
    const url = API.tunnel.sante.lead.main;
    const request = axios.get(url, {
      params,
      headers: {
        Authorization: `bearer ${mainToken}`
      }
    });
    return request
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        resolve({ error: true, res: err });
      });
  });
}
