import { createGlobalStyle } from "styled-components";
import breakpoint from "styled-components-breakpoint";

import reset from "styled-reset";
import "swiper/css/swiper.css";
// Material design style
import "@material/react-checkbox/dist/checkbox.css";
import "@material/react-radio/dist/radio.css";
import "@material/react-tab-bar/dist/tab-bar.css";
import "@material/react-tab-scroller/dist/tab-scroller.css";
import "@material/react-tab/dist/tab.css";
import "@material/react-tab-indicator/dist/tab-indicator.css";
import "@material/react-dialog/dist/dialog.css";

const MainDefaultStyle = createGlobalStyle`
  ${reset}
  html { font-size: 62.5%; }
  button {
    margin: 0;
    padding: 0;
    border: none;
    font: inherit;
    color: inherit;
    background: none;
  }
  input,
 	input:focus,
 	button,
 	button:focus {
		outline:none;
  }
  main {  	
  	min-height: 100vh;
  	overflow: hidden;
  	display: flex;
    flex-flow: column wrap;
    .top-container {
      margin-bottom: 71px; // compensation header
    }
    > div:not(.cookie-bar):not(.retour) {
      display: flex;
      flex-flow: column wrap;
    }
    footer {
      margin-top: auto;
    }
  }
  input[type="number"] {
    -moz-appearance: textfield;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
  
  .mdc-dialog {
    z-index: 999997 !important;
    .mdc-dialog__container {
      height: 100%;
      width: 100%;
      .mdc-dialog__surface {
        border-radius: 0;
        max-height: 100%;
        max-width: 100%;
        min-width: 100%;
      }
      .mdc-dialog__content {
        padding: 0;
        > div {
          .wrapper-content {
            background-color: ${props => props.theme.colors.bgColors.light};
          }
        }
      }
    }
  }
  ${breakpoint("desktop")`
    .mdc-dialog__content {
      border-radius: 5px;
    }
    .mdc-dialog {
      .mdc-dialog__container {
        align-items: center;
        .mdc-dialog__surface {
          border-radius: 5px;
          max-width: 780px;
          min-width: 780px;
          width: 780px;
          .wrapper-content {
            max-width: 100%;
          }
        }
      }
    }
  `};
`;

export default MainDefaultStyle;
