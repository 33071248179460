import axios from "axios";
// import qs from "qs";
import API from "../../../api";

export async function create(
  assuranceType,
  mainToken,
  infoVehicule,
  saisieUser,
  askedInputs,
  step,
  simulationId,
  risqueId,
  origineWebCode
) {
  const params = {
    pageDefinitionOrigineId: step,
    simulationId,
    risqueId,
    origineWebCode
  };
  return new Promise(resolve => {
    const url = API.tunnel.common.inputProcess[assuranceType][infoVehicule];
    const data = askedInputs.map((input, index) => {
      return {
        versionEntiteDefinitionId: input.attribut.versionEntiteDefinitionId,
        attributDefinitionId: input.attribut.attributDefinitionId,
        ...saisieUser[index]
      };
    });
    const request = axios.post(url, data, {
      params,
      headers: {
        Authorization: `bearer ${mainToken}`,
        "Content-Type": "application/json"
      }
    });
    return request
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        resolve({ error: true, res: err });
      });
  });
}

export async function getBack(
  assuranceType,
  mainToken,
  simulationId,
  risqueId,
  inputProcessCode,
  pagePrecedenteId,
  origineWebCode
) {
  const params = {
    sens: "backward",
    pageDefinitionOrigineId: pagePrecedenteId,
    simulationId,
    risqueId,
    origineWebCode
  };
  return new Promise(resolve => {
    const url = API.tunnel.common.inputProcess[assuranceType][inputProcessCode];
    const request = axios.get(url, {
      params,
      headers: {
        Authorization: `bearer ${mainToken}`,
        "Content-Type": "application/json"
      }
    });
    return request
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        resolve({ error: true, res: err });
      });
  });
}

export async function get(
  assuranceType,
  mainToken,
  simulationId,
  risqueId,
  inputProcessType,
  origineWebCode
) {
  const params = {
    sens: "forward",
    simulationId,
    risqueId,
    origineWebCode
  };
  return new Promise(resolve => {
    const url = API.tunnel.common.inputProcess[assuranceType][inputProcessType];
    const request = axios.get(url, {
      params,
      headers: {
        Authorization: `bearer ${mainToken}`
      }
    });
    return request
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        resolve({ error: true, res: err });
      });
  });
}

export async function reprise(
  assuranceType,
  mainToken,
  simulationId,
  risqueId,
  inputProcessType,
  pageDefinitionOrigineId,
  origineWebCode
) {
  const params = {
    simulationId,
    risqueId,
    pageDefinitionOrigineId,
    origineWebCode,
    sens: "current"
  };
  return new Promise(resolve => {
    const url = API.tunnel.common.inputProcess[assuranceType][inputProcessType];
    const request = axios.get(url, {
      params,
      headers: {
        Authorization: `bearer ${mainToken}`
      }
    });
    return request
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        resolve({ error: true, res: err });
      });
  });
}
