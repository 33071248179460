// Envoi de la requête demande de contact
export const isDemandeContactLoading = state =>
  state.opportuniteProspection.loading;

// Envoi succes
export const isDemandeSuccessfull = state =>
  state.opportuniteProspection.success;

// Erreur de l'envoi de la demande
export const isDemandeContactOnError = state =>
  state.opportuniteProspection.error;
