import * as a from "./actions";

const INITIAL_INPUT_PROCESS_STATE = {
  process: {},
  error: null,
  loading: false,
  success: false,
  insideReadWriteComponent: false,
  valeursIncoherentes: [],
  onError: false,
  errors: [],
  input: {},
  attributOnError: {}
};

export function inputProcess(state = INITIAL_INPUT_PROCESS_STATE, action) {
  switch (action.type) {
    case a.CREATE_INPUT_PROCESS.REQUEST:
      return {
        ...state,
        success: false,
        error: null,
        loading: true
      };
    case a.GET_BACK_INPUT_PROCESS.SUCCESS:
    case a.REPRISE_INPUT_PROCESS.SUCCESS:
    case a.GET_INPUT_PROCESS.SUCCESS:
    case a.CREATE_INPUT_PROCESS.SUCCESS:
      return {
        ...state,
        process: action.payload,
        success: true,
        error: null,
        onError: false,
        loading: false,
        valeursIncoherentes: action.incoherence,
        input: action.payload,
        errors: null,
        attributOnError: null
      };
    case a.GET_INPUT_PROCESS.ERROR:
    case a.GET_BACK_INPUT_PROCESS.ERROR:
    case a.CREATE_INPUT_PROCESS.ERROR:
    case a.REPRISE_INPUT_PROCESS.ERROR:
      return {
        ...state,
        success: false,
        error: action.payload,
        loading: false
      };
    case a.GET_INPUT_PROCESS.FAILURE:
    case a.REPRISE_INPUT_PROCESS.FAILURE:
    case a.GET_BACK_INPUT_PROCESS.FAILURE:
    case a.CREATE_INPUT_PROCESS.FAILURE:
      return {
        ...state,
        success: false,
        onError: true,
        loading: false,
        errors: action.payload.errors,
        input: action.payload.model,
        attributOnError: action.attr
      };
    // GET INPUT PROCESS
    case a.GET_INPUT_PROCESS.REQUEST:
    case a.GET_BACK_INPUT_PROCESS.REQUEST:
    case a.REPRISE_INPUT_PROCESS.REQUEST:
      return {
        ...state,
        success: false,
        error: null,
        loading: true
      };

    case a.NO_NEED_REPRISE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: false,
        error: null
      };
    /* STOP LOAD INPUT PROCESS */
    case a.STOP_LOADING_INPUT_PROCESS:
      return {
        ...state,
        loading: false
      };
    /* SET INSIDE READ WRITE COMPONENT */
    case a.SET_INPUT_PROCESS_READ_WRITE_INSIDE_COMPONENT:
      return {
        ...state,
        insideReadWriteComponent: !state.insideReadWriteComponent
      };
    /* RESET INPUT PROCESS */
    case a.RESET_INPUT_PROCESS:
      return INITIAL_INPUT_PROCESS_STATE;
    default:
      return state;
  }
}

export default inputProcess;
