import axios from "axios";
import qs from "qs";
import API from "../api";

export async function create(
  mainToken,
  civilite,
  nom,
  telephone,
  branche,
  origineWebCode
) {
  return new Promise(resolve => {
    const url = API.opportuniteProspection.main;
    const data = qs.stringify({
      civilite: civilite,
      nom: nom,
      telephone: encodeURI(telephone),
      branche,
      origineWebCode
    });

    const request = axios.post(url, data, {
      headers: {
        Authorization: `bearer ${mainToken}`,
        "Content-Type": "application/x-www-form-urlencoded"
      }
    });
    return request
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        resolve({ error: true, res: err });
      });
  });
}

export async function createWithComment(
  mainToken,
  civilite,
  nom,
  telephone,
  branche,
  origineWebCode,
  comment
) {
  return new Promise(resolve => {
    const url = API.opportuniteProspection.withComment;
    const data = qs.stringify({
      civilite: civilite,
      nom: nom,
      telephone: encodeURI(telephone),
      branche,
      origineWebCode,
      comment
    });
    const request = axios.post(url, data, {
      headers: {
        Authorization: `bearer ${mainToken}`,
        "Content-Type": "application/x-www-form-urlencoded"
      }
    });
    return request
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        resolve({ error: true, res: err });
      });
  });
}
