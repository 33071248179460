import styled from "styled-components";

export const DeconnexionStyled = styled.div`
  :hover {
    cursor: pointer;
  }
  :hover .infoBulleDeco {
    display: block;
    position: absolute;
    width: 65px;
    height: 45px;
    z-index: 10;
    color: black;
    top: 10px;
  }
  .infoBulleDeco {
    display: none;
  }
  text-align: center;
  cursor: default;
  display: flex;
  margin: auto;
  margin-left: 15px;
  flex-direction: column !important;
  > button:hover {
    cursor: pointer;
  }
  > img {
    margin: auto;
    width: 28px;
    height: 28px;
  }
  > img:hover {
    .infoBulleDeco {
      display: flex;
      position: absolute;
      width: 65px;
      height: 45px;
    }
  }
`;
