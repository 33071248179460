import * as a from "./actions";

const INITIAL_STATE = {
  ajout: {
    loading: false,
    success: false,
    error: false,
    telephones: []
  },
  connexionClient: {
    success: false,
    loading: false,
    error: false
  },
  verifierTelephone: {
    /*filleul0: {
      success: false,
      loading: false,
      error: false,
      valide: false
    },
    filleul1: {
      success: false,
      loading: false,
      error: false,
      valide: false
    },
    filleul2: {
      success: false,
      loading: false,
      error: false,
      valide: false
    },
    filleul3: {
      success: false,
      loading: false,
      error: false,
      valide: false
    },
    filleul4: {
      success: false,
      loading: false,
      error: false,
      valide: false
    }*/
  }
};

export function parrainage(state = INITIAL_STATE, action) {
  switch (action.type) {
    case a.VERIFIER_TELEPHONE_FILLEUL.REQUEST:
      const verifierTelephonePutRequest = state.verifierTelephone;
      verifierTelephonePutRequest[`filleul${action.index}`] = {
        success: false,
        loading: true,
        error: false,
        valide: false
      };
      return {
        ...state,
        verifierTelephone: verifierTelephonePutRequest
      };
    case a.VERIFIER_TELEPHONE_FILLEUL.SUCCESS:
      const verifierTelephonePutSuccess = state.verifierTelephone;
      verifierTelephonePutSuccess[`filleul${action.payload.index}`] = {
        success: true,
        loading: false,
        error: false,
        valide: false
      };
      return {
        ...state,
        verifierTelephone: verifierTelephonePutSuccess
      };
    case a.VERIFIER_TELEPHONE_FILLEUL.FAILURE:
      const verifierTelephonePutFailure = state.verifierTelephone;
      verifierTelephonePutFailure[`filleul${action.payload.index}`] = {
        success: false,
        loading: false,
        error: true,
        valide: false
      };
      return {
        ...state,
        verifierTelephone: verifierTelephonePutFailure
      };
    // AJOUT PARRAINAGE
    case a.AJOUTER_PARRAINAGES.REQUEST:
      return {
        ...state,
        ajout: {
          ...state.ajout,
          loading: true,
          success: false,
          error: false
        }
      };
    case a.AJOUTER_PARRAINAGES.SUCCESS:
      return {
        ...state,
        ajout: {
          ...state.ajout,
          loading: false,
          success: true,
          error: false,
          telephones: action.payload
        }
      };
    case a.AJOUTER_PARRAINAGES.FAILURE:
      return {
        ...state,
        ajout: {
          ...state.ajout,
          loading: false,
          success: false,
          error: true
        }
      };
    // CONNEXION CLIENT
    case a.CONNEXION_CLIENT.REQUEST:
      return {
        ...state,
        connexionClient: {
          ...state.connexionClient,
          loading: true,
          success: false,
          error: false
        }
      };
    case a.CONNEXION_CLIENT.SUCCESS:
      return {
        ...state,
        connexionClient: {
          ...state.connexionClient,
          loading: false,
          success: true,
          error: false
        }
      };
    case a.CONNEXION_CLIENT.FAILURE:
      return {
        ...state,
        connexionClient: {
          ...state.connexionClient,
          loading: false,
          success: false,
          error: action.payload
        }
      };
    default:
      return state;
  }
}

export default parrainage;
