import styled from "styled-components";
import breakpoint from "styled-components-breakpoint";
import topBackground from "../../assets/category-top-bg.svg";

export const StyledCategoryHeader = styled.div`
  background-color: ${props => props.theme.colors.themeColors.altLight};
  background-image: local(none) url(${topBackground});
  background-position: calc(((100vw - 980px) * 0.5) - 525px) 50%;
  background-repeat: no-repeat;
  background-size: auto auto;
  height: 190px;
  width: 100%;
  ${breakpoint("mobile", "tablet")`
    background-position: calc(50% - 110px) 46%;
    background-size: 375% 375%;
    height: 135px;
  `};
  ${breakpoint("tablet", "desktop")`
    height: 160px;
  `};
  .wrapper-content {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    padding: 20px;
  }
  .title {
    color: ${props => props.theme.colors.themeColors.white};
    text-transform: uppercase;
  }
`;

export const StyledSearchHeader = styled.div`
  background-color: ${props => props.theme.colors.themeColors.light};
  box-sizing: border-box;
  .title {
    color: ${props => props.theme.colors.themeColors.white};
    flex: 2 2 0;
    padding-right: 25px;
    text-align: right;
    ${breakpoint("mobile", "tablet")`
      flex: none;
      margin-bottom:15px !important;
      padding-right: 0;
      text-align:left;
      width: 100%;
    `};
  }
  .wrapper-content {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-flow: row wrap;
    padding: 25px 35px;
    ${breakpoint("mobile", "desktop")`
      padding-left:${props => props.theme.layouts.page.padding};
      padding-right:${props => props.theme.layouts.page.padding};
    `};
    > div {
      box-sizing: border-box;
      flex: 3 3 0;
    }
  }
`;
