export const VERIFIER_TELEPHONE_FILLEUL = {
  REQUEST: "parrainage/verifierTelephoneFilleul/GET/REQUEST",
  SUCCESS: "parrainage/verifierTelephoneFilleul/GET/SUCCESS",
  FAILURE: "parrainage/verifierTelephoneFilleul/GET/FAILURE"
};

export const AJOUTER_PARRAINAGES = {
  REQUEST: "parrainage/AJOUTER/POST/REQUEST",
  SUCCESS: "parrainage/AJOUTER/POST/SUCCESS",
  FAILURE: "parrainage/AJOUTER/POST/FAILURE"
};

export const CONNEXION_CLIENT = {
  REQUEST: "parrainage/CONNEXION_CLIENT/GET/REQUEST",
  SUCCESS: "parrainage/CONNEXION_CLIENT/GET/SUCCESS",
  FAILURE: "parrainage/CONNEXION_CLIENT/GET/FAILURE"
};

export const verifierTelephoneFilleul = (telephone, index) => {
  return {
    type: VERIFIER_TELEPHONE_FILLEUL.REQUEST,
    telephone,
    index
  };
};

export const ajouterParrainages = telephones => {
  return {
    type: AJOUTER_PARRAINAGES.REQUEST,
    telephones
  };
};

export const connexionClient = guid => {
  return {
    type: CONNEXION_CLIENT.REQUEST,
    guid
  };
};
