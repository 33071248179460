import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";

import {
  StyledButton,
  StyledInternalLink,
  StyledOutsideLink,
  StyledRedirectButton
} from "./style";

// const allowedLinkStyles = ["noStyle", "plain", "border"];

const LinkButton = props => {
  const {
    config,
    staticContext,
    altStyle,
    thirdStyle,
    history,
    to,
    onClick,
    match,
    location,
    children,
    ...rest
  } = props;

  const type = config.type || "link";
  const styleType = () => {
    if (typeof config.style !== "undefined") {
      return config.style;
    }
    // if (allowedLinkStyles.includes(config.style)) return config.style;
    return null;
  };
  const isSubmitButton =
    config.type === "button" &&
    typeof config.isSubmit !== "undefined" &&
    config.isSubmit
      ? true
      : false;

  const redirectTo = to => {
    return {
      pathname: to
    };
  };

  const linkButtonClassName = () => {
    const style = styleType();
    const classnames = [];
    classnames.push(props.className);
    if (style === "noStyle") classnames.push("no-style");
    if (style === "plain") classnames.push("plain");
    if (altStyle) classnames.push("alt-style");
    if (thirdStyle) classnames.push("third-style");
    if (style === "border") classnames.push("border");
    return classnames.join(" ");
  };

  const isOutside = to => {
    const toCheck = ["http://", "https://", "www", "tel:", "mailto:"];
    if (typeof to === "string") {
      const patternChecked = toCheck.reduce((error, pattern) => {
        return error + (to.indexOf(pattern) > -1 ? 1 : 0);
      }, 0);
      return patternChecked > 0;
    } else {
      return 0;
    }
  };

  if (type === "link") {
    if (typeof to !== "undefined")
      if (isOutside(to))
        return (
          <StyledOutsideLink
            {...rest}
            href={to}
            className={linkButtonClassName()}
          >
            {children}
          </StyledOutsideLink>
        );
    if (typeof children[0] === "string")
      return (
        <StyledInternalLink {...rest} to={to} className={linkButtonClassName()}>
          {" "}
          {children}
        </StyledInternalLink>
      );
    return (
      <StyledInternalLink {...rest} to={to} className={linkButtonClassName()}>
        {children}
      </StyledInternalLink>
    );
  } else if (type === "button") {
    if (typeof to !== "undefined")
      return (
        <StyledRedirectButton
          {...rest}
          className={linkButtonClassName()}
          onClick={event => {
            onClick && onClick(event);
            history.push(redirectTo(to));
          }}
          staticContext={staticContext}
          history={history}
          match={match}
          location={location}
        />
      );
    return (
      <StyledButton
        className={linkButtonClassName()}
        isSubmit={isSubmitButton}
        {...rest}
        onClick={onClick}
      >
        <span>{children}</span>
      </StyledButton>
    );
  }
  return null;
};

LinkButton.propTypes = {
  // config to add
  type: PropTypes.string,
  history: PropTypes.object,
  to: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      pathname: PropTypes.string,
      state: PropTypes.object
    })
  ]),
  onClick: PropTypes.func,
  match: PropTypes.object,
  location: PropTypes.object,
  className: PropTypes.string,
  noMargin: PropTypes.bool
};

export default withRouter(LinkButton);
