import styled from "styled-components";
import { formLineCSS, inputSelectCSS } from "../../../form-styles";

export const StyledInput = styled.div`
  ${formLineCSS};
  &.isOnWarning div input {
    border: ${props => props.theme.colors.themeColors.altLight} 2px solid;
  }
  .input-wrapper {
    > input {
      ${inputSelectCSS};
      padding: 0 15px;
      width: 100%;
    }
  }
  &.error {
    .input-wrapper {
      .indicator {
        border: 1px solid ${props => props.theme.colors.statusColors.error};
        border-right: none;
      }
    }
    input {
      border-color: ${props => props.theme.colors.statusColors.error};
    }
  }
  &.input-amount-error {
    + .error {
      margin-bottom: 15px;
    }
  }
`;
