import styled, { css } from "styled-components";
import { paragraphDefaultStyle } from "../paragraph";

export const labelsStyle = css`
  ${props => {
    return paragraphDefaultStyle("formFieldLabel");
  }};
`;

export const FormWrapper = styled.form.attrs({
  noValidate: true
})`
  padding-bottom: 30px;
  .form-item {
    margin-bottom: 15px;
    margin-left: auto;
    margin-right: auto;
    max-width: 345px;
    &:last-of-type {
      margin-bottom: 25px;
    }
  }
  [type="submit"] {
    margin-bottom: 0;
  }
  .container-label {
    ${labelsStyle};
  }
`;

export const formLineCSS = css`
  display: flex;
  flex-direction: column;
  > label {
    ${labelsStyle};
  }
  input,
  select,
  option,
  .indicator > span {
    color: ${props => props.theme.colors.themeColors.darkgrey};
    font-family: ${props => props.theme.fonts.forms.name};
    font-size: 1.8rem;
    font-weight: ${props => props.theme.fonts.forms.regular};
  }
`;

export const inputSelectCSS = css`
  border: 1px solid ${props => props.theme.colors.themeColors.lightgrey};
  border-radius: 5px;
  box-sizing: border-box;
  height: 50px;
  position: relative;
  &:not([type="date"]) {
    align-items: center;
    display: flex;
    justify-content: flex-start;
  }
`;
