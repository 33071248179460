import React, { Component } from "react";
import { withNamespaces } from "react-i18next";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";

// Import component
import WrapperContent from "../wrapper-content";
import ErrorGlobal from "../error-global";
import Title from "../title";
import LinkButton from "../link-button";
import Header from "../header";

// Import media
import theme from "../../theme-style";

// Import store
import { postLog } from "../../store/logger/actions";

// Import style
import { StyledErrorContainer } from "./style";

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      postLog
    },
    dispatch
  )
});

class ErrorContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      msgLog: props.messageLog || ""
    };
  }

  componentDidMount() {
    const { actions, errors } = this.props;
    try {
      let isSimpleErrors = false;
      let isErrors = false;
      if (errors !== null && typeof errors !== "undefined") {
        if (errors.error !== null && typeof errors.error !== "undefined") {
          if (typeof errors.error.length !== "undefined") {
            isErrors = errors.error.length > 0;
          }
        } else if (typeof errors.length !== "undefined") {
          isSimpleErrors = true;
        }
      }
      if (isErrors) {
        actions.postLog({
          message: this.state.msgLog,
          level: "error",
          error: JSON.stringify(this.props.errors.error),
          info: this.props.info
        });
      } else if (isSimpleErrors) {
        actions.postLog({
          message: this.state.msgLog,
          level: "warn",
          error: JSON.stringify(this.props.errors),
          info: this.props.info
        });
      }
    } catch (e) {
      // actions.postLog({
      //   message: "ERREUR PostLog :: " + e,
      //   level: "warn",
      //   error: e,
      //   info: "le post log dans le erreur container a eu un bug :: " + e
      // });
      console.log("ERREUR HAS NO REQUIRED ID INDEX :: ", e);
    }
  }

  render() {
    const { t, errors, hasHeader, noButton, souscriptionEnCours } = this.props;
    const { VoitureTriste } = theme.icons.theme;
    let isErrors = false;
    let isSimpleErrors = false;
    if (errors !== null && typeof errors !== "undefined") {
      if (errors.error !== null && typeof errors.error !== "undefined") {
        if (typeof errors.error.length !== "undefined") {
          isErrors = errors.error.length > 0;
        }
      } else if (typeof errors.length !== "undefined") {
        isSimpleErrors = true;
      }
    }
    const titleLabel = () => {
      return souscriptionEnCours
        ? isErrors
          ? t("errors.InformationsIncoherentes")
          : t("errors.souscriptionInformationsManquantes")
        : t("errors.oupsUneErreur");
    };
    const buttonLabel = () => {
      return souscriptionEnCours
        ? t("shared.quitterSouscriptionEnCours")
        : t("shared.retourSurAccueil");
    };
    if (isErrors) {
      return (
        <StyledErrorContainer>
          {hasHeader && <Header onlyLogo />}
          <div className="error-container">
            <WrapperContent>
              <Title noMargin textAlign="center" titleType={3}>
                {titleLabel()}
              </Title>
              <img src={VoitureTriste} alt="desole" />
              {!noButton && (
                <LinkButton config={{ type: "link", style: "plain" }} to="/">
                  {buttonLabel()}
                </LinkButton>
              )}
              {isErrors && <ErrorGlobal errors={errors.error} traducteur={t} />}
            </WrapperContent>
          </div>
        </StyledErrorContainer>
      );
    } else if (isSimpleErrors) {
      return (
        <StyledErrorContainer>
          <div className="error-container">
            <WrapperContent>
              <Title noMargin textAlign="center" titleType={3}>
                {titleLabel()}
              </Title>
              <img src={VoitureTriste} alt="desole" />
              {!noButton && (
                <LinkButton config={{ type: "link", style: "plain" }} to="/">
                  {buttonLabel()}
                </LinkButton>
              )}
              {isSimpleErrors && <ErrorGlobal errors={errors} />}
            </WrapperContent>
          </div>
        </StyledErrorContainer>
      );
    } else {
      return (
        <StyledErrorContainer>
          {hasHeader && <Header onlyLogo />}
          <WrapperContent>
            <Title noMargin textAlign="center" titleType={3}>
              {titleLabel()}
            </Title>
            <img src={VoitureTriste} alt="desole" />
            <LinkButton config={{ type: "link", style: "plain" }} to="/">
              {buttonLabel()}
            </LinkButton>
          </WrapperContent>
        </StyledErrorContainer>
      );
    }
  }
}

export default compose(
  withNamespaces("translations"),
  connect(
    null,
    mapDispatchToProps
  )
)(ErrorContainer);
