import axios from "axios";
import qs from "qs";
import API from "../api";

export async function create(
  mainToken,
  civilite,
  prenom,
  nom,
  telephone,
  assuranceType
) {
  return new Promise(resolve => {
    const url = API.client.create;
    const data = qs.stringify({
      civilite: civilite,
      prenom: prenom,
      nom: nom,
      assuranceType: assuranceType,
      telephone: encodeURI(telephone)
    });
    const request = axios.post(url, data, {
      headers: {
        Authorization: `bearer ${mainToken}`,
        "Content-Type": "application/x-www-form-urlencoded"
      }
    });
    return request
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        resolve({ error: true, res: err });
      });
  });
}

export async function connect(mainToken, telephone, password) {
  return new Promise(resolve => {
    const url = API.client.connect;
    const data = qs.stringify({
      telephone: encodeURI(telephone),
      password: password
    });
    const request = axios.post(url, data, {
      headers: {
        Authorization: `bearer ${mainToken}`,
        "Content-Type": "application/x-www-form-urlencoded"
      }
    });
    return request
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        resolve({ error: true, res: err });
      });
  });
}

export async function forgot(mainToken, telephone) {
  return new Promise(resolve => {
    const url = API.client.forgot;
    const data = qs.stringify({
      telephone: encodeURI(telephone)
    });
    const request = axios.post(url, data, {
      headers: {
        Authorization: `bearer ${mainToken}`,
        "Content-Type": "application/x-www-form-urlencoded"
      }
    });
    return request
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        resolve({ error: true, res: err });
      });
  });
}

export async function authentificationNecessaire(mainToken, telephone) {
  return new Promise(resolve => {
    const url = API.client.authentificationNecessaire;
    const params = {
      telephone: encodeURI(telephone)
    };
    const request = axios.get(url, {
      params,
      headers: {
        Authorization: `bearer ${mainToken}`
      }
    });
    return request
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        resolve({ error: true, res: err });
      });
  });
}
