export const GET_ARTICLE = {
  REQUEST: "article/GET/REQUEST",
  SUCCESS: "article/GET/SUCCESS",
  FAILURE: "article/GET/FAILURE"
};
export const RESET_ARTICLE = "ARTICLE/RESET";

export const GET_ARTICLES = {
  REQUEST: "articles/GET/REQUEST",
  SUCCESS: "articles/GET/SUCCESS",
  FAILURE: "articles/GET/FAILURE"
};

export const GET_HELP_ARTICLES = {
  REQUEST: "helpArticles/GET/REQUEST",
  SUCCESS: "helpArticles/GET/SUCCESS",
  FAILURE: "helpArticles/GET/FAILURE"
};

export const RESET_ARTICLES = "ARTICLES/RESET";

export const getArticle = slug => {
  return {
    type: GET_ARTICLE.REQUEST,
    slug
  };
};

export const resetArticle = () => {
  return {
    type: RESET_ARTICLE
  };
};

export const getArticles = (typeArticles, value) => {
  return {
    type: GET_ARTICLES.REQUEST,
    typeArticles,
    value
  };
};

export const getHelpArticles = () => {
  return {
    type: GET_HELP_ARTICLES.REQUEST
  };
};

export const resetArticles = () => {
  return {
    type: RESET_ARTICLES
  };
};
