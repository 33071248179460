import axios from "axios";
import API from "../../../api";

export async function get(assuranceType, mainToken, simulationId) {
  const params = {
    simulationId
  };
  return new Promise(resolve => {
    const url = API.tunnel.common.tarif[assuranceType];
    const request = axios.get(url, {
      params,
      headers: {
        Authorization: `bearer ${mainToken}`
      }
    });

    return request
      .then(res => {
        resolve(res);
      })
      .catch(res => {
        resolve({ error: true, res: res });
      });
  });
}

export async function post(
  assuranceType,
  mainToken,
  simulationId,
  risqueId,
  cotationId
) {
  const params = {
    simulationId,
    risqueId,
    cotationId
  };
  return new Promise(resolve => {
    const url = API.tunnel.common.tarif[assuranceType];
    const request = axios.post(url, null, {
      params,
      headers: {
        Authorization: `bearer ${mainToken}`,
        "Content-Type": "application/json"
      }
    });
    return request
      .then(res => {
        resolve(res);
      })
      .catch(res => {
        resolve({ error: true, res: res });
      });
  });
}
