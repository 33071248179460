import { call, all, put, takeLatest, select } from "redux-saga/effects";
import * as a from "./actions";

// Import selects
import * as informationSelectors from "../information/selectors";
// Services
import * as verifierTelephoneService from "./services";
// Sagas
import * as loggerSagas from "../logger/saga";

function* verifierTelephone({ telephone }) {
  try {
    const mainToken = yield select(informationSelectors.getMainToken);
    const verifTel = yield call(
      verifierTelephoneService.get,
      mainToken,
      telephone
    );
    if (!verifTel.error) {
      yield put({
        type: a.VERIFIER_TELEPHONE.SUCCESS,
        payload: verifTel
      });
    } else {
      const errorRes = verifTel.res.response;
      if (errorRes.data.error.indexOf("NUMERO_TELEPHONE_INVALIDE") < 0) {
        yield call(loggerSagas.postLog, {
          message: "Telephone invalide 400 saga verifierTelephone" + telephone,
          error: errorRes.data,
          info: errorRes.request.status,
          level: "warn"
        });
      } else {
        yield put({
          type: a.VERIFIER_TELEPHONE.FAILURE,
          payload: errorRes.data
        });
      }
    }
  } catch (e) {}
}

export default all([
  takeLatest(a.VERIFIER_TELEPHONE.REQUEST, verifierTelephone)
]);
