import { call, all, put, takeLatest, select } from "redux-saga/effects";
import * as a from "./actions";

// Import selects from INFORMATION
import * as informationSelectors from "../../../information/selectors";
import * as leadSelectors from "../lead/selectors";
import * as reseauxSoinsService from "./services";
import * as leadSaga from "../lead/saga";
import * as loggerSagas from "../../../logger/saga";
import * as leadActions from "../lead/actions";

export function* getReseauxSoins() {
  const reprise = yield call(leadSaga.repriseLead);
  if (reprise) {
    yield put({
      type: leadActions.REPRISE_LEAD.SUCCESS
    });
    const mainToken = yield select(informationSelectors.getMainToken);
    const lead = yield select(leadSelectors.getLead);
    const data = {
      mainToken,
      ...lead
    };
    const reseauxSoins = yield call(reseauxSoinsService.get, data);
    if (!reseauxSoins.error) {
      yield put({
        type: a.GET_RESEAUX_SOINS.SUCCESS,
        payload: reseauxSoins
      });
    } else {
      const errorRes = reseauxSoins.res.response;
      yield call(loggerSagas.postLogErrorApi, {
        sagaInit: "getReseauxSoins",
        apiResponseUrl: errorRes.request.responseURL,
        statusResponse: errorRes.request.status,
        dataResponse: errorRes.data,
        token: mainToken,
        params: {
          ...lead
        }
      });
      yield put({
        type: a.GET_RESEAUX_SOINS.FAILURE,
        payload: true
      });
    }
  }
}

export default all([takeLatest(a.GET_RESEAUX_SOINS.REQUEST, getReseauxSoins)]);
