import styled, { css } from "styled-components";
import LayoutCorporate from "../../layouts/corporate";

export const bodyContentStyle = css`
  .bodyContent {
    font-family: "Raleway", sans-serif;
    font-weight: 400;
    .title {
      color: ${props => props.theme.colors.themeColors.regular};
      font-weight: ${props => props.theme.fonts.main.variants.bold};
      font-size: 2rem;
    }
    .paragraph .linkButton {
      color: ${props => props.theme.colors.themeColors.thirdLight};
      font-weight: ${props => props.theme.fonts.main.variants.medium};
      &:hover {
        background-color: ${props =>
          props.theme.colors.themeColors.thirdUltraLight};
      }
      line-height: inherit;
    }
    .paragraph,
    .list li {
      word-break: break-word;
    }
    .list {
      ol,
      ul {
        box-sizing: border-box;
        color: ${props => props.theme.colors.themeColors.regular};
        display: block;
        font-size: 1.4rem;
        margin-bottom: 30px;
        padding: 0 40px;
        li {
          align-items: flex-start;
          display: flex;
          margin-bottom: 7px;
          margin-top: 20px;
          line-height: 1.75;
          &:before {
            color: ${props => props.theme.colors.themeColors.light};
            margin: 0 20px 0 0;
            padding: 0 5px;
          }
        }
      }
      ol {
        counter-reset: numberedList;
        li {
          counter-increment: numberedList;
          &:before {
            border-bottom: 4px solid
              ${props => props.theme.colors.themeColors.ultralight};
            box-sizing: border-box;
            content: counter(numberedList) ".";
            font-weight: ${props => props.theme.fonts.main.variants.bold};
            font-size: 2.5rem;
            line-height: 1.25;
            padding-bottom: 2.5px;
          }
        }
      }
    }
    ul {
      list-style-type: disc;
      li {
        &:before {
          line-height: 1.75;
          content: "●";
        }
      }
    }
  }
`;

export const StyledSimpleContentPage = styled(LayoutCorporate)`
  section {
    .wrapper-content {
      article {
        padding-top: 5px;
      }
    }
    ${bodyContentStyle};
  }
`;
