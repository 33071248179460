import { call, all, put, takeLatest, select } from "redux-saga/effects";
import * as a from "./actions";

// Services
import * as articleService from "./services";
// Sagas
import * as loggerSagas from "../logger/saga";
import * as tunnelSagas from "../tunnel/tunnel/saga";
import * as informationSelectors from "../information/selectors";

function* getArticle({ slug }) {
  try {
    const mainToken = yield select(informationSelectors.getMainToken);
    const article = yield call(articleService.getOne, mainToken, slug);
    if (!article.error) {
      yield put({
        type: a.GET_ARTICLE.SUCCESS,
        payload: article
      });
    } else {
      const errorRes = article.res.response;
      if (errorRes.request.status === 404) {
        yield call(tunnelSagas.redirectToUrl, "/404");
      } else {
        if (errorRes.request.status !== 400) {
          yield call(loggerSagas.postLogErrorApi, {
            sagaInit: "getArticle",
            apiResponseUrl: errorRes.request.responseURL,
            statusResponse: errorRes.request.status,
            dataResponse: errorRes.data,
            token: mainToken,
            params: {
              slug
            }
          });
        }
        yield put({
          type: a.GET_ARTICLE.FAILURE,
          payload: errorRes.data
        });
      }
    }
  } catch (e) {}
}

function* getArticles({ typeArticles, value }) {
  try {
    const mainToken = yield select(informationSelectors.getMainToken);

    const articles = yield call(
      articleService.get,
      mainToken,
      typeArticles,
      value
    );
    if (!articles.error) {
      yield put({
        type: a.GET_ARTICLES.SUCCESS,
        payload: articles
      });
    } else {
      const errorRes = articles.res.response;
      if (errorRes.request.status === 404) {
        yield call(tunnelSagas.redirectToUrl, "/404");
      } else {
        if (errorRes.request.status !== 400) {
          yield call(loggerSagas.postLogErrorApi, {
            sagaInit: "getArticles",
            apiResponseUrl: errorRes.request.responseURL,
            statusResponse: errorRes.request.status,
            dataResponse: errorRes.data,
            token: mainToken,
            params: {}
          });
        }
        yield put({
          type: a.GET_ARTICLES.FAILURE,
          payload: errorRes.data
        });
      }
    }
  } catch (e) {}
}

function* getHelpArticles() {
  try {
    const mainToken = yield select(informationSelectors.getMainToken);

    const typeArticles = "aide";
    const helpArticles = yield call(
      articleService.get,
      mainToken,
      typeArticles
    );
    if (!helpArticles.error) {
      yield put({
        type: a.GET_HELP_ARTICLES.SUCCESS,
        payload: helpArticles
      });
    } else {
      const errorRes = helpArticles.res.response;
      if (errorRes.request.status === 404) {
        yield call(tunnelSagas.redirectToUrl, "/404");
      } else {
        if (errorRes.request.status !== 400) {
          yield call(loggerSagas.postLogErrorApi, {
            sagaInit: "getHelpArticles",
            apiResponseUrl: errorRes.request.responseURL,
            statusResponse: errorRes.request.status,
            dataResponse: errorRes.data,
            token: mainToken,
            params: {}
          });
        }
        yield put({
          type: a.GET_HELP_ARTICLES.FAILURE,
          payload: errorRes.data
        });
      }
    }
  } catch (e) {}
}

export default all([
  takeLatest(a.GET_ARTICLE.REQUEST, getArticle),
  takeLatest(a.GET_ARTICLES.REQUEST, getArticles),
  takeLatest(a.GET_HELP_ARTICLES.REQUEST, getHelpArticles)
]);
