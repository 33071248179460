export const CLOSE_MODALS = {
  REQUEST: "modals/SET/REQUEST",
  SUCCESS: "modals/SET/SUCCESS",
  FAILURE: "modals/SET/FAILURE"
};

export const OPENCLOSE_MODAL_ETRE_RAPPELLE = {
  REQUEST: "modalEtreRappelle/SET/REQUEST",
  SUCCESS: "modalEtreRappelle/SET/SUCCESS",
  FAILURE: "modalEtreRappelle/SET/FAILURE"
};

export const OPENCLOSE_MODAL_CAMPAIGN = {
  REQUEST: "modalCampaign/SET/REQUEST",
  SUCCESS: "modalCampaign/SET/SUCCESS",
  FAILURE: "modalCampaign/SET/FAILURE"
};

export const OPENCLOSE_MODAL_SAVE_TUNNEL_AUTO = {
  REQUEST: "modalSaveTunnelAuto/SET/REQUEST",
  SUCCESS: "modalSaveTunnelAuto/SET/SUCCESS",
  FAILURE: "modalSaveTunnelAuto/SET/FAILURE"
};

export const closeAllModals = () => {
  return {
    type: CLOSE_MODALS.REQUEST
  };
};

export const openCloseModalEtreRappelle = isOpened => {
  return {
    type: OPENCLOSE_MODAL_ETRE_RAPPELLE.REQUEST,
    isOpened
  };
};

export const openCloseModalCampaign = isOpened => {
  return {
    type: OPENCLOSE_MODAL_CAMPAIGN.REQUEST,
    isOpened
  };
};

export const openCloseModalSaveTunnelAuto = isOpened => {
  return {
    type: OPENCLOSE_MODAL_SAVE_TUNNEL_AUTO.REQUEST,
    isOpened
  };
};
