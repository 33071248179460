import { call, all, put, takeLatest, select } from "redux-saga/effects";
import * as a from "./actions";

// Import selects from INFORMATION
import * as informationSelectors from "../../../information/selectors";
import * as tranchesAgesService from "./services";
import * as loggerSagas from "../../../logger/saga";

export function* getTranchesAges() {
  const mainToken = yield select(informationSelectors.getMainToken);
  const tranchesAges = yield call(tranchesAgesService.get, mainToken);
  if (!tranchesAges.error) {
    yield put({
      type: a.GET_TRANCHES_AGES.SUCCESS,
      payload: tranchesAges
    });
  } else {
    const errorRes = tranchesAges.res.response;
    yield call(loggerSagas.postLogErrorApi, {
      sagaInit: "getTranchesAges",
      apiResponseUrl: errorRes.request.responseURL,
      statusResponse: errorRes.request.status,
      dataResponse: errorRes.data,
      token: mainToken,
      params: {}
    });
    yield put({
      type: a.GET_TRANCHES_AGES.FAILURE,
      payload: true
    });
  }
}

export default all([takeLatest(a.GET_TRANCHES_AGES.REQUEST, getTranchesAges)]);
