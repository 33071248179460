import { call, all, put, takeLatest, select } from "redux-saga/effects";
import * as a from "./actions";

// Selectors
import * as informationSelectors from "../../../information/selectors";
import * as tunnelSelectors from "../../tunnel/selectors";
// Sagas
import * as tunnelSagas from "../../tunnel/saga";
import * as loggerSagas from "../../../logger/saga";
// Actions
import * as dateEffetActions from "../date-effet-contrat/actions";
// Services
import * as recapitulatifService from "./services";

function* getRecapitulatif({ assuranceType }) {
  try {
    const mainToken = yield select(informationSelectors.getMainToken);
    yield call(tunnelSagas.rebindIdsFromSessionStorage);
    const simulationIds = yield select(tunnelSelectors.getCurrentSimulationIds);
    if (simulationIds.simulationId === null) {
      yield call(
        tunnelSagas.redirectToUrl,
        `/assurance-${assuranceType || "automobile"}/informations-manquantes`
      );
    } else {
      const recapitulatif = yield call(
        recapitulatifService.get,
        assuranceType,
        mainToken,
        simulationIds.simulationId
      );
      if (!recapitulatif.error) {
        yield put({
          type: a.GET_RECAPITULATIF.SUCCESS,
          payload: recapitulatif
        });
      } else {
        const errorRes = recapitulatif.res.response;
        if (errorRes.request.status !== 400) {
          yield call(loggerSagas.postLogErrorApi, {
            sagaInit: "getRecapitulatif",
            apiResponseUrl: errorRes.request.responseURL,
            statusResponse: errorRes.request.status,
            dataResponse: errorRes.data,
            token: mainToken,
            params: {
              simulationId: simulationIds.simulationId
            }
          });
        }
        yield put({
          type: a.GET_RECAPITULATIF.FAILURE,
          payload: true
        });
      }
    }
  } catch (e) {
    yield put({
      type: a.GET_RECAPITULATIF.FAILURE,
      payload: true
    });
  }
}

function* setRecapitulatifModifyGo({ assuranceType, go }) {
  try {
    sessionStorage.setItem("modificationContratGo", JSON.stringify(go));
    yield put({
      type: a.SET_RECAPITULATIF_MODIFICATION_GO.SUCCESS,
      payload: go
    });
    yield put({
      type: dateEffetActions.RESET_DATE_EFFET_STATUS
    });
    let redirectionUrl;
    switch (go) {
      case "dateDebut":
        redirectionUrl = `/assurance-${assuranceType}/debut-assurance`;
        break;
      case "formule":
        redirectionUrl = `/assurance-${assuranceType}/tarif`;
        break;
      default:
    }
    yield call(tunnelSagas.redirectToUrl, redirectionUrl);
  } catch (e) {
    yield put({
      type: a.SET_RECAPITULATIF_MODIFICATION_GO.FAILURE,
      payload: true
    });
  }
}

export default all([
  takeLatest(a.GET_RECAPITULATIF.REQUEST, getRecapitulatif),
  takeLatest(
    a.SET_RECAPITULATIF_MODIFICATION_GO.REQUEST,
    setRecapitulatifModifyGo
  )
]);
