import styled, { css } from "styled-components";

const itemLinkCSS = css`
  align-items: center;
  box-shadow: none;
  color: inherit;
  font-family: ${props => props.theme.fonts.main.name};
  font-size: 1.6rem;
  font-weight: ${props => props.theme.fonts.main.variants.bold};
  display: flex;
  flex-flow: row wrap;
  line-height: 1;
  padding: 20px;
  text-decoration: none;
  text-shadow: none;
  > span {
    align-items: center;
    display: flex;
    flex-flow: row wrap;
    width: 100%;
    svg {
      height: auto;
      transform: translateY(-2px);
      width: 1.6rem;
    }
    > span {
      padding: 0 10px;
    }
  }
  &.active,
  &:hover {
    background: none;
    color: inherit;
  }
`;

export const StyledMainMenu = styled.aside`
  background-color: ${props => props.theme.colors.bgColors.dark};
  box-sizing: border-box;
  left: 0;
  overflow: hidden;
  position: fixed;
  top: 68px;
  width: 100%;
  z-index: 49000;
`;

export const CommonStyledMenuWrapper = styled.div.attrs({
  className: "menu-wrapper"
})`
  background-color: ${props => props.theme.colors.bgColors.altLight};
  color: ${props => props.theme.colors.themeColors.regular};
  .title {
    outline: none;
  }
  > div > .title {
    border-bottom: 1px solid ${props => props.theme.colors.bgColors.altRegular} !important;
  }
  .link {
    ${itemLinkCSS};
  }
  &::after {
    box-shadow: 0px 3px 15px 0px rgba(108, 122, 137, 0.2);
    -webkit-box-shadow: 0px 3px 15px 0px rgba(108, 122, 137, 0.2);
  }
`;
