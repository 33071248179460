import styled, { css } from "styled-components";
import breakpoint from "styled-components-breakpoint";

export const commonPopinFormStyle = css`
  > div {
    &:not(.initial) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      .title {
        max-width: 90%;
      }
      img {
        margin: 25px auto 60px;
      }
    }
  }
  &.is-loading {
    //position: relative;
    &:before {
      background-color: ${props => props.theme.colors.themeColors.white80};
      content: "";
      display: block;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 150;
    }
    .loader {
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 153;
    }
  }
  ${breakpoint("desktop")`
    > div:not(.initial) .title {
      max-width: 100%;
    }
  `};
`;

export const StyledPopinContent = styled.div`
  background-color: ${props => props.theme.colors.bgColors.light};
  overflow-x: hidden;
  position: relative;
  header {
    background-color: ${props => props.theme.colors.themeColors.altLight};
    height: 60px;
    width: 100%;
    > div {
      align-items: center;
      box-sizing: border-box;
      color: ${props => props.theme.colors.themeColors.regular};
      display: flex;
      justify-content: center;
      height: 100%;
      padding: 0 25px;
      position: relative;
      width: 100%;
    }
    .title {
      font-size: 1.6rem;
      font-weight: 700;
      margin-left: 10px;
      width: auto;
    }
    .close-icon {
      color: ${props => props.theme.colors.themeColors.regular};
      cursor: pointer;
      right: 35px;
      position: absolute;
      top: 50%;
      transform: rotate(45deg) translateY(-30px);
    }
  }
  .wrapper-content {
    padding: 20px 20px 20px;
    .title {
      font-size: 2rem;
      font-weight: 700;
    }
  }
  ${breakpoint("mobile", "tablet")`
    padding-top: 60px;
    header {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 30;
    }
  `};
  ${breakpoint("desktop")`
    border-radius: 5px;
  `};
`;
