const automobileConstants = {
  inputProcess: [
    "InfoVehicule1",
    "InfoVehicule2",
    "InfoVehicule3",
    "InfoVehicule4",
    "InfoPermis1"
  ],
  inputProcessReadWrite: ["InfoVehicule1", "InfoPermis1"],
  startTunnel: "choixModeSaisie",
  uploadablesDocuments: ["carteGrise", "permisConduire", "carteIdentite"],
  documentAttenduNotAllowed: ["CG", "Identite", "Permis"]
};

const santeConstants = {
  inputProcess: ["InfoSanteIdentite1", "InfoSanteIdentite2"],
  inputProcessReadWrite: ["InfoSanteIdentite1"],
  startTunnel: "assurerFamille",
  uploadablesDocuments: ["carteIdentite"]
};

const pathToMedia = window.location.href.includes("baloon")
  ? "baloon"
  : "default";

const appConstants = {
  mainAPIEndpoint: `${process.env.REACT_APP_API_URL}/api/`,
  websiteUrl: window.location.origin,
  titleSeparator: " - ",
  campaign: {
    popin: [
      /*{
        name: "leCoinAfrique"
      }*/
    ]
  },
  searchURLIds: [
    "guid",
    "campaignPopin",
    "parrainage_guid",
    "paiement_guid",
    "signature_guid"
  ],
  allowedProducts: ["automobile", "sante", "voyage"],
  allowedTunnels: ["automobile", "sante"],
  civilite: [
    {
      text: "translate:shared.civilite.monsieur",
      value: "MR"
    },
    {
      text: "translate:shared.civilite.madame",
      value: "MME"
    }
  ],
  mediasPaths: {
    partenaires: partnerCode =>
      `/image/partenaires/Logo_Compagnie_${partnerCode}.png`,
    marquesAutomobile: (referentielCode, referenceCode) =>
      `/image/references/${referentielCode}_${referenceCode}.png`,
    booleanImages: (inputProcessCode, inputDefinitionPageCode) =>
      `/image/inputProcess/${inputProcessCode}_${inputDefinitionPageCode}.svg`,
    garanties: {
      categorie: code =>
        `/image/garanties/categories/${pathToMedia}/${code}.svg`,
      risqueCouvert: code =>
        `/image/garanties/risquecouvert/${pathToMedia}/${code}.svg`
    },
    operateursPaiement: code => `image/operateurs/${code}.jpg`
  },
  inputProcess: {
    automobile: automobileConstants.inputProcess,
    sante: santeConstants.inputProcess
  },
  inputProcessReadWrite: {
    automobile: automobileConstants.inputProcessReadWrite,
    sante: santeConstants.inputProcessReadWrite
  },
  phone: {
    sep: " ",
    masks: {
      ci: {
        indicator: "225",
        modulo: "2,2,2,2,2",
        placeholder: "00 00 00 00 00"
      },
      sn: {
        indicator: "221",
        modulo: "2,3,2,2",
        placeholder: "00 000 00 00"
      },
      cm: {
        indicator: "237",
        modulo: "3,2,2,2",
        placeholder: "000 00 00 00"
      },
      ga: {
        indicator: "241",
        modulo: "2,2,2,2",
        placeholder: "00 00 00 00"
      },
      ne: {
        indicator: "227",
        modulo: "2,3,3",
        placeholder: "00 000 000"
      },
      tg: {
        indicator: "228",
        modulo: "2,2,2,2",
        placeholder: "00 00 00 00"
      }
    }
  },
  tunnel: {
    start: {
      automobile: automobileConstants.startTunnel,
      sante: santeConstants.startTunnel
    }
  },
  uploadableDocuments: {
    automobile: automobileConstants.uploadablesDocuments,
    sante: santeConstants.uploadablesDocuments
  },
  documentAttenduNotAllowed: {
    automobile: automobileConstants.documentAttenduNotAllowed
  },
  paiement: {
    validSteps: ["succes", "echec", "saisie-telephone", "explication"]
  },
  contentButtons: [
    "ButtonSiteCommercial.Facebook",
    "ButtonSiteCommercial.TarifAuto",
    "ButtonSiteCommercial.TarifSante",
    "ButtonSiteCommercial.RappelConseiller"
  ],
  pagesAides: [
    "qui-sommes-nous",
    "comment-acheter-mon-assurance-chez-baloon",
    "comprendre-mon-contrat-d-assurance",
    "renouveler-ou-modifier-mon-assurance",
    "que-faire-en-cas-d-accident",
    "les-formules-et-garanties"
  ]
};

export default appConstants;
