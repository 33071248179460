// Import actions from store
import { call, all, put, takeLeading, select, take } from "redux-saga/effects";
// Actions
import * as a from "./actions";
import * as tunnelActions from "../../tunnel/actions";
import * as uploadDocumentActions from "../upload-document/actions";
import * as dateEffetContratActions from "../date-effet-contrat/actions";
import {
  isAnonyme,
  extractUserInfos
} from "../../../../helpers/data/cookie-user";
// Selectors
import * as informationSelectors from "../../../information/selectors";
import * as tunnelSelectors from "../../tunnel/selectors";
import * as inputProcessSelectors from "./selectors";
// Saga
import * as tunnelSagas from "../../tunnel/saga";
import * as loggerSagas from "../../../logger/saga";
// Services
import * as inputProcessService from "./services";

// Helpers
import isEmpty from "../../../../helpers/data/is-object-empty";
// Constants
import appConstants from "../../../../configuration/constants";

export function* createInputProcess({
  inputProcessType,
  assuranceType,
  saisieUser,
  askedInputs
}) {
  const origineWebCode = yield select(
    informationSelectors.getCurrentVisitorOrigin
  );
  const mainToken = yield select(informationSelectors.getMainToken);
  const currentSimulationIds = yield select(
    tunnelSelectors.getCurrentSimulationIds
  );
  const pagePrecedenteId = yield select(
    inputProcessSelectors.getPagePrecedenteId
  );

  if (noRequiredIds(assuranceType, currentSimulationIds)) {
    yield call(
      tunnelSagas.redirectToUrl,
      `/assurance-${assuranceType || "automobile"}/informations-manquantes`
    );
  } else {
    const input = yield call(
      inputProcessService.create,
      assuranceType,
      mainToken,
      inputProcessType,
      saisieUser,
      askedInputs,
      pagePrecedenteId,
      currentSimulationIds.simulationId,
      currentSimulationIds.risqueId,
      origineWebCode
    );
    let errorRes = null;
    if (typeof input.res !== "undefined") {
      errorRes =
        input.res !== null
          ? input.res.response !== null
            ? input.res.response
            : null
          : null;
      if (errorRes !== null) {
      }
    }
    const params = {
      saisieUser,
      askedInputs,
      pagePrecedenteId,
      simulationId: currentSimulationIds.simulationId,
      risqueId: currentSimulationIds.risqueId
    };
    const response = input.data;
    if (isResponse(response)) {
      if (isOnSuccess(response)) {
        yield call(saveDataOnSucessFrom, response);
        yield call(
          redirectOnSuccessFrom,
          response,
          assuranceType,
          inputProcessType
        );
      } else if (isEndOfInputProcess(response)) {
        yield call(redirectEndOfIP, inputProcessType, assuranceType);
      } else if (isOnErrorMetier(response)) {
        let warningsConcat = "";
        let infoWarns = "";
        response.errors.forEach(error => {
          const valeurIncoherente = askedInputs.find(
            i => i.attribut.code === error.attributCode
          ).attribut.value;
          warningsConcat += error.errorCode + "\n";
          infoWarns += "Regles metier : " + error.regleMetierDto.code + "\n";
          infoWarns += "Attribut concerné : " + error.attributCode + "\n\n";
          infoWarns += "Valeur incohérente :: " + valeurIncoherente;
        });

        yield call(loggerSagas.postLog, {
          message: "On a une erreur metier !",
          level: "warn",
          info: infoWarns,
          error: warningsConcat
        });
        yield call(setErrorMetierDatas, askedInputs, response);
      } else if (isOnError(errorRes)) {
        yield call(logErrorFrom, errorRes, mainToken, params);
        yield call(setErrorData, errorRes);
      }
    }
  }
}

export function* getInputProcess({
  inputProcessType,
  assuranceType,
  startInputProcess
}) {
  try {
    const mainToken = yield select(informationSelectors.getMainToken);
    const origineWebCode = yield select(
      informationSelectors.getCurrentVisitorOrigin
    );
    const currentSimulationsIds = yield select(
      tunnelSelectors.getCurrentSimulationIds
    );

    const input = yield call(
      inputProcessService.get,
      assuranceType,
      mainToken,
      currentSimulationsIds.simulationId,
      currentSimulationsIds.risqueId,
      inputProcessType,
      origineWebCode
    );
    let errorRes = null;
    if (typeof input.res !== "undefined") {
      errorRes =
        input.res !== null
          ? input.res.response !== null
            ? input.res.response
            : null
          : null;
      if (errorRes !== null) {
      }
    }

    if (isEndOfInputProcess(input)) {
      switch (assuranceType) {
        case "automobile":
          yield call(redirectEndOfIPAutomobile, inputProcessType);
          break;
        case "sante":
          yield call(redirectEndOfIPSante, inputProcessType);
          break;
        default:
      }
    } else if (isOnSuccess(input)) {
      yield call(saveDataOnSucessFrom, input);

      yield put({
        type: a.GET_INPUT_PROCESS.SUCCESS,
        payload: input.model
      });
      const currentProcessName = yield select(
        inputProcessSelectors.getCurrentInputProcessName
      );
      yield put({
        type: tunnelActions.NAVIGATE_INTO.REQUEST,
        sectionName: `inputProcess___${currentProcessName}`
      });

      if (input.model) {
        yield call(
          redirectInputProcess,
          assuranceType,
          input.model.inputProcessCode,
          input.model.pageDefinition.code
        );
      }
    } else if (isOnErrorMetier(input)) {
      yield put({
        type: a.GET_INPUT_PROCESS.FAILURE,
        payload: input,
        attr: null
      });
    } else if (isOnError(input)) {
      yield call(loggerSagas.postLogErrorApi, {
        sagaInit: "getInputProcess",
        apiResponseUrl: errorRes.request.responseURL,
        statusResponse: errorRes.request.status,
        dataResponse: errorRes.data,
        token: mainToken,
        params: {
          simulationId: currentSimulationsIds.simulationId,
          risqueId: currentSimulationsIds.risqueId,
          inputProcessType
        }
      });
      yield put({
        type: a.GET_INPUT_PROCESS.ERROR,
        payload: errorRes
      });
    }
  } catch (e) {
    yield call(loggerSagas.postLog, {
      message: "erreur saga getInputProcess",
      level: "error",
      info: "try catch saga getInputProcess",
      error: e
    });
  }
}

export function* getBackInputProcess({ assuranceType, inputProcessCode }) {
  const isEditingRW = yield select(inputProcessSelectors.isEditingReadWrite);
  const mainToken = yield select(informationSelectors.getMainToken);
  const currentSimulationsIds = yield select(
    tunnelSelectors.getCurrentSimulationIds
  );
  const pagePrecedenteId = yield select(
    tunnelSelectors.getCurrentSimulationInputProcess
  );
  if (isEditingRW) {
    yield put({
      type: a.STOP_LOADING_INPUT_PROCESS
    });
    yield put({
      type: a.SET_INPUT_PROCESS_READ_WRITE_INSIDE_COMPONENT
    });
  } else {
    const input = yield call(
      inputProcessService.getBack,
      assuranceType,
      mainToken,
      currentSimulationsIds.simulationId,
      currentSimulationsIds.risqueId,
      inputProcessCode,
      pagePrecedenteId.id
    );
    let errorRes = null;
    if (typeof input.res !== "undefined") {
      errorRes =
        input.res !== null
          ? input.res.response !== null
            ? input.res.response
            : null
          : null;
      if (errorRes !== null) {
        console.log("RESPONSE ERROR :: ", errorRes);
      }
    }

    if (isEndOfInputProcess(input)) {
      yield call(tunnelSagas.setInputProcess);
      yield put({
        type: a.GET_BACK_INPUT_PROCESS.SUCCESS,
        payload: null
      });
      yield call(backFirstIP, inputProcessCode, assuranceType);
    } else if (isOnSuccess(input)) {
      yield call(saveDataOnSucessFrom, input);

      yield put({
        type: a.GET_BACK_INPUT_PROCESS.SUCCESS,
        payload: input.model
      });
      const currentProcessName = yield select(
        inputProcessSelectors.getCurrentInputProcessName
      );
      yield put({
        type: tunnelActions.NAVIGATE_INTO.REQUEST,
        sectionName: `inputProcess___${currentProcessName}`
      });
      yield call(
        redirectInputProcess,
        assuranceType,
        input.model.inputProcessCode,
        input.model.pageDefinition.code
      );
    } else if (isOnErrorMetier(input)) {
      yield put({
        type: a.GET_BACK_INPUT_PROCESS.FAILURE,
        payload: input,
        attr: null
      });
    } else if (isOnError(input)) {
      if (errorRes.request.status !== 400) {
        yield call(loggerSagas.postLogErrorApi, {
          sagaInit: "getBackInputProcess",
          apiResponseUrl: errorRes.request.responseURL,
          statusResponse: errorRes.request.status,
          dataResponse: errorRes.data,
          token: mainToken,
          params: {
            simulationId: currentSimulationsIds.simulationId,
            risqueId: currentSimulationsIds.risqueId
          }
        });
      }
      yield put({
        type: a.GET_BACK_INPUT_PROCESS.ERROR,
        payload: errorRes
      });
    }
  }
}

export function* endOfReadWriteInputProcess({
  inputProcessType,
  assuranceType
}) {
  switch (assuranceType) {
    case "automobile":
      yield call(endOfReadWriteInputProcessAutomobile, inputProcessType);
      break;
    case "sante":
      yield call(endOfReadWriteInputProcessSante, inputProcessType);
      break;
    default:
  }
}

export function* repriseInputProcess({ assuranceType, processName }) {
  const origineWebCode = yield select(
    informationSelectors.getCurrentVisitorOrigin
  );
  const currentProcess = yield select(inputProcessSelectors.getCurrentProcess);
  const isIP2Marque = yield select(tunnelSelectors.isIP2Marque);
  if (isEmpty(currentProcess) && !isIP2Marque) {
    // need reprise
    const currentProcessInfos = yield select(
      tunnelSelectors.getCurrentSimulationInputProcess
    );
    const mainToken = yield select(informationSelectors.getMainToken);
    const currentSimulationIds = yield select(
      tunnelSelectors.getCurrentSimulationIds
    );
    const pageDefinitionOrigineId = currentProcessInfos.id;
    const noRequiredIds = yield call(
      hasNotRequiredIds,
      assuranceType,
      processName
    );
    if (noRequiredIds) {
      yield call(
        tunnelSagas.redirectToUrl,
        `/assurance-${assuranceType || "automobile"}/informations-manquantes`
      );
    } else {
      const input = yield call(
        inputProcessService.reprise,
        assuranceType,
        mainToken,
        currentSimulationIds.simulationId,
        currentSimulationIds.risqueId,
        currentProcessInfos.name,
        pageDefinitionOrigineId,
        origineWebCode
      );
      let errorRes = null;
      if (typeof input.res !== "undefined") {
        errorRes =
          input.res !== null
            ? input.res.response !== null
              ? input.res.response
              : null
            : null;
        if (errorRes !== null) {
          console.log("RESPONSE ERROR :: ", errorRes);
        }
      }
      if (isEndOfInputProcess(input)) {
        yield call(tunnelSagas.setInputProcess);
        yield put({
          type: a.REPRISE_INPUT_PROCESS.SUCCESS,
          payload: null
        });
      } else if (isOnSuccess(input)) {
        yield call(saveDataOnSucessFrom, input);

        yield put({
          type: a.REPRISE_INPUT_PROCESS.SUCCESS,
          payload: input.model
        });
      } else if (isOnErrorMetier(input)) {
        yield put({
          type: a.REPRISE_INPUT_PROCESS.FAILURE,
          payload: input,
          attr: null
        });
      } else if (isOnError(input)) {
        if (errorRes.request.status !== 400) {
          yield call(loggerSagas.postLogErrorApi, {
            sagaInit: "repriseInputProcess",
            apiResponseUrl:
              typeof errorRes !== "undefined"
                ? errorRes.request.responseURL
                : true,
            statusResponse:
              typeof errorRes !== "undefined" ? errorRes.request.status : true,
            dataResponse:
              typeof errorRes !== "undefined" ? errorRes.data : true,
            token: mainToken,
            params: {
              simulationId: currentSimulationIds.simulationId,
              risqueId: currentSimulationIds.risqueId,
              inputProcessType: currentProcessInfos.name,
              pageDefinitionOrigineId
            }
          });
        }
        yield put({
          type: a.REPRISE_INPUT_PROCESS.ERROR,
          payload: errorRes
        });
      }

      // if (!input.error) {
      //   yield put({
      //     type: a.REPRISE_INPUT_PROCESS.SUCCESS,
      //     payload: input
      //   });
      // }
      // else {
      //   const errorRes = input.res.response;
      //   yield call(loggerSagas.postLogErrorApi, {
      //     sagaInit: "repriseInputProcess",
      //     apiResponseUrl:
      //       typeof errorRes !== "undefined"
      //         ? errorRes.request.responseURL
      //         : true,
      //     statusResponse:
      //       typeof errorRes !== "undefined" ? errorRes.request.status : true,
      //     dataResponse: typeof errorRes !== "undefined" ? errorRes.data : true,
      //     token: mainToken,
      //     params: {
      //       simulationId: currentSimulationIds.simulationId,
      //       risqueId: currentSimulationIds.risqueId,
      //       inputProcessType: currentProcessInfos.name,
      //       pageDefinitionOrigineId
      //     }
      //   });
      //   yield put({
      //     type: a.REPRISE_INPUT_PROCESS.FAILURE,
      //     payload: true
      //   });
      // }
    }
  } else {
    yield put({
      type: a.REPRISE_INPUT_PROCESS.SUCCESS,
      payload: null
    });
  }
}

export default all([
  takeLeading(a.CREATE_INPUT_PROCESS.REQUEST, createInputProcess),
  takeLeading(a.GET_INPUT_PROCESS.REQUEST, getInputProcess),
  takeLeading(a.GET_BACK_INPUT_PROCESS.REQUEST, getBackInputProcess),
  takeLeading(a.END_OF_READWRITE_INPUT_PROCESS, endOfReadWriteInputProcess),
  takeLeading(a.REPRISE_INPUT_PROCESS.REQUEST, repriseInputProcess)
]);

//Fonction saga privé ::

function* saveDataOnSucessFrom(response) {
  yield call(tunnelSagas.setInputProcess, {
    name: response.model.inputProcessCode,
    id: response.model.pageDefinition.id
  });
  yield call(tunnelSagas.setIds, {
    simulationId: response.model.simulationId,
    risqueId: response.model.risqueId
  });
}
function* redirectOnSuccessFrom(response, assuranceType, inputProcessType) {
  yield call(
    redirectInputProcess,
    assuranceType,
    inputProcessType,
    response.model.pageDefinition.code
  );
  yield put({
    type: a.CREATE_INPUT_PROCESS.SUCCESS,
    payload: response.model
  });
}

function* setErrorMetierDatas(inputs, response) {
  const attr = getAttributInput(
    inputs,
    response.errors[0].attributDefinitionId,
    response.errors[0].versionEntiteDefinitionId
  );
  yield put({
    type: a.CREATE_INPUT_PROCESS.FAILURE,
    payload: response,
    attr: attr
  });
}

function* logErrorFrom(errorRes, mainToken, params) {
  yield call(loggerSagas.postLogErrorApi, {
    sagaInit: "createInputProcess",
    apiResponseUrl: errorRes.request.responseURL,
    statusResponse: errorRes.request.status,
    dataResponse: errorRes.data,
    token: mainToken,
    params
  });
}

function* setErrorData(errorRes) {
  yield put({
    type: a.CREATE_INPUT_PROCESS.ERROR,
    payload: errorRes
  });
}

function* redirectInputProcess(
  assuranceType,
  inputProcessCode,
  pageDefinitionCode
) {
  let redirectionUrl;
  if (
    typeof pageDefinitionCode !== "undefined" &&
    pageDefinitionCode !== null
  ) {
    redirectionUrl = `/assurance-${assuranceType}/process/${inputProcessCode}/${pageDefinitionCode}`;
  } else {
    redirectionUrl = `/assurance-${assuranceType}/process/${inputProcessCode}`;
  }
  yield call(tunnelSagas.redirectToUrl, redirectionUrl, true);
}

function* hasNotRequiredIds(assuranceType, processName) {
  const currentSimulationIds = yield select(
    tunnelSelectors.getCurrentSimulationIds
  );
  const isIP2Marque = yield select(tunnelSelectors.isIP2Marque);
  const noRequiredIds = () => {
    let hasNoIds = false;
    if (assuranceType === "automobile") {
      if (processName === "InfoPermis1") {
        hasNoIds = currentSimulationIds.simulationId === null;
      } else {
        hasNoIds =
          !isIP2Marque &&
          (currentSimulationIds.simulationId === null ||
            currentSimulationIds.risqueId === null);
      }
    }
    if (assuranceType === "sante") {
      hasNoIds = currentSimulationIds.simulationId === null;
    }
    return hasNoIds;
  };
  return noRequiredIds();
}

function* endOfReadWriteInputProcessAutomobile(inputProcessType) {
  switch (inputProcessType) {
    case "InfoVehicule1":
      yield put({
        type: a.GET_INPUT_PROCESS.REQUEST,
        inputProcessType: "InfoVehicule3",
        assuranceType: "automobile",
        startInputProcess: true
      });
      break;
    case "InfoPermis1":
      yield put({
        type: uploadDocumentActions.NEED_UPLOAD_DOCUMENT.REQUEST,
        assuranceType: "automobile",
        documentType: "carteIdentite"
      });
      const waitSuccess = yield take(
        uploadDocumentActions.NEED_UPLOAD_DOCUMENT.SUCCESS
      );
      const need = waitSuccess.payload.carteIdentite;
      const country = yield select(informationSelectors.getCountry);
      let redirectionUrl = "/assurance-automobile/recapitulatif";
      if (need || country === "cm") {
        redirectionUrl = "/assurance-automobile/envoi-document/carte-identite";
      }
      yield call(tunnelSagas.redirectToUrl, redirectionUrl);
      break;
    default:
  }
}

function* endOfReadWriteInputProcessSante(inputProcessType) {
  switch (inputProcessType) {
    case "InfoSanteIdentite1":
      yield call(
        tunnelSagas.redirectToUrl,
        "/assurance-sante/informations-assures"
      );
      break;
    default:
  }
}

function* redirectEndOfIPAutomobile(inputProcessType) {
  let redirectionUrl;
  const isTarifFirst = yield select(
    informationSelectors.isTarifBeforeInfoClient
  );
  switch (inputProcessType) {
    case "InfoVehicule2":
      yield put({
        type: a.GET_INPUT_PROCESS.REQUEST,
        inputProcessType: "InfoVehicule3",
        assuranceType: "automobile",
        startInputProcess: true
      });
      break;
    case "InfoVehicule3":
      if (isTarifFirst) {
        yield call(tunnelSagas.redirectToUrl, "/assurance-automobile/tarif");
      } else {
        if (isAnonyme() || extractUserInfos() === null) {
          yield call(
            tunnelSagas.redirectToUrl,
            "/assurance-automobile/preparation-contrat"
          );
        } else {
          yield call(tunnelSagas.redirectToUrl, "/assurance-automobile/tarif");
        }
      }
      break;
    case "InfoVehicule4":
      const country = yield select(informationSelectors.getCountry);
      redirectionUrl = "/assurance-automobile/envoi-document/permis-conduire";
      if (country === "sn" || country === "tg") {
        redirectionUrl = "/assurance-automobile/recapitulatif";
      }
      yield call(tunnelSagas.redirectToUrl, redirectionUrl);
      break;
    default:
  }
}

function* redirectEndOfIPSante(/*inputProcessType*/) {
  yield call(
    tunnelSagas.redirectToUrl,
    "/assurance-sante/informations-assures"
  );
}

function* redirectEndOfIP(inputProcessType, assuranceType) {
  yield call(tunnelSagas.setInputProcess);
  yield put({
    type: a.STOP_LOADING_INPUT_PROCESS
  });
  const isRWInputProcess =
    appConstants.inputProcessReadWrite[assuranceType].indexOf(
      inputProcessType
    ) > -1;
  if (isRWInputProcess) {
    const startInputProcess = true;
    yield put({
      type: a.GET_INPUT_PROCESS.REQUEST,
      inputProcessType,
      assuranceType,
      startInputProcess
    });
  } else {
    switch (assuranceType) {
      case "automobile":
        yield call(redirectEndOfIPAutomobile, inputProcessType);
        break;
      case "sante":
        yield call(redirectEndOfIPSante, inputProcessType);
        break;
      default:
    }
  }
}

function* backFirstIPAutomobile(inputProcessType) {
  switch (inputProcessType) {
    case "InfoVehicule1":
      yield call(tunnelSagas.redirectToUrl, "/assurance-automobile");
      break;
    case "InfoVehicule2":
      const country = yield select(informationSelectors.getCountry);
      if (country === "ga" || country === "ne") {
        yield call(tunnelSagas.redirectToUrl, "/assurance/automobile");
      } else {
        yield call(tunnelSagas.redirectToUrl, "/assurance-automobile");
      }
      break;
    case "InfoVehicule3":
      let hasBeenRadlad = yield select(
        tunnelSelectors.getCurrentSimulationRadlad
      );
      hasBeenRadlad = hasBeenRadlad["carteGrise"];
      if (hasBeenRadlad) {
        yield put({
          type: a.GET_INPUT_PROCESS.REQUEST,
          inputProcessType: "InfoVehicule1",
          assuranceType: "automobile",
          startInputProcess: false
        });
      } else {
        yield call(getBackInputProcess, {
          assuranceType: "automobile",
          inputProcessCode: "InfoVehicule2"
        });
      }
      break;
    case "InfoVehicule4":
      let needCheckRadlad = yield select(
        tunnelSelectors.getCurrentSimulationCheckRadlad
      );
      needCheckRadlad = needCheckRadlad["carteGrise"];
      if (needCheckRadlad) {
        yield put({
          // reset status post dateEffet
          type: dateEffetContratActions.RESET_DATE_EFFET_STATUS
        });
        yield call(
          tunnelSagas.redirectToUrl,
          "/assurance-automobile/debut-assurance"
        );
      } else {
        yield call(
          tunnelSagas.redirectToUrl,
          "/assurance-automobile/envoi-document/carte-grise"
        );
      }
      break;
    case "InfoPermis1":
      yield call(
        tunnelSagas.redirectToUrl,
        "/assurance-automobile/envoi-document/permis-conduire"
      );
      break;
    default:
  }
}

function* backFirstIPSante(inputProcessType) {
  switch (inputProcessType) {
    case "InfoSanteIdentite1":
    case "InfoSanteIdentite2":
      yield call(
        tunnelSagas.redirectToUrl,
        "/assurance-sante/envoi-document/carte-identite"
      );
      break;
    default:
  }
}

function* backFirstIP(inputProcessType, assuranceType) {
  yield call(tunnelSagas.setInputProcess);
  yield put({
    type: tunnelActions.REMOVE_LAST_PARCOURS
  });
  switch (assuranceType) {
    case "automobile":
      yield call(backFirstIPAutomobile, inputProcessType);
      break;
    case "sante":
      yield call(backFirstIPSante, inputProcessType);
      break;
    default:
  }
}

//Fonctions outil ::
const noRequiredIds = (assuranceType, currentSimulationIds) => {
  let hasNoIdsSante = false;
  if (assuranceType === "sante") {
    hasNoIdsSante = currentSimulationIds.simulationId === null;
  }
  return hasNoIdsSante;
};

const isOnSuccess = response => {
  return (
    response.model !== null &&
    !response.isOnErrorMetier &&
    response.model !== "undefined"
  );
};

const isEndOfInputProcess = response => {
  return response.model === null && response.errors.length === 0;
};
const isOnErrorMetier = response => {
  return (
    response.errors !== null &&
    typeof response.errors !== "undefined" &&
    response.errors.length > 0
  );
};

const isResponse = response => {
  return response !== null && typeof response !== "undefined";
};

const isOnError = errorRes => {
  return errorRes !== null && typeof errorRes !== "undefined";
};

const getAttributInput = (inputs, attributId, entiteId) => {
  let result = null;
  inputs.forEach(input => {
    const attributTmp = input.attribut;
    if (attributTmp.attributDefinitionId === attributId) {
      if (attributTmp.versionEntiteDefinitionId === entiteId) {
        result = attributTmp;
        return;
      }
    }
  });
  return result;
};
