import React, { Component } from "react";

// Import components
import ErrorContainer from "../error-container";

// Import style
// import { StyledErrorBoundary } from "./style";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      info: null,
      error: null
    };
  }

  componentDidCatch(error, info) {
    this.setState({
      hasError: true,
      error: error,
      info: info.componentStack
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        <ErrorContainer
          messageLog="React a généré une erreur"
          error={this.state.error}
          info={this.state.info}
        />
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
