import * as a from "./actions";

const INITIAL_UPLOAD_DOCUMENT_STATE = {
  currentUploadStep: -1,
  create: {
    error: false,
    loading: false,
    success: false,
    radLad: false,
    risqueId: null,
    simulationId: null,
    duration: null
  },
  need: {
    error: false,
    loading: false,
    success: false,
    isNeeded: {}
  },
  documentAttendu: {
    error: false,
    loading: false,
    success: false,
    code: null,
    natureDocumentClientRefId: null
  },
  documentsRisqueAttendu: {
    list: {
      error: false,
      loading: false,
      success: false,
      data: []
    },
    create: {
      error: false,
      loading: false,
      success: false
    }
  }
};

export function uploadDocument(state = INITIAL_UPLOAD_DOCUMENT_STATE, action) {
  switch (action.type) {
    case a.CREATE_UPLOAD_DOCUMENT.REQUEST:
      return {
        ...state,
        create: {
          error: false,
          loading: true,
          success: false
        }
      };
    case a.CREATE_UPLOAD_DOCUMENT.SUCCESS:
      return {
        ...state,
        create: {
          error: false,
          loading: false,
          success: true,
          radLad: action.payload.radLad,
          risqueId: action.payload.risqueId,
          simulationId: action.payload.simulationId,
          duration: action.payload.duration
        }
      };
    case a.CREATE_UPLOAD_DOCUMENT.FAILURE:
      return {
        ...state,
        create: {
          error: true,
          loading: false,
          success: false,
          radLad: false
        }
      };
    case a.NEED_UPLOAD_DOCUMENT.REQUEST:
      return {
        ...state,
        need: {
          error: false,
          loading: true,
          success: false,
          isNeeded: {
            ...state.need.isNeeded
          }
        }
      };
    case a.NEED_UPLOAD_DOCUMENT.SUCCESS:
      return {
        ...state,
        need: {
          error: false,
          loading: false,
          success: true,
          isNeeded: {
            ...state.need.isNeeded,
            ...action.payload
          }
        }
      };
    case a.NEED_UPLOAD_DOCUMENT.FAILURE:
      return {
        ...state,
        need: {
          error: true,
          loading: false,
          success: false,
          isNeeded: {}
        }
      };
    case a.RESET_UPLOAD_DOCUMENT:
      return INITIAL_UPLOAD_DOCUMENT_STATE;
    case a.SET_CURRENT_UPLOAD_STEP.SUCCESS:
      return {
        ...state,
        currentUploadStep: action.step
      };
    case a.GET_NEXT_DOCUMENT_ATTENDU.REQUEST:
    case a.CREATE_UPLOAD_DOCUMENT_ATTENDU.REQUEST:
      return {
        ...state,
        documentAttendu: {
          error: false,
          loading: true,
          success: false,
          code: null,
          natureDocumentClientRefId: null
        }
      };
    case a.GET_NEXT_DOCUMENT_ATTENDU.SUCCESS:
    case a.CREATE_UPLOAD_DOCUMENT_ATTENDU.SUCCESS:
      return {
        ...state,
        documentAttendu: {
          error: false,
          loading: false,
          success: true,
          code: action.payload.code,
          natureDocumentClientRefId: action.payload.natureDocumentClientRefId
        }
      };
    case a.GET_NEXT_DOCUMENT_ATTENDU.FAILURE:
    case a.CREATE_UPLOAD_DOCUMENT_ATTENDU.FAILURE:
      return {
        ...state,
        documentAttendu: {
          error: true,
          loading: false,
          success: false,
          code: null,
          natureDocumentClientRefId: null
        }
      };
    /**
     * GET DOCUMENTS RISQUE ATTENDU
     **/
    case a.GET_DOCUMENTS_RISQUE_ATTENDU.REQUEST:
      return {
        ...state,
        documentsRisqueAttendu: {
          ...state.documentsRisqueAttendu,
          list: {
            ...state.documentsRisqueAttendu.list,
            error: false,
            loading: true,
            success: false
          }
        }
      };
    case a.GET_DOCUMENTS_RISQUE_ATTENDU.SUCCESS:
      return {
        ...state,
        documentsRisqueAttendu: {
          ...state.documentsRisqueAttendu,
          list: {
            ...state.documentsRisqueAttendu.list,
            error: false,
            loading: false,
            success: true,
            data: action.payload
          }
        }
      };
    case a.GET_DOCUMENTS_RISQUE_ATTENDU.FAILURE:
      return {
        ...state,
        documentsRisqueAttendu: {
          ...state.documentsRisqueAttendu,
          list: {
            ...state.documentsRisqueAttendu.list,
            error: true,
            loading: false,
            success: false
          }
        }
      };
    /**
     * CREATE DOCUMENTS RISQUE ATTENDU
     **/
    case a.CREATE_UPLOAD_DOCUMENT_RISQUE_ATTENDU.REQUEST:
      return {
        ...state,
        documentsRisqueAttendu: {
          ...state.documentsRisqueAttendu,
          create: {
            ...state.documentsRisqueAttendu.create,
            error: false,
            loading: true,
            success: false
          }
        }
      };
    case a.CREATE_UPLOAD_DOCUMENT_RISQUE_ATTENDU.SUCCESS:
      return {
        ...state,
        documentsRisqueAttendu: {
          ...state.documentsRisqueAttendu,
          list: {
            ...state.documentsRisqueAttendu.list,
            error: false,
            loading: false,
            success: false,
            data: action.payload
          },
          create: {
            ...state.documentsRisqueAttendu.create,
            error: false,
            loading: false,
            success: true
          }
        }
      };
    case a.CREATE_UPLOAD_DOCUMENT_RISQUE_ATTENDU.FAILURE:
      return {
        ...state,
        documentsRisqueAttendu: {
          ...state.documentsRisqueAttendu,
          create: {
            ...state.documentsRisqueAttendu.create,
            error: true,
            loading: false,
            success: false
          }
        }
      };
    default:
      return state;
  }
}

export default uploadDocument;
