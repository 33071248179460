import styled from "styled-components";

export const StyledBurgerMenuIcon = styled.div`
  height: 25px;
  svg {
    color: ${props => props.theme.colors.themeColors.regular};
    cursor: pointer;
    height: 100%;
    overflow: visible;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
`;
