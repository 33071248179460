export const SET_IS_TUNNEL = {
  REQUEST: "tunnel/is/SET/REQUEST",
  SUCCESS: "tunnel/is/SET/SUCCESS",
  FAILURE: "tunnel/is/SET/FAILURE"
};

export const SET_TUNNEL_CURRENT_STEP = {
  REQUEST: "tunnel/step/SET/REQUEST",
  SUCCESS: "tunnel/step/SET/SUCCESS",
  FAILURE: "tunnel/step/SET/FAILURE"
};

export const SET_CURRENT_INPUT_PROCESS = "tunnel/CURRENT_INPUT_PROCESS/SET";

export const RESET_CURRENT_INPUT_PROCESS = "tunnel/CURRENT_INPUT_PROCESS/RESET";

// export const RESET_NEW_SAVE_TUNNEL_AUTO = "saveTunnelAuto/RESET";

export const SET_CURRENT_SOUSCRIPTION_IDS = "tunnel/currentSouscriptionIds/SET";
export const RESET_CURRENT_SOUSCRIPTION_IDS =
  "tunnel/currentSouscriptionIds/RESET";

export const SET_CURRENT_SOUSCRIPTION_RADLAD =
  "tunnel/currentSouscriptionRadlad/SET";
export const RESET_CURRENT_SOUSCRIPTION_RADLAD =
  "tunnel/currentSouscriptionRadlad/RESET";

export const INIT_CHECKRADLAD = "tunnel/currentSouscriptionCheckRadlad/INIT";

export const SET_CURRENT_SOUSCRIPTION_CHECKRADLAD =
  "tunnel/currentSouscriptionCheckRadlad/SET";
export const RESET_CURRENT_SOUSCRIPTION_CHECKRADLAD =
  "tunnel/currentSouscriptionCheckRadlad/RESET";

export const SET_CURRENT_SOUSCRIPTION_RISQUE_ID =
  "tunnel/currentSouscriptionRisqueId/SET";

export const SET_CURRENT_SOUSCRIPTION_SIMULATION_ID =
  "tunnel/currentSouscriptionSimulationId/SET";

export const SET_CURRENT_SOUSCRIPTION_LEAD_ID =
  "tunnel/currentSouscriptionLeadId/SET";

export const GO_BACK = {
  REQUEST: "tunnel/goBack/REQUEST",
  SUCCESS: "tunnel/goBack/SUCCESS",
  FAILURE: "tunnel/goBack/FAILURE"
};

export const GO_BACK_FROM_START_TUNNEL = "tunnel/goBackFromStartTunnel";

export const RESET_TUNNEL = "tunnel/RESET";
export const RESET_FULL_TUNNEL_VARIABLES = "tunnel/FULL_RESET";

export const SHOW_GO_BACK = "tunnel/showGoBack";

export const NAVIGATE_INTO = {
  REQUEST: "tunnel/navigateInto/REQUEST",
  SUCCESS: "tunnel/navigateInto/SUCCESS"
};

export const RESET_GO_BACK_BOOL = "tunnel/RESET_GO_BACK_BOOL";

export const REMOVE_LAST_PARCOURS = "tunnel/REMOVE_LAST_PARCOURS";

export const SET_ASSURANCE_TYPE = "tunnel/SET_ASSURANCE_TYPE";

export const BIND_SESSION_STORAGE_TO_STORE =
  "tunnel/BIND_SESSION_STORAGE_TO_STORE";

export const setIsTunnel = isTunnel => {
  return {
    type: SET_IS_TUNNEL.REQUEST,
    isTunnel
  };
};

export const setTunnelStep = currentStepId => {
  return {
    type: SET_TUNNEL_CURRENT_STEP.REQUEST,
    currentStepId
  };
};
/*
export const resetSaveTunnelAuto = () => {
  return {
    type: RESET_NEW_SAVE_TUNNEL_AUTO
  };
};*/

// Set current souscription
export const setCurrentSouscriptionIds = ids => {
  return {
    type: SET_CURRENT_SOUSCRIPTION_IDS,
    ids
  };
};

// Set radlad documents
export const setRadladDocuments = radlad => {
  return {
    type: SET_CURRENT_SOUSCRIPTION_RADLAD,
    payload: radlad
  };
};

// Init check radlad
export const initCheckRadlad = checkRadlad => {
  return {
    type: INIT_CHECKRADLAD,
    checkRadlad
  };
};

// Set check radlad documents
export const setCheckRadladDocuments = checkRadlad => {
  return {
    type: SET_CURRENT_SOUSCRIPTION_CHECKRADLAD,
    checkRadlad
  };
};

export const setCurrentSouscriptionRisqueId = risqueId => {
  return {
    type: SET_CURRENT_SOUSCRIPTION_RISQUE_ID,
    risqueId
  };
};

export const setCurrentSouscriptionSimulationId = simulationId => {
  return {
    type: SET_CURRENT_SOUSCRIPTION_SIMULATION_ID,
    simulationId
  };
};

export const setCurrentSouscriptionLeadId = leadId => {
  return {
    type: SET_CURRENT_SOUSCRIPTION_LEAD_ID,
    leadId
  };
};

// show Go back to component
export const showGoBackTunnel = visible => {
  return {
    type: SHOW_GO_BACK,
    visible
  };
};

// Go back to component
export const goBackTunnel = assuranceType => {
  return {
    type: GO_BACK.REQUEST,
    assuranceType
  };
};

export const goBackFromStartTunnel = assuranceType => {
  return {
    type: GO_BACK_FROM_START_TUNNEL,
    assuranceType
  };
};

// Go back to component and reset
export const resetTunnel = () => {
  return {
    type: RESET_TUNNEL
  };
};

// Go back to component and reset
export const resetFullTunnelVariables = () => {
  return {
    type: RESET_FULL_TUNNEL_VARIABLES
  };
};

// Remove last parcours item
export const removeLastParcours = () => {
  return {
    type: REMOVE_LAST_PARCOURS
  };
};

export const resetGoBackBool = () => {
  return {
    type: RESET_GO_BACK_BOOL
  };
};

// Parcours utlisateur
export const navigateInto = (sectionName, assuranceType) => {
  return {
    type: NAVIGATE_INTO.REQUEST,
    sectionName,
    assuranceType
  };
};

export const setAssuranceType = assuranceType => {
  return {
    type: SET_ASSURANCE_TYPE,
    assuranceType
  };
};

export const bindSessionStorageToStore = isOnTunnel => {
  return {
    type: BIND_SESSION_STORAGE_TO_STORE,
    isOnTunnel
  };
};

export const setCurrentInputProcess = (name, pageId) => {
  return {
    type: SET_CURRENT_INPUT_PROCESS,
    name,
    pageId
  };
};

export const removeCurrentInputProcess = () => {
  return {
    type: RESET_CURRENT_INPUT_PROCESS
  };
};
