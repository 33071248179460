import React, { Component } from "react";
import styled, { css } from "styled-components";
import breakpoint from "styled-components-breakpoint";
import { Cross } from "styled-icons/icomoon/Cross";

const colorEnv = env => {
  let style = "";
  switch (env) {
    case "Demo":
      style = "mediumseagreen";
      break;
    case "Recette":
      style = "mediumpurple";
      break;
    case "Preproduction":
      style = "cornflowerblue";
      break;
    default:
      style = "red";
      break;
  }
  return css`
    ${style};
  `;
};

const StyledBarEnv = styled.div`
  background-color: ${props => colorEnv(props.baloonEnv)};
  box-sizing: border-box;
  font-family: ${props => props.theme.fonts.main.name};
  font-weight: ${props => props.theme.fonts.main.variants.default};
  left: 0;
  padding: 8px 0;
  position: fixed;
  width: 100%;
  z-index: 1111111;
  strong {
    font-weight: ${props => props.theme.fonts.main.variants.bold};
  }
  div {
    box-sizing: border-box;
    color: ${props => props.theme.colors.themeColors.white};
    display: block;
    font-size: 1.4rem;
    padding: 0 40px 0 20px;
    text-align: center;
  }
  svg {
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
  ${breakpoint("mobile", "tablet")`
    div {
      text-align: left;
    }
  `};
`;

class BarEnv extends Component {
  constructor(props) {
    super(props);
    this.state = {
      baloonEnv: ""
    };
    this.onCloseEnvBar = this.onCloseEnvBar.bind(this);
  }

  setEnvironnement() {
    let envBaloon = null;
    if (
      typeof process.env.REACT_APP_BALOON_ENV !== "undefined" &&
      process.env.REACT_APP_BALOON_ENV.toLowerCase() !== "production"
    )
      envBaloon = process.env.REACT_APP_BALOON_ENV;
    this.setState({
      baloonEnv: envBaloon
    });
  }

  componentDidMount() {
    this.setEnvironnement();
  }

  onCloseEnvBar(e) {
    e.preventDefault();
    this.setState({
      baloonEnv: null
    });
  }

  render() {
    const { baloonEnv } = this.state;
    if (baloonEnv !== null)
      return (
        <StyledBarEnv baloonEnv={baloonEnv} className="bar-env">
          <div>
            {/* {t('T.U')} */}
            Vous êtes dans l'environnement de <strong>{baloonEnv}</strong>
            <Cross size={14} onClick={e => this.onCloseEnvBar(e)} />
          </div>
        </StyledBarEnv>
      );
    return null;
  }
}

export default BarEnv;
