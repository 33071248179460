import createSagaMiddleware from "redux-saga";
import { all } from "redux-saga/effects";

import informationSaga from "./information/saga";
import gestionnaireSaga from "./user-baloonpro/saga";
import mainMenuSaga from "./main-menu/saga";
import modalSaga from "./modal/saga";
import tunnelSaga from "./tunnel/tunnel/saga";
import opportuniteProspectionSaga from "./opportunite-prospection/saga";
import uploadDocumentSaga from "./tunnel/common/upload-document/saga";
import inputProcessSaga from "./tunnel/common/input-process/saga";
import referenceListSaga from "./reference-list/saga";
import tarifSaga from "./tunnel/common/tarif/saga";
import clientSaga from "./client/saga";
import dateEffetContratSaga from "./tunnel/common/date-effet-contrat/saga";
import recapitulatifSaga from "./tunnel/common/recapitulatif/saga";
import creationContratSaga from "./tunnel/common/creation-contrat/saga";
import paiementSaga from "./tunnel/common/paiement/saga";
import verifierTelephoneSaga from "./verifier-telephone/saga";
import signatureSaga from "./tunnel/common/signature/saga";
import articleSaga from "./article/saga";
import loggerSaga from "./logger/saga";
import parrainageSaga from "./parrainage/saga";
import reprisePaiementSaga from "./reprise-paiement/saga";
// Santé
import tranchesAgesSaga from "./tunnel/sante/tranches-ages/saga";
import leadSaga from "./tunnel/sante/lead/saga";
import reseauxSoinsSaga from "./tunnel/sante/reseaux-soins/saga";
import tauxCouvertureSaga from "./tunnel/sante/taux-couverture/saga";
import assuresSaga from "./tunnel/sante/assures/saga";
import questionsMedicalesSaga from "./tunnel/sante/questions-medicales/saga";

export const sagaMiddleware = createSagaMiddleware();

export default function* mainSaga() {
  yield all([
    informationSaga,
    gestionnaireSaga,
    mainMenuSaga,
    modalSaga,
    tunnelSaga,
    opportuniteProspectionSaga,
    uploadDocumentSaga,
    inputProcessSaga,
    referenceListSaga,
    tarifSaga,
    clientSaga,
    dateEffetContratSaga,
    recapitulatifSaga,
    creationContratSaga,
    paiementSaga,
    signatureSaga,
    loggerSaga,
    tranchesAgesSaga,
    leadSaga,
    reseauxSoinsSaga,
    tauxCouvertureSaga,
    assuresSaga,
    verifierTelephoneSaga,
    articleSaga,
    questionsMedicalesSaga,
    parrainageSaga,
    reprisePaiementSaga
  ]);
}
