import React, { Component } from "react";
import { withRouter } from "react-router";
import { withNamespaces } from "react-i18next";
import { compose } from "redux";
import { connect } from "react-redux";
// Import style
import { StyledLoader } from "./style";

// Import icon
import BaloonO from "../icons/baloon-o";
import LoaderGenerique from "../../assets/loading.svg";

import {
  getTheme,
  isInformationsLoading
} from "../../store/information/selectors";

const mapStateToProps = state => ({
  charteGraphique: getTheme(state),
  isLoadingInfo: isInformationsLoading(state)
});

class LoaderUpload extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { charteGraphique } = this.props;
    const isMarqueBlanche = charteGraphique !== "baloon";

    return (
      <StyledLoader className="loader" isMB={isMarqueBlanche}>
        {isMarqueBlanche && <img src={LoaderGenerique} />}
        {!isMarqueBlanche && <BaloonO />}
      </StyledLoader>
    );
  }
}

export default compose(
  withNamespaces("translations"),
  withRouter,
  connect(
    mapStateToProps,
    null
  )
)(LoaderUpload);
