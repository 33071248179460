import React from "react";
import { StyledCategoryHeader } from "./style";
import { StyledSearchHeader } from "./style";
import WrapperContent from "../../components/wrapper-content";

const PageHeader = props => {
  const { children, page } = props;
  if (page === "category") {
    return (
      <StyledCategoryHeader>
        <WrapperContent>{children}</WrapperContent>
      </StyledCategoryHeader>
    );
  } else if (page === "search") {
    return (
      <StyledSearchHeader>
        <WrapperContent>{children}</WrapperContent>
      </StyledSearchHeader>
    );
  }
  return null;
};

export default PageHeader;
