import React, { Component } from "react";
import Cookies from "js-cookie";
import connect from "react-redux/es/connect/connect";

import { withNamespaces } from "react-i18next";
import { bindActionCreators, compose } from "redux";
import { forgotClient } from "../../../store/client/actions";
import { getMainToken } from "../../../store/information/selectors";
import {
  extractUserInfos,
  isAnonyme,
  setToken
} from "../../../helpers/data/cookie-user";

const mapStateToProps = state => ({
  dataToken: extractUserInfos(state),
  isNotConnected: isAnonyme(state),
  mainToken: getMainToken(state)
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      forgotClient,
      setToken
    },
    dispatch
  )
});

class DeconnexionMenuItem extends Component {
  constructor(props) {
    super(props);
    this.onClickDeconnexion = this.onClickDeconnexion.bind(this);
  }
  onClickDeconnexion(e) {
    const { dataToken } = this.props;
    try {
      if (dataToken !== null) {
        this.props.actions.forgotClient(
          typeof dataToken !== "undefined"
            ? dataToken.telephone
            : "aucun telephone"
        );
      }
      Cookies.remove("TOKEN_USER");
    } catch (e) {}
  }
  render() {
    const { dataToken, t, isNotConnected } = this.props;
    if (isNotConnected) return t("shared.connexion");
    let nom = null;
    if (dataToken !== null && typeof dataToken !== "undefined") {
      nom = dataToken.nom;
    }
    return (
      <div onClick={this.onClickDeconnexion}>
        {nom + " - " + t("shared.deconnexion")}
      </div>
    );
  }
}

export default compose(
  withNamespaces("translations"),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(DeconnexionMenuItem);
