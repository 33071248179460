// Is loading user-baloonpro
import { isUserBaloonPro as isUserBaloonProInsideCookie } from "../../helpers/data/cookie-user";
import { getRouterSearch } from "../router/selectors";

export const isUserBaloonProLoading = state => state.userBaloonPro.loading;

// Is success user-baloonpro
export const isUserBaloonProSuccess = state => state.userBaloonPro.success;

// Is failure user-baloonpro
export const isUserBaloonProError = state => state.userBaloonPro.error;

// Get clientId
export const getClientId = state => state.userBaloonPro.clientId;

export const isUserBaloonPro = state => {
  const pattern = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
  let is = false;
  let guid = null;
  if (getRouterSearch(state) !== null) {
    const routerSearch = getRouterSearch(state);
    if (
      typeof routerSearch.guid !== "undefined" &&
      routerSearch.guid !== "" &&
      pattern.test(routerSearch.guid)
    ) {
      is = true;
      guid = routerSearch.guid;
    }
  } else {
    if (isUserBaloonProInsideCookie()) {
      is = true;
      guid = null;
    }
  }
  return {
    is,
    guid
  };
};
