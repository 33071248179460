import axios from "axios";
import API from "../../../api";

export async function get(mainToken, simulationId) {
  return new Promise(resolve => {
    const url = API.tunnel.sante.assures.main;
    const params = {
      simulationId: simulationId
    };
    const request = axios.get(url, {
      params,
      headers: {
        Authorization: `bearer ${mainToken}`
      }
    });
    return request
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        resolve({ error: true, res: err });
      });
  });
}

export async function post(mainToken, data) {
  const dataPost = JSON.stringify(data);
  return new Promise(resolve => {
    const url = API.tunnel.sante.assures.main;
    const request = axios.post(url, dataPost, {
      headers: {
        Authorization: `bearer ${mainToken}`,
        "Content-Type": "application/json"
      }
    });
    return request
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        resolve({ error: true, res: err });
      });
  });
}
