import React, { Component } from "react";
import { withNamespaces } from "react-i18next";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { compose } from "redux";
import { Spring, animated } from "react-spring";
import Measure from "react-measure";
import { withRouter } from "react-router";

// Import components
import { Accordion } from "../../../../accordion";
import ItemNavText from "../item-nav-text";
import ChildList from "./components/child-list";
import Paragraph from "../../../../paragraph";
import LinkButton from "../../../../link-button";
import { activeId } from "../..";

// Store
import { openCloseModalEtreRappelle } from "../../../../../store/modal/actions";
import { openCloseMainMenu } from "../../../../../store/main-menu/actions";
import { getMainMenuState } from "../../../../../store/main-menu/selectors";
import {
  getContactInfos,
  getTheme
} from "../../../../../store/information/selectors";

// Helpers
import { isAnonyme } from "../../../../../helpers/data/cookie-user";
// Import data
import { data } from "../../../data-component";

// Import style
import { StyledMenuCorporate } from "./style";

const mapStateToProps = state => ({
  mainMenuState: getMainMenuState(state),
  isNotConnected: isAnonyme(state),
  contactInfos: getContactInfos(state),
  charteGraphique: getTheme(state)
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      openCloseModalEtreRappelle,
      openCloseMainMenu
    },
    dispatch
  )
});

const Expandable = ({
  children,
  urlEspaceClient,
  className,
  config,
  element,
  expanded,
  charteGraphique,
  ...rest
}) => {
  const Component = element || "div";
  if (charteGraphique !== "axa") {
    return (
      <Measure offset margin>
        {({ measureRef, contentRect: { offset, margin } }) => {
          return (
            <Spring
              native
              from={{ height: 0 }}
              to={{
                height: expanded
                  ? typeof offset.height === "undefined"
                    ? "auto"
                    : offset.height + margin.bottom + margin.top
                  : 0
              }}
            >
              {style => (
                <animated.div
                  style={{ overflow: "hidden", ...style }}
                  {...rest}
                >
                  <Component style={{ overflow: "auto" }} ref={measureRef}>
                    {children}
                  </Component>
                </animated.div>
              )}
            </Spring>
          );
        }}
      </Measure>
    );
  } else {
    return null;
  }
};

class MenuCorporate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menuItems: []
    };
    this.openModalEtreRappelle = this.openModalEtreRappelle.bind(this);
    this.onClickPhoneNumber = this.onClickPhoneNumber.bind(this);
    this.onGoToAideCategory = this.onGoToAideCategory.bind(this);
  }

  componentDidMount() {
    const { t, contactInfos, urlEspaceClient, helpArticles } = this.props;
    let menuItems = null;
    // if (charteGraphique !== "axa") {
    menuItems = data.content(
      t,
      contactInfos,
      urlEspaceClient,
      this.onClickPhoneNumber,
      helpArticles
    );
    // }
    this.setState({
      menuItems
    });
  }

  onGoToAideCategory = (e, url) => {
    e.preventDefault();
    const { history, actions } = this.props;
    history.push(url);
    actions.openCloseMainMenu(false, -1);
  };

  onClickPhoneNumber = (e, phone) => {
    e.preventDefault();
    window.open(phone);
  };

  openModalEtreRappelle(e) {
    const { actions } = this.props;
    e.preventDefault();
    actions.openCloseModalEtreRappelle(true);
  }

  render() {
    const { t, mainMenuState } = this.props;
    const { menuItems } = this.state;
    const { openedName } = mainMenuState;
    const isBaloon = window.location.href.includes("baloon");
    return (
      <StyledMenuCorporate>
        <Accordion
          expandedItems={activeId(openedName)}
          activeIdToOpen={activeId(openedName)}
        >
          {menuItems.map((menuItem, index) => {
            if (
              !menuItem.isDesktopOnly &&
              ((!isBaloon && menuItem.isMarqueBlanche) || isBaloon)
            ) {
              const { icon, label, child, url } = menuItem;
              if (
                typeof child !== "undefined" &&
                child.length > 0 &&
                label !== "notConnected"
              ) {
                return (
                  <Accordion.Item key={index} id={index}>
                    {({ contentProps, titleProps }) => (
                      <React.Fragment>
                        <div className="title" {...titleProps}>
                          <ItemNavText
                            label={label}
                            icon={icon}
                            url={typeof url !== "undefined" ? url : null}
                          />
                        </div>
                        <Expandable {...contentProps}>
                          <ChildList
                            child={child}
                            openModalFunc={this.openModalEtreRappelle}
                            noMargin={
                              menuItem.name === "aide" && child.length > 6
                            }
                            isAideBlock={menuItem.name === "aide"}
                          />
                          {menuItem.name === "aide" &&
                            child.length > 6 && (
                              <Paragraph noMargin className="tous-aide">
                                <LinkButton
                                  config={{
                                    type: "button",
                                    style: "noStyle"
                                  }}
                                  onClick={e =>
                                    this.onGoToAideCategory(e, "/aide")
                                  }
                                >
                                  {t("shared.tousNosArticlesDAide")}
                                </LinkButton>
                              </Paragraph>
                            )}
                        </Expandable>
                      </React.Fragment>
                    )}
                  </Accordion.Item>
                );
              } else if (label === "notConnected") {
                return false;
              } else {
                return (
                  <Accordion.Item key={index} id={index}>
                    {({ contentProps, titleProps }) => (
                      <React.Fragment>
                        <div className="title no-child" {...titleProps}>
                          <ItemNavText
                            label={label}
                            icon={icon}
                            url={typeof url !== "undefined" ? url : null}
                          />
                        </div>
                      </React.Fragment>
                    )}
                  </Accordion.Item>
                );
              }
            }
            return false;
          })}
        </Accordion>
      </StyledMenuCorporate>
    );
  }
}

export default compose(
  withNamespaces("translations"),
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(MenuCorporate);
