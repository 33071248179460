import React, { Component } from "react";
import Cookies from "js-cookie";
import connect from "react-redux/es/connect/connect";
import { withNamespaces } from "react-i18next";
import { bindActionCreators, compose } from "redux";
import Title from "../../title";
import WrapperViewResponsive from "../../wrapper-view-responsive";
import BurgerMenuIcon from "../../burger-menu-icon";
import LinkButton from "../../link-button";
import Logo from "../../logo";
import { User, Newspaper, CreditCard } from "styled-icons/fa-regular";
import { Phone, Question, InfoCircle } from "styled-icons/fa-solid";
import Deconnexion from "../../deconnexion/index";
import ErrorGlobal from "../../error-global/index";

// Helpers
import {
  isAnonyme,
  isClient,
  extractUserInfos
} from "../../../helpers/data/cookie-user";

// Store
import { getMainMenuState } from "../../../store/main-menu/selectors";
import {
  getUrlEspaceClient,
  getTheme
} from "../../../store/information/selectors";
import {
  isMainMenuVisible as isMainMenuVisibleSelector,
  isHeaderOnyLogo as isHeaderOnyLogoSelector,
  getHeaderOnlyLogoURL
} from "../../../store/layouts-show-hide/selectors";
import { openCloseMainMenu } from "../../../store/main-menu/actions";
import { openCloseModalEtreRappelle } from "../../../store/modal/actions";
import { forgotClient } from "../../../store/client/actions";
import { addEventGoogleDemandeContact } from "../../../helpers/tags/tagsHelper";

const mapStateToProps = state => ({
  mainMenuState: getMainMenuState(state),
  urlEspaceClient: getUrlEspaceClient(state),
  isMainMenuVisible: isMainMenuVisibleSelector(state),
  isHeaderOnyLogo: isHeaderOnyLogoSelector(state),
  headerOnlyLogoURL: getHeaderOnlyLogoURL(state),
  isNotConnected: isAnonyme(state),
  isClientConnected: isClient(state),
  dataToken: extractUserInfos(state),
  charteGraphique: getTheme(state)
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      openCloseModalEtreRappelle,
      openCloseMainMenu,
      forgotClient
    },
    dispatch
  )
});

class HeaderCorporate extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.onClickDeconnexion = this.onClickDeconnexion.bind(this);
    this.state = {
      isNotConnected: this.props.isNotConnected,
      isClient: this.props.isClient,
      dataToken: this.props.dataToken
    };
  }

  handleClick(name, e) {
    e.preventDefault();
    if (name === "contact") {
      addEventGoogleDemandeContact();
      this.props.actions.openCloseModalEtreRappelle(true);
    }
    if (name === "quiSommesNous") {
      this.props.history.push("/aide/qui-sommes-nous");
    }
    if (name === "devis") {
      this.props.history.push("/assurance-automobile");
    } else if (name === "aide") {
      this.props.history.push("/aides/selection");
    }
  }

  componentDidUpdate(prevProps) {
    const { isNotConnected, isClient, dataToken } = this.props;
    if (
      isNotConnected !== prevProps.isNotConnected ||
      isClient !== prevProps.isClient ||
      dataToken !== prevProps.dataToken
    ) {
      this.setState({
        isNotConnected: isNotConnected,
        dataToken: dataToken,
        isClient: isClient
      });
    }
  }

  onClickDeconnexion(e) {
    const { dataToken } = this.props;
    if (dataToken !== null && typeof dataToken !== "undefined") {
      this.props.actions.forgotClient(dataToken.telephone);
    }
    Cookies.remove("TOKEN_USER");
    this.props.history.push("/");
    window.location.reload();
  }

  render() {
    const {
      t,
      onlyLogo,
      isMainMenuVisible,
      isHeaderOnyLogo,
      headerOnlyLogoURL,
      charteGraphique
    } = this.props;
    const isMarqueBlanche =
      charteGraphique !== "baloon" || !window.location.href.includes("baloon");
    const { isNotConnected, dataToken } = this.state;
    const showOnlyLogo = !onlyLogo && !isHeaderOnyLogo;
    let nom = null;

    if (dataToken !== null && typeof dataToken !== "undefined") {
      nom = dataToken.nom;
    }
    try {
      const headerMobileCorp = (
        <React.Fragment>
          {((!onlyLogo && isMainMenuVisible) || !isHeaderOnyLogo) && (
            <BurgerMenuIcon />
          )}
          <div
            className={`${onlyLogo ? "only-logo-wrapper" : ""} ${
              isHeaderOnyLogo ? "alone" : ""
            }`}
          >
            <Title titleType={3} noMargin textAlign="center">
              <Logo
                onlyLogo={onlyLogo}
                url={headerOnlyLogoURL ? headerOnlyLogoURL : null}
              />
            </Title>
          </div>

          {showOnlyLogo && (
            <div className="mobileMenu">
              <LinkButton
                config={{
                  type: "link",
                  style: "noStyle"
                }}
                to={this.props.urlEspaceClient}
              >
                <User
                  size={20}
                  className={isNotConnected ? "" : "UserConnected"}
                  color={isNotConnected ? "" : "rgb(24, 119, 72)"}
                />
              </LinkButton>
            </div>
          )}
        </React.Fragment>
      );
      let headerDesktopCorp = null;
      headerDesktopCorp = (
        <React.Fragment>
          <div
            className={`${onlyLogo ? "only-logo-wrapper" : ""}${
              isHeaderOnyLogo ? " alone" : ""
            }`}
          >
            <Title titleType={3} noMargin textAlign="center">
              <Logo
                onlyLogo={onlyLogo}
                url={headerOnlyLogoURL ? headerOnlyLogoURL : null}
              />
            </Title>
          </div>
          {showOnlyLogo && (
            <div className="desktopMenu">
              {!isMarqueBlanche && (
                <>
                  <div
                    className={
                      this.props.mainMenuState.openedName === "aide"
                        ? "current"
                        : ""
                    }
                  >
                    <LinkButton
                      config={{
                        type: "button",
                        style: "noStyle"
                      }}
                      onClick={e => this.handleClick("quiSommesNous", e)}
                    >
                      <InfoCircle size={15} />
                      <span>{t("menuPrincipal.aide.QuiSommesNous")}</span>
                    </LinkButton>
                  </div>
                  <div
                    className={
                      this.props.mainMenuState.openedName === "aide"
                        ? "current"
                        : ""
                    }
                  >
                    <LinkButton
                      config={{
                        type: "button",
                        style: "noStyle"
                      }}
                      onClick={e => this.handleClick("aide", e)}
                    >
                      <Question size={15} />
                      <span>{t("menuPrincipal.aide.title")}</span>
                    </LinkButton>
                  </div>
                </>
              )}
              {!isMarqueBlanche && (
                <div onClick={() => this.props.actions.openCloseMainMenu()}>
                  <LinkButton
                    config={{
                      type: "link",
                      style: "noStyle"
                    }}
                    to="/actualite"
                  >
                    <span>
                      <Newspaper size={15} />
                      <span>{t("shared.actualite.short")}</span>
                    </span>
                  </LinkButton>
                </div>
              )}
              <div
                className={
                  this.props.mainMenuState.openedName === "contact"
                    ? "current"
                    : ""
                }
              >
                <LinkButton
                  config={{
                    type: "button",
                    style: "noStyle"
                  }}
                  onClick={e => this.handleClick("contact", e)}
                >
                  <Phone size={15} />
                  <span>{t("menuPrincipal.contact.title")}</span>
                </LinkButton>
              </div>

              {/* Devis en ligne */}
              <div
                className={
                  this.props.mainMenuState.openedName === "contact"
                    ? "current"
                    : ""
                }
              >
                <LinkButton
                  config={{
                    type: "button",
                    style: "noStyle"
                  }}
                  onClick={e => this.handleClick("devis", e)}
                >
                  <CreditCard size={15} />
                  <span>{t("menuPrincipal.devis.title")}</span>
                </LinkButton>
              </div>

              <div className="espacePerso">
                <LinkButton
                  altStyle
                  config={{
                    type: "link",
                    style: "plain"
                  }}
                  to={this.props.urlEspaceClient}
                >
                  <User size={20} />
                  {isNotConnected && t("shared.espacePersonnel")}
                  {!isNotConnected && nom}
                </LinkButton>
                {!isNotConnected && (
                  <>
                    <Deconnexion isOnHome={true} history={this.props.history} />
                  </>
                )}
              </div>
            </div>
          )}
        </React.Fragment>
      );

      const headerCorp = {
        mobile: headerMobileCorp,
        desktop:
          headerDesktopCorp === null ? <ErrorGlobal /> : headerDesktopCorp
      };

      return <WrapperViewResponsive componentRender={headerCorp} />;
    } catch (e) {
      this.props.actions.postLog({
        message: "erreur dans le header corporate",
        info: "header corporatie generation du render ::\n" + e,
        level: "error",
        error: e
      });
    }
  }
}

export default compose(
  withNamespaces("translations"),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(HeaderCorporate);
