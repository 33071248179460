// Is loading lead
export const isLeadLoading = state => state.tunnel.sante.lead.loading;

// Is success lead
export const isLeadSuccess = state => state.tunnel.sante.lead.success;

// Is failure lead
export const isLeadError = state => state.tunnel.sante.lead.error;

// Get tranches ages
export const getLead = state => {
  return {
    clientId: state.tunnel.sante.lead.clientId,
    emailClient: state.tunnel.sante.lead.emailClient,
    formuleItcRefId: state.tunnel.sante.lead.formuleItcRefId,
    id: state.tunnel.sante.lead.id,
    nbAdultes: state.tunnel.sante.lead.nbAdultes,
    nbJeunes: state.tunnel.sante.lead.nbJeunes,
    nbSeniors: state.tunnel.sante.lead.nbSeniors,
    pathologieClientCode: state.tunnel.sante.lead.pathologieClientCode,
    telephoneClient: state.tunnel.sante.lead.telephoneClient,
    typeReseauSoinsRefId: state.tunnel.sante.lead.typeReseauSoinsRefId
  };
};

// Get telephone client
export const getTelephoneClient = state =>
  state.tunnel.sante.lead.telephoneClient;

// Get email client
export const getEmailClient = state => state.tunnel.sante.lead.emailClient;

// Get clientId
export const getClientId = state => state.tunnel.sante.lead.clientId;

// Get typeReseauSoinsRefId
export const getTypeReseauSoinsRefId = state =>
  state.tunnel.sante.lead.typeReseauSoinsRefId;

// Get typeReseauSoinsRefId
export const getFormuleItcRefId = state =>
  state.tunnel.sante.lead.formuleItcRefId;

// Get nb Enfants
export const getNbEnfants = state => state.tunnel.sante.lead.nbJeunes;

// Get nb Adultes
export const getNbAdultes = state => state.tunnel.sante.lead.nbAdultes;

// Get nb Seniors
export const getNbSeniors = state => state.tunnel.sante.lead.nbSeniors;

// Get lead id
export const getLeadId = state => state.tunnel.sante.lead.id;

// Is reprise loading
export const isRepriseLoading = state =>
  state.tunnel.sante.lead.reprise.loading;

// Is reprise loading
export const isRepriseSuccess = state =>
  state.tunnel.sante.lead.reprise.success;

// Is reprise loading
export const isRepriseError = state => state.tunnel.sante.lead.reprise.error;
