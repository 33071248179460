import styled from "styled-components";
import breakpoint from "styled-components-breakpoint";
import LinkButton from "../link-button";

export const StyledLogo = styled(LinkButton)`
  font-size: initial;
  line-height: 1;
  ${breakpoint("desktop")`
    cursor: pointer;
  `};
`;
