import React from "react";
import LinkButton from "../../components/link-button";
import WhatsAppIcon from "../../assets/whatsapp.svg";
import InstaLogo from "../../assets/instagram.png";
import DeconnexionMenuItem from "../deconnexion/menu-component/index";
import {
  ArrowRight,
  Car,
  MapMarker,
  Medkit,
  Phone,
  Plane,
  Question,
  User,
  CreditCard
} from "styled-icons/fa-solid";
import { Envelope, Newspaper } from "styled-icons/fa-regular";
import IconBaloonO from "../../components/icons/baloon-o";
const textWhatsApp = "Bonjour Baloon";
const urlInstagram = "https://www.instagram.com/baloonassurance/";

export const data = {
  content: (
    t,
    contactInfos,
    urlEspaceClient,
    onClickPhoneNumber,
    helpArticles,
    isNotConnected
  ) => {
    if (contactInfos.whatsapp === null) {
      contactInfos.whatsapp = "";
    }
    if (contactInfos.telephoneFixe === null) {
      contactInfos.telephoneFixe = "";
    }
    const isBaloon = window.location.href.includes("baloon");
    const dataAideConstruct = () => {
      const data = {
        name: "aide",
        isMarqueBlanche: false,
        splitDesktop: 2,
        icon: <Question size={16} />,
        label: t("menuPrincipal.aide.title"),
        child: [
          {
            icon: <ArrowRight size={16} />,
            label: t("menuPrincipal.aide.label1"),
            url: "/aide/qui-sommes-nous"
          },
          {
            icon: <ArrowRight size={16} />,
            label: t("menuPrincipal.aide.label2"),
            url: "/aide/comment-acheter-mon-assurance-chez-baloon"
          },
          {
            icon: <ArrowRight size={16} />,
            label: t("menuPrincipal.aide.label3"),
            url: "/aide/comprendre-mon-contrat-d-assurance"
          },
          {
            icon: <ArrowRight size={16} />,
            label: t("menuPrincipal.aide.label4"),
            url: "/aide/renouveler-ou-modifier-mon-assurance"
          },
          {
            icon: <ArrowRight size={16} />,
            label: t("menuPrincipal.aide.label5"),
            url: "/aide/que-faire-en-cas-d-accident"
          },
          {
            icon: <ArrowRight size={16} />,
            label: t("menuPrincipal.aide.label6"),
            url: "/aide/les-formules-et-garanties"
          }
        ]
      };
      if (typeof helpArticles !== "undefined" && isBaloon) {
        helpArticles.map(article => {
          data.child.push({
            icon: <ArrowRight size={16} />,
            label: article.title,
            url: `/aide/${article.slug}`
          });
          return false;
        });
      }
      return data;
    };

    return [
      {
        name: "nos-assurances",
        isMarqueBlanche: false,
        splitDesktop: 1,
        icon: <IconBaloonO />,
        label: t("menuPrincipal.nosAssurances.title"),
        child: [
          {
            icon: <Car size={16} />,
            label: `${t("produit.assurance")} ${t("produit.automobile")}`,
            url: "/assurance/automobile"
          },
          {
            icon: <Medkit size={16} />,
            label: `${t("produit.assurance")} ${t("produit.sante")}`,
            url: "/assurance/sante"
          },
          {
            icon: <Plane size={16} />,
            label: `${t("produit.assurance")} ${t("produit.voyage")}`,
            url: window.location.host.includes("ci")
              ? `${process.env.REACT_APP_VOYAGE_URL}`
              : "/assurance/voyage"
          }
        ]
      },
      { ...dataAideConstruct() },
      {
        name: "actualite",
        isMarqueBlanche: false,
        isMobileOnly: true,
        icon: <Newspaper size={16} />,
        label: t("shared.actualite.long"),
        url: "/actualite"
      },
      {
        name: "contact",
        isMarqueBlanche: true,
        splitDesktop: [3, 1],
        icon: <Phone size={16} />,
        label: t("menuPrincipal.contact.title"),
        child: [
          {
            icon: <Phone size={16} />,
            label: (
              <span className="join-us-by-phone">
                <LinkButton
                  onClick={e =>
                    onClickPhoneNumber(
                      e,
                      `tel:${
                        contactInfos.telephoneFixe !== null
                          ? contactInfos.telephoneFixe.value
                          : "" || ""
                      }`
                    )
                  }
                  config={{ type: "button", style: "noStyle" }}
                >
                  {contactInfos.telephoneFixe !== null
                    ? contactInfos.telephoneFixe.label
                    : "" || ""}
                </LinkButton>
              </span>
            )
          },
          {
            icon: <Envelope size={16} />,
            label: contactInfos.email,
            url: {
              spec: `mailto:${contactInfos.email}`
            }
          },
          {
            icon: <MapMarker size={16} />,
            label: contactInfos.adresse
          },
          {
            icon: <Phone size={16} />,
            label: t("contact.etreAppele"),
            openModal: true
          },
          {
            icon: <img alt="" src={WhatsAppIcon} style={{ width: "32px" }} />,
            label: t("contact.mobile.whatsapp"),
            openModal: true,
            isWhatsApp: true,
            url:
              "https://wa.me/" +
              contactInfos.whatsapp.label +
              "?text=" +
              textWhatsApp
          },
          {
            icon: (
              <img
                alt=""
                src={InstaLogo}
                style={{ width: "28px", marginRight: "4px" }}
              />
            ),
            label: t("contact.mobile.instagram"),
            openModal: true,
            isWhatsApp: true,
            url: urlInstagram
          }
        ]
      },
      {
        icon: <CreditCard size={16} />,
        label: t("menuPrincipal.devis.title"),
        url: "/assurance-automobile"
      },
      {
        icon: <User size={16} />,
        label: t("shared.espacePersonnel"),
        url: {
          spec: urlEspaceClient
        }
      },

      {
        isMarqueBlanche: true,
        icon: <IconBaloonO size={16} />,
        label: t("global.home"),
        url: "/"
      },
      {
        isMarqueBlanche: true,
        icon: <User size={16} />,
        label: isNotConnected ? "notConnected" : <DeconnexionMenuItem />,
        url: {
          spec: "/"
        }
      }
    ];
  }
};
