// Import configs
import colors from "./colors";
import breakpoints from "./breakpoints";
import gutters from "./gutters";
import fonts from "./fonts";
import sizes from "./sizes";
import layouts from "./layouts";
import icons from "./icons";

const theme = {
  colors,
  breakpoints,
  gutters,
  fonts,
  sizes,
  icons,
  layouts: layouts()
};

export default theme;
