import { getTunnelSante_choixQuestionsMedicales } from "../../../../helpers/data/cookie-user";

// Get cookie getTunnelSante_choixQuestionsMedicales
export const getChoixQuestionsMedicalesCookie = () =>
  getTunnelSante_choixQuestionsMedicales();

// Is loading get questions médicales
export const isGetChoixQuestionsMedicalesLoading = state =>
  state.tunnel.sante.questionsMedicales.get.loading;

// Is success get questions médicales
export const isGetChoixQuestionsMedicalesSuccess = state =>
  state.tunnel.sante.questionsMedicales.get.success;

// Is failure get questions médicales
export const isGetChoixQuestionsMedicalesError = state =>
  state.tunnel.sante.questionsMedicales.get.error;

// Is loading post questions médicales
export const isPostChoixQuestionsMedicalesLoading = state =>
  state.tunnel.sante.questionsMedicales.post.loading;

// Is success post questions médicales
export const isPostChoixQuestionsMedicalesSuccess = state =>
  state.tunnel.sante.questionsMedicales.post.success;

// Is failure post questions médicales
export const isPostChoixQuestionsMedicalesError = state =>
  state.tunnel.sante.questionsMedicales.post.error;
