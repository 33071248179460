// Get current state of opened modal etre rappellé
export const isModalEtreRappelleOpened = state =>
  state.modal.etreRappelle.isOpened;

// Get current state of opened modal CAMPAIGN
export const isModaCampaignOpened = state => state.modal.campaign.isOpened;

// Get current state of opened modal save tunnel auto
export const isModalSaveTunnelAutoOpened = state =>
  state.modal.saveTunnelAuto.isOpened;
