import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { compose } from "redux";
import { connect } from "react-redux";

// Import layout
import { StyledLayoutCorporate } from "./style";

// Import components
import Footer from "../../components/footer";

// Store
import { setIsTunnel } from "../../store/tunnel/tunnel/actions";
// import { openCloseMainMenu } from "../../store/main-menu/actions";
// import { getMainMenuState } from "../../store/main-menu/selectors";
/*
const mapStateToProps = state => ({
  mainMenuState: getMainMenuState(state),
});*/

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      setIsTunnel
      // openCloseMainMenu,
    },
    dispatch
  )
});

class LayoutCorporate extends Component {
  componentDidMount() {
    this.props.actions.setIsTunnel(false);
  }

  /*
  handleClickOutside() {
    if (this.props.mainMenuState.isOpened)
      this.props.actions.openCloseMainMenu(false, -1);
  }
  */

  render() {
    return (
      <StyledLayoutCorporate {...this.props} hasHeader>
        {this.props.children}
        <Footer />
      </StyledLayoutCorporate>
    );
  }
}

export default compose(
  connect(
    null,
    mapDispatchToProps
  )
)(LayoutCorporate);
