const formatPhoneNumber = (phoneNumber, modele, separator) => {
  const modeleLength = modele.length;
  const mask = (phone, modele) => {
    //Fonction créant un masque sur le numéro de téléphone selon un modèle fourni
    let phoneNumberMasked = [];

    for (let i = 0; i < modeleLength; i++) {
      //Pour tout les groupes de chiffres du modèle
      let charStart = 0;
      if (i !== 0) {
        for (let j = 0; j < i; j++) {
          charStart += parseInt(modele[j], 10); //On prends le premier chiffre du groupe sur le numéro de téléphone
        }
      }
      let charEnd = 0;
      for (let j = 0; j <= i; j++) {
        charEnd += parseInt(modele[j], 10); //On prends de dernier chiffre du groupe sur le numéro de téléphone
      }
      phoneNumberMasked.push(phone.slice(charStart, charEnd)); //On prends tout les chiffre entre ces deux chiffres et on les ajoute à l'array
    }
    const checkEmpty = digits => {
      //On filtre les index vides de l'array (ceux pour lesquels aucun chiffre n'a encore été tapé)
      return digits !== "";
    };
    return phoneNumberMasked.filter(checkEmpty).join(separator); //Et on transforme l'array en une string
  };
  return mask(phoneNumber, modele);
};

export default formatPhoneNumber;
