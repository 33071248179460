import styled from "styled-components";
// import breakpoint from "styled-components-breakpoint";
import LayoutCorporate from "../../layouts/corporate";

export const StyledNotFoundPage = styled(LayoutCorporate)`
  &.error {
    .wrapper-content {
      align-items: center;
      display: flex;
      flex-direction: column;
    }
    img {
      margin-bottom: 30px;
      margin-top: 30px;
    }
  }
  &:not(.error) {
    .wrapper-content {
      align-items: center;
      display: flex;
      flex-direction: column;
    }
    .svg-container {
      box-sizing: border-box;
      margin-bottom: 50px;
      max-width: 350px;
      padding: 0 30px;
      width: 100%;
      img {
        src: ${props => props.theme.icons.theme.Media404};
        width: 100%;
      }
    }
  }
  .notFound-intro {
    .linkButton {
      text-decoration: underline;
    }
  }
`;
