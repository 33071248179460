import * as a from "./actions";

const INITIAL_SIGNATURE_STATE = {
  main: {
    error: false,
    loading: false,
    success: false,
    statut: null,
    urlRedirection: null
  },
  reprise: {
    error: false,
    loading: false,
    success: false,
    contratId: null,
    versionContratId: null,
    branche: null,
    token: null
  },
  isSignature: {
    succes: false,
    loading: false,
    error: false,
    state: false
  },
  attestation: {
    succes: false,
    loading: false,
    error: false,
    state: false,
    dematerialisationAttestationPossible: false,
    lienAttestation: null
  }
};

export function signature(state = INITIAL_SIGNATURE_STATE, action) {
  switch (action.type) {
    //SIGNATURE :
    case a.SIGNATURE.REQUEST:
      return {
        ...state,
        main: {
          ...state.main,
          success: false,
          error: false,
          loading: true
        }
      };
    case a.SIGNATURE.SUCCESS:
      return {
        ...state,
        main: {
          ...state.main,
          success: true,
          error: false,
          loading: false,
          statut: action.payload.statut,
          urlRedirection: action.payload.urlRedirection
        }
      };
    case a.SIGNATURE.FAILURE:
      return {
        ...state,
        main: {
          ...state.main,
          success: false,
          error: true,
          loading: false
        }
      };
    //REPRISE SIGNATURE :
    case a.GET_REPRISE_SIGNATURE_INFORMATION.REQUEST:
      return {
        ...state,
        reprise: {
          ...state.reprise,
          success: false,
          error: false,
          loading: true
        }
      };
    case a.GET_REPRISE_SIGNATURE_INFORMATION.SUCCESS:
      return {
        ...state,
        reprise: {
          ...state.reprise,
          success: true,
          error: false,
          loading: false,
          contratId: action.payload.contratId,
          versionContratId: action.payload.versionContratId,
          branche:
            action.payload.branche.toLowerCase() === "auto"
              ? "automobile"
              : action.payload.branche,
          token: action.payload.token
        }
      };
    case a.GET_REPRISE_SIGNATURE_INFORMATION.FAILURE:
      return {
        ...state,
        reprise: {
          ...state.reprise,
          success: false,
          error: true,
          loading: false
        }
      };
    case a.IS_SIGNATURE.REQUEST:
      return {
        ...state,
        isSignature: {
          success: false,
          error: false,
          loading: true,
          state: false
        }
      };
    case a.IS_SIGNATURE.SUCCESS:
      return {
        ...state,
        isSignature: {
          success: true,
          error: false,
          loading: false,
          state: action.payload
        }
      };
    case a.IS_SIGNATURE.FAILURE:
      return {
        ...state,
        isSignature: {
          success: false,
          error: action.payload,
          loading: false,
          state: false
        }
      };

    case a.ATTESTATION.REQUEST:
      return {
        ...state,
        attestation: {
          ...state.attestation,
          success: false,
          error: false,
          loading: true,
          state: false
        }
      };
    case a.ATTESTATION.SUCCESS:
      return {
        ...state,
        attestation: {
          ...state.attestation,
          success: true,
          error: false,
          loading: false,
          dematerialisationAttestationPossible:
            action.payload.dematerialisationAttestationPossible,
          lienAttestation: action.payload.lienAttestation
        }
      };
    case a.ATTESTATION.FAILURE:
      return {
        ...state,
        attestation: {
          success: false,
          error: action.payload,
          loading: false,
          state: false
        }
      };
    default:
      return state;
  }
}

export default signature;
