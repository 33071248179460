import axios from "axios";
import API from "../../../api";

export async function get(mainToken, clientId) {
  const params = {
    clientId
  };
  return new Promise(resolve => {
    const url = API.tunnel.sante.questionsMedicales.main;
    const request = axios.get(url, {
      params,
      headers: {
        Authorization: `bearer ${mainToken}`
      }
    });

    return request
      .then(res => {
        resolve(res);
      })
      .catch(res => {
        resolve({ error: true, res: res });
      });
  });
}

export async function post(mainToken, choix, clientId) {
  const bodyFormData = new FormData();
  bodyFormData.set("value", choix);
  bodyFormData.set("clientId", clientId);
  return new Promise(resolve => {
    const url = API.tunnel.sante.questionsMedicales.main;
    const request = axios.post(url, bodyFormData, {
      headers: {
        Authorization: `bearer ${mainToken}`,
        "Content-Type": "multipart/form-data"
      }
    });
    return request
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        resolve({ error: true, res: err });
      });
  });
}
