import ReactPixel from "react-facebook-pixel";
import BingTracking from "react-tracking-bing";

export function addGoogle(googleId) {
  let isPresent = false;
  window.dataLayer.forEach(data => {
    if (data[0] === "js" || (data[0] === "config" && data[1] === googleId)) {
      isPresent = true;
      return;
    }
  });
  if (!isPresent) {
    gtag("js", new Date());
    gtag("config", googleId);
    // gtag("config", googleAnId);
  } else {
  }
}

function gtag() {
  window.dataLayer.push(arguments);
}

export function gtagEvent(id, label) {
  gtag("event", "conversion", {
    send_to: id + "/" + label
  });
}

export function addEventGoogleDemandeContact() {
  // gtag("event", "conversion", {
  //   send_to:
  //    process.env.REACT_APP_LOCAL_GOOGLE_TAG_ID + "/r4GkCOD4gu0BEPzckIoD",
  //   value: "DemandeDeContact",
  //   data: window.location.href,
  // event_callback: function(){
  //   console.log("Args :: ", arguments);
  // }
  // });
  // if (window.location.host.includes("baloon.ci")) {
  //   console.log(
  //     "Gtag Log : On ajoute l'event de convertion DEMANDE DE CONTACT a google analytics :: UA-182620750-3"
  //   );
  //   // gtag("event", "conversion", {
  //   //   send_to: "UA-182620750-3/r4GkCOD4gu0BEPzckIoD",
  //   // event_callback: function() {
  //   //   // console.log("Args :: ", arguments);
  //   // }
  //   // });
  //   gtag("event", "login", {
  //     send_to: "UA-182620750-3",
  //     event_category: "generate_lead",
  //     event_label: "Tarif",
  //     event_callback: function() {
  //       console.log("Args :: ", arguments);
  //     }
  //   });
  // }

  gtag("event", "conversion", {
    send_to: "AW-826551932/r4GkCOD4gu0BEPzckIoD"
  });

  // let idGoogle;
  // switch (window.location.host) {
  //   case "www.baloon.ga":
  //     idGoogle = "G-2Z36NQ5L3G";
  //     break;
  //   case "www.baloon.ne":
  //     idGoogle = "G-Z4JP5R5YG2";
  //     break;
  //   case "www.baloon.cm":
  //     idGoogle = "G-KMX5K1W4VR";
  //     break;
  //   case "www.baloon.ci":
  //     idGoogle = "G-XHK1E3X56B";
  //     break;
  //   case "www.baloon.sn":
  //     idGoogle = "G-0M6S38HKT7";
  //     break;
  //   case "preproduction-ci.assurance.com":
  //     idGoogle = "G-G1CTX561MC";
  //     break;
  // }
  // if (window.location.href.includes("preproduction-ci")) {
  //   idGoogle = "G-G1CTX561MC";
  // }
  // gtag("event", "conversion", {
  //   send_to: idGoogle,
  //   value: "DemandeDeContact",
  //   data: window.location.href
  // });
}

export function tagGoogleUniversalAnalytic() {
  let idGoogle;
  switch (window.location.hostname) {
    case "www.baloon.ga":
      idGoogle = "UA-182620750-1";
      break;
    case "www.baloon.ne":
      idGoogle = "UA-182620750-5";
      break;
    case "www.baloon.cm":
      idGoogle = "UA-182620750-7";
      break;
    case "www.baloon.ci":
      idGoogle = "UA-182620750-3";
      break;
    case "www.baloon.sn":
      idGoogle = "UA-182620750-6";
      break;
    // case "preproduction-ci.assurance.com":
    //   idGoogle = "G-G1CTX561MC";
    //   break;
  }
  gtag("config", idGoogle);
}

export function tagGoogleUniversalAnalyticMois() {
  gtag("config", "UA-182620750-8");
}

export function addEventTarif() {
  if (window.location.host.includes("baloon.ci")) {
    gtag("event", "conversion", {
      send_to: "UA-182620750-3/OKwgCNWv1e0BEPzckIoD"
    });
  }

  gtag("event", "conversion", {
    send_to: "AW-826551932/OKwgCNWv1e0BEPzckIoD",
    value: "Tarif"
  });

  // let idGoogle;
  // switch (window.location.hostname) {
  //   case "www.baloon.ga":
  //     idGoogle = "G-2Z36NQ5L3G";
  //     break;
  //   case "www.baloon.ne":
  //     idGoogle = "G-Z4JP5R5YG2";
  //     break;
  //   case "www.baloon.cm":
  //     idGoogle = "G-KMX5K1W4VR";
  //     break;
  //   case "www.baloon.ci":
  //     idGoogle = "G-XHK1E3X56B";
  //     break;
  //   case "www.baloon.sn":
  //     idGoogle = "G-0M6S38HKT7";
  //     break;
  //   case "preproduction-ci.assurance.com":
  //     idGoogle = "G-G1CTX561MC";
  //     break;
  // }
  // if (window.location.href.includes("preproduction-ci")) {
  //   idGoogle = "G-G1CTX561MC";
  // }
  // gtag("send", {
  //   hitType: "event",
  //   eventCategory: "User",
  //   eventAction: "generate_lead",
  //   eventLabel: "DemandeDeContact",
  //   userId: 123,
  //   value: "DemandeDeContact",
  //   send_to: idGoogle
  // });
}

export function addEventPaiement() {
  gtag("event", "conversion", {
    send_to: "AW-826551932/4-BKCIe-xO0BEPzckIoD"
  });
}

export function addEventDevisEnLigne() {
  gtag("event", "conversion", {
    send_to: "AW-826551932/AyxBCJz804gDEPzckIoD",
    value: "Demande devis en ligne"
  });
}

export function addEventGoogle(montantTotal, formule, acteurCommercial) {
  let isPresent = false;
  if (!isPresent) {
    gtag("event", "add_to_cart", {
      value: montantTotal,
      items: [
        {
          id: formule,
          location_id: acteurCommercial,
          google_business_vertical: "custom"
        }
      ]
    });
  }
}

export function addFacebookTag(facebookId) {
  const advancedMatching = { em: "noreply@baloon.africa" };
  const options = {
    autoConfig: true,
    debug: false
  };
  ReactPixel.init(facebookId, advancedMatching, options);
  ReactPixel.pageView();
}

export function addEventFacebook(montant, devise) {
  ReactPixel.track("Purchase", { value: montant, currency: devise });
}

export function addBingTag(bingId) {
  BingTracking.initialize(bingId);
  BingTracking.trackPageview();
}
