import React, { Component } from "react";
import cuid from "cuid";

// Import style
import { StyledInput } from "./style";

class Input extends Component {
  constructor(props) {
    super(props);
    this.inputElementRef = React.createRef();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.triggerFocus !== this.props.triggerFocus)
      this.triggerFocusInput();
  }

  triggerFocusInput() {
    const inputRef = this.inputElementRef.current;
    inputRef.focus();
  }

  render() {
    const {
      dispatch,
      inputLabel = null,
      inputType = "text",
      inputName,
      inputRequired = false,
      className,
      onChangeCallback,
      onChangeCallbackPhone,
      onInputKeyDown,
      inputPrefix,
      inputPostfix2,
      triggerFocus,
      inputError,
      inputClassName,
      inputValue,
      isOnWarning,
      ...rest
    } = this.props;
    const labelId = cuid();
    const Error = () => {
      if (inputError)
        return (
          <div style={{ backgroundColor: "red", color: "white" }}>
            {inputError}
          </div>
        );
      return null;
    };
    return (
      <StyledInput
        className={`${
          typeof className !== "undefined" ? className : ""
        } form-item
          ${inputError ? "error" : ""}
          ${isOnWarning && "isOnWarning"}
        `}
      >
        {inputLabel && <label htmlFor={labelId}>{inputLabel}</label>}
        <div className="input-wrapper">
          {inputPrefix && inputPrefix}
          <input
            className={`${
              typeof inputClassName !== "undefined" ? inputClassName : ""
            }`}
            ref={this.inputElementRef}
            onChange={e => onChangeCallback(e)}
            type={inputType}
            id={labelId}
            name={inputName}
            required={inputRequired}
            onKeyDown={onInputKeyDown}
            value={inputValue}
            {...rest}
          >
            {inputPostfix2 && inputPostfix2}
          </input>
        </div>
        <Error />
      </StyledInput>
    );
  }
}

export default Input;
