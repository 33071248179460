import axios from "axios";
// import qs from "qs";
import API from "../../../api";

export async function create(
  mainToken,
  assuranceType,
  documentType,
  file,
  simulationId,
  isCompleting,
  origineWebCode
) {
  return new Promise(resolve => {
    const url =
      API.tunnel.common.uploadDocument[assuranceType].create[documentType];
    const data = new FormData();
    data.append("file", file);
    const before = Date.now();
    const request = axios.post(url, data, {
      params: {
        simulationId: simulationId,
        completerEntite: isCompleting,
        origineWebCode: origineWebCode
      },
      headers: {
        Authorization: `bearer ${mainToken}`,
        "Content-Type": "multipart/form-data"
      }
    });
    return request
      .then(res => {
        res.duration = (Date.now() - before) / 1000;
        resolve(res);
      })
      .catch(err => {
        resolve({ error: true, res: err });
      });
  });
}

export async function need(
  mainToken,
  assuranceType,
  documentType,
  simulationId
) {
  return new Promise(resolve => {
    const url =
      API.tunnel.common.uploadDocument[assuranceType].need[documentType];
    const request = axios.get(url, {
      params: {
        simulationId: simulationId
      },
      headers: {
        Authorization: `bearer ${mainToken}`
      }
    });
    return request
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        resolve({ error: true, res: err });
      });
  });
}

export async function getAttendu(assuranceType, mainToken, contratId) {
  return new Promise(resolve => {
    const url = API.tunnel.common.uploadDocument[assuranceType].documentAttendu;
    const request = axios.get(url, {
      params: {
        contratId: contratId
      },
      headers: {
        Authorization: `bearer ${mainToken}`
      }
    });
    return request
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        resolve({ error: true, res: err });
      });
  });
}

export async function createAttendu(
  assuranceType,
  mainToken,
  contratId,
  documentRefId,
  file
) {
  return new Promise(resolve => {
    const url = API.tunnel.common.uploadDocument[assuranceType].documentAttendu;
    const data = new FormData();
    data.append("file", file);
    const request = axios.post(url, data, {
      params: {
        contratId: contratId,
        natureDocumentAttenduRefId: documentRefId
      },
      headers: {
        Authorization: `bearer ${mainToken}`,
        "Content-Type": "multipart/form-data"
      }
    });
    return request
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        resolve({ error: true, res: err });
      });
  });
}

export async function getRisqueAttendu(assuranceType, mainToken, contratId) {
  return new Promise(resolve => {
    const url =
      API.tunnel.common.uploadDocument[assuranceType].documentsRisqueAttendu;
    const request = axios.get(url, {
      params: {
        contratId: contratId
      },
      headers: {
        Authorization: `bearer ${mainToken}`,
        "Content-Type": "multipart/form-data"
      }
    });
    return request
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        resolve({ error: true, res: err });
      });
  });
}

export async function createRisqueAttendu(
  assuranceType,
  mainToken,
  contratId,
  natureDocumentAttenduRefId,
  file,
  risqueId
) {
  return new Promise(resolve => {
    const url =
      API.tunnel.common.uploadDocument[assuranceType].documentsRisqueAttendu;
    const data = new FormData();
    data.append("file", file);
    const request = axios.post(url, data, {
      params: {
        contratId: contratId,
        natureDocumentAttenduRefId: natureDocumentAttenduRefId,
        risqueId: risqueId
      },
      headers: {
        Authorization: `bearer ${mainToken}`,
        "Content-Type": "multipart/form-data"
      }
    });
    return request
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        resolve({ error: true, res: err });
      });
  });
}
