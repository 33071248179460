import React from "react";
import ItemNavText from "../../../item-nav-text";

const ChildList = props => {
  const { child, openModalFunc, noMargin } = props;
  const listStyle = () => {
    const style = {};
    if (noMargin) style.marginBottom = 0;
    return style;
  };
  return (
    <ul style={listStyle()}>
      {child.map((childItem, index) => {
        const { icon, label, url, openModal, isWhatsApp } = childItem;
        const isModalItem = openModal ? openModalFunc : () => {};
        // if (isAideBlock && index < 6)
        if (isWhatsApp) {
          return (
            <li key={index} className={"whatsappContactMobile"}>
              <ItemNavText
                name={"whatsApp"}
                label={label}
                icon={icon}
                url={typeof url !== "undefined" ? url : null}
                onClick={isModalItem}
              />
            </li>
          );
        } else {
          return (
            <li key={index}>
              <ItemNavText
                label={label}
                icon={icon}
                url={typeof url !== "undefined" ? url : null}
                onClick={isModalItem}
              />
            </li>
          );
        }
        // return null;
      })}
    </ul>
  );
};

export default ChildList;
