import styled from "styled-components";
import breakpoint from "styled-components-breakpoint";

export const StyledWrapperContent = styled.div.attrs({
  className: "wrapper-content"
})`
  .download-conditions {
    display: flex;
    width: 360px;
    margin: auto;
  }
  .download-conditions > button {
    width: 35%;
  }
  background-color: ${props =>
    props.bgColor ? props.theme.colors.themeColors.altLight : "transparent"};
  box-sizing: border-box;
  position: relative;
  width: 980px;
  margin: 0 auto;
  padding: 40px ${props => props.theme.layouts.page.padding} 20px;
  ${breakpoint("mobile", "desktop")`
    width: 100%;
  `};
  ${breakpoint("desktop")`
    margin: 0 auto;
  `};
`;
