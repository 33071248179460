import { call, all, put, takeLatest, select } from "redux-saga/effects";

// Helpers
import b64toBlob from "../../../../helpers/media/b64-to-blob";

// Actions
import * as a from "./actions";
import * as tunnelActions from "../../tunnel/actions";
import * as inputProcessActions from "../input-process/actions";
import * as dateEffetContratActions from "../date-effet-contrat/actions";
// Selectors
import * as informationSelectors from "../../../information/selectors";
import * as tunnelSelectors from "../../tunnel/selectors";
import * as uploadDocumentSelectors from "./selectors";
// Sagas
import * as tunnelSagas from "../../tunnel/saga";
import * as loggerSagas from "../../../logger/saga";
// Services
import * as uploadDocumentService from "./services";

function* getEndOfUploadAutomobile({ documentType }) {
  switch (documentType) {
    case "carteGrise":
      let needCheckRadLad = yield select(
        tunnelSelectors.getCurrentSimulationCheckRadlad
      );
      needCheckRadLad = needCheckRadLad["carteGrise"];
      if (needCheckRadLad) {
        yield put({
          type: inputProcessActions.GET_INPUT_PROCESS.REQUEST,
          inputProcessType: "InfoVehicule1",
          assuranceType: "automobile",
          startInputProcess: false
        });
      } else {
        yield put({
          type: inputProcessActions.GET_INPUT_PROCESS.REQUEST,
          inputProcessType: "InfoVehicule4",
          assuranceType: "automobile",
          startInputProcess: true
        });
      }
      break;
    case "permisConduire":
      yield put({
        type: inputProcessActions.GET_INPUT_PROCESS.REQUEST,
        inputProcessType: "InfoPermis1",
        assuranceType: "automobile",
        startInputProcess: false
      });
      break;
    case "carteIdentite":
      yield call(
        tunnelSagas.redirectToUrl,
        "/assurance-automobile/recapitulatif"
      );
      break;
    default:
  }
}

function* getEndOfUploadSante({ documentType }) {
  const radladDocuments = yield select(
    tunnelSelectors.getCurrentSimulationRadlad
  );
  let inputProcessType = "InfoSanteIdentite2";
  let startInputProcess = true;
  if (radladDocuments[documentType]) {
    inputProcessType = "InfoSanteIdentite1";
    startInputProcess = false;
  }
  yield put({
    type: inputProcessActions.GET_INPUT_PROCESS.REQUEST,
    inputProcessType,
    assuranceType: "sante",
    startInputProcess
  });
}

function* createUploadDocument({
  assuranceType,
  documentType,
  file,
  needToComplete
}) {
  try {
    const origineWebCode = yield select(
      informationSelectors.getCurrentVisitorOrigin
    );
    const mainToken = yield select(informationSelectors.getMainToken);
    let currentSimulation = yield select(
      tunnelSelectors.getCurrentSimulationIds
    );
    if (currentSimulation.simulationId === null) {
      yield call(tunnelSagas.rebindIdsFromSessionStorage);
    }
    currentSimulation = yield select(tunnelSelectors.getCurrentSimulationIds);
    const isCompleting = needToComplete;
    const uploadDoc = yield call(
      uploadDocumentService.create,
      mainToken,
      assuranceType,
      documentType,
      file,
      currentSimulation.simulationId,
      isCompleting,
      origineWebCode
    );
    if (!uploadDoc.error) {
      yield call(tunnelSagas.setRadladDocuments, {
        [documentType]: uploadDoc.data.radLad
      });
      yield put({
        type: a.CREATE_UPLOAD_DOCUMENT.SUCCESS,
        payload: {
          radLad: uploadDoc.data.radLad,
          risqueId: uploadDoc.data.risqueId,
          simulationId: uploadDoc.data.simulationId,
          duration: uploadDoc.duration
        }
      });
      yield call(tunnelSagas.setIds, {
        simulationId: uploadDoc.data.simulationId,
        risqueId:
          typeof uploadDoc.data.risqueId !== "undefined"
            ? uploadDoc.data.risqueId
            : null
      });
    } else {
      const errorRes = uploadDoc.res.response;
      if (errorRes.request.status !== 400) {
        yield call(loggerSagas.postLogErrorApi, {
          sagaInit: "createUploadDocument",
          apiResponseUrl: errorRes.request.responseURL,
          statusResponse: errorRes.request.status,
          dataResponse: errorRes.data,
          token: mainToken,
          params: {
            documentType,
            file,
            simulationId: currentSimulation.simulationId,
            isCompleting
          }
        });
      }
      yield put({
        type: a.CREATE_UPLOAD_DOCUMENT.FAILURE,
        payload: {
          error: true
        }
      });
    }
  } catch (e) {}
}

export function* needUploadDocument({ assuranceType, documentType }) {
  try {
    const mainToken = yield select(informationSelectors.getMainToken);
    const currentSimulation = yield select(
      tunnelSelectors.getCurrentSimulationIds
    );
    if (currentSimulation.simulationId === null) {
      yield call(
        tunnelSagas.redirectToUrl,
        `/assurance-${assuranceType || "automobile"}/informations-manquantes`
      );
    } else {
      const needUploadDoc = yield call(
        uploadDocumentService.need,
        mainToken,
        assuranceType,
        documentType,
        currentSimulation.simulationId
      );
      if (!needUploadDoc.error) {
        const payload = {};
        payload[documentType] = needUploadDoc;
        yield put({
          type: a.NEED_UPLOAD_DOCUMENT.SUCCESS,
          payload: payload
        });
      } else {
        const errorRes = needUploadDoc.res.response;
        if (errorRes.request.status !== 400) {
          yield call(loggerSagas.postLogErrorApi, {
            sagaInit: "needUploadDocument",
            apiResponseUrl: errorRes.request.responseURL,
            statusResponse: errorRes.request.status,
            dataResponse: errorRes.data,
            token: mainToken,
            params: {
              documentType,
              simulationId: currentSimulation.simulationId
            }
          });
        }
        yield put({
          type: a.NEED_UPLOAD_DOCUMENT.FAILURE,
          payload: true
        });
      }
    }
  } catch (e) {
    yield put({
      type: a.NEED_UPLOAD_DOCUMENT.FAILURE,
      payload: true
    });
  }
}

function* getNextDocumentAttendu({ assuranceType }) {
  try {
    const mainToken = yield select(informationSelectors.getMainToken);
    const ids = yield select(tunnelSelectors.getCurrentSimulationIds);
    const contratId = ids.contratId;
    const documentAttendu = yield call(
      uploadDocumentService.getAttendu,
      assuranceType,
      mainToken,
      contratId
    );
    if (documentAttendu === null || !documentAttendu.error) {
      const payload = {
        code: null,
        natureDocumentClientRefId: null
      };
      if (documentAttendu && documentAttendu.code) {
        payload.code = documentAttendu.code;
        payload.natureDocumentClientRefId =
          documentAttendu.natureDocumentClientRefId;
      }
      yield put({
        type: a.GET_NEXT_DOCUMENT_ATTENDU.SUCCESS,
        payload: payload
      });
    } else {
      const errorRes = documentAttendu.res.response;
      if (errorRes.request.status !== 400) {
        yield call(loggerSagas.postLogErrorApi, {
          sagaInit: "getNextDocumentAttendu",
          apiResponseUrl: errorRes.request.responseURL,
          statusResponse: errorRes.request.status,
          dataResponse: errorRes.data,
          token: mainToken,
          params: {
            contratId
          }
        });
      }
      yield put({
        type: a.GET_NEXT_DOCUMENT_ATTENDU.FAILURE
      });
    }
  } catch (e) {}
}

function* createDocumentAttendu({ assuranceType, documentRefId, file }) {
  try {
    const mainToken = yield select(informationSelectors.getMainToken);
    const ids = yield select(tunnelSelectors.getCurrentSimulationIds);
    const contratId = ids.contratId;
    const documentAttendu = yield call(
      uploadDocumentService.createAttendu,
      assuranceType,
      mainToken,
      contratId,
      documentRefId,
      file
    );
    if (documentAttendu === null || !documentAttendu.error) {
      const payload = {
        code: null,
        natureDocumentClientRefId: null
      };
      if (documentAttendu && documentAttendu.code) {
        payload.code = documentAttendu.code;
        payload.natureDocumentClientRefId =
          documentAttendu.natureDocumentClientRefId;
      }
      yield put({
        type: a.CREATE_UPLOAD_DOCUMENT_ATTENDU.SUCCESS,
        payload: payload
      });
    } else {
      const errorRes = documentAttendu.res.response;
      if (errorRes.request.status !== 400) {
        yield call(loggerSagas.postLogErrorApi, {
          sagaInit: "createDocumentAttendu",
          apiResponseUrl: errorRes.request.responseURL,
          statusResponse: errorRes.request.status,
          dataResponse: errorRes.data,
          token: mainToken,
          params: {
            contratId,
            documentRefId,
            file
          }
        });
      }
      yield put({
        type: a.CREATE_UPLOAD_DOCUMENT_ATTENDU.FAILURE
      });
    }
  } catch (e) {}
}

function* setCurrentUploadStep({ step }) {
  yield put({
    type: a.SET_CURRENT_UPLOAD_STEP.SUCCESS,
    step
  });
}

function* goBackSante(documentType) {
  switch (documentType) {
    case "carteIdentite":
      yield call(
        tunnelSagas.redirectToUrl,
        "/assurance-sante/information-preparation-contrat"
      );
      break;
    default:
  }
}

function* goBackAutomobile(documentType) {
  switch (documentType) {
    case "carteGrise":
      let needCheckRadlad = yield select(
        tunnelSelectors.getCurrentSimulationCheckRadlad
      );
      needCheckRadlad = needCheckRadlad["carteGrise"];
      if (needCheckRadlad) {
        yield call(tunnelSagas.redirectToUrl, "/assurance-automobile");
      } else {
        yield put({
          // reset status post dateEffet
          type: dateEffetContratActions.RESET_DATE_EFFET_STATUS
        });
        yield call(
          tunnelSagas.redirectToUrl,
          "/assurance-automobile/debut-assurance"
        );
      }
      break;
    case "permisConduire":
      yield put({
        type: inputProcessActions.GET_BACK_INPUT_PROCESS.REQUEST,
        inputProcessCode: "InfoVehicule4",
        assuranceType: "automobile"
      });
      break;
    case "carteIdentite":
      yield put({
        type: inputProcessActions.GET_INPUT_PROCESS.REQUEST,
        inputProcessType: "InfoPermis1",
        assuranceType: "automobile",
        startInputProcess: false
      });
      break;
    default:
  }
}

export function* goBack({ assuranceType, documentType }) {
  const currentUploadStep = yield select(
    uploadDocumentSelectors.getCurrentUploadStep
  );
  if (currentUploadStep !== 1) {
    yield put({
      type: tunnelActions.REMOVE_LAST_PARCOURS
    });
    if (assuranceType === "automobile") {
      yield call(goBackAutomobile, documentType);
    } else if (assuranceType === "sante") {
      yield call(goBackSante, documentType);
    }
  } else {
    yield put({
      type: a.SET_CURRENT_UPLOAD_STEP.SUCCESS,
      step: -1
    });
  }
}

function photosToBlob(documents) {
  documents.map((doc, index) => {
    let image = null;
    if (doc.imageBase64 !== null) {
      const blob = b64toBlob(doc.imageBase64);
      image = URL.createObjectURL(blob);
    }
    documents[index].image = image;
    delete documents[index].imageBase64;
    delete documents[index].contentType;
    return false;
  });
  return documents;
}

function* getDocumentsRisqueAttendu({ assuranceType }) {
  try {
    const currentIds = yield select(tunnelSelectors.getCurrentSimulationIds);
    const mainToken = yield select(informationSelectors.getMainToken);
    const documentsRisqueAttendu = yield call(
      uploadDocumentService.getRisqueAttendu,
      assuranceType,
      mainToken,
      currentIds.contratId
    );
    if (!documentsRisqueAttendu.error) {
      const docs = photosToBlob(documentsRisqueAttendu);
      yield put({
        type: a.GET_DOCUMENTS_RISQUE_ATTENDU.SUCCESS,
        payload: docs
      });
    } else {
      const errorRes = documentsRisqueAttendu.res.response;
      yield call(loggerSagas.postLogErrorApi, {
        sagaInit: "getDocumentsRisqueAttendu",
        apiResponseUrl: errorRes.request.responseURL,
        statusResponse: errorRes.request.status,
        dataResponse: errorRes.data,
        token: mainToken,
        params: {
          contratId: currentIds.contratId
        }
      });
      yield put({
        type: a.GET_DOCUMENTS_RISQUE_ATTENDU.FAILURE,
        payload: true
      });
    }
  } catch (e) {}
}

function* createDocumentRisqueAttendu({
  assuranceType,
  natureDocumentAttenduRefId,
  file,
  risqueId
}) {
  try {
    const mainToken = yield select(informationSelectors.getMainToken);
    const currentIds = yield select(tunnelSelectors.getCurrentSimulationIds);
    const uploadDocRisqueAttendu = yield call(
      uploadDocumentService.createRisqueAttendu,
      assuranceType,
      mainToken,
      currentIds.contratId,
      natureDocumentAttenduRefId,
      file,
      risqueId
    );
    if (!uploadDocRisqueAttendu.error) {
      const docs = photosToBlob(uploadDocRisqueAttendu);
      yield put({
        type: a.CREATE_UPLOAD_DOCUMENT_RISQUE_ATTENDU.SUCCESS,
        payload: docs
      });
    } else {
      const errorRes = uploadDocRisqueAttendu.res.response;
      yield call(loggerSagas.postLogErrorApi, {
        sagaInit: "createDocumentRisqueAttendu",
        apiResponseUrl: errorRes.request.responseURL,
        statusResponse: errorRes.request.status,
        dataResponse: errorRes.data,
        token: mainToken,
        params: {
          contratId: currentIds.contratId,
          natureDocumentAttenduRefId,
          file,
          risqueId
        }
      });
      yield put({
        type: a.CREATE_UPLOAD_DOCUMENT_RISQUE_ATTENDU.FAILURE,
        payload: {
          error: true
        }
      });
    }
  } catch (e) {}
}

export default all([
  takeLatest(a.CREATE_UPLOAD_DOCUMENT.REQUEST, createUploadDocument),
  takeLatest(a.NEED_UPLOAD_DOCUMENT.REQUEST, needUploadDocument),
  takeLatest(a.GET_NEXT_DOCUMENT_ATTENDU.REQUEST, getNextDocumentAttendu),
  takeLatest(a.CREATE_UPLOAD_DOCUMENT_ATTENDU.REQUEST, createDocumentAttendu),
  takeLatest(a.GET_END_OF_UPLOAD_SANTE, getEndOfUploadSante),
  takeLatest(a.GET_END_OF_UPLOAD_AUTO, getEndOfUploadAutomobile),
  takeLatest(a.SET_CURRENT_UPLOAD_STEP.REQUEST, setCurrentUploadStep),
  takeLatest(a.GET_DOCUMENTS_RISQUE_ATTENDU.REQUEST, getDocumentsRisqueAttendu),
  takeLatest(a.GO_BACK, goBack),
  takeLatest(
    a.CREATE_UPLOAD_DOCUMENT_RISQUE_ATTENDU.REQUEST,
    createDocumentRisqueAttendu
  )
]);
