import React, { Component } from "react";
import { withNamespaces } from "react-i18next";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router";

// Helpers
import { isAnonyme } from "../../../../../helpers/data/cookie-user";
// Import components
import ChildList from "./components/child-list";
import WrapperContent from "../../../../wrapper-content";
import Paragraph from "../../../../paragraph";
import LinkButton from "../../../../link-button";

// Actions
import { openCloseModalEtreRappelle } from "../../../../../store/modal/actions";
import { openCloseMainMenu } from "../../../../../store/main-menu/actions";

// Selectors
import { getMainMenuState } from "../../../../../store/main-menu/selectors";
import { getContactInfos } from "../../../../../store/information/selectors";

// Import data
import { data } from "../../../data-component";

// Import style
import { StyledMenuCorporate } from "./style";

const mapStateToProps = state => ({
  mainMenuState: getMainMenuState(state),
  contactInfos: getContactInfos(state),
  isNotConnected: isAnonyme(state)
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      openCloseModalEtreRappelle,
      openCloseMainMenu
    },
    dispatch
  )
});

class MenuCorporate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menuItems: []
    };
    this.openModalEtreRappelle = this.openModalEtreRappelle.bind(this);
    this.onClickPhoneNumber = this.onClickPhoneNumber.bind(this);
    this.onGoToAideCategory = this.onGoToAideCategory.bind(this);
  }

  componentDidMount() {
    const {
      t,
      isNotConnected,
      contactInfos,
      urlEspaceClient,
      helpArticles
    } = this.props;
    const menuItems = data.content(
      t,
      contactInfos,
      urlEspaceClient,
      this.onClickPhoneNumber,
      helpArticles,
      isNotConnected
    );
    this.setState({
      menuItems
    });
  }

  onGoToAideCategory = (e, url) => {
    e.preventDefault();
    const { history, actions } = this.props;
    history.push(url);
    actions.openCloseMainMenu(false, -1);
  };

  onClickPhoneNumber = (e, phone) => {
    e.preventDefault();
    window.open(phone);
  };

  openModalEtreRappelle(e) {
    const { actions } = this.props;
    e.preventDefault();
    actions.openCloseModalEtreRappelle(true);
  }

  render() {
    const { t, mainMenuState } = this.props;
    const { menuItems } = this.state;
    const { openedName } = mainMenuState;
    let menuItem = menuItems.find(item => item.name === openedName);
    if (typeof menuItem === "undefined" || menuItems.length <= 0) {
      return null;
    } else {
      if (!menuItem.isMobileOnly) {
        const { child, splitDesktop } = menuItem;
        return (
          <StyledMenuCorporate>
            <WrapperContent className="list">
              <ChildList
                child={child}
                splitDesktop={splitDesktop}
                openModalFunc={this.openModalEtreRappelle}
                isAideBlock={openedName === "aide"}
              />
            </WrapperContent>
            {openedName === "aide" &&
              child.length > 6 && (
                <WrapperContent className="tous-aide">
                  <Paragraph noMargin size="small">
                    <LinkButton
                      config={{
                        type: "button",
                        style: "noStyle"
                      }}
                      onClick={e => this.onGoToAideCategory(e, "/aide")}
                    >
                      {t("shared.tousNosArticlesDAide")}
                    </LinkButton>
                  </Paragraph>
                </WrapperContent>
              )}
          </StyledMenuCorporate>
        );
      }
    }
  }
}

export default compose(
  withNamespaces("translations"),
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(MenuCorporate);
