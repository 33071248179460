export const SET_CREATION_CONTRAT = {
  REQUEST: "creationContrat/SET/REQUEST",
  SUCCESS: "creationContrat/SET/SUCCESS",
  FAILURE: "creationContrat/SET/FAILURE"
};

export const DOWNLOAD_CONDITIONS = {
  REQUEST: "conditions/download/REQUEST",
  SUCCESS: "conditions/download/SUCCESS",
  FAILURE: "conditions/download/FAILURE"
};

export const setCreationContrat = (assuranceType, redirectionUrl) => {
  return {
    type: SET_CREATION_CONTRAT.REQUEST,
    assuranceType,
    redirectionUrl
  };
};

export const downloadConditions = conditionType => {
  return {
    type: DOWNLOAD_CONDITIONS.REQUEST,
    conditionType
  };
};
