import { call, all, put, takeLatest, select } from "redux-saga/effects";
import * as a from "./actions";

// Cookie
import { setToken } from "../../helpers/data/cookie-user";
// Actions
import * as creationContratActions from "../tunnel/common/creation-contrat/actions";
// Selectors
import * as informationSelectors from "../information/selectors";
// Services
import * as clientService from "./services";
// Sagas
import * as tunnelSagas from "../tunnel/tunnel/saga";
import * as loggerSagas from "../logger/saga";

// Helpers
import formatPhoneNumber from "../../helpers/data/format-phone-number";

function* createClient({ civilite, prenom, nom, telephone, assuranceType }) {
  try {
    const isTarifFirst = yield select(
      informationSelectors.isTarifBeforeInfoClient
    );
    const mainToken = yield select(informationSelectors.getMainToken);
    const newClient = yield call(
      clientService.create,
      mainToken,
      civilite,
      prenom,
      nom,
      telephone,
      assuranceType
    );
    if (!newClient.error) {
      setToken(newClient.data.token);
      yield put({
        type: a.CREATE_CLIENT.SUCCESS,
        payload: newClient.data.token
      });
      if (assuranceType === "automobile") {
        if (isTarifFirst) {
          yield call(
            tunnelSagas.redirectToUrl,
            "/assurance-automobile/debut-assurance"
          );
        } else {
          yield call(tunnelSagas.redirectToUrl, "/assurance-automobile/tarif");
        }
      }
    } else {
      const errReponse = newClient.res.response;
      if (errReponse.data.error.indexOf("NUMERO_TELEPHONE_DEJA_UTILISE") >= 0) {
        if (assuranceType === "automobile") {
          const phoneConfig = yield select(informationSelectors.getPhoneConfig);
          const phoneToSend = formatPhoneNumber(
            telephone.replace(`+${phoneConfig.indicator}`, ""),
            phoneConfig.modulo.split(","),
            phoneConfig.separator
          );
          yield call(
            tunnelSagas.redirectToUrl,
            "/assurance-automobile/connexion",
            {
              telephone: phoneToSend
            }
          );
          yield put({
            type: a.RESET_LOADING_CREATION_CLIENT
          });
        }
      } else {
        const errorRes = newClient.res.response;
        if (errorRes.data.error.indexOf("NUMERO_TELEPHONE_INVALIDE") < 0) {
          yield call(loggerSagas.postLog, {
            message:
              "Telephone invalide 400 saga verifierTelephone" + telephone,
            error: errorRes.data,
            info: errorRes.request.status,
            level: "warn"
          });
        }
        yield put({
          type: a.CREATE_CLIENT.FAILURE,
          payload: errReponse.data
        });
      }
    }
  } catch (e) {}
}

function* connectClient({ assuranceType, telephone, password }) {
  try {
    const mainToken = yield select(informationSelectors.getMainToken);
    const isTarifFirst = yield select(
      informationSelectors.isTarifBeforeInfoClient
    );
    const connectClient = yield call(
      clientService.connect,
      mainToken,
      telephone,
      password
    );
    if (!connectClient.error) {
      setToken(connectClient.token);
      yield put({
        type: a.CONNECT_CLIENT.SUCCESS,
        payload: connectClient.token
      });
      if (assuranceType === "automobile") {
        if (isTarifFirst) {
          yield call(
            tunnelSagas.redirectToUrl,
            "/assurance-automobile/debut-assurance"
          );
        } else {
          yield call(tunnelSagas.redirectToUrl, "/assurance-automobile/tarif");
        }
      } else if (assuranceType === "sante") {
        yield put({
          type: creationContratActions.SET_CREATION_CONTRAT.REQUEST,
          assuranceType,
          redirectionUrl: true
        });
      }
    } else {
      const errorRes = connectClient.res.response;
      if (errorRes.request.status !== 400) {
        yield call(loggerSagas.postLogErrorApi, {
          sagaInit: "connectClient",
          apiResponseUrl: errorRes.request.responseURL,
          statusResponse: errorRes.request.status,
          dataResponse: errorRes.data,
          token: mainToken,
          params: {
            telephone,
            password
          }
        });
      }
      yield put({
        type: a.CONNECT_CLIENT.FAILURE,
        payload: errorRes.data
      });
    }
  } catch (e) {}
}

function* forgotClient({ telephone }) {
  try {
    const mainToken = yield select(informationSelectors.getMainToken);
    const forgotClient = yield call(clientService.forgot, mainToken, telephone);
    if (forgotClient.status === 200) {
      yield put({
        type: a.FORGOT_CLIENT.SUCCESS,
        payload: true
      });
    } else {
      const errorRes = forgotClient.res.response;
      if (errorRes.request.status !== 400) {
        yield call(loggerSagas.postLogErrorApi, {
          sagaInit: "forgotClient",
          apiResponseUrl: errorRes.request.responseURL,
          statusResponse: errorRes.request.status,
          dataResponse: errorRes.data,
          token: mainToken,
          params: {
            telephone
          }
        });
      }
      yield put({
        type: a.FORGOT_CLIENT.FAILURE,
        payload: errorRes.data
      });
    }
  } catch (e) {}
}

function* authentificationNecessaire({ assuranceType, telephone }) {
  try {
    const mainToken = yield select(informationSelectors.getMainToken);
    const authentificationNecessaire = yield call(
      clientService.authentificationNecessaire,
      mainToken,
      telephone
    );
    if (!authentificationNecessaire.error) {
      sessionStorage.setItem(
        "authentificationClientNecessaire",
        JSON.stringify(authentificationNecessaire)
      );
      yield put({
        type: a.AUTHENTIFICATION_CLIENT_NECESSAIRE.SUCCESS,
        payload: authentificationNecessaire
      });
      if (assuranceType === "sante") {
        if (authentificationNecessaire) {
          yield call(tunnelSagas.redirectToUrl, "/assurance-sante/connexion");
        } else {
          const redirectionUrl = "/assurance-sante/paiement";
          yield put({
            type: creationContratActions.SET_CREATION_CONTRAT.REQUEST,
            assuranceType,
            redirectionUrl
          });
        }
      }
    } else {
      const errorRes = forgotClient.res.response;
      if (errorRes.request.status !== 400) {
        yield call(loggerSagas.postLogErrorApi, {
          sagaInit: "authentificationNecessaire",
          apiResponseUrl: errorRes.request.responseURL,
          statusResponse: errorRes.request.status,
          dataResponse: errorRes.data,
          token: mainToken,
          params: {
            telephone
          }
        });
      }
      yield put({
        type: a.AUTHENTIFICATION_CLIENT_NECESSAIRE.FAILURE
      });
    }
  } catch (e) {}
}

export default all([
  takeLatest(a.CREATE_CLIENT.REQUEST, createClient),
  takeLatest(a.CONNECT_CLIENT.REQUEST, connectClient),
  takeLatest(a.FORGOT_CLIENT.REQUEST, forgotClient),
  takeLatest(
    a.AUTHENTIFICATION_CLIENT_NECESSAIRE.REQUEST,
    authentificationNecessaire
  )
]);
