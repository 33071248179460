export const GET_TAUX_COUVERTURE = {
  REQUEST: "tauxCouverture/GET/REQUEST",
  SUCCESS: "tauxCouverture/GET/SUCCESS",
  FAILURE: "tauxCouverture/GET/FAILURE"
};

export const RESET_TAUX_COUVERTURE = "tauxCouverture/RESET";

export const getTauxCouverture = () => {
  return {
    type: GET_TAUX_COUVERTURE.REQUEST
  };
};
