export const GET_RECAPITULATIF = {
  REQUEST: "recapitulatif/GET/REQUEST",
  SUCCESS: "recapitulatif/GET/SUCCESS",
  FAILURE: "recapitulatif/GET/FAILURE"
};

export const SET_RECAPITULATIF_MODIFICATION_GO = {
  REQUEST: "recapitulatif/SET_MODIFICATION/REQUEST",
  SUCCESS: "recapitulatif/SET_MODIFICATION/SUCCESS",
  FAILURE: "recapitulatif/SET_MODIFICATION/FAILURE"
};

export const SET_RECAPITULATIF_MODIFICATION = "recapitulatif/SET_MODIFICATION";

export const RESET_RECAPITULATIF_MODIFICATION =
  "recapitulatif/RESET_MODIFICATION";

export const getRecapitulatif = assuranceType => {
  return {
    type: GET_RECAPITULATIF.REQUEST,
    assuranceType
  };
};

export const setRecapitulatifModification = (assuranceType, go) => {
  return {
    type: SET_RECAPITULATIF_MODIFICATION_GO.REQUEST,
    assuranceType,
    go
  };
};

export const resetRecapitulatifModification = () => {
  return {
    type: RESET_RECAPITULATIF_MODIFICATION
  };
};
