import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { compose } from "redux";

// Import components
import ProgressBar from "../tunnel/components/progress-bar";

// Import headers
import HeaderCorporate from "./corporate";
import HeaderTunnel from "./tunnel";

import { isMarqueBlanche } from "../../store/information/selectors";
// Selectors
import { isUserOnTunnel } from "../../store/tunnel/tunnel/selectors";
import { isHeaderOnyLogo as isHeaderOnyLogoSelector } from "../../store/layouts-show-hide/selectors";

// Import style
import { HeaderWrapper } from "./style";
import WrapperContentHeader from "../wrapper-content/wrapperHeader";

const mapStateToProps = state => ({
  isOnTunnel: isUserOnTunnel(state),
  isHeaderOnyLogo: isHeaderOnyLogoSelector(state),
  isMarqueBlanche: isMarqueBlanche(state)
});

class Header extends Component {
  renderHeaderItem() {
    const {
      isOnTunnel,
      onlyLogo,
      isHeaderOnyLogo,
      helpArticlesLength
    } = this.props;
    if (onlyLogo || isHeaderOnyLogo) {
      return (
        <HeaderCorporate
          onlyLogo={onlyLogo && !isHeaderOnyLogo}
          history={this.props.history}
        />
      );
    } else {
      if (isOnTunnel) {
        return <HeaderTunnel history={this.props.history} />;
      }
      return (
        <HeaderCorporate
          helpArticlesLength={helpArticlesLength}
          history={this.props.history}
        />
      );
    }
  }
  render() {
    const { isOnTunnel, className, isMarqueBlanche } = this.props;
    const headerClassName = `header-${isOnTunnel ? "tunnel" : "corporate"}`;
    return (
      <HeaderWrapper
        className={`${headerClassName}${className ? ` ${className}` : ""}`}
        id="header"
      >
        <WrapperContentHeader>{this.renderHeaderItem()}</WrapperContentHeader>
        {isOnTunnel && !isMarqueBlanche && <ProgressBar />}
      </HeaderWrapper>
    );
  }
}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    null
  )
)(Header);
