import { getRouterSearch } from "../router/selectors";

// get phone errors
export const getPhoneErrors = state => {
  const keys = Object.keys(state.parrainage.verifierTelephone);
  const errors = [];
  if (keys.length > 0) {
    keys.map(key => {
      if (state.parrainage.verifierTelephone[key].error) errors.push(key);
      return false;
    });
    return errors;
  }
  return [];
};

// Is loading post ajout parrainage
export const isAjoutParrainageLoading = state => state.parrainage.ajout.loading;

// Is loading post ajout parrainage
export const isAjoutParrainageSuccess = state => state.parrainage.ajout.success;

// Is loading post ajout parrainage
export const isAjoutParrainageError = state => state.parrainage.ajout.error;

// Get téléphones parrainés
export const getParrainagePhones = state => state.parrainage.ajout.telephones;

// Is loading connexion client
export const isParrainageConnexionClientLoading = state =>
  state.parrainage.connexionClient.loading;

// Is loading connexion client
export const isParrainageConnexionClientSuccess = state =>
  state.parrainage.connexionClient.success;

// Is loading connexion client
export const isParrainageConnexionClientError = state =>
  state.parrainage.connexionClient.error;

// Get parrainage guid
export const getParrainageGuid = state => {
  const pattern = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
  let guid = null;
  if (getRouterSearch(state) !== null) {
    const routerSearch = getRouterSearch(state);
    if (
      typeof routerSearch.parrainage_guid !== "undefined" &&
      routerSearch.parrainage_guid !== "" &&
      pattern.test(routerSearch.parrainage_guid)
    ) {
      guid = routerSearch.parrainage_guid;
    }
  }
  return guid;
};
