export const GET_ASSURES = {
  REQUEST: "assures/GET/REQUEST",
  SUCCESS: "assures/GET/SUCCESS",
  FAILURE: "assures/GET/FAILURE"
};

export const POST_ASSURES = {
  REQUEST: "assures/POST/REQUEST",
  SUCCESS: "assures/POST/SUCCESS",
  FAILURE: "assures/POST/FAILURE"
};

export const getAssures = () => {
  return {
    type: GET_ASSURES.REQUEST
  };
};

export const postAssures = data => {
  return {
    type: POST_ASSURES.REQUEST,
    data
  };
};
