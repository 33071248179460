import React, { Component } from "react";
import { Trans, withNamespaces } from "react-i18next";
import Cookies from "js-cookie";
import { Close } from "styled-icons/material/Close";

// Import components
import WrapperContent from "../../components/wrapper-content";
import Paragraph from "../../components/paragraph";
import LinkButton from "../../components/link-button";

// Import style
import { StyledCookieBar } from "./style";

class CookieBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visibleCookie: false
    };
    this.hideCookie = this.hideCookie.bind(this);
  }

  componentDidMount() {
    const isCookieExists = Cookies.get("ACCEPT");
    this.setState({
      visibleCookie: typeof isCookieExists !== "undefined"
    });
  }

  hideCookie = () => {
    Cookies.set("ACCEPT", true, {
      expires: 7,
      secure: process.env.NODE_ENV === "production"
    });
    this.setState({
      visibleCookie: typeof Cookies.get("ACCEPT") !== "undefined"
    });
  };

  render() {
    const { t } = this.props;
    return (
      <StyledCookieBar visible={this.state.visibleCookie}>
        <WrapperContent>
          <Paragraph noMargin size="small">
            <Trans i18nKey="cookieBar.text">
              CookieBar text
              <LinkButton
                config={{
                  type: "link"
                }}
                to={"/politique-confidentialite"}
              >
                CookieBarUrl
              </LinkButton>
              Cookie bar end text
            </Trans>
          </Paragraph>
          <div
            onClick={() => {
              this.hideCookie();
            }}
          >
            <Paragraph noMargin size="small">
              <LinkButton
                noMargin
                config={{
                  type: "button"
                }}
              >
                {t("cookieBar.buttonLabel")}
              </LinkButton>
            </Paragraph>
          </div>
          <button
            onClick={() => {
              this.hideCookie();
            }}
          >
            <Close size={20} />
          </button>
        </WrapperContent>
      </StyledCookieBar>
    );
  }
}

export default withNamespaces("translations")(CookieBar);
