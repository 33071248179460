// Envoi de la requête GET
export const isCreationContratLoading = state =>
  state.tunnel.common.creationContrat.loading;

// Envoi succes GET
export const isCreationContratSuccessfull = state =>
  state.tunnel.common.creationContrat.success;

// Erreur de l'envoi GET
export const isCreationContratError = state =>
  state.tunnel.common.creationContrat.error;

export const getContratId = state =>
  state.tunnel.common.creationContrat.contratId;

export const getVersionContratId = state =>
  state.tunnel.common.creationContrat.versionContratId;

export const isDownloadConditionsLoad = state =>
  state.tunnel.common.creationContrat.conditions.loading;
export const isDownloadConditionsOnError = state =>
  state.tunnel.common.creationContrat.conditions.error;
export const isDownloadConditionsOnSuccess = state =>
  state.tunnel.common.creationContrat.conditions.success;
export const getConditionsParticulieresPdf = state =>
  state.tunnel.common.creationContrat.conditions.success;
export const getConditionsGeneralesPdf = state =>
  state.tunnel.common.creationContrat.conditions.success;
