import * as a from "./actions";

const INITIAL_CREATION_CONTRAT_STATE = {
  error: false,
  loading: false,
  success: false,
  contratId: null,
  quittanceId: null,
  versionContratId: null,
  token: null,
  conditions: {
    generales: null,
    particulieres: null,
    error: false,
    success: false,
    loading: false
  }
};

export function creationContrat(
  state = INITIAL_CREATION_CONTRAT_STATE,
  action
) {
  switch (action.type) {
    case a.SET_CREATION_CONTRAT.REQUEST:
      return {
        ...state,
        success: false,
        error: false,
        loading: true
      };
    case a.SET_CREATION_CONTRAT.SUCCESS:
      return {
        ...state,
        success: true,
        error: false,
        loading: false,
        contratId: action.payload.contratId,
        quittanceId: action.payload.quittanceId,
        versionContratId: action.payload.versionContratId,
        token: action.payload.token
      };
    case a.SET_CREATION_CONTRAT.FAILURE:
      return {
        ...state,
        success: false,
        error: true,
        loading: false
      };
    case a.DOWNLOAD_CONDITIONS.REQUEST:
      return {
        ...state,
        conditions: {
          ...state.download,
          loading: true,
          error: false,
          success: false
        }
      };
    case a.DOWNLOAD_CONDITIONS.SUCCESS:
      return {
        ...state,
        conditions: {
          ...state.download,
          generales:
            action.contitionType === "conditionsGenerales"
              ? action.payload
              : null,
          particulieres:
            action.contitionType === "conditionsParticulieres"
              ? action.payload
              : null,
          loading: false,
          error: false,
          success: true
        }
      };
    default:
      return state;
  }
}

export default creationContrat;
