import React, { Component } from "react";
import { withTheme } from "styled-components";

class WrapperViewResponsive extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: 0
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  componentToReturn() {
    const { theme, componentRender } = this.props;
    const { breakpoints } = theme;
    const { width } = this.state;
    const keys = Object.keys(componentRender);
    const responsiveCases = keys.filter(value => value !== "default");

    return responsiveCases.map((responsiveCase, index) => {
      if (width >= breakpoints[responsiveCase])
        if (
          typeof responsiveCases[index + 1] === "undefined" ||
          width < breakpoints[responsiveCases[index + 1]]
        ) {
          return (
            <React.Fragment key={index}>
              {componentRender[responsiveCase]}
            </React.Fragment>
          );
        } else {
          return (
            <React.Fragment key={index}>
              {componentRender["default"]}
            </React.Fragment>
          );
        }
      return null;
    });
  }

  updateWindowDimensions() {
    this.setState({
      width: window.innerWidth
    });
  }

  render() {
    return <React.Fragment>{this.componentToReturn()}</React.Fragment>;
  }
}

export default withTheme(WrapperViewResponsive);
