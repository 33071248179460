import { call, all, put, takeLatest, select } from "redux-saga/effects";
import * as a from "./actions";

// Import selectors from INFORMATION
import * as clientSelectors from "../client/selectors";
import * as loggerSagas from "../logger/saga";

// Import services
import * as referenceListService from "./services";

function* getReferenceList({ codeReferentiel, refParentId }) {
  try {
    const mainToken = yield select(clientSelectors.getUserToken);
    const references = yield call(
      referenceListService.get,
      mainToken,
      codeReferentiel,
      refParentId
    );
    if (!references.error) {
      yield put({
        type: a.GET_REFERENCE_LIST.SUCCESS,
        payload: references
      });
    } else {
      const errorRes = references.res.response;
      if (errorRes.request.status !== 400) {
        yield call(loggerSagas.postLogErrorApi, {
          sagaInit: "getReferenceList",
          apiResponseUrl: errorRes.request.responseURL,
          statusResponse: errorRes.request.status,
          dataResponse: errorRes.data,
          token: mainToken,
          params: {
            codeReferentiel,
            refParentId
          }
        });
      }
      yield put({
        type: a.GET_REFERENCE_LIST.FAILURE,
        payload: true
      });
    }
  } catch (e) {}
}

export default all([
  takeLatest(a.GET_REFERENCE_LIST.REQUEST, getReferenceList)
]);
