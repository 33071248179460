import axios from "axios";
import API from "../api";

export async function getPaiementRepriseInformations(guid, mainToken) {
  const params = {
    guid: guid
  };
  return new Promise(resolve => {
    const url = API.reprisePaiement.main;
    const request = axios.get(url, {
      params,
      headers: {
        Authorization: `bearer ${mainToken}`
      }
    });
    return request
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        resolve({ error: true, res: err });
      });
  });
}
