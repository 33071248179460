import axios from "axios";
import API from "../../../api";

export async function get(mainToken) {
  return new Promise(resolve => {
    const url = API.tunnel.sante.tranchesAges.main;
    const request = axios.get(url, {
      headers: {
        Authorization: `bearer ${mainToken}`
      }
    });
    return request
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        resolve({ error: true, res: err });
      });
  });
}
