import * as a from "./actions";

const INITIAL_STATE = {
  success: false,
  loading: false,
  error: null,
  clientId: null,
  emailClient: null,
  formuleItcRefId: null,
  id: null,
  nbAdultes: null,
  nbJeunes: null,
  nbSeniors: null,
  pathologieClientCode: null,
  telephoneClient: null,
  typeReseauSoinsRefId: null,
  reprise: {
    success: false,
    loading: false,
    error: null
  }
};

export function lead(state = INITIAL_STATE, action) {
  switch (action.type) {
    case a.GET_LEAD.REQUEST:
    case a.CREATE_LEAD.REQUEST:
      return {
        ...state,
        success: false,
        loading: true,
        error: null
      };
    case a.GET_LEAD.SUCCESS:
    case a.CREATE_LEAD.SUCCESS:
      return {
        ...state,
        success: true,
        loading: false,
        error: null,
        clientId: action.payload.clientId,
        emailClient: action.payload.emailClient,
        formuleItcRefId: action.payload.formuleItcRefId,
        id: action.payload.id,
        nbAdultes: action.payload.nbAdultes,
        nbJeunes: action.payload.nbJeunes,
        nbSeniors: action.payload.nbSeniors,
        pathologieClientCode: action.payload.pathologieClientCode,
        telephoneClient: action.payload.telephoneClient,
        typeReseauSoinsRefId: action.payload.typeReseauSoinsRefId
      };
    case a.GET_LEAD.FAILURE:
    case a.CREATE_LEAD.FAILURE:
      return {
        ...state,
        success: false,
        error: action.payload,
        loading: false
      };
    /* REPRISE */
    case a.REPRISE_LEAD.REQUEST:
      return {
        ...state,
        reprise: {
          success: false,
          loading: true,
          error: null
        }
      };
    case a.REPRISE_LEAD.SUCCESS:
      return {
        ...state,
        reprise: {
          success: true,
          loading: false,
          error: null
        }
      };
    case a.DELETE_LEAD_ID.SUCCESS:
      return {
        ...state,
        id: null
      };
    case a.RESET_LEAD:
      return INITIAL_STATE;
    default:
      return state;
  }
}

export default lead;
