import React from "react";
import { Switch, Redirect, Route } from "react-router-dom";
import Loadable from "react-loadable";

// Import components
import PrivacyPolicy from "../containers/privacy-policy";
import Cgu from "../containers/cgu";
import NotFound from "../containers/not-found";
import PageLoader from "../components/page-loader";
import HasNoRequiredInfos from "../containers/has-no-required-infos";

const Homepage = Loadable({
  loader: () => import("../containers/homepage/index"),
  loading: () => <PageLoader />,
  modules: ["homepage"]
});

const LandingPageMarqueBlanche = Loadable({
  loader: () => import("../containers/landing-page-marque-blanche/index"),
  loading: () => <PageLoader />,
  modules: ["landingPageAxa"]
});

const PageLogin = Loadable({
  loader: () => import("../containers/page-login/index"),
  loading: () => <PageLoader />,
  modules: ["login"]
});

const PageProduit = Loadable({
  loader: () => import("../containers/page-produit/index"),
  loading: () => <PageLoader />,
  modules: ["produit"]
});

const PageTunnel = Loadable({
  loader: () => import("../containers/page-tunnel/index"),
  loading: () => <PageLoader />,
  modules: ["pagetunnel"]
});

const PageInputProcess = Loadable({
  loader: () => import("../containers/input-process/index"),
  loading: () => <PageLoader />,
  modules: ["pageinputprocess"]
});

const PageTarification = Loadable({
  loader: () => import("../containers/tarification/index"),
  loading: () => <PageLoader />,
  modules: ["pagetarification"]
});

const PagePreparationPaiement = Loadable({
  loader: () => import("../containers/preparation-paiement/index"),
  loading: () => <PageLoader />,
  modules: ["pagepreparationpaiement"]
});

const PageEnvoiDocument = Loadable({
  loader: () => import("../containers/envoi-document/index"),
  loading: () => <PageLoader />,
  modules: ["pageenvoidocument"]
});

const PagePreparationContrat = Loadable({
  loader: () => import("../containers/preparation-contrat/index"),
  loading: () => <PageLoader />,
  modules: ["pagepreparationcontrat"]
});

const PageDebutAssurance = Loadable({
  loader: () => import("../containers/debut-assurance/index"),
  loading: () => <PageLoader />,
  modules: ["pagedebutassurance"]
});

const PageRecapitulatifContrat = Loadable({
  loader: () => import("../containers/recapitulatif/index"),
  loading: () => <PageLoader />,
  modules: ["pagerecapitulatifcontrat"]
});

const PagePaiement = Loadable({
  loader: () => import("../containers/paiement/index"),
  loading: () => <PageLoader />,
  modules: ["pagepaiement"]
});

const PagePaiementNoOperators = Loadable({
  loader: () => import("../containers/paiement-no-operators/index"),
  loading: () => <PageLoader />,
  modules: ["pagepaiementnooperators"]
});

const PageSignature = Loadable({
  loader: () => import("../containers/signature-electronique/index"),
  loading: () => <PageLoader />,
  modules: ["pagesignature"]
});

const PageSignatureValidee = Loadable({
  loader: () => import("../containers/signature-electronique-validee/index"),
  loading: () => <PageLoader />,
  modules: ["pagesignaturevalidee"]
});

const Bravo = Loadable({
  loader: () => import("../containers/bravo/index"),
  loading: () => <PageLoader />,
  modules: ["bravo"]
});

const PageDocumentAttendu = Loadable({
  loader: () => import("../containers/document-attendu/index"),
  loading: () => <PageLoader />,
  modules: ["pagedocumentattendu"]
});

const PageArticle = Loadable({
  loader: () => import("../containers/page-article/index"),
  loading: () => <PageLoader />,
  modules: ["pagearticle"]
});

const PageSelectAide = Loadable({
  loader: () => import("../containers/pageSelectAide/index"),
  loading: () => <PageLoader />,
  modules: ["pageselectaide"]
});

const PageListingArticles = Loadable({
  loader: () => import("../containers/page-articles/index"),
  loading: () => <PageLoader />,
  modules: ["pagelistingarticles"]
});

const PageSanteContactClient = Loadable({
  loader: () => import("../containers/sante/informations-contact/index"),
  loading: () => <PageLoader />,
  modules: ["pagesantecontactclient"]
});

const PageLieuCouvertureSoins = Loadable({
  loader: () => import("../containers/sante/lieu-couverture-soins/index"),
  loading: () => <PageLoader />,
  modules: ["pagelieucouverturesoins"]
});

const PageTauxCouverture = Loadable({
  loader: () => import("../containers/sante/taux-couverture/index"),
  loading: () => <PageLoader />,
  modules: ["pagetauxcouverture"]
});

const PageInformationPreparationContrat = Loadable({
  loader: () =>
    import("../containers/sante/information-preparation-contrat/index"),
  loading: () => <PageLoader />,
  modules: ["pageinformationpreparationcontrat"]
});

const PageInformationsAssures = Loadable({
  loader: () => import("../containers/sante/informations-assures/index"),
  loading: () => <PageLoader />,
  modules: ["pageinformationsassures"]
});

const PageInstructionsPhotosAssures = Loadable({
  loader: () => import("../containers/sante/instructions-photos-assures/index"),
  loading: () => <PageLoader />,
  modules: ["pageinstructionsphotosassures"]
});

const PagePhotosAssures = Loadable({
  loader: () => import("../containers/sante/photos-assures/index"),
  loading: () => <PageLoader />,
  modules: ["pagephotosassures"]
});

const PageQuestionsMedicales = Loadable({
  loader: () => import("../containers/sante/questions-medicales/index"),
  loading: () => <PageLoader />,
  modules: ["questionsmedicales"]
});

const PageContactMedical = Loadable({
  loader: () => import("../containers/sante/contact-medical/index"),
  loading: () => <PageLoader />,
  modules: ["contactmedical"]
});
/*
*
* PARRAINAGES
*
 */
const PageParrainage = Loadable({
  loader: () => import("../containers/parrainage/index"),
  loading: () => <PageLoader />,
  modules: ["pageparrainage"]
});

/*
*
* CAMPAIGNS
*
 */
const CampaignPageCoinAfrique = Loadable({
  loader: () => import("../containers/campaigns/demande-contact"),
  loading: () => <PageLoader />,
  modules: ["campaigncoinafrique"]
});

const DemandeContact = Loadable({
  loader: () => import("../containers/campaigns/demande-contact"),
  loading: () => <PageLoader />,
  modules: ["campaigncoinafrique"]
});

const CampagneSante = Loadable({
  loader: () => import("../containers/campaigns/campagne-sante"),
  loading: () => <PageLoader />,
  modules: ["campaigncoinafrique"]
});

const Routes = () => (
  <Switch>
    <Route exact path="/" component={Homepage} />
    <Route exact path="/home" component={LandingPageMarqueBlanche} />
    <Route exact path="/coinafrique" component={CampaignPageCoinAfrique} />
    <Route exact path="/demandecontactsante" component={CampagneSante} />
    <Route exact path="/demandecontact" component={DemandeContact} />
    <Route exact path="/assurance/:productSlug" component={PageProduit} />
    <Route exact path="/assurance-:assuranceType" component={PageTunnel} />
    <Route
      exact
      path="/assurance-:assuranceType/informations-manquantes"
      component={HasNoRequiredInfos}
    />
    <Route
      exact
      path="/assurance-:assuranceType/process/:processName/:pageName"
      component={PageInputProcess}
    />
    <Route
      exact
      path="/assurance-:assuranceType/tarif"
      component={PageTarification}
    />
    <Route
      exact
      path="/assurance-:assuranceType/envoi-document/:document"
      component={PageEnvoiDocument}
    />
    <Route
      exact
      path="/assurance-:assuranceType/preparation-paiement/:info"
      component={PagePreparationPaiement}
    />
    <Route
      exact
      path="/assurance-:assuranceType/preparation-contrat"
      component={PagePreparationContrat}
    />
    <Route
      exact
      path="/assurance-:assuranceType/debut-assurance"
      component={PageDebutAssurance}
    />
    <Route
      exact
      path="/assurance-:assuranceType/recapitulatif"
      component={PageRecapitulatifContrat}
    />
    <Route
      exact
      path="/assurance-:assuranceType/paiement"
      component={PagePaiement}
    />
    <Route
      exact
      path="/assurance-:assuranceType/rappel-paiement"
      component={PagePaiementNoOperators}
    />
    <Route
      exact
      path="/assurance-:assuranceType/paiement/:step"
      component={PagePaiement}
    />
    <Route
      exact
      path="/assurance-:assuranceType/signature-electronique"
      component={PageSignature}
    />
    <Route
      exact
      path="/assurance-:assuranceType/signature-electronique/:status"
      component={PageSignature}
    />
    <Route
      exact
      path="/assurance-:assuranceType/signature-electronique-validee"
      component={PageSignatureValidee}
    />
    <Route exact path="/assurance-:assuranceType/bravo" component={Bravo} />
    <Route
      exact
      path="/assurance-:assuranceType/document-attendu/:document"
      component={PageDocumentAttendu}
    />
    {/* ROUTES SANTE */}
    <Route
      exact
      path="/assurance-:assuranceType/informations-de-contact"
      component={PageSanteContactClient}
    />
    <Route
      exact
      path="/assurance-:assuranceType/lieu-couverture-soins"
      component={PageLieuCouvertureSoins}
    />
    <Route
      exact
      path="/assurance-:assuranceType/taux-couverture"
      component={PageTauxCouverture}
    />
    <Route
      exact
      path="/assurance-:assuranceType/information-preparation-contrat"
      component={PageInformationPreparationContrat}
    />
    <Route
      exact
      path="/assurance-:assuranceType/informations-assures"
      component={PageInformationsAssures}
    />
    <Route
      exact
      path="/assurance-:assuranceType/instructions-photos-assures"
      component={PageInstructionsPhotosAssures}
    />
    <Route
      exact
      path="/assurance-:assuranceType/photos-assures"
      component={PagePhotosAssures}
    />
    <Route
      exact
      path="/assurance-:assuranceType/questions-medicales"
      component={PageQuestionsMedicales}
    />
    <Route
      exact
      path="/assurance-:assuranceType/contact-medical"
      component={PageContactMedical}
    />
    {/* Pages ARTICLE */}
    <Route exact path="/article/:articleSlug" component={PageArticle} />
    <Route exact path="/actualite" component={PageListingArticles} />
    <Route
      exact
      path="/actualite/:categoryName"
      component={PageListingArticles}
    />
    {/* Pages AIDE */}
    <Route exact path="/aide" component={PageListingArticles} />
    <Route exact path="/aide/:articleAideSlug" component={PageArticle} />
    <Route exact path="/aides/selection" component={PageSelectAide} />
    {/* Pages ACCOUNT */}
    <Route
      exact
      path="/assurance-:assuranceType/connexion"
      component={PageLogin}
    />
    <Route exact path="/politique-confidentialite" component={PrivacyPolicy} />
    <Route exact path="/conditions-generales" component={Cgu} />
    {/* Pages parrainage */}
    <Route exact path="/parrainage" component={PageParrainage} />
    <Route exact path="/404" component={NotFound} />
    <Redirect to="/404" />
  </Switch>
);

export default Routes;
