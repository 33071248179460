// Import helpers
import { getToken, extractUserInfos } from "../../helpers/data/cookie-user";

// Envoi de la requête
export const isCreateClientLoading = state => state.client.create.loading;

// Envoi succes
export const isCreateClientSuccessfull = state => state.client.create.success;

// Erreur de l'envoi
export const isCreateClientError = state => state.client.create.error;

// Envoi de la requête
export const isConnectClientLoading = state => state.client.connect.loading;

// Envoi succes
export const isConnectClientSuccessfull = state => state.client.connect.success;

// Erreur de l'envoi
export const isConnectClientError = state => state.client.connect.error;

// Envoi de la requête
export const isForgotClientLoading = state => state.client.forgot.loading;

// Envoi succes
export const isForgotClientSuccessfull = state => state.client.forgot.success;

// Erreur de l'envoi
export const isForgotClientError = state => state.client.forgot.error;

// Envoi de la requête
export const isAuthentificationClientNecessaireLoading = state =>
  state.client.authentificationNecessaire.loading;

// Envoi succes
export const isAuthentificationClientNecessaireSuccessfull = state =>
  state.client.authentificationNecessaire.success;

// Erreur de l'envoi
export const isAuthentificationClientNecessaireError = state =>
  state.client.authentificationNecessaire.error;

// Status authentification client nécessaire
export const getAuthentificationClientNecessaire = state =>
  state.client.authentificationNecessaire.status;

// Get user token
export const getUserToken = state => {
  if (getToken() !== null) return getToken();
  return state.client.token;
};

export const getExtractedUserInfosFrom = state => {
  if (getToken() !== null) return extractUserInfos();
  return null;
};
