import React, { Component } from "react";
import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";

// Import components
import Input from "../../../tunnel/components/input";
import PhoneInput from "../../../tunnel/components/phone-input";
import { FormWrapper } from "../../../form-styles";
import ErrorGlobal from "../../../error-global";

// Store
import {
  getPhoneConfig,
  isMarqueBlanche
} from "../../../../store/information/selectors";
import { getLastParcours } from "../../../../store/tunnel/tunnel/selectors";
import { isUserOnTunnel } from "../../../../store/tunnel/tunnel/selectors";

// Import style
import { StyledPopinFormMarqueBlanche } from "./style";

// Import helpers
import FormHelper, { isFormValid } from "../../../../helpers/forms";

const formDefaults = (clientIds, phoneConfig, fromContactServiceClient) => {
  const values = {
    isSubmittable: false,
    nom: "",
    telephone: "",
    formValidation: {
      telephone: false
    }
  };
  if (fromContactServiceClient) {
    values.sujetDemande = null;
  }
  if (clientIds !== null) {
    values.isSubmittable = true;
    values.nom = clientIds.nom;
    let phone = [];
    const phoneNb = clientIds.telephone
      .replace(`+${phoneConfig.indicator}`, "")
      .split()[0];
    let spaces = 0;
    for (let i = 0; i < phoneConfig.placeholder.length; i++) {
      if (phoneConfig.placeholder.charAt(i) === " ") {
        spaces++;
      }
      phone.push(
        phoneConfig.placeholder.charAt(i) === "0"
          ? phoneNb.charAt(i - spaces)
          : " "
      );
    }
    values.telephone = phone.join("");
    values.formValidation.telephone = true;
  }
  return values;
};

const mapStateToProps = state => ({
  phoneConfig: getPhoneConfig(state),
  lastParcours: getLastParcours(state),
  userOnTunnel: isUserOnTunnel(state),
  isMarqueBlanche: isMarqueBlanche(state)
});

class PopinFormMarqueBlanche extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        ...formDefaults(
          props.clientIds,
          props.phoneConfig,
          props.fromContactServiceClient
        )
      }
    };
    this.onChangeFormInput = this.onChangeFormInput.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.constructRadioListSujetDemande = this.constructRadioListSujetDemande.bind(
      this
    );
    this.onSelectSujetDemande = this.onSelectSujetDemande.bind(this);
  }

  onSelectSujetDemande(e) {
    e.preventDefault();
    const formState = this.state.form;
    formState.sujetDemande = e.target.value;
    this.setState({
      form: formState
    });
  }

  setFormInputItem(e, name) {
    const formState = this.state.form;
    const formValidation = formState.formValidation;
    if (typeof formValidation[name] !== "undefined")
      formValidation[name] = FormHelper(e);

    formState[name] = e.target.value;
    formState.formValidation = formValidation;
    formState.isSubmittable = isFormValid(formState.formValidation);

    this.setState(
      {
        form: formState
      },
      () => {
        this.props.callbackSubmittable(this.state.form.isSubmittable);
      }
    );
  }

  componentDidMount() {
    const { fromContactServiceClient } = this.props;
    if (fromContactServiceClient) {
      const { form } = this.state;
      form.sujetDemande = this.currentPage();
      this.setState({
        form
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.resetForm !== this.props.resetForm && this.props.resetForm) {
      const form = {
        ...formDefaults(
          this.props.clientIds,
          this.props.phoneConfig,
          this.props.fromContactServiceClient
        )
      };
      this.setState({
        form
      });
    }
  }

  onCallbackInputPhone(e) {
    e.preventDefault();
    const name = e.target.name;
    this.setFormInputItem(e, name);
  }

  onChangeFormInput(e) {
    e.preventDefault();
    const name = e.target.name;
    this.setFormInputItem(e, name);
  }

  onFormSubmit(e) {
    e.preventDefault();
    if (isFormValid(this.state.form.formValidation)) {
      const fieldCtrl = field => {
        if (field !== "" && typeof field === "string") return field;
        return null;
      };

      const data = {
        civilite: null, //fieldCtrl(this.state.form.civilite),
        nom: fieldCtrl(this.state.form.nom),
        telephone: fieldCtrl(
          `+${
            this.props.phoneConfig.indicator
          }${this.state.form.telephone.replace(/\s/g, "")}`
        )
      };
      data.branche = "auto";

      // Callback data
      this.props.callback(data);
    }
  }

  currentPage = () => {
    return "auto";
  };

  constructRadioListSujetDemande() {
    const { t } = this.props;
    const lastParcours = this.props.lastParcours;
    if (lastParcours) {
      return [
        {
          selected: this.currentPage() === "auto",
          text: t("shared.auto"),
          value: "auto"
        },
        {
          selected: this.currentPage() === "sante",
          text: t("shared.sante"),
          value: "sante"
        },
        {
          selected: this.currentPage() === "assuranceVoyage",
          text: t("shared.voyage"),
          value: "assuranceVoyage"
        },
        {
          selected: this.currentPage() === "autre",
          text: t("shared.autre"),
          value: "autre"
        }
      ];
    }
    return [];
  }

  render() {
    const { t, isOnError, children } = this.props;
    const { form } = this.state;
    const { nom, telephone } = form;
    return (
      <StyledPopinFormMarqueBlanche>
        <FormWrapper onSubmit={this.onFormSubmit}>
          <Input
            inputClassName="nom"
            inputLabel={t("shared.nom")}
            inputName="nom"
            inputType="text"
            value={nom}
            onChangeCallback={e => this.onChangeFormInput(e)}
            tabIndex={1}
          />
          <PhoneInput
            inputLabel={t("shared.telephone")}
            inputName="telephone"
            inputRequired={true}
            value={telephone}
            onChangeCallbackPhone={e => this.onCallbackInputPhone(e)}
            inputError={isOnError}
            tabIndex={2}
          />
          {isOnError && (
            <ErrorGlobal errors={[t("errors.telephone.invalide")]} />
          )}
          {children}
        </FormWrapper>
      </StyledPopinFormMarqueBlanche>
    );
  }
}

export default compose(
  withNamespaces("translations"),
  connect(
    mapStateToProps,
    null
  )
)(PopinFormMarqueBlanche);
