import React, { Component } from "react";
import { withRouter } from "react-router";
import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import { Check, Circle } from "styled-icons/fa-solid";

// Components
import Title from "../../../../title";
import Paragraph from "../../../../paragraph";

// Store
import {
  getAssuranceType,
  getCurrentStep
} from "../../../../../store/tunnel/tunnel/selectors";

// Import icons & shape
import BaloonShape from "../../../../baloon-shape";

import theme from "../../../../../theme-style";

const souscriptionListItem = [
  "menuTunnel.maSouscription.estimerTarif",
  "menuTunnel.maSouscription.preparerContrat",
  "menuTunnel.maSouscription.payer",
  "menuTunnel.maSouscription.signer"
];

const mapStateToProps = state => ({
  activeStepId: getCurrentStep(state),
  assuranceType: getAssuranceType(state)
});

const Separator = () => (
  <svg className="separator">
    <line x1="0" y1="0" x2="100%" y2="0" />
  </svg>
);

class SouscriptionSteps extends Component {
  renderStepIconStatus(index) {
    const currentId = this.props.activeStepId;
    if (index < currentId) return <Check size={14} className="icon-status" />;
    if (index === currentId) return <Circle size={8} className="icon-status" />;
  }

  stepsListClassName = index => {
    const currentId = this.props.activeStepId;
    if (index < currentId) return "passed";
    if (index === currentId) return "current";
  };

  render() {
    const { t, assuranceType } = this.props;
    const { valiseSante, whiteCarIcon } = theme.icons.theme;
    const TopIcon = () => {
      if (assuranceType === "automobile")
        return <img src={whiteCarIcon} alt="voitureBlanche" />;
      if (assuranceType === "sante")
        return <img src={valiseSante} alt="valiseSante" />;
      return null;
    };
    return (
      <div className="ma-souscription">
        <div>
          <TopIcon />
          <div>
            <Title titleType={3} noMargin>
              {t("menuTunnel.maSouscription.label")}
            </Title>
            <Paragraph noMargin>
              {t("menuTunnel.maSouscription.suivreEvolutionAchat")}
            </Paragraph>
          </div>
        </div>
        <ul>
          {souscriptionListItem.map((listItem, index) => (
            <li key={index} className={this.stepsListClassName(index)}>
              <div>
                {index < souscriptionListItem.length &&
                  index > 0 && <Separator />}
                <div>
                  <BaloonShape>
                    <span>{index + 1}</span>
                  </BaloonShape>
                </div>
                {index < souscriptionListItem.length - 1 && <Separator />}
              </div>
              <div>
                <Paragraph textAlign="center" noMargin>
                  {t(listItem)}
                </Paragraph>
                {this.renderStepIconStatus(index)}
              </div>
            </li>
          ))}
        </ul>
      </div>
    );
  }
}

export default compose(
  withRouter,
  withNamespaces("translations"),
  connect(
    mapStateToProps,
    null
  )
)(SouscriptionSteps);
