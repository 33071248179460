import React from "react";
import { StyledWrapperContentHeader } from "../header/corporate/style";

const WrapperContentHeader = props => {
  const { children, ...rest } = props;
  return (
    <StyledWrapperContentHeader {...rest}>
      {children}
    </StyledWrapperContentHeader>
  );
};

export default WrapperContentHeader;
