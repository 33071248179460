import React from "react";
import Paragraph from "../paragraph";

const ParagraphBreaks = props => {
  const { className } = props;
  if (typeof props.text === "undefined" || props.text === "") {
    return null;
  } else {
    const hasToSplit = props.text.indexOf("\\n");
    if (hasToSplit) {
      const textSplit = props.text.split("\\n");
      return textSplit.map((paragraphContent, index) => {
        if (index !== textSplit.length - 1) {
          return (
            <Paragraph noMargin key={index} className={className}>
              {paragraphContent}
            </Paragraph>
          );
        } else {
          return (
            <Paragraph
              noMargin={props.noMargin || false}
              key={index}
              className={typeof className !== "undefined" ? className : ""}
            >
              {paragraphContent}
            </Paragraph>
          );
        }
      });
    } else {
      return (
        <Paragraph noMargin className={className}>
          {props.text}
        </Paragraph>
      );
    }
  }
};

export default ParagraphBreaks;
