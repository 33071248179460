import React from "react";
import { withNamespaces } from "react-i18next";
import slug from "limax";

// Import components
import RadioCardItem from "./components/item";
import Paragraph from "../../../paragraph";

// Import style
import { StyledRadioCardList } from "./style";

const RadioCardList = props => {
  const {
    t,
    data,
    listDirection = "row",
    isPaiement,
    onClickRadio,
    currentChoice,
    radioName,
    noLabel,
    oneLine,
    labelContainer,
    radioRequired = false,
    rcClassName,
    isOnWarning
  } = props;
  if (data)
    return (
      <StyledRadioCardList
        listDirection={listDirection}
        oneLine={oneLine}
        className="radios-wrapper"
      >
        {typeof labelContainer !== "undefined" && (
          <Paragraph className="container-label">{t(labelContainer)}</Paragraph>
        )}
        <div
          className={`radio-choices ${
            typeof rcClassName !== "undefined" ? rcClassName : ""
          }`}
        >
          {data.map((card, index) => (
            <RadioCardItem
              className={card.classname}
              listDirection={listDirection}
              key={index}
              name={slug(radioName)}
              data={card}
              radioChecked={currentChoice}
              onClick={e => onClickRadio(e, card.value, card.text)}
              isPaiement={isPaiement}
              noLabel={noLabel}
              oneLine={oneLine}
              radioRequired={radioRequired}
              isOnWarning={isOnWarning}
            />
          ))}
        </div>
      </StyledRadioCardList>
    );
  return null;
};

export default withNamespaces("translations")(RadioCardList);
