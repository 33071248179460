import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";

// Store
import { getCurrentStep } from "../../../../store/tunnel/tunnel/selectors";

// Import style
import { StyledProgressBar } from "./style";

const mapStateToProps = state => ({
  currentStep: getCurrentStep(state)
});

class ProgressBar extends Component {
  render() {
    const { currentStep } = this.props;
    return (
      <StyledProgressBar currentStep={currentStep}>
        <span />
      </StyledProgressBar>
    );
  }
}

export default compose(
  connect(
    mapStateToProps,
    null
  )
)(ProgressBar);
