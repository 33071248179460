import styled, { css } from "styled-components";
import PropTypes from "prop-types";

const smallSize = itemName => {
  let style = ``;
  style += `
    font-size: 1.6rem;
  `;
  if (itemName === "paragraph" || itemName === "radioList") {
    style += `
      line-height: 1.1875;
    `;
  }
  return style;
};

const normalSize = itemName => {
  let style = ``;
  style += `
    font-size: 1.8rem;
  `;
  if (itemName === "paragraph" || itemName === "radioList") {
    style += `
      line-height: 1.33;
    `;
  }
  return style;
};

const largeSize = itemName => {
  let style = ``;
  style += `
    font-size: 2rem;
  `;
  if (itemName === "paragraph" || itemName === "radioList") {
    style += `
      line-height: 1.2;
    `;
  }
  return style;
};

export const paragraphDefaultStyle = (itemName, selected) => {
  return css`
    font-family: ${props => props.theme.fonts.forms.name};
    font-weight: ${props => props.theme.fonts.forms.variants.default};
    ${props => {
      if (itemName === "paragraph") {
        let style = ``;
        const { size } = props;
        switch (size) {
          case "small":
            style += smallSize(itemName);
            break;
          case "large":
            style += largeSize(itemName);
            break;
          default:
            style += normalSize(itemName);
        }

        style += `
          color: ${props.theme.colors.themeColors.regular};
          margin-bottom: ${props.noMargin ? 0 : "20px"};
          text-align: ${
            props.textAlign === "left" ||
            props.textAlign === "center" ||
            props.textAlign === "right" ||
            props.textAlign === "justify"
              ? props.textAlign
              : "left"
          };
        `;
        return style;
      } else if (itemName === "radioList") {
        return `
          color: ${
            selected
              ? props.theme.colors.themeColors.thirdLight
              : props.theme.colors.themeColors.darkgrey
          };
          ${normalSize(itemName)};
        `;
      } else if (itemName === "formFieldLabel") {
        return `
          color: ${props.theme.colors.themeColors.lightgrey};
          ${smallSize(itemName)};
          margin-bottom: 10px;
        `;
      }
    }};
  `;
};

export const Paragraph = styled.p.attrs({
  className: "paragraph"
})`
  ${paragraphDefaultStyle("paragraph")};
  .linkButton {
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
  }
`;

Paragraph.propTypes = {
  children: PropTypes.node.isRequired,
  noMargin: PropTypes.bool,
  textAlign: PropTypes.string,
  size: PropTypes.string
};

export default Paragraph;
