import styled from "styled-components";
import breakpoint from "styled-components-breakpoint";

export const Deconnexion = styled.div`
  text-align: center;
  :hover {
    cursor: pointer;
  }
  cursor: default;
  display: flex;
  margin: auto;
  flex-direction: column;
  > button:hover {
    cursor: pointer;
  }
  > button {
    cursor: default;
    border-radius: 5px;
    display: flex;
    margin: auto;
    flex-direction: column;
  }
`;

export const HeaderWrapper = styled.header`
  background-color: ${props => props.theme.colors.themeColors.white};
  box-shadow: 0px 3px 15px 0px rgba(108, 122, 137, 0.2);
  box-sizing: border-box;
  height: 68px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 50000;
  .connectedDivMobile {
    background-color: green;
    border-radius: 5px;
    padding: 3px;
  }
  .title {
    display: flex;
    justify-content: center;
  }
  .wrapper-content {
    align-items: center;
    display: flex;
    flex-flow: row wrap;
    height: 100%;
    padding: 0 20px 0;
    ${breakpoint("mobile", "tablet")`
      padding: 0 ${props => props.theme.layouts.page.padding} 0;
    `};
    > div {
      display: flex;
      flex-direction: row;
      &:first-of-type,
      &:last-of-type {
        display: flex;
        flex-direction: row;
        flex: 0 0 auto;
        align-items: center;
      }
      &:last-of-type {
        justify-content: flex-end;
      }
      &:nth-child(2) {
        flex: 1 1 auto;
      }
      &.desktopMenu {
        display: flex;
        width: auto;
        flex: 1 1 auto;
        justify-content: flex-end;
        > div {
          a.linkButton {
            align-items: center;
            border-radius: 20px;
            box-sizing: border-box;
            cursor: pointer;
            display: flex;
            flex-flow: row wrap;
            font-size: 1.5rem;
            font-weight: 700;
            justify-content: center;
            margin: 0 auto 15px;
            max-width: 345px;
            padding: 10px 15px;
            text-decoration: none;
            width: 100%;
          }
        }
        > div.current .linkButton span span::after {
          content: "";
          display: block;
          height: 2px;
          width: 100%;
          position: absolute;
          bottom: -5px;
          left: 0;
          background-color: ${props =>
            props.theme.colors.themeColors.thirdLight};
        }
        > div:hover .linkButton span span {
          color: ${props => props.theme.colors.themeColors.dark};
        }
        > div {
          a.linkButton,
          button.linkButton {
            width: initial;
            margin: 0;
            span {
              border: none;
              padding: 0;
              color: ${props => props.theme.colors.themeColors.regular};
              height: 21px;
              line-height: 21px;
              display: flex;
              align-items: center;
              span {
                position: relative;
                font-weight: 400;
              }
            }
          }
          &:nth-last-child(2) button {
            padding-right: 30px;
          }
        }
        a {
          max-width: 165px !important;
          margin: auto 0;
          svg {
            margin-right: 10px;
          }
        }
      }
    }
  }
  &.header-corporate {
    .title {
      margin-right: 10px;
    }
  }
  &.header-tunnel {
    height: 71px;
    .title {
      font-size: 1.6rem;
      font-weight: 700;
      white-space: nowrap;
      vertical-align: middle;
    }
    .wrapper-content {
      height: calc(100% - 4px);
      padding-right: 10px;
      > div {
        &:last-of-type {
          .baloon-shape {
            background-color: ${props =>
              props.theme.colors.themeColors.altLight};
            padding: 0;
            height: 50px;
            width: 50px;
          }
        }
      }
    }
  }
  .only-logo-wrapper {
    height: 100%;
    width: 100%;
    .title,
    .only-logo {
      height: 100%;
    }
    .title {
      margin-right: 0;
    }
    .only-logo {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .alone {
    justify-content: center !important;
    width: 100% !important;
    .title {
      justify-content: inherit !important;
      margin-right: 0;
    }
  }
  ${breakpoint("desktop")`
    &.header-tunnel {
      .title {
        cursor: pointer;
      }
      .wrapper-content {
        > div {
          .title {
            &:last-child {
              margin-left: 20px;
            }
          }
        }
      }
    }
    &.header-corporate {
      .title {
        justify-content: flex-start;
      }
    }
    .wrapper-content {
      justify-content: space-between;
      > div:nth-child(2){
        .title{
          width:auto
        }
      }
      .desktopMenu {
        .linkButton {
          max-width: 100% !important;
          width: auto;
        }
      }
     
    }

  `};
  .axadiv {
    width: 100%;
    margin: auto;
    margin-top: 15px;
  }
  .Axa {
    margin-top: 5px;
    color: ${props => props.theme.colors.themeColors.regular};
  }
  .imageLogo {
    margin-right: 25px;
  }
  .textTitre2 {
    margin-top: 8px;
  }
  .espacePerso {
    display: flex;
    flex-direction: row;
  }
`;
