import React from "react";
import { Phone } from "styled-icons/fa-solid";
import IconBaloonO from "../../../../icons/baloon-o";
export const data = {
  content: t => {
    return {
      items: [
        {
          icon: <Phone size={16} />,
          label: t("contact.etreAppele"),
          openModal: true,
          modalName: "etreRappelle"
        },
        {
          icon: <IconBaloonO size={16} />,
          label: t("global.home"),
          goToHome: true
        }
      ]
    };
  }
};
