import React, { Component } from "react";
import { withRouter } from "react-router";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { compose } from "redux";
import { Spring, animated } from "react-spring";
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks
} from "body-scroll-lock";
import OutsideClickHandler from "react-outside-click-handler";
import { isChrome } from "react-device-detect";

// Selectors
import { getMainMenuState } from "../../../store/main-menu/selectors";
import {
  getUrlEspaceClient,
  getTheme
} from "../../../store/information/selectors";
import { isUserOnTunnel } from "../../../store/tunnel/tunnel/selectors";
// Actions
import { openCloseMainMenu } from "../../../store/main-menu/actions";

// Import style
import { StyledMainMenu } from "./style";

// Import components
import MenuCorporate from "./components/corporate";
import MenuTunnel from "./components/tunnel";

const mapStateToProps = state => ({
  mainMenuState: getMainMenuState(state),
  isUserOnTunnel: isUserOnTunnel(state),
  urlEspaceClient: getUrlEspaceClient(state),
  charteGraphique: getTheme(state)
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      openCloseMainMenu
    },
    dispatch
  )
});

export const activeId = id => {
  if (typeof id !== "undefined") if (id >= 0) return [id];
  return [];
};

class MainMenu extends Component {
  constructor(props) {
    super(props);
    this.targetRef = React.createRef();
    this.targetElement = null;
  }

  componentDidMount() {
    this.targetElement = this.targetRef.current;
  }

  componentWillUnmount() {
    clearAllBodyScrollLocks();
  }

  componentDidUpdate(prevProps) {
    if (this.props.mainMenuState.isOpened !== prevProps.mainMenuState.isOpened)
      if (this.props.mainMenuState.isOpened) {
        disableBodyScroll(this.targetElement);
      } else {
        enableBodyScroll(this.targetElement);
      }

    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.props.actions.openCloseMainMenu(false, -1);
    }
  }

  menuRender() {
    if (this.props.isUserOnTunnel) return <MenuTunnel />;
    return (
      <MenuCorporate
        urlEspaceClient={this.props.urlEspaceClient}
        helpArticles={this.props.helpArticles}
      />
    );
  }

  handleClickOutside = e => {
    if (e.target.closest(".burger-menu") === null)
      if (this.props.mainMenuState.isOpened)
        this.props.actions.openCloseMainMenu(false, -1);
  };

  render() {
    const { mainMenuState } = this.props;
    const { isOpened } = mainMenuState;
    return (
      <OutsideClickHandler onOutsideClick={this.handleClickOutside}>
        <Spring
          native
          key={"main-menu"}
          config={{ tension: 120, friction: 14, precision: 1 }}
          from={{ h: 0 }}
          to={{ h: isOpened ? 100 : 0 }}
        >
          {({ h }) => (
            <StyledMainMenu ref={this.targetElement}>
              <animated.div
                style={{
                  height: h.interpolate(
                    value => `calc(${value}vh - ${isChrome ? "68" : "68"}px)`
                  ),
                  overflow: "hidden auto"
                }}
                className="menu-container"
              >
                {this.menuRender()}
              </animated.div>
            </StyledMainMenu>
          )}
        </Spring>
      </OutsideClickHandler>
    );
  }
}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(MainMenu);
