import axios from "axios";
// import qs from "qs";
import API from "../../../api";

export async function get(assuranceType, mainToken, simulationId) {
  const params = {
    simulationId
  };
  return new Promise(resolve => {
    const url = API.tunnel.common.dateEffetContrat[assuranceType];
    const request = axios.get(url, {
      params,
      headers: {
        Authorization: `bearer ${mainToken}`
      }
    });
    return request
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        resolve({ error: true, res: err });
      });
  });
}

export async function post(
  assuranceType,
  mainToken,
  simulationId,
  dateEffetContrat
) {
  const params = {
    simulationId,
    dateEffetContrat: dateEffetContrat
  };
  return new Promise(resolve => {
    const url = API.tunnel.common.dateEffetContrat[assuranceType];
    const request = axios.post(url, null, {
      params,
      headers: {
        Authorization: `bearer ${mainToken}`
      }
    });
    return request
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        resolve({ error: true, res: err });
      });
  });
}
