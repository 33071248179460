import React from "react";
import { StyledSvgIcon } from "./style";
import PropTypes from "prop-types";

const SvgIcon = props => {
  const { width, height, viewBox, children, isActive, alt, className } = props;

  return (
    <StyledSvgIcon
      width={width}
      height={height}
      viewBox={viewBox}
      version="1.1"
      isActive={isActive}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      role="img"
      aria-label={alt}
      className={className}
    >
      <title>{alt}</title>
      {children}
    </StyledSvgIcon>
  );
};

SvgIcon.propTypes = {
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
  viewBox: PropTypes.string.isRequired,
  isActive: PropTypes.bool
};

export default SvgIcon;
