import styled from "styled-components";
import { CommonStyledMenuWrapper } from "../../style";

export const StyledMenuCorporate = styled(CommonStyledMenuWrapper)`
  background-color: ${props => props.theme.colors.themeColors.white};
  padding: 30px 0;
  .wrapper-content {
    display: flex;
    padding-bottom: 0;
    padding-top: 0;
    width: 800px;
  }
  .wrapper-content.list {
    align-items: start;
    justify-content: center;
  }
  .tous-aide {
    padding-top: 10px;
    .paragraph {
      margin-left: auto;
    }
    .linkButton {
      margin-bottom: 0;
      padding: 0;
    }
  }
  ul {
    color: ${props => props.theme.colors.themeColors.thirdLight};
    display: flex;
    flex-direction: column;
    margin-right: 30px;
    &:last-child {
      margin-right: 0;
    }
    li {
      margin-bottom: 20px;
      .link {
        padding: 0;
        > span {
          padding-left: 20px;
          position: relative;
          svg {
            left: 0;
            position: absolute;
            top: 3px;
          }
          span {
            line-height: 1.25;
          }
        }
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
    &:not(.unique-line) {
      //max-width:px;
    }
    &.unique-line {
      flex-direction: row;
      justify-content: center;
      margin-right: 0;
      width: 100%;
      li {
        margin-right: 30px;
        width: auto;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
  .join-us-by-phone {
    .linkButton {
      color: ${props => props.theme.colors.themeColors.thirdLight};
      font-size: 2.4rem;
      font-weight: ${props => props.theme.fonts.main.variants.bold};
      margin: 0;
      padding: 0;
      > span {
        border: none;
        line-height: 1;
        padding: 0;
      }
    }
  }
`;
