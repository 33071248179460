const models = () => {
  return {
    page: {
      padding: "20px",
      bgColor: "#f5f5f5"
    }
  };
};

export default models;
