import styled, { keyframes } from "styled-components";

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const StyledLoader = styled.div`
  align-items: center;
  background-color: ${props =>
    props.isMB ? "none" : props.theme.colors.themeColors.regular};
  border-radius: 50%;
  display: flex;
  height: 61px;
  justify-content: center;
  margin: 0 auto;
  width: 61px;
  > svg {
    color: ${props => props.theme.colors.themeColors.altLight};
    height: auto;
    width: 45%;
    animation: ${rotate} 2000ms linear infinite;
  }
  > img {
    margin: auto;
    height: auto;
    width: 85%;
    animation: ${rotate} 2000ms linear infinite;
  }
`;
