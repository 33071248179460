import { all, put, takeLatest } from "redux-saga/effects";
import * as a from "./actions";

function* setMainMenuState({ isOpened, openedName }) {
  try {
    yield put({
      type: a.OPENCLOSE_MAINMENU.SUCCESS,
      payload: {
        isOpened: isOpened,
        openedName: typeof openedName !== "string" ? null : openedName
      }
    });
  } catch (e) {
    yield put({
      type: a.OPENCLOSE_MAINMENU.FAILURE,
      error: e.message
    });
  }
}

export default all([
  takeLatest(a.OPENCLOSE_MAINMENU.REQUEST, setMainMenuState)
]);
