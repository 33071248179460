export const CREATE_OPPORTUNITE_PROSPECTION = {
  REQUEST: "opportuniteProspection/CREATE/REQUEST",
  SUCCESS: "opportuniteProspection/CREATE/SUCCESS",
  FAILURE: "opportuniteProspection/CREATE/FAILURE"
};

export const CREATE_OPPORTUNITE_PROSPECTION_WITH_COMMENT = {
  REQUEST: "opportuniteProspectionWithComment/CREATE/REQUEST",
  SUCCESS: "opportuniteProspectionWithComment/CREATE/SUCCESS",
  FAILURE: "opportuniteProspectionWithComment/CREATE/FAILURE"
};

export const RESET_NEW_OPPORTUNITE_PROSPECTION = "opportuniteProspection/RESET";

export const createOpportuniteProspection = ({
  civilite,
  nom,
  telephone,
  branche,
  isFromCoinAfrique
}) => {
  return {
    type: CREATE_OPPORTUNITE_PROSPECTION.REQUEST,
    civilite,
    nom,
    telephone,
    branche,
    isFromCoinAfrique
  };
};

export const createOpportuniteProspectionWithComment = ({
  civilite,
  nom,
  telephone,
  sujetDemande,
  branche,
  origineWebCode,
  comment
}) => {
  return {
    type: CREATE_OPPORTUNITE_PROSPECTION_WITH_COMMENT.REQUEST,
    civilite,
    nom,
    telephone,
    sujetDemande,
    branche,
    origineWebCode,
    comment
  };
};

export const resetOpportuniteProspection = () => {
  return {
    type: RESET_NEW_OPPORTUNITE_PROSPECTION
  };
};
