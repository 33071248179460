export const GET_TRANCHES_AGES = {
  REQUEST: "tranchesAges/GET/REQUEST",
  SUCCESS: "tranchesAges/GET/SUCCESS",
  FAILURE: "tranchesAges/GET/FAILURE"
};

export const getTranchesAges = () => {
  return {
    type: GET_TRANCHES_AGES.REQUEST
  };
};
