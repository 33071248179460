import { call, all, takeLatest, select } from "redux-saga/effects";
import * as a from "./actions";
import API from "../api";

// Import selects
import * as tunnelSelectors from "../tunnel/tunnel/selectors";
import * as informationSelectors from "../information/selectors";
// Services
import * as loggerService from "./services";

export function* postLogErrorApi({
  sagaInit,
  apiResponseUrl,
  statusResponse,
  dataResponse,
  token,
  params
}) {
  try {
    const mainToken = yield select(informationSelectors.getMainToken);
    const parcoursUtilisateur = yield select(tunnelSelectors.getParcours);
    const urlCourante = API.logger.main;
    const level = "error";
    let message = "\nSite Commercial Pays - Erreur API\n\n";
    message += "📢 Init saga\n\n";
    message += sagaInit;
    message += `\n--------------------------------\n\n`;
    message += "📢 URL de réponse de l'API\n\n";
    message += apiResponseUrl;
    message += `\n--------------------------------\n\n`;
    message += "📢 Statut de la requête\n\n";
    message += statusResponse;
    message += `\n--------------------------------\n\n`;
    message += "📢 Réponse de l'API\n\n";
    message += JSON.stringify(dataResponse, null, 4);
    message += `\n--------------------------------\n\n`;
    message += "📢 Token fourni\n\n";
    message += token;
    message += `\n--------------------------------\n\n`;
    message += "📢 Paramètres de l'API :\n\n";
    message += JSON.stringify(params, null, 4);
    message += `\n--------------------------------\n\n`;
    message += "🚀 Parcours utilisateur :\n";
    message += parcoursUtilisateur.join(" ➡ ");
    message += `\n--------------------------------\n\n`;
    message += "🚀 Url Courante appelé :\n";
    message += urlCourante;
    message += `\n--------------------------------\n\n`;
    message += "💻 User agent informations :\n";
    message += `Browser CodeName: ${navigator.appCodeName}\n`;
    message += `Browser Name: ${navigator.appName}\n`;
    message += `Browser Version: ${navigator.appVersion}\n`;
    message += `Cookies Enabled: ${navigator.cookieEnabled}\n`;
    message += `Browser Language: ${navigator.language}\n`;
    message += `Browser Online: ${navigator.onLine}\n`;
    message += `Platform: ${navigator.platform}\n`;
    message += `User-agent header: ${navigator.userAgent}\n`;
    message += `\n--------------------------------\n\n`;
    yield call(loggerService.post, mainToken, message, level);
  } catch (e) {}
}

export function* postLog({ message, level, info, error }) {
  try {
    let levelLog = "";
    if (level !== null && typeof level !== "undefined") {
      levelLog = level;
    } else {
      levelLog = "error";
    }
    // console.log("info :: ", info);
    // console.log("info json :: ", JSON.stringify(info));

    let infoError = info ? JSON.stringify(info) : "none";
    let reworkMessage = "\nSite Commercial Pays - Erreur React\n\n";
    const mainToken = yield select(informationSelectors.getMainToken);
    const parcoursUtilisateur = yield select(tunnelSelectors.getParcours);
    reworkMessage += message;
    reworkMessage += `\n--------------------------------\n\n`;
    reworkMessage += "💣 Error :\n";
    reworkMessage += typeof error !== "undefined" ? error.toString() : "";
    reworkMessage += `\n--------------------------------\n\n`;
    reworkMessage += "💣 Informations error :\n";
    reworkMessage += infoError;
    reworkMessage += "\nUrl courante : \n" + window.location.href;
    reworkMessage += `\n--------------------------------\n\n`;
    reworkMessage += "🚀 Parcours utilisateur :\n";
    reworkMessage += parcoursUtilisateur.join(" ➡ ");
    reworkMessage += `\n--------------------------------\n\n`;
    if (level == "error") {
      reworkMessage += "💻 User agent informations :\n";
      reworkMessage += `Browser CodeName: ${navigator.appCodeName}\n`;
      reworkMessage += `Browser Name: ${navigator.appName}\n`;
      reworkMessage += `Browser Version: ${navigator.appVersion}\n`;
      reworkMessage += `Cookies Enabled: ${navigator.cookieEnabled}\n`;
      reworkMessage += `Browser Language: ${navigator.language}\n`;
      reworkMessage += `Browser Online: ${navigator.onLine}\n`;
      reworkMessage += `Platform: ${navigator.platform}\n`;
      reworkMessage += `User-agent header: ${navigator.userAgent}\n`;
      reworkMessage += `\n--------------------------------\n\n`;
    }
    reworkMessage += "📢 Token fourni\n\n";
    reworkMessage += mainToken;
    reworkMessage += `\n--------------------------------\n\n`;
    yield call(loggerService.post, mainToken, reworkMessage, levelLog);
  } catch (e) {
    const message =
      "On a eu une erreur dans le saga du logger quelque chose n'a pas fonctionné" +
      "\nerror:" +
      e;
    console.log("message erreur logger saga :: ", message);
    // yield call(loggerService.post, mainToken, message, "warn", null, null);
    console.log("Erreur dans le saga du logger :: ", message);
    console.log("Infos :: ", e);
  }
}

export default all([takeLatest(a.POST_LOG, postLog)]);
