import React from "react";

// Components
import WrapperContent from "../wrapper-content";
import Loader from "../loader";

// Style
import { StyledPageLoader } from "./style";

const PageLoader = () => {
  return (
    <StyledPageLoader>
      <div>
        <WrapperContent>
          <Loader />
        </WrapperContent>
      </div>
    </StyledPageLoader>
  );
};

export default PageLoader;
