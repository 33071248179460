export const CREATE_INPUT_PROCESS = {
  REQUEST: "inputProcess/CREATE/REQUEST",
  SUCCESS: "inputProcess/CREATE/SUCCESS",
  FAILURE: "inputProcess/CREATE/FAILURE",
  ERROR: "inputProcess/CREATE/ERROR"
};

export const GET_INPUT_PROCESS = {
  REQUEST: "inputProcess/GET/REQUEST",
  SUCCESS: "inputProcess/GET/SUCCESS",
  FAILURE: "inputProcess/GET/FAILURE",
  ERROR: "inputProcess/GET/ERROR"
};

export const GET_BACK_INPUT_PROCESS = {
  REQUEST: "inputProcess/back/GET/REQUEST",
  SUCCESS: "inputProcess/back/GET/SUCCESS",
  FAILURE: "inputProcess/back/GET/FAILURE",
  ERROR: "inputProcess/back/GET/ERROR"
};

export const END_OF_READWRITE_INPUT_PROCESS = "inputProcess/endOfReadWrite";

export const REPRISE_INPUT_PROCESS = {
  REQUEST: "inputProcess/REPRISE/REQUEST",
  SUCCESS: "inputProcess/REPRISE/SUCCESS",
  FAILURE: "inputProcess/REPRISE/FAILURE",
  ERROR: "inputProcess/REPRISE/ERROR"
};

export const GO_BACK = "inputProcess/GO_BACK";
export const NO_NEED_REPRISE_SUCCESS = "inputProcess/NO_NEED_REPRISE/SUCCESS";
export const STOP_LOADING_INPUT_PROCESS = "inputProcess/stopLoading/SET";
export const SET_INPUT_PROCESS_READ_WRITE_INSIDE_COMPONENT =
  "inputProcess/readWriteInsideComponent/SET";
export const RESET_INPUT_PROCESS_READ_WRITE_INSIDE_COMPONENT =
  "inputProcess/readWriteInsideComponent/RESET";
export const RESET_INPUT_PROCESS = "inputProcess/RESET";

export const createInputProcess = (
  inputProcessType,
  assuranceType,
  saisieUser,
  askedInputs
) => {
  return {
    type: CREATE_INPUT_PROCESS.REQUEST,
    inputProcessType,
    assuranceType,
    saisieUser,
    askedInputs
  };
};

export const getBackInputProcess = (assuranceType, inputProcessCode) => {
  return {
    type: GET_BACK_INPUT_PROCESS.REQUEST,
    assuranceType,
    inputProcessCode
  };
};

export const getInputProcess = (
  inputProcessType,
  assuranceType,
  startInputProcess
) => {
  return {
    type: GET_INPUT_PROCESS.REQUEST,
    inputProcessType,
    assuranceType,
    startInputProcess
  };
};

export const endOfReadWriteInputProcess = (inputProcessType, assuranceType) => {
  return {
    type: END_OF_READWRITE_INPUT_PROCESS,
    inputProcessType,
    assuranceType
  };
};

export const resetInputProcess = () => {
  return {
    type: RESET_INPUT_PROCESS
  };
};

export const setInputProcessReadWriteInsideComponent = () => {
  return {
    type: SET_INPUT_PROCESS_READ_WRITE_INSIDE_COMPONENT
  };
};

export const repriseInputProcess = (assuranceType, processName) => {
  return {
    type: REPRISE_INPUT_PROCESS.REQUEST,
    assuranceType,
    processName
  };
};

export const stopLoadingInputProcess = () => {
  return {
    type: STOP_LOADING_INPUT_PROCESS
  };
};
