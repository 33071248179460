import React from "react";
import Title from "../../components/title";
import WrapperContent from "../../components/wrapper-content";

// Import icons
import { Plus } from "styled-icons/feather/Plus";

// Import style
import { StyledPopinContent } from "./style";

const PopinContent = props => {
  const { header, component, onClose } = props;
  const { title, icon } = header;

  return (
    <StyledPopinContent>
      <header>
        <div>
          {icon && icon}
          {title && (
            <Title titleType={1} noMargin>
              {title}
            </Title>
          )}
          <Plus className="close-icon" size={42} onClick={onClose} />
        </div>
      </header>
      <WrapperContent>{component}</WrapperContent>
    </StyledPopinContent>
  );
};

export default PopinContent;
