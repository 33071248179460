import React, { Component } from "react";
import { withRouter } from "react-router";
import { withNamespaces } from "react-i18next";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { compose } from "redux";

// Import components
import SouscriptionSteps from "./souscription-steps";
import ItemNavText from "../item-nav-text";

// Actions
import {
  openCloseModalEtreRappelle,
  openCloseModalSaveTunnelAuto
} from "../../../../../store/modal/actions";
import { resetFullTunnelVariables } from "../../../../../store/tunnel/tunnel/actions";

// Import data
import { data } from "./data-component";

// Import style
import { StyledMenuTunnel } from "./style";

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      openCloseModalEtreRappelle,
      openCloseModalSaveTunnelAuto,
      resetFullTunnelVariables
    },
    dispatch
  )
});

class MenuTunnel extends Component {
  constructor(props) {
    super(props);
    this.openModalEtreRappelle = this.openModalEtreRappelle.bind(this);
    this.openModaSaveTunnelAuto = this.openModaSaveTunnelAuto.bind(this);
  }

  openModaSaveTunnelAuto(e) {
    e.preventDefault();
    this.props.actions.openCloseModalSaveTunnelAuto(true);
  }

  openModalEtreRappelle(e) {
    e.preventDefault();
    this.props.actions.openCloseModalEtreRappelle(true);
  }

  returnNavFunction(e, modalName, goToHome) {
    let func;
    if (modalName) {
      switch (modalName) {
        case "etreRappelle":
          func = this.openModalEtreRappelle(e);
          break;
        case "saveTunnelAuto":
          func = this.openModaSaveTunnelAuto(e);
          break;
        default:
          break;
      }
    } else if (goToHome) {
      this.props.actions.resetFullTunnelVariables();

      this.props.history.push("/");
      func = () => {};
    }
    return func;
  }

  render() {
    const { t } = this.props;
    const dataContent = data.content(t);
    const { items } = dataContent;
    return (
      <StyledMenuTunnel>
        <div>
          <SouscriptionSteps />
          {items.map((menuItem, index) => {
            const { icon, label, url, modalName, goToHome } = menuItem;
            return (
              <div key={index} className="title">
                <ItemNavText
                  label={label}
                  icon={icon}
                  url={typeof url !== "undefined" ? url : null}
                  onClick={
                    typeof url !== "undefined"
                      ? () => {}
                      : e => this.returnNavFunction(e, modalName, goToHome)
                  }
                />
              </div>
            );
          })}
        </div>
      </StyledMenuTunnel>
    );
  }
}

export default compose(
  withRouter,
  withNamespaces("translations"),
  connect(
    null,
    mapDispatchToProps
  )
)(MenuTunnel);
