import Cookies from "js-cookie";
import JwtDecode from "jwt-decode";
import moment from "moment";

export const isTokenValid = () => {
  const cookieToken = Cookies.get("TOKEN_USER");
  const decoded = JwtDecode(cookieToken);

  let role =
    decoded["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"];
  let usersessionorganisationid =
    decoded["http://www.itc/core/claims/usersessionorganisationid"];
  let contactorganisationid =
    decoded["http://www.itc/core/claims/contactorganisationid"];

  if (role.indexOf("CLIENT") > -1) {
    Cookies.remove("TOKEN_USER");
    window.location.reload();
  }

  if (role.indexOf("Anonyme") > -1) {
    Cookies.remove("TOKEN_USER");
    window.location.reload();
  }

  if (
    role.indexOf("UtilisateurSysteme") > -1 &&
    usersessionorganisationid == undefined
  ) {
    Cookies.remove("TOKEN_USER");
    window.location.reload();
  }

  if (role.indexOf("CONTACT") > -1 && contactorganisationid == undefined) {
    Cookies.remove("TOKEN_USER");
    window.location.reload();
  }

  const tokenExpirationDate = new Date(decoded.exp * 1000);
  return moment(tokenExpirationDate).isSameOrAfter(new Date());
};

export const isCookieExists = tokenName => {
  return typeof Cookies.get(tokenName) !== "undefined";
};

export const extractUserInfos = () => {
  const cookieToken = Cookies.get("TOKEN_USER");
  try {
    const decoded = JwtDecode(cookieToken);
    const tokenKeys = Object.keys(decoded);
    const isGestionnaire =
      tokenKeys.indexOf("http://www.itc/core/claims/utilisateur/id") > -1;
    if (isGestionnaire) {
      return null;
    }
    return {
      civilite: "",
      prenom:
        decoded[
          "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname"
        ] || "",
      nom:
        decoded["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"] ||
        "",
      telephone:
        decoded[
          "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/mobilephone"
        ] || ""
    };
  } catch (e) {
    return null;
  }
};

const searchRole = role => {
  let hasRole = false;
  const cookieToken = getToken();
  if (cookieToken) {
    const decoded = JwtDecode(cookieToken);
    hasRole =
      decoded[
        "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
      ].indexOf(role) > -1;
    return hasRole;
  } else {
    return true;
  }
};

export const isClient = () => {
  return searchRole("CONTACT");
};

export const isUserBaloonPro = () => {
  return searchRole("Addit");
};

export const isAnonyme = () => {
  return searchRole("UtilisateurSysteme") || extractUserInfos === null;
};

export const getSessionId = token => {
  return new Promise((resolve, reject) => {
    const decoded = JwtDecode(token);
    resolve(decoded["jti"]);
    return null;
  });
};

export const getClientId = token => {
  if (token) {
    const decoded = JwtDecode(token);
    return decoded["http://www.itc/core/claims/contact/contactid"] || "";
  } else {
    return null;
  }
};

export const setToken = token => {
  const decoded = JwtDecode(token);
  const exp = new Date(decoded.exp * 1000);
  Cookies.set("TOKEN_USER", token, {
    expires: exp,
    secure: process.env.NODE_ENV === "production"
  });
};

export const getTunnelSante_choixQuestionsMedicales = () => {
  if (isCookieExists("USER_TUNNEL_SANTE_CHOIX_QUESTIONS_MEDICALES"))
    return Cookies.get("USER_TUNNEL_SANTE_CHOIX_QUESTIONS_MEDICALES");
  return null;
};

export const setTunnelSante_choixQuestionsMedicales = choice => {
  Cookies.set("USER_TUNNEL_SANTE_CHOIX_QUESTIONS_MEDICALES", choice, {
    expires: 365,
    secure: process.env.NODE_ENV === "production"
  });
};

export const getToken = () => {
  let token;
  if (isCookieExists("TOKEN_USER")) {
    if (!isTokenValid()) {
      token = null;
    } else {
      token = Cookies.get("TOKEN_USER");
    }
  } else {
    token = null;
  }
  return token;
};
