import * as a from "./actions";

const INITIAL_STATE = {
  success: false,
  loading: false,
  error: false,
  reseauxSoins: []
};

export function reseauxSoins(state = INITIAL_STATE, action) {
  switch (action.type) {
    case a.GET_RESEAUX_SOINS.REQUEST:
      return {
        ...state,
        success: false,
        loading: true,
        error: false
      };
    case a.GET_RESEAUX_SOINS.SUCCESS:
      return {
        ...state,
        success: true,
        loading: false,
        error: null,
        reseauxSoins: action.payload
      };
    case a.GET_RESEAUX_SOINS.FAILURE:
      return {
        ...state,
        success: false,
        error: true,
        loading: false
      };
    case a.RESET_RESEAUX_SOINS:
      return INITIAL_STATE;
    default:
      return state;
  }
}

export default reseauxSoins;
