import * as a from "./actions";
import appConstants from "../../configuration/constants";

const INITIAL_STATE = {
  success: false,
  loading: false,
  error: null,
  pays: null,
  nomActeurCommercial: null,
  horairesOuverture: [],
  adresse: null,
  coordonneesContact: [],
  partenairesAssureurs: [],
  locales: [],
  charteGraphique: "Baloon",
  isMarqueBlanche: false,
  token: null,
  operateursPaiementActifs: [],
  espaceClientUrl: null,
  branches: [],
  campaign: {
    popin: appConstants.campaign.popin
  },
  isBandeauCovid19: false,
  tracking: {
    fbTracking: false,
    bingTracking: false,
    demandeContact: false,
    tarif: false,
    paiement: false
  },
  homepage: null,
  isTarifFirst: process.env.REACT_APP_IS_TARIF_FIRST === "1",
  isTimeoutonLoaderDocument: process.env.REACT_APP_IS_TIMEOUT === "1",
  timeoutLoaderDocument: process.env.REACT_APP_TIMEOUT_UPLOAD_DOCUMENT * 1000,
  isGtagLog: false
};

export function configuration(state = INITIAL_STATE, action) {
  switch (action.type) {
    case a.SET_INFORMATION.REQUEST:
      return {
        ...state,
        success: false,
        loading: true,
        error: null,
        envURL: action.envURL
      };
    case a.SET_INFORMATION.SUCCESS:
      return {
        ...state,
        success: true,
        loading: false,
        error: null,
        pays: action.payload.pays,
        nomActeurCommercial: action.payload.nomActeurCommercial,
        horairesOuverture: action.payload.horairesOuverture,
        adresse: action.payload.adresse,
        coordonneesContact: action.payload.coordonneesContact,
        partenairesAssureurs: action.payload.partenairesAssureurs,
        locales: action.payload.locales,
        charteGraphique: action.payload.charteGraphique, //action.payload.charteGraphique,
        isMarqueBlanche: action.payload.isMarqueBlanche,
        token: action.payload.token,
        operateursPaiementActifs: [...action.payload.operateursPaiementActifs],
        espaceClientUrl: action.payload.espaceClientUrl.replace(
          "api/EspaceClient/v1/connexion",
          "EspaceClient/connexion"
        ),
        branches: action.payload.branches,
        campaign: state.campaign,
        isBandeauCovid19: action.payload.afficherBandeau,
        homepage: action.payload.homepageActeurCommercial,
        localisation: action.payload.localisation
      };
    case a.SET_INFORMATION.FAILURE:
      return {
        ...state,
        success: false,
        error: true,
        loading: false,
        campaign: state.campaign
      };
    case a.SET_TRACKING_FB.REQUEST:
      return {
        ...state,
        tracking: {
          ...state.tracking,
          fbTracking: true
        }
      };
    case a.SET_TRACKING_BING.REQUEST:
      return {
        ...state,
        tracking: {
          ...state.tracking,
          bingTracking: true
        }
      };
    case a.SET_TRACKING_DEMANDE_DE_CONTACT.REQUEST:
      return {
        ...state,
        tracking: {
          ...state.tracking,
          demandeContact: true
        }
      };
    case a.SET_TRACKING_TARIF.REQUEST:
      return {
        ...state,
        tracking: {
          ...state.tracking,
          tarif: true
        }
      };
    case a.SET_TRACKING_PAIEMENT.REQUEST:
      return {
        ...state,
        tracking: {
          ...state.tracking,
          paiement: true
        }
      };
    case a.SET_GTAG_LOG.REQUEST:
      return {
        ...state,
        isGtagLog: true
      };
    default:
      return state;
  }
}

export default configuration;
