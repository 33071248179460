import React, { Component } from "react";
import Cookies from "js-cookie";
import connect from "react-redux/es/connect/connect";
import LogOut from "../../assets/logout.png";

import { withNamespaces } from "react-i18next";
import { bindActionCreators, compose } from "redux";
import { forgotClient } from "../../store/client/actions";
import { getMainToken } from "../../store/information/selectors";
import { navigateInto } from "../../store/tunnel/tunnel/actions";
import { resetFullTunnelVariables } from "../../store/tunnel/tunnel/actions";
import { DeconnexionStyled } from "./style";
import { extractUserInfos } from "../../helpers/data/cookie-user";

const mapStateToProps = state => ({
  dataToken: extractUserInfos(state),
  mainToken: getMainToken(state)
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      forgotClient,
      navigateInto,
      resetFullTunnelVariables
    },
    dispatch
  )
});
export class Deconnexion extends Component {
  constructor(props) {
    super(props);
    this.onClickDeconnexion = this.onClickDeconnexion.bind(this);
  }
  onClickDeconnexion(e) {
    const { dataToken } = this.props;
    try {
      if (dataToken !== null && typeof dataToken !== "undefined") {
        this.props.actions.forgotClient(dataToken.telephone);
      }
      Cookies.remove("TOKEN_USER");
      this.props.actions.resetFullTunnelVariables();
      window.location.reload();
    } catch (e) {}
  }
  render() {
    const { dataToken, t } = this.props;
    if (dataToken !== null) {
      return (
        <DeconnexionStyled onClick={this.onClickDeconnexion}>
          {/* <LogOutCircle  size={20} /> */}
          <img alt="" src={LogOut} />
          <div className="infoBulleDeco">{t("shared.deconnexion")}</div>
        </DeconnexionStyled>
      );
    } else {
      return null;
    }
  }
}

export default compose(
  withNamespaces("translations"),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(Deconnexion);
