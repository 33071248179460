import React, { Component } from "react";
import { withRouter } from "react-router";
import Helmet from "react-helmet";
import { withNamespaces } from "react-i18next";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import { bindActionCreators } from "redux";
import Dialog, { DialogContent } from "@material/react-dialog";
import disableScroll from "disable-scroll";

// Actions
import {
  openCloseModalEtreRappelle
  // openCloseModalCampaign
} from "../../store/modal/actions";

// Import selectors
import { isModalEtreRappelleOpened } from "../../store/modal/selectors";
import { getTheme, isMarqueBlanche } from "../../store/information/selectors";

// Import components
import PopinContent from "../../components/popin-content";
import ContactServiceClient from "../../components/popin-content/components/contact-service-client";
import ContactServiceClientMarqueBlanche from "../../components/popin-content/components/contact-service-client-marque-blanche";

// Import page style
import { StyledPage } from "./style";

// Import icons
import { Phone } from "styled-icons/fa-solid";

// Medias
import mainBaloonShare from "../../assets/main-baloon-share.png";
import appConstants from "../../configuration/constants";

const mapStateToProps = state => ({
  etreRapelleModalOpened: isModalEtreRappelleOpened(state),
  charteGraphique: getTheme(state),
  isMarqueBlanche: isMarqueBlanche(state)
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      openCloseModalEtreRappelle
    },
    dispatch
  )
});

class Page extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resetContentPopin: false,
      isValidPopinCampaign: props.currentPopinCampaign !== null
    };
    this.onCloseEtreRappellePopin = this.onCloseEtreRappellePopin.bind(this);
    this.onCloseCampaignPopin = this.onCloseCampaignPopin.bind(this);
    this.onBlockScroll = this.onBlockScroll.bind(this);
  }

  componentDidMount() {
    const { actions, currentPopinCampaign } = this.props;
    if (currentPopinCampaign) {
      actions.openCloseModalCampaign(true);
    }
    window.dataLayer = window.dataLayer || [];
  }

  onBlockScroll(isLoading) {
    if (isLoading) {
      disableScroll.on();
    } else {
      disableScroll.off();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currentPopinCampaign !== this.props.currentPopinCampaign) {
      this.setState(
        {
          isValidPopinCampaign: this.props.currentPopinCampaign !== null
        },
        () => {}
      );
    }
  }

  onCloseEtreRappellePopin(e) {
    e.preventDefault();
    this.setState({
      resetContentPopin: !this.state.resetContentPopin
    });
    this.props.actions.openCloseModalEtreRappelle(
      !this.props.etreRapelleModalOpened
    );
  }

  onCloseCampaignPopin(e) {
    e.preventDefault();
    this.props.actions.openCloseModalCampaign(!this.props.campaignModalOpened);
  }

  renderTitle(titlePage) {
    if (!titlePage) {
      return "";
    }
    const { t } = this.props;
    const isMarqueBlanche = !window.location.href.includes("baloon");
    const text = isMarqueBlanche ? "global.siteNameDefault" : "global.siteName";
    let title = titlePage.trim();
    let tradText = t(text).trim();
    //if (tradText.indexOf(title) >= 0) {
    if (title == t("pageTitle.homePage")) {
      //title += ` ${appConstants.titleSeparator} ${tradText}`;
      return tradText;
    }
    return title;
  }

  getMetaTags(
    {
      titlePage,
      description,
      image,
      contentType,
      twitter,
      noCrawl,
      published,
      updated,
      category,
      tags
    },
    pathname
  ) {
    const { t } = this.props;
    const text =
      this.props.charteGraphique === "baloon"
        ? "global.siteName"
        : "global.siteNameDefault";
    const theTitle = titlePage ? titlePage.substring(0, 60) : t(text);
    const theDescription = description
      ? description.substring(0, 155)
      : t("global.description");
    const theImage = image ? image : mainBaloonShare;
    const metaTags = [
      { itemprop: "name", content: theTitle },
      { itemprop: "description", content: theDescription },
      { itemprop: "image", content: theImage },
      { name: "viewport", content: "width=device-width, user-scalable=no" },
      { name: "description", content: theDescription },
      { name: "twitter:card", content: "summary_large_image" },
      { name: "twitter:title", content: theTitle },
      { name: "twitter:description", content: theDescription },
      { name: "twitter:image:src", content: theImage },
      { property: "og:title", content: theTitle },
      { property: "og:type", content: contentType || "website" },
      { property: "og:url", content: "" + pathname },
      { property: "og:image", content: theImage },
      { property: "og:description", content: theDescription },
      { property: "og:site_name", content: t(text) }
    ];

    if (noCrawl) {
      metaTags.push({ name: "robots", content: "noindex, nofollow" });
    }

    if (published) {
      metaTags.push({ name: "article:published_time", content: published });
    }
    if (updated) {
      metaTags.push({ name: "article:modified_time", content: updated });
    }
    if (category) {
      metaTags.push({ name: "article:section", content: category });
    }
    if (tags) {
      metaTags.push({ name: "article:tag", content: tags });
    }
    // metaTags.push({ name: "fb:app_id", content: tags });

    return metaTags;
  }

  render() {
    const {
      t,
      location,
      children,
      id,
      className,
      etreRapelleModalOpened,
      campaignModalOpened,
      currentPopinCampaign,
      charteGraphique,
      isMarqueBlanche,
      ...rest
    } = this.props;
    const { resetContentPopin } = this.state;
    const baloonDemandeContact = (
      <ContactServiceClient
        onClose={e => this.onCloseEtreRappellePopin(e)}
        modalClosing={etreRapelleModalOpened}
        resetContent={resetContentPopin}
        callbackBlockScroll={this.onBlockScroll}
      />
    );
    const marqueBlancheDemandeContact = (
      <ContactServiceClientMarqueBlanche
        onClose={e => this.onCloseEtreRappellePopin(e)}
        modalClosing={etreRapelleModalOpened}
        resetContent={resetContentPopin}
        callbackBlockScroll={this.onBlockScroll}
      />
    );
    const demandeContact = isMarqueBlanche
      ? marqueBlancheDemandeContact
      : baloonDemandeContact;
    const textHeaderPoppin = isMarqueBlanche
      ? t("shared.serviceClientMarqueBlanche")
      : t("shared.serviceClientBaloon");
    return (
      <StyledPage {...rest}>
        <div id={id} className={className}>
          <Helmet
            title={
              rest.titlePage
                ? this.renderTitle(rest.titlePage)
                : t("global.siteName").trim()
            }
            htmlAttributes={{
              lang: "fr-CI",
              itemscope: undefined,
              itemtype: `http://schema.org/${rest.schema || "WebPage"}`
            }}
            link={[
              {
                rel: "canonical",
                href: `${appConstants.websiteUrl}${location.pathname}`
              }
            ]}
            meta={this.getMetaTags(
              rest,
              `${appConstants.websiteUrl}${location.pathname}`
            )}
          />
          {children}
        </div>
        {etreRapelleModalOpened && (
          <Dialog open={etreRapelleModalOpened} escapeKeyAction="">
            <DialogContent>
              <PopinContent
                blockScroll={true}
                header={{
                  title: textHeaderPoppin,
                  icon: <Phone size={16} />
                }}
                component={demandeContact}
                onClose={e => this.onCloseEtreRappellePopin(e)}
              />
            </DialogContent>
          </Dialog>
        )}
        {/*currentPopinCampaign && (
          <Dialog open={campaignModalOpened} escapeKeyAction="">
            <DialogContent />
          </Dialog>
        )*/}
      </StyledPage>
    );
  }
}

Page.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string
};

export default compose(
  withRouter,
  withNamespaces("translations"),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(Page);
