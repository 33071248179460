//ICONS TUNNEL ::
import NotFoundTruckB from "../../assets/baloon-icons/404truck.svg";
import NotFoundTruckD from "../../assets/default-icons/404truck.svg";

import BravoB from "../../assets/baloon-icons/bravo.svg";
import BravoD from "../../assets/default-icons/bravo.svg";
import CniB from "../../assets/baloon-icons/carte-identite.svg";
import CniD from "../../assets/default-icons/carte-identite.svg";

import ChoixUploadCGB from "../../assets/baloon-icons/choix-upload-cg.svg";
import ChoixUploadCGD from "../../assets/default-icons/choix-upload-cg.svg";

import MobileFormB from "../../assets/baloon-icons/mobile-form.svg";
import MobileFormD from "../../assets/default-icons/mobile-form.svg";

import PermisConduireB from "../../assets/baloon-icons/permis-conduire.svg";
import PermisConduireD from "../../assets/default-icons/permis-conduire.svg";

import SignB from "../../assets/baloon-icons/sign.svg";
import SignD from "../../assets/default-icons/sign.svg";

import ThumbupB from "../../assets/baloon-icons/thumbup.svg";
import ThumbupD from "../../assets/default-icons/thumbup.svg";

import UssdPaiementB from "../../assets/baloon-icons/ussd-paiement.svg";
import UssdPaiementD from "../../assets/default-icons/ussd-paiement.svg";

import ValiseSanteB from "../../assets/baloon-icons/valise-sante.svg";
import ValiseSanteD from "../../assets/default-icons/valise-sante.svg";

import VoitureTristeB from "../../assets/baloon-icons/voiture-triste.svg";
import VoitureTristeD from "../../assets/default-icons/voiture-triste.svg";

import WhiteCarB from "../../assets/baloon-icons/white-car.svg";
import WhiteCarD from "../../assets/default-icons/white-car.svg";

//GARANTIES ::
import Assistance247B from "../../assets/baloon-icons/garanties/assistance-24-7.svg";
import Assistance247D from "../../assets/default-icons/garanties/assistance-24-7.svg";

import BrisGlaceB from "../../assets/baloon-icons/garanties/bris-glace.svg";
import BrisGlaceD from "../../assets/default-icons/garanties/bris-glace.svg";

import ConducteurPassagersB from "../../assets/baloon-icons/garanties/conducteur-passagers.svg";
import ConducteurPassagersD from "../../assets/default-icons/garanties/conducteur-passagers.svg";

import IncendieB from "../../assets/baloon-icons/garanties/incendie.svg";
import IncendieD from "../../assets/default-icons/garanties/incendie.svg";

import RecoursDefenseB from "../../assets/baloon-icons/garanties/recours-defense.svg";
import RecoursDefenseD from "../../assets/default-icons/garanties/recours-defense.svg";

import RemboursementAnticipeB from "../../assets/baloon-icons/garanties/remboursement-anticipe.svg";
import RemboursementAnticipeD from "../../assets/default-icons/garanties/remboursement-anticipe.svg";

import ResponsabiliteCivileB from "../../assets/baloon-icons/garanties/responsabilite-civile.svg";
import ResponsabiliteCivileD from "../../assets/default-icons/garanties/responsabilite-civile.svg";

import VolB from "../../assets/baloon-icons/garanties/vol.svg";
import VolD from "../../assets/default-icons/garanties/vol.svg";

import RedirectionPaiementB from "../../assets/baloon-icons/redirection-paiement.svg";
import RedirectionPaiementD from "../../assets/default-icons/redirection-paiement.svg";

const garantiesBaloon = {
  Assistance247: Assistance247B,
  BrisGlace: BrisGlaceB,
  ConducteurPassagers: ConducteurPassagersB,
  Incendie: IncendieB,
  RecoursDefense: RecoursDefenseB,
  RemboursementAnticipe: RemboursementAnticipeB,
  ResponsabiliteCivile: ResponsabiliteCivileB,
  Vol: VolB
};

const garantiesDefault = {
  Assistance247: Assistance247D,
  BrisGlace: BrisGlaceD,
  ConducteurPassagers: ConducteurPassagersD,
  Incendie: IncendieD,
  RecoursDefense: RecoursDefenseD,
  RemboursementAnticipe: RemboursementAnticipeD,
  ResponsabiliteCivile: ResponsabiliteCivileD,
  Vol: VolD
};

const baloonIcons = {
  Media404: NotFoundTruckB,
  BravoIco: BravoB,
  IconCarteIdentite: CniB,
  UploadCG: ChoixUploadCGB,
  MobileForm: MobileFormB,
  IconPermisConduire: PermisConduireB,
  SignatureIco: SignB,
  Thumbup: ThumbupB,
  USSDPaiementIcon: UssdPaiementB,
  valiseSante: ValiseSanteB,
  VoitureTriste: VoitureTristeB,
  whiteCarIcon: WhiteCarB,
  RedirectionPaiement: RedirectionPaiementB,
  ...garantiesBaloon
};

const defaultIcons = {
  Media404: NotFoundTruckD,
  BravoIco: BravoD,
  IconCarteIdentite: CniD,
  UploadCG: ChoixUploadCGD,
  MobileForm: MobileFormD,
  IconPermisConduire: PermisConduireD,
  SignatureIco: SignD,
  Thumbup: ThumbupD,
  USSDPaiementIcon: UssdPaiementD,
  valiseSante: ValiseSanteD,
  VoitureTriste: VoitureTristeD,
  whiteCarIcon: WhiteCarD,
  RedirectionPaiement: RedirectionPaiementD,
  ...garantiesDefault
};

const icons = {
  brandsIcons: {
    default: defaultIcons,
    baloon: baloonIcons
  },
  theme: baloonIcons
};

export default icons;
