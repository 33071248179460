import React, { Component } from "react";
import Cookies from "js-cookie";
import Title from "../../title";
import { withNamespaces } from "react-i18next";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { compose } from "redux";
// Store
import { getMainMenuState } from "../../../store/main-menu/selectors";
import { openCloseMainMenu } from "../../../store/main-menu/actions";
import { openCloseModalEtreRappelle } from "../../../store/modal/actions";
import { resetFullTunnelVariables } from "../../../store/tunnel/tunnel/actions";
import { getAssuranceType } from "../../../store/tunnel/tunnel/selectors";
import { forgotClient } from "../../../store/client/actions";

import {
  isMarqueBlanche,
  getTheme
} from "../../../store/information/selectors";
// Helpers
import { isAnonyme, extractUserInfos } from "../../../helpers/data/cookie-user";

// Import components
import { Car, Phone, Medkit } from "styled-icons/fa-solid";
import BaloonShape from "../../baloon-shape";
import BurgerMenuIcon from "../../burger-menu-icon";
import WrapperViewResponsive from "../../wrapper-view-responsive";
import LinkButton from "../../link-button";
import BaloonAssurance from "../../icons/baloon-assurance";
import AxaLogo from "../../../assets/logo_axa.png";
import OleaLogo from "../../../assets/logo_olea.png";

const mapStateToProps = state => ({
  mainMenuState: getMainMenuState(state),
  dataToken: extractUserInfos(state),
  isNotConnected: isAnonyme(state),
  assuranceType: getAssuranceType(state),
  isMarqueBlanche: isMarqueBlanche(state),
  charteGraphique: getTheme(state)
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      openCloseMainMenu,
      openCloseModalEtreRappelle,
      resetFullTunnelVariables,
      forgotClient
    },
    dispatch
  )
});

class HeaderTunnel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      charteGraphique: this.props.charteGraphique
    };
    this.onDeconnexion = this.onDeconnexion.bind(this);
    this.openModal = this.openModal.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  goHomeAndReset(e) {
    e.preventDefault();
    this.props.actions.resetFullTunnelVariables();
    this.props.history.push("/");
  }

  goHomeAndResetOnAxa(e) {
    e.preventDefault();
    this.props.actions.resetFullTunnelVariables();
    this.props.history.push("/");
  }

  handleClick(id, e) {
    e.preventDefault();
    this.props.actions.openCloseMainMenu(
      !this.props.mainMenuState.isOpened ||
        this.props.mainMenuState.openedId !== id,
      this.props.mainMenuState.isOpened &&
      this.props.mainMenuState.openedId === id
        ? -1
        : id
    );
  }

  openModal(e) {
    e.preventDefault();
    const { actions } = this.props;
    actions.openCloseModalEtreRappelle(true);
  }

  onDeconnexion(e) {
    const { dataToken } = this.props;
    if (dataToken !== null && typeof dataToken !== "undefined") {
      this.props.actions.forgotClient(dataToken.telephone);
    }
    Cookies.remove("TOKEN_USER");
    this.props.history.push("/");
    window.location.reload();
  }

  render() {
    const { t, assuranceType, isMarqueBlanche, charteGraphique } = this.props;
    const titleHeaderLocale = `${t(
      `produit.${!isMarqueBlanche ? assuranceType : "automobileEnLigne"}`
    )}`;
    const isBaloon = !isMarqueBlanche;
    let Logo = null;
    if (!isBaloon) {
      if (charteGraphique === "axa" && typeof charteGraphique !== "undefined") {
        Logo = <img className="imageLogo" src={AxaLogo} />;
      } else if (charteGraphique === "olea") {
        Logo = <img className="imageLogo" width="75" src={OleaLogo} />;
      } else if (!window.location.href.includes("baloon")) {
        const currentUrl = window.location.href;
        if (currentUrl.includes("axa")) {
          Logo = <img className="imageLogo" src={AxaLogo} />;
        }
      }
      if (window.location.href.includes("olea")) {
        Logo = <img className="imageLogo" width="75" src={OleaLogo} />;
      }
    } else {
      Logo = <BaloonAssurance />;
    }
    const headerMobileTunnel = (
      <React.Fragment>
        {isBaloon && <BurgerMenuIcon />}
        <div>
          <Title
            titleType={4}
            noMargin
            textAlign="center"
            onClick={e => (!isBaloon ? this.goHomeAndResetOnAxa(e) : null)}
          >
            {!isBaloon && Logo}

            <div className="textTitre2">{titleHeaderLocale}</div>
          </Title>
        </div>
        {isBaloon && (
          <div className="mobileMenu">
            <div onClick={e => this.openModal(e)}>
              <BaloonShape>
                <Phone size={20} />
              </BaloonShape>
            </div>
          </div>
        )}
      </React.Fragment>
    );
    const IconDesktop = () => {
      if (assuranceType === "automobile") {
        return <Car />;
      } else if (assuranceType === "sante") {
        return <Medkit />;
      }
    };

    const headerDesktopTunnel = (
      <React.Fragment>
        <div className={this.props.charteGraphique === "axa" ? "axadiv" : ""}>
          {!isBaloon && (
            <Title
              noMargin
              textAlign="center"
              onClick={e => this.goHomeAndReset(e)}
            >
              {Logo}
            </Title>
          )}
          <Title
            titleType={4}
            noMargin
            textAlign="center"
            onClick={e => this.goHomeAndResetOnAxa(e)}
          >
            <div className="textTitre2">{titleHeaderLocale}</div>
          </Title>
        </div>
        {isBaloon && (
          <div className="desktopMenu">
            <div className={this.props.mainMenuState.isOpened ? "current" : ""}>
              <LinkButton
                config={{
                  type: "button",
                  style: "noStyle"
                }}
                onClick={e => this.handleClick(0, e)}
              >
                <IconDesktop />
                <span>{t("menuTunnel.maSouscription.label")}</span>
              </LinkButton>
            </div>
            <div className={this.props.charteGraphique === "axa" ? "Axa" : ""}>
              <LinkButton
                config={{
                  type: "button",
                  style: "plain"
                }}
                thirdStyle
                onClick={e => this.openModal(e)}
              >
                <Phone size={20} />
                {t("contact.appeller.numero")}
              </LinkButton>
            </div>
          </div>
        )}
        {!isBaloon && (
          <div className="desktopMenu">
            <div className={this.props.charteGraphique === "axa" ? "Axa" : ""}>
              <LinkButton
                config={{
                  type: "button",
                  style: "plain"
                }}
                thirdStyle
                onClick={e => this.openModal(e)}
              >
                <Phone size={20} />
                {t("contact.appeller.numero")}
              </LinkButton>
            </div>
          </div>
        )}
      </React.Fragment>
    );

    const headerTunnel = {
      mobile: headerMobileTunnel,
      desktop: headerDesktopTunnel
    };

    return <WrapperViewResponsive componentRender={headerTunnel} />;
  }
}

export default compose(
  withNamespaces("translations"),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(HeaderTunnel);
