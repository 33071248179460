import { call, all, put, takeLatest, select, take } from "redux-saga/effects";
import * as a from "./actions";
import Cookies from "js-cookie";

// Helpers
import { setToken } from "../../helpers/data/cookie-user";
// Actions
import * as questionsMedicalesActions from "../tunnel/sante/questions-medicales/actions";
// Import selects
import * as informationSelectors from "../information/selectors";
// Services
import * as userBaloonProService from "./services";
// Sagas
import * as loggerSagas from "../logger/saga";

function* getUserBaloonProInformations({ assuranceType, guid }) {
  try {
    const mainToken = yield select(informationSelectors.getMainToken);
    const userBaloonPro = yield call(
      userBaloonProService.get,
      mainToken,
      assuranceType,
      guid
    );
    if (!userBaloonPro.error) {
      const currentClientId = userBaloonPro.clientId;
      sessionStorage.setItem(
        "userBaloonProClientId",
        JSON.stringify(currentClientId)
      );
      Cookies.remove("USER_TUNNEL_SANTE_CHOIX_QUESTIONS_MEDICALES");
      let choix = false;
      if (currentClientId !== null) {
        yield put({
          type: questionsMedicalesActions.GET_CHOIX_QUESTIONS_MEDICALES.REQUEST,
          clientId: currentClientId
        });
        const waitSuccess = yield take(
          questionsMedicalesActions.GET_CHOIX_QUESTIONS_MEDICALES.SUCCESS
        );
        choix = waitSuccess.payload.data;
      }
      sessionStorage.setItem("userBaloonProSanteReponse", choix);
      yield put({
        type: a.GET_USER_BALOONPRO_INFO.SUCCESS,
        payload: userBaloonPro
      });
      setToken(userBaloonPro.token);
    } else {
      const errorRes = userBaloonPro.res.response;
      if (errorRes.request.status !== 400) {
        yield call(loggerSagas.postLogErrorApi, {
          sagaInit: "getUserBaloonProInformations",
          apiResponseUrl: errorRes.request.responseURL,
          statusResponse: errorRes.request.status,
          dataResponse: errorRes.data,
          token: mainToken,
          params: {
            guid
          }
        });
      }
      yield put({
        type: a.GET_USER_BALOONPRO_INFO.FAILURE,
        payload: true
      });
    }
  } catch (e) {}
}

export default all([
  takeLatest(a.GET_USER_BALOONPRO_INFO.REQUEST, getUserBaloonProInformations)
]);
