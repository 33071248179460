import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { compose } from "redux";

// Import components
import LinkButton from "../../../../link-button";

// Import actions
import { openCloseMainMenu } from "../../../../../store/main-menu/actions";
import { Phone } from "styled-icons/fa-solid";

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      openCloseMainMenu
    },
    dispatch
  )
});

class ItemNavText extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(e, url) {
    e.preventDefault();
    this.props.history.push(url);
    this.props.actions.openCloseMainMenu(false, -1);
  }

  render() {
    const { icon, label, url, onClick, isDesktopButtonModal } = this.props;
    if (url) {
      return (
        <span className="link" onClick={e => this.handleClick(e, url)}>
          <span>
            {icon}
            <span>{label}</span>
          </span>
        </span>
      );
    } else {
      if (isDesktopButtonModal)
        return (
          <LinkButton
            altStyle
            config={{ type: "button", style: "plain" }}
            onClick={onClick}
          >
            <Phone />
            {label}
          </LinkButton>
        );
      return (
        <span className="link" onClick={onClick}>
          <span>
            {icon}
            <span>{label}</span>
          </span>
        </span>
      );
    }
  }
}

export default compose(
  withRouter,
  connect(
    null,
    mapDispatchToProps
  )
)(ItemNavText);
