import {
  setToken,
  getToken,
  isClient,
  getClientId
} from "../../helpers/data/cookie-user";
import { call, select, all, put, takeLatest, take } from "redux-saga/effects";

import * as a from "./actions";
import * as questionsMedicalesActions from "../tunnel/sante/questions-medicales/actions";
import * as userBaloonProActions from "../user-baloonpro/actions";
import * as userBaloonProSelectors from "../user-baloonpro/selectors";
import * as parrainageSelectors from "../parrainage/selectors";
import * as reprisePaiementSelectors from "../reprise-paiement/selectors";
import * as signatureSelectors from "../tunnel/common/signature/selectors";
import * as parrainageActions from "../parrainage/actions";
import * as reprisePaiementActions from "../reprise-paiement/actions";
import * as signatureActions from "../tunnel/common/signature/actions";
import * as informationService from "./services";
import * as tunnelSagas from "../tunnel/tunnel/saga";
import * as loggerSagas from "../logger/saga";

function* getInformation({ envURL }) {
  try {
    const referrer = document.referrer;
    const information = yield call(informationService.get, envURL, referrer);
    if (!information.error) {
      const hasBranchSante = information.branches.indexOf("Sante") > -1;
      const userBaloonPro = yield select(
        userBaloonProSelectors.isUserBaloonPro
      );
      const parrainageGuid = yield select(
        parrainageSelectors.getParrainageGuid
      );
      const paiementGuid = yield select(
        reprisePaiementSelectors.getReprisePaiementGuid
      );
      const signatureGuid = yield select(
        signatureSelectors.getRepriseSignatureGuid
      );

      const guid = userBaloonPro.guid;
      if (!getToken()) {
        setToken(information.token);
      } else {
        if (guid) {
          yield put({
            type: userBaloonProActions.GET_USER_BALOONPRO_INFO.REQUEST,
            assuranceType: "sante",
            guid
          });
          const waitSuccess = yield take(
            userBaloonProActions.GET_USER_BALOONPRO_INFO.SUCCESS
          );
          if (waitSuccess.payload) {
          }
        } else if (isClient() && !paiementGuid && !signatureGuid) {
          if (hasBranchSante) {
            const clientId = getClientId(getToken());
            yield put({
              type:
                questionsMedicalesActions.GET_CHOIX_QUESTIONS_MEDICALES.REQUEST,
              clientId
            });
            const waitSuccess = yield take(
              questionsMedicalesActions.GET_CHOIX_QUESTIONS_MEDICALES.SUCCESS
            );
            let choix = waitSuccess.payload.data;
            if (choix === null) choix = "NeSaisPas";
            questionsMedicalesActions.setCookieChoixQuestionsMedicales(choix);
          }
          yield put({
            type: a.SET_INFORMATION.SUCCESS,
            payload: information
          });
        } else if (parrainageGuid !== null) {
          yield put({
            type: parrainageActions.CONNEXION_CLIENT.REQUEST,
            guid: parrainageGuid
          });
          const resultParrainageConnexion = yield take([
            parrainageActions.CONNEXION_CLIENT.FAILURE,
            parrainageActions.CONNEXION_CLIENT.SUCCESS
          ]);
          if (typeof resultParrainageConnexion.payload.error !== "undefined") {
            yield put({
              type: a.SET_INFORMATION.SUCCESS,
              payload: information
            });
            yield call(tunnelSagas.redirectToUrl, "/");
          } else {
            yield put({
              type: a.SET_INFORMATION.SUCCESS,
              payload: information
            });
            yield call(tunnelSagas.redirectToUrl, "/parrainage");
          }
        } else if (paiementGuid !== null) {
          yield put({
            type:
              reprisePaiementActions.GET_REPRISE_PAIEMENT_INFORMATION.REQUEST,
            guid: paiementGuid
          });
          const resultReprisePaiementConnexion = yield take([
            reprisePaiementActions.GET_REPRISE_PAIEMENT_INFORMATION.FAILURE,
            reprisePaiementActions.GET_REPRISE_PAIEMENT_INFORMATION.SUCCESS
          ]);
          if (
            typeof resultReprisePaiementConnexion.payload.error !== "undefined"
          ) {
            yield put({
              type: a.SET_INFORMATION.SUCCESS,
              payload: information
            });
            yield call(tunnelSagas.redirectToUrl, "/");
          } else {
            const paiementBranche = yield select(
              reprisePaiementSelectors.getBranche
            );
            yield put({
              type: a.SET_INFORMATION.SUCCESS,
              payload: information
            });

            yield call(
              tunnelSagas.redirectToUrl,
              `/assurance-${paiementBranche}/paiement`
            );
          }
        } else if (signatureGuid !== null) {
          yield put({
            type: signatureActions.GET_REPRISE_SIGNATURE_INFORMATION.REQUEST,
            guid: signatureGuid
          });
          const resultSignature = yield take([
            signatureActions.GET_REPRISE_SIGNATURE_INFORMATION.FAILURE,
            signatureActions.GET_REPRISE_SIGNATURE_INFORMATION.SUCCESS
          ]);
          if (typeof resultSignature.payload.error !== "undefined") {
            yield put({
              type: a.SET_INFORMATION.SUCCESS,
              payload: information
            });
            yield call(tunnelSagas.redirectToUrl, "/");
          } else {
            const signatureBranche = yield select(
              signatureSelectors.getBrancheRepriseSignature
            );
            yield put({
              type: a.SET_INFORMATION.SUCCESS,
              payload: information
            });
            yield call(
              tunnelSagas.redirectToUrl,
              `/assurance-${signatureBranche}/signature-electronique`
            );
          }
        }
      }
      if (
        (!isClient() || guid) &&
        !parrainageGuid &&
        !paiementGuid &&
        !signatureGuid
      ) {
        yield put({
          type: a.SET_INFORMATION.SUCCESS,
          payload: information
        });
        if (guid) {
          yield call(tunnelSagas.redirectToUrl, "/assurance-sante");
        }
      }
    } else {
      const errorRes = information.res.response;
      yield call(loggerSagas.postLogErrorApi, {
        sagaInit: "getInformation",
        apiResponseUrl: errorRes.request.responseURL,
        statusResponse: errorRes.request.status,
        dataResponse: errorRes.data,
        token: "pas de token",
        params: {
          envURL
        }
      });
      yield put({
        type: a.SET_INFORMATION.FAILURE,
        error: true
      });
    }
  } catch (e) {
    yield call(
      loggerSagas.postLog({
        message: "ERREUR SAGA INFORMATION",
        error: e,
        info: "saga api information",
        level: "error"
      })
    );
  }
}

export default all([takeLatest(a.SET_INFORMATION.REQUEST, getInformation)]);
