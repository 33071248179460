import { call, all, put, take, takeLatest, select } from "redux-saga/effects";
import * as a from "./actions";

// Import selects from INFORMATION
import * as informationSelectors from "../information/selectors";
import * as informationActions from "../information/actions";
import * as loggerSagas from "../logger/saga";
import * as opportuniteProspectionService from "./services";
import * as tagHelper from "../../helpers/tags/tagsHelper";

function* createOpportuniteProspection({
  civilite,
  nom,
  telephone,
  branche = null,
  isFromCoinAfrique = false
}) {
  try {
    let origineWebCode = yield select(
      informationSelectors.getCurrentVisitorOrigin
    );
    //let tracking = yield select(informationSelectors.getTracking);
    const mainToken = yield select(informationSelectors.getMainToken);

    const opportuniteProspection = yield call(
      opportuniteProspectionService.create,
      mainToken,
      civilite,
      nom,
      telephone,
      branche,
      origineWebCode
    );
    if (!opportuniteProspection.error) {
      yield put({
        type: a.CREATE_OPPORTUNITE_PROSPECTION.SUCCESS,
        payload: true
      });
      if (process.env.REACT_APP_LOCALE_AS_PROD) {
        tagHelper.addEventGoogleDemandeContact();
        yield take([
          informationActions.SET_TRACKING_DEMANDE_DE_CONTACT.REQUEST
        ]);
      }
    } else {
      const errorRes = opportuniteProspection.res.response;
      if (errorRes.request.status !== 400) {
        yield call(loggerSagas.postLogErrorApi, {
          sagaInit: "createOpportuniteProspection",
          apiResponseUrl: errorRes.request.responseURL,
          statusResponse: errorRes.request.status,
          dataResponse: errorRes.data,
          token: mainToken,
          params: {
            civilite,
            nom,
            telephone,
            branche
          }
        });
      }
      yield put({
        type: a.CREATE_OPPORTUNITE_PROSPECTION.FAILURE,
        payload: errorRes.data
      });
    }
  } catch (e) {}
}

function* createOpportuniteProspectionWithComment({
  civilite,
  nom,
  telephone,
  comment,
  branche,
  origineWebCode
}) {
  try {
    let origineWebCode = yield select(
      informationSelectors.getCurrentVisitorOrigin
    );

    const mainToken = yield select(informationSelectors.getMainToken);
    const opportuniteProspection = yield call(
      opportuniteProspectionService.createWithComment,
      mainToken,
      civilite,
      nom,
      telephone,
      branche,
      origineWebCode,
      comment
    );

    if (!opportuniteProspection.error) {
      yield put({
        type: a.CREATE_OPPORTUNITE_PROSPECTION_WITH_COMMENT.SUCCESS,
        payload: true
      });
      if (process.env.REACT_APP_LOCALE_AS_PROD) {
        tagHelper.addEventGoogleDemandeContact();
      }
    } else {
      const errorRes = opportuniteProspection.res.response;
      if (errorRes.request.status !== 400) {
        yield call(loggerSagas.postLogErrorApi, {
          sagaInit: "createOpportuniteProspection",
          apiResponseUrl: errorRes.request.responseURL,
          statusResponse: errorRes.request.status,
          dataResponse: errorRes.data,
          token: mainToken,
          params: {
            civilite,
            nom,
            telephone,
            branche,
            comment
          }
        });
      }
      yield put({
        type: a.CREATE_OPPORTUNITE_PROSPECTION_WITH_COMMENT.FAILURE,
        payload: errorRes.data
      });
    }
  } catch (e) {}
}

export default all([
  takeLatest(
    a.CREATE_OPPORTUNITE_PROSPECTION.REQUEST,
    createOpportuniteProspection
  ),
  takeLatest(
    a.CREATE_OPPORTUNITE_PROSPECTION_WITH_COMMENT.REQUEST,
    createOpportuniteProspectionWithComment
  )
]);
