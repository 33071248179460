import { call, all, put, takeLatest, select } from "redux-saga/effects";
import * as a from "./actions";

import * as paiementServices from "./services";
import * as informationSelectors from "../information/selectors";

import * as loggerSagas from "../logger/saga";
import * as tunnelSagas from "../tunnel/tunnel/saga";

import { setToken } from "../../helpers/data/cookie-user";

function* getReprisePaiementToken({ guid }) {
  try {
    const mainToken = yield select(informationSelectors.getMainToken);
    const paiementInformations = yield call(
      paiementServices.getPaiementRepriseInformations,
      guid,
      mainToken
    );
    if (!paiementInformations.error) {
      const tokenClient = paiementInformations.token;
      setToken(tokenClient);
      sessionStorage.setItem("isReprisePaiement", JSON.stringify(true));
      yield call(tunnelSagas.setIds, {
        quittanceId: paiementInformations.quittanceId,
        contratId: paiementInformations.contratId,
        versionContratId: paiementInformations.versionContratId
      });
      yield put({
        type: a.GET_REPRISE_PAIEMENT_INFORMATION.SUCCESS,
        payload: paiementInformations
      });
    } else {
      const errorRes = paiementInformations.res.response;
      if (errorRes.request.status !== 400) {
        yield call(loggerSagas.postLogErrorApi, {
          sagaInit: "paiementInformations",
          apiResponseUrl: errorRes.request.responseURL,
          statusResponse: errorRes.request.status,
          dataResponse: errorRes.data,
          token: mainToken,
          params: {
            guid
          }
        });
      }
      yield put({
        type: a.GET_REPRISE_PAIEMENT_INFORMATION.FAILURE,
        payload: { error: true }
      });
    }
  } catch (e) {}
}

export default all([
  takeLatest(
    a.GET_REPRISE_PAIEMENT_INFORMATION.REQUEST,
    getReprisePaiementToken
  )
]);
