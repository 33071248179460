/*
*
* ONE ARTICLE
*
 */
// Envoi de la requête
export const isGetArticleLoading = state => state.article.article.loading;

// Envoi succes
export const isGetArticleSuccessfull = state => state.article.article.success;

// Erreur de l'envoi
export const isGetArticleError = state => state.article.article.error;

// Get article data
export const getArticleData = state => state.article.article.data;

/*
*
* ARTICLES
*
 */
// Envoi de la requête
export const isGetArticlesLoading = state => state.article.articles.loading;

// Envoi succes
export const isGetArticlesSuccessfull = state => state.article.articles.success;

// Erreur de l'envoi
export const isGetArticlesError = state => state.article.articles.error;

// Erreur de l'envoi
export const getArticles = state => state.article.articles.data;

/*
*
* HELP ARTICLES
*
 */
// Envoi de la requête
export const isGetHelpArticlesLoading = state => state.article.aide.loading;

// Envoi succes
export const isGetHelpArticlesSuccessfull = state => state.article.aide.success;

// Erreur de l'envoi
export const isGetHelpArticlesError = state => state.article.aide.error;

// Erreur de l'envoi
export const getHelpArticles = state => state.article.aide.data;
